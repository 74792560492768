import { React, useState } from "react";

import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";
import EnhancedDueDiligence from "./EnhancedDueDiligence";
import Tasks from "./Tasks";
import Segments from "./Segments";
import Billing from "./Billing";
import Invoice from "./Invoice";
import BillingRates from "./BillingRates";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Templates = ({ partner, partnerData, partnerUser }) => {
  const [navValue, setNavValue] = useState(0);

  const handleNav = (event, newValue) => {
    setNavValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          onChange={handleNav}
          value={navValue}
          aria-label="Tabs where selection follows focus"
          selectionFollowsFocus
        >
          <Tab label="Tasks" {...a11yProps(0)} />
          <Tab label="Segments" {...a11yProps(1)} />
          {/*<Tab label="Billing items" {...a11yProps(3)} /> */}
          {/*<Tab label="Billing rates" {...a11yProps(4)} /> */}
          {/*<Tab label="Invoice" {...a11yProps(5)} /> */}
        </Tabs>
      </Box>

      <TabPanel value={navValue} index={0}>
        <Tasks partner={partner} partnerData={partnerData} partnerUser={partnerUser} />
      </TabPanel>

      <TabPanel value={navValue} index={1}>
        <Segments partner={partner} partnerData={partnerData} partnerUser={partnerUser} />
      </TabPanel>

      {/*
      <TabPanel value={navValue} index={3}>
        <Billing partner={partner} partnerData={partnerData} partnerUser={partnerUser} />
      </TabPanel>

      <TabPanel value={navValue} index={4}>
        <BillingRates partner={partner} partnerData={partnerData} partnerUser={partnerUser} />
      </TabPanel>

      <TabPanel value={navValue} index={5}>
        <Invoice partner={partner} partnerData={partnerData} partnerUser={partnerUser} />
      </TabPanel> */}
    </>
  );
};

export default Templates;
