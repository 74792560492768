import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";
import fileCheckLottie from "../../../components/lotties/file-check.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Close, Delete, Edit, KeyboardArrowLeft, PermMedia } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
  List,
  ListItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CountrySelect from "../../../components/form-elements/CountrySelect";
import FileUpload from "./identity/FileUpload";
import Notary from "./identity/Notary";
import Certifier from "./identity/Certifier";

const Identification = ({
  partner,
  caseUUID,
  entityData,
  caseData,
  activeView,
  getPerson,
  requestData,
  getRequestData,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: fileCheckLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [documentData, setDocumentData] = useState();

  const [uploadDocument, setUploadDocument] = useState(false);

  const documentTypeOptions = [
    { value: 1, label: "Passport" },
    { value: 2, label: "Driving License" },
    { value: 3, label: "National ID" },
    { value: 4, label: "Residence Permit" },
  ];

  const [documentType, setDocumentType] = useState();
  const [issuingCountry, setIssuingCountry] = useState();

  const [documentReference, setDocumentReference] = useState("");

  const [issuingDate, setIssuingDate] = useState();
  const [expiryDate, setExpiryDate] = useState();

  const [passwordProtected, setPasswordProtected] = useState(false);
  const [password, setPassword] = useState("");

  const [notarised, setNotarised] = useState(false);
  const [certified, setCertified] = useState(false);

  const [imagesRequired, setImagesRequired] = useState(0);
  const [documentUUID, setDocumentUUID] = useState("");

  const [image, setImage] = useState(1);
  const [frontFile, setFrontFile] = useState();
  const [backFile, setBackFile] = useState();

  const [notaryFirstName, setNotaryFirstName] = useState("");
  const [notaryMiddleNames, setNotaryMiddleNames] = useState("");
  const [notaryLastName, setNotaryLastName] = useState("");
  const [notaryEmail, setNotaryEmail] = useState("");
  const [notaryDiallingCode, setNotaryDiallingCode] = useState("");
  const [notaryPhoneCountry, setNotaryPhoneCountry] = useState("");
  const [notaryTelephoneNumber, setNotaryTelephoneNumber] = useState("");
  const [notaryPhoneValue, setNotaryPhoneValue] = useState("");
  const [notaryDate, setNotaryDate] = useState();

  const [certifierFirstName, setCertifierFirstName] = useState("");
  const [certifierMiddleNames, setCertifierMiddleNames] = useState("");
  const [certifierLastName, setCertifierLastName] = useState("");
  const [certifierCompanyName, setCertifierCompanyName] = useState("");
  const [certifierEmail, setCertifierEmail] = useState("");
  const [certifierDiallingCode, setCertifierDiallingCode] = useState("");
  const [certifierPhoneCountry, setCertifierPhoneCountry] = useState("");
  const [certifierTelephoneNumber, setCertifierTelephoneNumber] = useState("");
  const [certifierPhoneValue, setCertifierPhoneValue] = useState("");
  const [certifierDate, setCertifierDate] = useState();
  const [certifierQualification, setCertifierQualification] = useState("");

  const getDocumentData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/identity`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      setDocumentData(response.data);

      if (!response.data.uploaded) {
        setEditMode(true);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleSetDocumentData = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/identity`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          document_type: documentType,
          country: issuingCountry ? issuingCountry.value : "",
          reference: documentReference,
          password: passwordProtected ? password : "",
          issue_date: issuingDate ? issuingDate.format("YYYY-MM-DD") : "",
          expiry_date: expiryDate ? expiryDate.format("YYYY-MM-DD") : "",

          verification_required: !notarised && !certified ? true : false,
          notarised_document: notarised,
          certified_document: certified,

          notary_first_name: notaryFirstName,
          notary_middle_names: notaryMiddleNames,
          notary_last_name: notaryLastName,

          notary_email_address: notaryEmail,
          notary_dialling_code: notaryDiallingCode ? notaryDiallingCode : "",
          notary_telephone_country: notaryPhoneCountry ? notaryPhoneCountry : "",
          notary_telephone_number: notaryTelephoneNumber ? notaryTelephoneNumber : "",
          notary_date: notaryDate ? notaryDate.format("YYYY-MM-DD") : "",

          certifier_first_name: certifierFirstName,
          certifier_middle_names: certifierMiddleNames,
          certifier_last_name: certifierLastName,
          certifier_company_name: certifierCompanyName,
          certifier_email_address: certifierEmail,
          certifier_dialling_code: certifierDiallingCode ? certifierDiallingCode : "",
          certifier_telephone_country: certifierPhoneCountry ? certifierPhoneCountry : "",
          certifier_telephone_number: certifierTelephoneNumber ? certifierTelephoneNumber : "",
          certifier_date: certifierDate ? certifierDate.format("YYYY-MM-DD") : "",
          certifier_qualification: certifierQualification ? certifierQualification.toString() : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      setDocumentUUID(response.data.document_uuid);
      setImagesRequired(response.data.images_required);
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const processUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("frontFile", frontFile);
    formData.append("frontFileName", frontFile.name);

    if (imagesRequired > 1) {
      formData.append("backFile", backFile);
      formData.append("backFileName", backFile.name);
    }

    formData.append("document_uuid", documentUUID);
    formData.append("person_uuid", entityData.value.person_uuid);

    try {
      const response = await partner.post("/v1/entity/people/person/identity/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "hosted",
        },
        onUploadProgress: function (progressEvent) {},

        //withCredentials: true,
      });

      getDocumentData();
      getRequestData();
      getCase();

      displayToast("Document uploaded successfully", () => {
        setProcessing(false);
        setUploadDocument(false);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getDocumentData();
  }, []);

  return isLoaded ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="h6">Identity verification</Typography>
        </Box>
      </Box>

      {!editMode ? (
        <Box>
          <Box sx={{ backgroundColor: "#8BBF9F", p: 2 }}>
            <Typography
              variant="h6"
              sx={{ color: "#ffffff", textTransform: "uppercase", textAlign: "center" }}
            >
              Upload completed successfully
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Lottie speed={1.0} options={successOptions} height={100} width={100} />
            </Box>
          </Box>

          <Divider />

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
            <Button
              variant="contained"
              size=""
              color="primary"
              onClick={() => {
                activeView.value = 6;
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {documentUUID ? (
            <Box sx={{}}>
              <Box>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">
                      {imagesRequired > 1
                        ? image == 1
                          ? "Document Front"
                          : "Document Back"
                        : "Document Image"}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ p: 2 }}>
                    <Typography sx={{ fontSize: "13px", mb: 2 }}>
                      When submitting an image of your identity document, please ensure the
                      following requirements are met to ensure a clear and usable scan:
                    </Typography>
                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                      <b>High Quality:</b> The image should be high-resolution, aiming for a minimum
                      of 300 DPI (dots per inch) to ensure all text and images are sharp and
                      legible.
                    </Typography>
                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                      <b>Full Document Display:</b> Capture the entire document in the frame. The
                      edges of the document should not be cut off, and the image should not be
                      cropped too closely to the text or features of the document.
                    </Typography>
                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                      <b>Proper Lighting:</b> Ensure the document is well-lit, but avoid glare that
                      can obscure any part of the information. Natural light is often best, but
                      direct sunlight should be avoided to reduce glare.
                    </Typography>
                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                      <b>No Obstructions:</b> Make sure the document is free from fingers holding
                      the document, clips, or any other obstructions that can cover the information
                      and features of the ID.
                    </Typography>

                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                      <b>Focus and Clarity:</b> The document should be in focus, with no blurring
                      that might make any text or images indistinct. Hold the camera steady or use a
                      flat surface to avoid shake. Neutral Background: Place the document on a
                      contrasting, uniform, and single-colored background. This helps the edges of
                      the document stand out clearly and aids in document verification processes.
                    </Typography>

                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                      <b>Avoid Filters and Edits:</b> Do not use any filters or editing features
                      that alter the colors or details of the document. The image should represent
                      the document as accurately as possible.
                    </Typography>

                    <Typography sx={{ fontSize: "13px", mb: 2 }}>
                      <b>Orientation:</b> The document should be oriented correctly in the image; it
                      should not be upside down or rotated. These guidelines are designed to ensure
                      that the uploaded image of your identity document is clear, readable, and
                      suitable for verification purposes.
                    </Typography>

                    <Typography sx={{ fontSize: "13px" }}>
                      Always double-check your image before submission to ensure all details are
                      visible and accurate.
                    </Typography>
                  </Box>

                  <Divider />

                  <Box sx={{ p: 2 }}>
                    {image == 1 && <FileUpload setFile={setFrontFile} />}
                    {image == 2 && <FileUpload setFile={setBackFile} />}

                    {frontFile && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,

                          border: 1,
                          borderColor: "divider",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  fontWeight: 600,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    pl: 2,
                                  }}
                                >
                                  <PermMedia />
                                </Box>
                                <Box
                                  sx={{
                                    borderRight: 1,
                                    borderLeft: 1,
                                    borderColor: "divider",
                                    p: 1,
                                  }}
                                >
                                  <Typography variant="">
                                    {imagesRequired > 1 ? "Document front" : "Filename"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                fontSize: 13,
                                pl: 2,
                              }}
                            >
                              {frontFile.path}
                            </Box>
                          </Box>

                          <Box sx={{ pr: 1 }}>
                            <IconButton
                              title="Remove file"
                              onClick={() => {
                                setFrontFile(null);
                                setImage(1);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {backFile && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          border: 1,
                          borderTop: frontFile ? 0 : 1,
                          borderColor: "divider",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  fontWeight: 600,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    pl: 2,
                                  }}
                                >
                                  <PermMedia />
                                </Box>
                                <Box
                                  sx={{
                                    borderRight: 1,
                                    borderLeft: 1,
                                    borderColor: "divider",
                                    p: 1,
                                  }}
                                >
                                  <Typography variant="">
                                    {imagesRequired > 1 ? "Document back" : "Filename"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                fontSize: 13,
                                pl: 2,
                              }}
                            >
                              {backFile.path}
                            </Box>
                          </Box>

                          <Box sx={{ pr: 1 }}>
                            <IconButton
                              title="Remove file"
                              onClick={() => {
                                setBackFile(null);
                                setImage(2);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                      <Box>
                        {imagesRequired > 1 && image == 2 && (
                          <Button variant="outlined" onClick={() => setImage(1)}>
                            Back
                          </Button>
                        )}
                      </Box>

                      {imagesRequired == 1 ? (
                        <LoadingButton
                          disableElevation
                          onClick={processUpload}
                          loading={processing}
                          variant="contained"
                          color="success"
                        >
                          Upload
                        </LoadingButton>
                      ) : image == 1 ? (
                        <Button variant="contained" onClick={() => setImage(2)}>
                          Continue
                        </Button>
                      ) : (
                        <LoadingButton
                          disableElevation
                          onClick={processUpload}
                          loading={processing}
                          variant="contained"
                          color="success"
                        >
                          Upload
                        </LoadingButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 4,
                  backgroundColor: "#f0f0f0",
                  borderRadius: 10,
                  fontWeight: 500,
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Please upload a clear and legible document that confirms your identity.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  The document must be valid and not expired.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Accepted document types include:
                </Typography>

                <Box sx={{ pl: 4 }}>
                  <List
                    sx={{
                      listStyleType: "disc",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>Passport</ListItem>
                    <ListItem>Driving license</ListItem>
                    <ListItem>National ID</ListItem>
                    <ListItem>Residence Permit</ListItem>
                  </List>
                </Box>

                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Accepted File Formats: JPG, PNG, PDF Maximum File Size: 5MB
                </Typography>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="subtitle2">Issuing country</Typography>
                  <CountrySelect
                    label="Issuing country"
                    value={issuingCountry}
                    setValue={setIssuingCountry}
                  />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="subtitle2">Document type</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="document-type-label">Document type</InputLabel>
                    <Select
                      labelId="document-type-label"
                      id="document-type"
                      label="Document type"
                      onChange={(e) => setDocumentType(e.target.value)}
                    >
                      {documentTypeOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="subtitle2">Document reference</Typography>
                  <TextField
                    fullWidth
                    label="Document reference"
                    value={documentReference}
                    onChange={(e) => setDocumentReference(e.target.value)}
                  />
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Password protected images</Typography>
                  </Box>

                  <Box>
                    <ToggleButtonGroup
                      color="primary"
                      value={passwordProtected}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) setPasswordProtected(value);
                      }}
                    >
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>

                <Collapse in={passwordProtected} exit={!passwordProtected}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant="subtitle2">Document password</Typography>
                    <TextField
                      fullWidth
                      label="Document password"
                      value={password}
                      onChange={(e, value) => {
                        if (value !== null) setPassword(e.target.value);
                      }}
                    />
                  </Box>
                </Collapse>

                <Box
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (requestData.value.requirements.source_of_wealth) {
                          activeView.value = 4;
                        } else if (requestData.value.requirements.fatca) {
                          activeView.value = 3;
                        } else if (requestData.value.requirements.pep_declaration) {
                          activeView.value = 2;
                        }
                      }}
                      disableElevation
                    >
                      <KeyboardArrowLeft />
                    </Button>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      loading={processing}
                      onClick={(e) => {
                        handleSetDocumentData(e);
                      }}
                      color="success"
                    >
                      Continue
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Identification;
