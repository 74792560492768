import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import central from "../../../../api/central";

import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Typography,
  Box,
  Button,
  Divider,
  Card,
  Avatar,
  CardHeader,
  CardContent,
  IconButton,
  Alert,
  Collapse,
} from "@mui/material";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  Block,
  Close,
  ContentCopy,
  Diversity2,
  Edit,
  Fingerprint,
  Groups,
  Hail,
  Home,
  Language,
  LocalPolice,
  MailLock,
  MonitorHeartOutlined,
  Newspaper,
  Person,
  Phone,
  PriceCheck,
  ReportProblem,
  Schema,
  Work,
} from "@mui/icons-material";

import CountrySelect from "../../../../components/form-elements/CountrySelect";

const NewClient = ({ partner, partnerUser, partnerData, getClients }) => {
  const useAuthHeader = authHeader();
  const navigate = useNavigate();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [groupName, setGroupName] = useState("");

  const [segmentOptions, setSegmentOptions] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);

  const [ownerOptions, setOwnerOptions] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");

  const [entityType, setEntityType] = useState(0); // 1 - individual | 2 - company

  const [step, setStep] = useState(0);

  const [lookupCorporation, setLookupCorporation] = useState(true);
  const [lookupName, setLookupName] = useState("");
  const [corporationOptions, setCorporationOptions] = useState([]);
  const [selectedCorporation, setSelectedCorporation] = useState("");

  const [registrationTypeOptions, setRegistrationTypeOptions] = useState([]);
  const [registrationType, setRegistrationType] = useState("");
  const [corporationTypeLabel, setCorporationTypeLabel] = useState("");
  const [registrationTypeData, setRegistrationTypeData] = useState({});
  const [registrationName, setRegistrationName] = useState("");
  const [registrationReference, setRegistrationReference] = useState("");
  const [registrationDate, setRegistrationDate] = useState(null);
  const [registrationCountry, setRegistrationCountry] = useState();

  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleNames, setMiddleNames] = useState("");
  const [lastName, setLastName] = useState("");

  const [kycTemplateOptions, setKycTemplateOptions] = useState([]);
  const [selectedKycTemplate, setSelectedKycTemplate] = useState("");
  const [kybTemplateOptions, setKybTemplateOptions] = useState([]);
  const [selectedKybTemplate, setSelectedKybTemplate] = useState("");

  const [kycTemplateData, setKycTemplateData] = useState({});
  const [kybTemplateData, setKybTemplateData] = useState({});
  const [selectedOwnerData, setSelectedOwnerData] = useState({});
  const [selectedSegmentsData, setSelectedSegmentsData] = useState([]);

  const [complianceProfileOptions, setComplianceProfileOptions] = useState([]);
  const [selectedComplianceProfile, setSelectedComplianceProfile] = useState("");

  const [complianceProfileData, setComplianceProfileData] = useState({});

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getSegments = async () => {
    try {
      const response = await partner.post(
        `/v1/client/segments/data/list/options`,
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setSegmentOptions(response.data.entries);
      } else {
        setSegmentOptions([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getClientOwners = async () => {
    try {
      const response = await partner.post(
        `/v1/users/data/users/with-permission/options`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          permission: 13,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setOwnerOptions(response.data.entries);
      } else {
        setOwnerOptions([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getComplianceProfiles = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/data/list/options`,
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setComplianceProfileOptions(response.data.entries);
      } else {
        setComplianceProfileOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getComplianceProfileData = async (uuid) => {
    if (uuid == "") {
      return;
    }
    try {
      const response = await partner.post(
        `/v1/screening/profile/data`,
        JSON.stringify({
          profile_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setComplianceProfileData(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  const getKycTemplates = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/data/case-templates/list/kyc/active`,
        JSON.stringify({
          profile_uuid: selectedComplianceProfile,
          template_type: 1,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setKycTemplateOptions(response.data.entries);

        response.data.entries.map((template) => {
          if (template.is_default) {
            setSelectedKycTemplate(template.uuid);
          }
        });
      } else {
        setKycTemplateOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getKybTemplates = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/data/case-templates/list/kyb/active`,
        JSON.stringify({
          profile_uuid: selectedComplianceProfile,
          template_type: 2,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setKybTemplateOptions(response.data.entries);

        response.data.entries.map((template) => {
          if (template.is_default) {
            setSelectedKybTemplate(template.uuid);
          }
        });
      } else {
        setKybTemplateOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getTemplateData = async (uuid, templateType) => {
    if (uuid == "") {
      return;
    }
    try {
      const response = await partner.post(
        `/v1/screening/templates/data`,
        JSON.stringify({
          template_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (templateType === 1) {
        setKycTemplateData(response.data);
      } else if (templateType === 2) {
        setKybTemplateData(response.data);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getAccountOwnerData = async (uuid) => {
    if (uuid == "") {
      return;
    }
    try {
      const response = await partner.post(
        `/v1/users/data/user`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          partner_user_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setSelectedOwnerData(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  const getSegmentsData = async () => {
    try {
      const response = await partner.post(
        `/v1/client/segments/data/list/selected`,
        JSON.stringify({
          segments: selectedSegments ? selectedSegments : [],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setSelectedSegmentsData(response.data.entries);
      } else {
        setSelectedSegmentsData([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleLookupCorporations = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/corporations/lookup/options`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          search_terms: lookupName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setCorporationOptions(response.data.entries);
      } else {
        setCorporationOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getCorporationData = async () => {
    if (selectedCorporation == "") {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          uuid: selectedCorporation,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationName(response.data.name);
      setRegistrationReference(response.data.company_number);
      setRegistrationDate(moment(response.data.incorporation_date));
      setRegistrationCountry(response.data.country);
      setCorporationTypeLabel(response.data.company_type);
    } catch (err) {
      handleError(err);
    }
  };

  const getRegistrationTypeOptions = async () => {
    if (!registrationCountry) {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/types/options`,
        JSON.stringify({
          country: registrationCountry.value
            ? registrationCountry.value
            : registrationCountry.code
            ? registrationCountry.code
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRegistrationTypeOptions(response.data.entries);
      } else {
        setRegistrationTypeOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getRegistrationTypeData = async () => {
    if (registrationType == "") {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/type-data`,
        JSON.stringify({
          type_uuid: registrationType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationTypeData(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  const handleCreateClient = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/create/profile`,
        JSON.stringify({
          kyc_template: selectedKycTemplate,
          kyb_template: selectedKybTemplate,

          profile_uuid: selectedComplianceProfile,

          entity_type: entityType,
          entity_uuid: entityType == 1 ? "" : selectedCorporation,

          registration_type: registrationType,
          registration_reference: registrationReference,
          registration_date: registrationDate ? registrationDate.format("YYYY-MM-DD") : "",
          registration_country: registrationCountry?.value ? registrationCountry.value : "",
          registered_name: registrationName,

          title: title,
          first_name: firstName,
          middle_names: middleNames,
          last_name: lastName,
          segments: selectedSegments ? selectedSegments : [],
          account_manager_uuid: selectedOwner,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getClients();

      displayToast("Client created successfully", () => {
        setProcessing(false);
        navigate(`/client/${response.data.client_uuid}`);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getSegments();
    getClientOwners();
    getComplianceProfiles();
  }, []);

  useEffect(() => {
    setStep(0);
    setCorporationOptions([]);
    setSelectedCorporation("");
  }, [entityType]);

  useEffect(() => {
    if (selectedComplianceProfile != "") {
      getKycTemplates();
      getKybTemplates();
      getComplianceProfileData(selectedComplianceProfile);
    } else {
      setComplianceProfileData({});
    }
  }, [selectedComplianceProfile]);

  useEffect(() => {
    if (selectedKycTemplate != "") {
      getTemplateData(selectedKycTemplate, 1);
    } else {
      setKycTemplateData({});
    }
  }, [selectedKycTemplate]);

  useEffect(() => {
    if (selectedKybTemplate != "") {
      getTemplateData(selectedKybTemplate, 2);
    } else {
      setKybTemplateData({});
    }
  }, [selectedKybTemplate]);

  useEffect(() => {
    if (selectedOwner != "") {
      getAccountOwnerData(selectedOwner);
    } else {
      setSelectedOwnerData({});
    }
  }, [selectedOwner]);

  useEffect(() => {
    if (selectedSegments.length > 0) {
      getSegmentsData();
    } else {
      setSelectedSegmentsData([]);
    }
  }, [selectedSegments]);

  useEffect(() => {
    if (selectedCorporation != "") {
      getCorporationData();
    }
  }, [selectedCorporation]);

  useEffect(() => {
    if (registrationCountry) {
      getRegistrationTypeOptions();
    } else {
      setRegistrationTypeOptions([]);
      setRegistrationType("");
    }
  }, [registrationCountry]);

  useEffect(() => {
    if (registrationType != "") {
      getRegistrationTypeData();
    }
  }, [registrationType]);

  return (
    <>
      {isLoaded ? (
        <Box>
          <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle2">New client entity type</Typography>
            </Box>

            <Box>
              <ToggleButtonGroup
                color="primary"
                value={entityType}
                exclusive
                onChange={(e, newValue) => setEntityType(newValue)}
                aria-label="Entity type"
              >
                <ToggleButton value={1}>Individual</ToggleButton>
                <ToggleButton value={2}>Corporation</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          {entityType > 0 && <Divider />}

          {entityType == 1 && (
            <>
              {step == 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box sx={{}}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="body2">
                        <strong>What is the clients name?</strong>
                      </Typography>
                    </Box>

                    <Divider />

                    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="name-title-label">Title</InputLabel>
                        <Select
                          labelId="name-title"
                          id="name-title"
                          value={title}
                          label="Title"
                          onChange={(e) => setTitle(e.target.value)}
                        >
                          {titleOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="First name"
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />

                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Middle name(s)"
                        variant="outlined"
                        value={middleNames}
                        onChange={(e) => setMiddleNames(e.target.value)}
                        helperText="If applicable, separate with spaces"
                      />

                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Last name"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />

                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="contained" color="primary" onClick={() => setStep(1)}>
                          Continue
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

              {step == 1 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box sx={{ border: 1, borderColor: "divider" }}>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="body2">
                            <strong>Select a compliance profile to apply to the client</strong>
                          </Typography>
                        </Box>

                        <Divider />

                        <Box sx={{ p: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="corporation-compliance-profile-label">
                              Compliance profile
                            </InputLabel>

                            <Select
                              labelId="corporation-compliance-profile-label"
                              id="corporation-compliance-profile"
                              value={selectedComplianceProfile}
                              label="Compliance profile"
                              onChange={(e) => setSelectedComplianceProfile(e.target.value)}
                            >
                              {complianceProfileOptions?.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography variant="body2">{option.label}</Typography>
                                    </Box>
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Collapse
                        in={selectedComplianceProfile != ""}
                        exit={selectedComplianceProfile == ""}
                      >
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body2">
                              <strong>
                                Select a KYC case template to apply to the clients onboarding
                              </strong>
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel id="sales-owner-label">KYC template</InputLabel>

                              <Select
                                labelId="person-kyc-template"
                                id="person-kyc-template"
                                value={selectedKycTemplate}
                                label="KYC template"
                                onChange={(e) => setSelectedKycTemplate(e.target.value)}
                              >
                                {kycTemplateOptions?.map((option, index) => (
                                  <MenuItem key={index} value={option.uuid}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography variant="body2">{option.name}</Typography>
                                      </Box>

                                      <Box sx={{ display: "flex", gap: 2, marginLeft: "5%" }}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            gap: 1,
                                            color: "#999999",
                                          }}
                                        >
                                          {option.options?.email_verification && (
                                            <MailLock title="Email verification" />
                                          )}
                                          {option.options?.telephone_verification && (
                                            <Phone title="Email verification" />
                                          )}
                                          {option.options?.citizenship && <Language />}
                                          {option.options?.address_verification && (
                                            <Home title="Address validation" />
                                          )}
                                          {option.options?.identity && (
                                            <Fingerprint title="Identity verification" />
                                          )}
                                          {option.options?.proof_of_life && (
                                            <MonitorHeartOutlined />
                                          )}
                                          {option.options?.sanctions && <Block />}
                                          {option.options?.peps && <LocalPolice />}
                                          {option.options?.warnings && <ReportProblem />}
                                          {option.options?.adverse_media && <Newspaper />}
                                        </Box>
                                        {option.is_default ? (
                                          <Chip
                                            className="square-chip"
                                            label="Default"
                                            sx={{
                                              backgroundColor: "#2274A5",
                                              color: "#ffffff",
                                              textTransform: "uppercase",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={() => setStep(0)}
                          >
                            Back
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={() => setStep(2)}
                          >
                            Continue
                          </Button>
                        </Box>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {step == 2 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                  <Box>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body2">
                              <strong>Assign the client to an account manager</strong>
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel id="owner-label">Account manager</InputLabel>

                              <Select
                                labelId="owner"
                                id="owner"
                                value={selectedOwner}
                                label="Account manager"
                                onChange={(e) => setSelectedOwner(e.target.value)}
                              >
                                {ownerOptions?.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {selectedOwner && (
                                <Box
                                  title="Clear selection"
                                  onClick={() => {
                                    setSelectedOwner("");
                                  }}
                                  sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer", px: 1, pt: 1 }}
                                >
                                  <Close />
                                </Box>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body2">
                              <strong>Assign the client to relevant segments</strong>
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel id="sales-owner-label">Segments</InputLabel>

                              <Select
                                labelId="segments"
                                id="segments"
                                multiple
                                value={selectedSegments}
                                label="Sales owner"
                                onChange={(e) => setSelectedSegments(e.target.value)}
                              >
                                {segmentOptions?.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={() => setStep(1)}
                      >
                        Back
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={() => setStep(3)}
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}

              {step == 3 && (
                <>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Card variant="outlined" sx={{ p: 0 }}>
                          <CardHeader avatar={<Person />} title="Individual client" />

                          <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Name</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(0)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>

                              {firstName ? (
                                <Typography variant="h6">{`${title} ${firstName} ${middleNames} ${lastName}`}</Typography>
                              ) : (
                                <Alert severity="error" variant="outlined">
                                  No name provided
                                </Alert>
                              )}
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Compliance profile</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(1)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>

                              <Box sx={{ mt: 0 }}>
                                {complianceProfileData?.profile_name ? (
                                  <>
                                    <Typography variant="h6">
                                      {complianceProfileData?.profile_name}
                                    </Typography>
                                  </>
                                ) : (
                                  <Alert severity="error" variant="outlined">
                                    No compliance profile selected
                                  </Alert>
                                )}
                              </Box>
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">KYC template</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(1)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                              {kycTemplateData?.name ? (
                                <>
                                  <Typography variant="h6">{kycTemplateData?.name}</Typography>
                                  <Box sx={{ display: "flex", gap: 2, color: "#999999", mt: 1 }}>
                                    {kycTemplateData?.options?.email_verification && (
                                      <MailLock title="Email verification" />
                                    )}
                                    {kycTemplateData?.options?.telephone_verification && (
                                      <Phone title="Email verification" />
                                    )}
                                    {kycTemplateData?.options?.citizenship && <Language />}
                                    {kycTemplateData?.options?.address_verification && (
                                      <Home title="Address validation" />
                                    )}
                                    {kycTemplateData?.options?.identity && (
                                      <Fingerprint title="Identity verification" />
                                    )}
                                    {kycTemplateData?.options?.proof_of_life && (
                                      <MonitorHeartOutlined />
                                    )}
                                    {kycTemplateData?.options?.sanctions && <Block />}
                                    {kycTemplateData?.options?.peps && <LocalPolice />}
                                    {kycTemplateData?.options?.warnings && <ReportProblem />}
                                    {kycTemplateData?.options?.adverse_media && <Newspaper />}
                                  </Box>
                                </>
                              ) : (
                                <Alert severity="error" variant="outlined">
                                  No KYC template selected
                                </Alert>
                              )}
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Account manager</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(2)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                              {selectedOwnerData?.name ? (
                                <>
                                  <Typography variant="h6">{selectedOwnerData?.name}</Typography>
                                </>
                              ) : (
                                <Alert severity="info" variant="outlined">
                                  You have not assigned an account manager
                                </Alert>
                              )}
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Segments</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(2)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                              {selectedSegments?.length > 0 ? (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  {selectedSegmentsData?.map((segment, index) => (
                                    <Chip
                                      label={segment.label}
                                      className="square-chip"
                                      key={index}
                                      sx={{
                                        backgroundColor: segment.color,
                                        color: segment.text_color,
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  ))}
                                </Box>
                              ) : (
                                <Alert severity="info" variant="outlined">
                                  You have not assigned the client to any segments
                                </Alert>
                              )}
                            </Box>
                          </CardContent>
                        </Card>

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            variant="outlined"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={() => setStep(2)}
                          >
                            Back
                          </LoadingButton>

                          <LoadingButton
                            disableElevation
                            loading={processing}
                            variant="contained"
                            color="success"
                            sx={{ mt: 2 }}
                            onClick={(e) => handleCreateClient(e)}
                          >
                            Create Client
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}

          {entityType == 2 && (
            <>
              {step == 0 && (
                <>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body2">
                              <strong>How would you like to input the business information?</strong>
                            </Typography>
                          </Box>
                          <Divider />
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                            <ToggleButtonGroup
                              color="primary"
                              value={lookupCorporation}
                              exclusive
                              onChange={(e, newValue) => {
                                setLookupCorporation(newValue);
                                setRegistrationCountry(null);
                                setSelectedCorporation("");
                                setRegistrationType("");
                                setRegistrationTypeOptions([]);
                                setRegistrationTypeData({});
                                setRegistrationName("");
                                setRegistrationReference("");
                                setRegistrationDate(null);
                              }}
                              aria-label="Entry type"
                            >
                              <ToggleButton value={true}>Lookup corporation</ToggleButton>
                              <ToggleButton value={false}>Manual entry</ToggleButton>
                            </ToggleButtonGroup>

                            {lookupCorporation ? (
                              <Box width={"100%"}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Business name"
                                  variant="outlined"
                                  value={lookupName}
                                  onChange={(e) => setLookupName(e.target.value)}
                                />

                                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                  <LoadingButton
                                    disableElevation
                                    loading={processing}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleLookupCorporations(e)}
                                  >
                                    Lookup corporation
                                  </LoadingButton>
                                </Box>

                                {corporationOptions?.length > 0 && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      mt: 2,
                                      border: 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <Box sx={{ p: 2 }}>
                                      <Typography variant="body2">
                                        <strong>Select a result</strong>
                                      </Typography>
                                    </Box>
                                    <Divider />

                                    <Box sx={{ p: 2 }}>
                                      <FormControl fullWidth>
                                        <InputLabel id="corporation-options-label">
                                          Search results
                                        </InputLabel>

                                        <Select
                                          labelId="corporation-options-label"
                                          id="corporation-options"
                                          value={selectedCorporation}
                                          label="Select corporation"
                                          onChange={(e) => {
                                            setSelectedCorporation(e.target.value);
                                          }}
                                        >
                                          {corporationOptions?.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                              <Typography variant="body2">
                                                {option.label}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            ) : (
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                  <CountrySelect
                                    value={registrationCountry}
                                    setValue={setRegistrationCountry}
                                  />
                                </Grid>

                                {registrationCountry && (
                                  <>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                      {registrationTypeOptions?.length > 0 && (
                                        <>
                                          <FormControl fullWidth>
                                            <InputLabel id="owner-label">
                                              Registration type
                                            </InputLabel>

                                            <Select
                                              labelId="owner"
                                              id="owner"
                                              value={registrationType}
                                              label="Account manager"
                                              onChange={(e) => setRegistrationType(e.target.value)}
                                            >
                                              {registrationTypeOptions?.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </>
                                      )}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label={
                                          registrationType &&
                                          registrationTypeData?.registration_required
                                            ? "Registered name"
                                            : "Business name"
                                        }
                                        variant="outlined"
                                        value={registrationName}
                                        onChange={(e) => setRegistrationName(e.target.value)}
                                      />
                                    </Grid>

                                    {registrationType &&
                                      registrationTypeData.registration_required && (
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                          <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Registration reference"
                                            variant="outlined"
                                            value={registrationReference}
                                            onChange={(e) =>
                                              setRegistrationReference(e.target.value)
                                            }
                                          />
                                        </Grid>
                                      )}

                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale={locale}
                                      >
                                        <DatePicker
                                          fullWidth
                                          openTo="year"
                                          label={`${
                                            registrationType &&
                                            registrationTypeData.registration_required
                                              ? "Registration date"
                                              : "Start date"
                                          }`}
                                          value={registrationDate ? registrationDate : null}
                                          onChange={(date) => {
                                            setRegistrationDate(date);
                                          }}
                                          slotProps={{ textField: { fullWidth: true } }}
                                          maxDate={moment()}
                                        />
                                      </LocalizationProvider>
                                    </Grid>

                                    {registrationType && registrationTypeData.description && (
                                      <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Card variant="outlined">
                                          <CardHeader title="Registration type information" />
                                          <CardContent sx={{ pt: 0 }}>
                                            <Typography variant="body2">
                                              {registrationTypeData.description}
                                            </Typography>
                                          </CardContent>
                                        </Card>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </Grid>
                            )}
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                          <Button variant="contained" color="primary" onClick={() => setStep(1)}>
                            Continue
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              {step == 1 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box sx={{ border: 1, borderColor: "divider" }}>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="body2">
                            <strong>Select a compliance profile to apply to the client</strong>
                          </Typography>
                        </Box>

                        <Divider />

                        <Box sx={{ p: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="corporation-compliance-profile-label">
                              Compliance profile
                            </InputLabel>

                            <Select
                              labelId="corporation-compliance-profile-label"
                              id="corporation-compliance-profile"
                              value={selectedComplianceProfile}
                              label="Compliance profile"
                              onChange={(e) => setSelectedComplianceProfile(e.target.value)}
                            >
                              {complianceProfileOptions?.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography variant="body2">{option.label}</Typography>
                                    </Box>
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Collapse
                        in={selectedComplianceProfile != ""}
                        exit={selectedComplianceProfile == ""}
                      >
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body2">
                              <strong>
                                Select a KYB case template to apply to the clients onboarding
                              </strong>
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel id="sales-owner-label">KYB template</InputLabel>

                              <Select
                                labelId="corporation-kyb-template"
                                id="corporation-kyb-template"
                                value={selectedKybTemplate}
                                label="KYC template"
                                onChange={(e) => setSelectedKybTemplate(e.target.value)}
                              >
                                {kybTemplateOptions?.map((option, index) => (
                                  <MenuItem key={index} value={option.uuid}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography variant="body2">{option.name}</Typography>
                                      </Box>

                                      <Box sx={{ display: "flex", gap: 2, marginLeft: "5%" }}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            gap: 1,
                                            color: "#999999",
                                          }}
                                        >
                                          {option.options?.officers && <Groups />}
                                          {option.options?.ownership && <Schema />}
                                          {option.options?.address_verification && <Home />}
                                          {option.options?.sanctions && <Block />}
                                          {option.options?.peps && <LocalPolice />}
                                          {option.options?.warnings && <ReportProblem />}
                                          {option.options?.adverse_media && <Newspaper />}
                                          {option.options?.fatca && <Diversity2 />}
                                          {option.options?.corporate_documents && <ContentCopy />}
                                        </Box>
                                        {option.is_default ? (
                                          <Chip
                                            className="square-chip"
                                            label="Default"
                                            sx={{
                                              backgroundColor: "#2274A5",
                                              color: "#ffffff",
                                              textTransform: "uppercase",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      </Collapse>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Collapse
                        in={selectedComplianceProfile != ""}
                        exit={selectedComplianceProfile == ""}
                      >
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body2">
                              <strong>
                                Select a KYC case template to apply to the clients onboarding
                              </strong>
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel id="sales-owner-label">KYC template</InputLabel>

                              <Select
                                labelId="corporation-kyc-template"
                                id="corporation-kyc-template"
                                value={selectedKycTemplate}
                                label="KYC template"
                                onChange={(e) => setSelectedKycTemplate(e.target.value)}
                              >
                                {kycTemplateOptions?.map((option, index) => (
                                  <MenuItem key={index} value={option.uuid}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography variant="body2">{option.name}</Typography>
                                      </Box>

                                      <Box sx={{ display: "flex", gap: 2, marginLeft: "5%" }}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            gap: 1,
                                            color: "#999999",
                                          }}
                                        >
                                          {option.options?.email_verification && <MailLock />}
                                          {option.options?.telephone_verification && <Phone />}
                                          {option.options?.citizenship && <Language />}
                                          {option.options?.address_verification && <Home />}
                                          {option.options?.identity && <Fingerprint />}
                                          {option.options?.proof_of_life && (
                                            <MonitorHeartOutlined />
                                          )}
                                          {option.options?.sanctions && <Block />}
                                          {option.options?.peps && <LocalPolice />}
                                          {option.options?.warnings && <ReportProblem />}
                                          {option.options?.adverse_media && <Newspaper />}
                                          {option.options?.fatca && <Diversity2 />}
                                          {option.options?.pep_declaration && <Hail />}
                                          {option.options?.source_of_wealth && <PriceCheck />}
                                        </Box>
                                        {option.is_default ? (
                                          <Chip
                                            className="square-chip"
                                            label="Default"
                                            sx={{
                                              backgroundColor: "#2274A5",
                                              color: "#ffffff",
                                              textTransform: "uppercase",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      </Collapse>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button variant="outlined" color="primary" onClick={() => setStep(0)}>
                      Back
                    </Button>

                    <Button variant="contained" color="primary" onClick={() => setStep(2)}>
                      Continue
                    </Button>
                  </Box>
                </Box>
              )}

              {step == 2 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                  <Box>
                    <Grid container spacing={2}>
                      {ownerOptions?.length > 0 && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box sx={{ border: 1, borderColor: "divider" }}>
                            <Box sx={{ p: 2 }}>
                              <Typography variant="body2">
                                <strong>Assign the client to an account manager</strong>
                              </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{ p: 2 }}>
                              <FormControl fullWidth>
                                <InputLabel id="owner-label">Account manager</InputLabel>

                                <Select
                                  labelId="owner"
                                  id="owner"
                                  value={selectedOwner}
                                  label="Account manager"
                                  onChange={(e) => setSelectedOwner(e.target.value)}
                                >
                                  {ownerOptions?.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {selectedOwner && (
                                  <Box
                                    title="Clear selection"
                                    onClick={() => {
                                      setSelectedOwner("");
                                    }}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      cursor: "pointer",
                                      px: 1,
                                      pt: 1,
                                    }}
                                  >
                                    <Close />
                                  </Box>
                                )}
                              </FormControl>
                            </Box>
                          </Box>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body2">
                              <strong>Assign the client to relevant segments</strong>
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel id="sales-owner-label">Segments</InputLabel>

                              <Select
                                labelId="segments"
                                id="segments"
                                multiple
                                value={selectedSegments}
                                label="Sales owner"
                                onChange={(e) => setSelectedSegments(e.target.value)}
                              >
                                {segmentOptions?.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={() => setStep(1)}
                          >
                            Back
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={() => setStep(3)}
                          >
                            Continue
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}

              {step == 3 && (
                <>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card variant="outlined" sx={{ p: 0 }}>
                          <CardHeader avatar={<Work />} title="Corporate client" />

                          <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">
                                    <strong>Business information</strong>
                                  </Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(0)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>

                              {registrationName ? (
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                  <Box>
                                    <Typography variant="body2">Registered name</Typography>
                                    <Typography variant="h6">{`${registrationName}`}</Typography>
                                  </Box>

                                  {registrationCountry && (
                                    <Box>
                                      <Typography variant="body2">Registered country</Typography>
                                      <Typography variant="h6">{`${
                                        registrationCountry.label
                                          ? registrationCountry.label
                                          : registrationCountry.name
                                      }`}</Typography>
                                    </Box>
                                  )}

                                  {corporationTypeLabel && (
                                    <Box>
                                      <Typography variant="body2">Corporation type</Typography>
                                      <Typography variant="h6">{`${corporationTypeLabel}`}</Typography>
                                    </Box>
                                  )}

                                  {registrationReference && (
                                    <Box>
                                      <Typography variant="body2">Reference</Typography>
                                      <Typography variant="h6">{`${registrationReference}`}</Typography>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Alert severity="error" variant="outlined">
                                  No business information has been provided
                                </Alert>
                              )}
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Compliance profile</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(1)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>

                              <Box sx={{ mt: 0 }}>
                                {complianceProfileData?.profile_name ? (
                                  <>
                                    <Typography variant="h6">
                                      {complianceProfileData?.profile_name}
                                    </Typography>
                                  </>
                                ) : (
                                  <Alert severity="error" variant="outlined">
                                    No compliance profile selected
                                  </Alert>
                                )}
                              </Box>
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Screening templates</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(1)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                              <Box>
                                <Typography variant="body2">KYB template</Typography>
                                {kybTemplateData?.name ? (
                                  <>
                                    <Typography variant="h6">{kybTemplateData?.name}</Typography>
                                    <Box sx={{ display: "flex", gap: 2, color: "#999999", mt: 1 }}>
                                      {kybTemplateData?.options?.officers && (
                                        <Groups title="Officers and control" />
                                      )}
                                      {kybTemplateData?.options?.ownership && (
                                        <Schema title="Ownership" />
                                      )}
                                      {kybTemplateData?.options?.address_verification && (
                                        <Home title="Address verification" />
                                      )}
                                      {kybTemplateData?.options?.corporate_documents && (
                                        <ContentCopy title="Corporate documents" />
                                      )}
                                      {kybTemplateData?.options?.sanctions && (
                                        <Block title="Sanctions" />
                                      )}
                                      {kybTemplateData?.options?.peps && (
                                        <LocalPolice title="State ownership" />
                                      )}
                                      {kycTemplateData?.options?.warnings && (
                                        <ReportProblem title="Warnings" />
                                      )}
                                      {kybTemplateData?.options?.adverse_media && (
                                        <Newspaper title="Adverse media" />
                                      )}
                                    </Box>
                                  </>
                                ) : (
                                  <Alert severity="error" variant="outlined">
                                    No KYB template selected
                                  </Alert>
                                )}
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="body2">KYC template</Typography>
                                {kycTemplateData?.name ? (
                                  <>
                                    <Typography variant="h6">{kycTemplateData?.name}</Typography>
                                    <Box sx={{ display: "flex", gap: 2, color: "#999999", mt: 1 }}>
                                      {kycTemplateData?.options?.email_verification && (
                                        <MailLock title="Email verification" />
                                      )}
                                      {kycTemplateData?.options?.telephone_verification && (
                                        <Phone title="Telephone verification" />
                                      )}
                                      {kycTemplateData?.options?.citizenship && (
                                        <Language title="Birth country" />
                                      )}
                                      {kycTemplateData?.options?.address_verification && (
                                        <Home title="Address validation" />
                                      )}
                                      {kycTemplateData?.options?.identity && (
                                        <Fingerprint title="Identity verification" />
                                      )}
                                      {kycTemplateData?.options?.proof_of_life && (
                                        <MonitorHeartOutlined title="Proof of life" />
                                      )}
                                      {kycTemplateData?.options?.source_of_wealth && (
                                        <PriceCheck title="Source of wealth" />
                                      )}
                                      {kycTemplateData?.options?.sanctions && (
                                        <Block title="Sanctions" />
                                      )}
                                      {kycTemplateData?.options?.peps && (
                                        <LocalPolice title="PEPs" />
                                      )}
                                      {kycTemplateData?.options?.warnings && (
                                        <ReportProblem title="Warnings" />
                                      )}
                                      {kycTemplateData?.options?.adverse_media && (
                                        <Newspaper title="Adverse media" />
                                      )}
                                    </Box>
                                  </>
                                ) : (
                                  <Alert severity="error" variant="outlined">
                                    No KYC template selected
                                  </Alert>
                                )}
                              </Box>
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Account manager</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(2)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                              {selectedOwnerData?.name ? (
                                <>
                                  <Typography variant="h6">{selectedOwnerData?.name}</Typography>
                                </>
                              ) : (
                                <Alert severity="info" variant="outlined">
                                  You have not assigned an account manager
                                </Alert>
                              )}
                            </Box>

                            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body2">Segments</Typography>
                                </Box>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setStep(2)}
                                  aria-label="Edit"
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                              {selectedSegments?.length > 0 ? (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  {selectedSegmentsData?.map((segment, index) => (
                                    <Chip
                                      label={segment.label}
                                      className="square-chip"
                                      key={index}
                                      sx={{
                                        backgroundColor: segment.color,
                                        color: segment.text_color,
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  ))}
                                </Box>
                              ) : (
                                <Alert severity="info" variant="outlined">
                                  You have not assigned the client to any segments
                                </Alert>
                              )}
                            </Box>
                          </CardContent>
                        </Card>

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            variant="outlined"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={() => setStep(2)}
                          >
                            Back
                          </LoadingButton>

                          <LoadingButton
                            disableElevation
                            loading={processing}
                            variant="contained"
                            color="success"
                            sx={{ mt: 2 }}
                            onClick={(e) => handleCreateClient(e)}
                          >
                            Create Client
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}
        >
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default NewClient;
