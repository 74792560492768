import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";

import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Name from "./Name";
import DateOfBirth from "./DateOfBirth";
import Citizenship from "./Citizenship";
import Telephone from "./Telephone";

const PersonalDetails = ({
  partner,
  caseUUID,
  entityData,
  caseData,
  activeView,
  getPerson,
  requestData,
  getRequestData,
  getCase,
}) => {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
            <Typography sx={{ textAlign: "center" }} variant="h6">
              Please confirm your personal details
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}
          ></Box>
        </Box>
      </Box>
      <Box>
        <Name
          partner={partner}
          caseUUID={caseUUID}
          entityData={entityData}
          caseData={caseData}
          activeView={activeView}
          getPerson={getPerson}
          requestData={requestData}
          getRequestData={getRequestData}
          getCase={getCase}
        />
        <DateOfBirth
          partner={partner}
          caseUUID={requestData.case_uuid}
          entityData={entityData}
          caseData={caseData}
          activeView={activeView}
          getPerson={getPerson}
          requestData={requestData}
          getRequestData={getRequestData}
          getCase={getCase}
        />

        <Telephone
          partner={partner}
          caseUUID={requestData.case_uuid}
          entityData={entityData}
          caseData={caseData}
          activeView={activeView}
          getPerson={getPerson}
          requestData={requestData}
          getRequestData={getRequestData}
          getCase={getCase}
        />

        <Citizenship
          partner={partner}
          caseUUID={requestData.case_uuid}
          entityData={entityData}
          caseData={caseData}
          activeView={activeView}
          getPerson={getPerson}
          requestData={requestData}
          getRequestData={getRequestData}
          getCase={getCase}
        />

        <Divider />
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
          <Box>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => {
                activeView.value = 1;
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PersonalDetails;
