import { React, useState, useEffect } from "react";

import partner from "../../../../api/partner";
import central from "../../../../api/central";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Fade,
  Button,
} from "@mui/material";

import { Close, Edit } from "@mui/icons-material";

import CountrySelect from "../../../../components/form-elements/CountrySelect";

function RegisteredAddress({
  partnerUser,
  entityData,
  setManageProfile,
  getEntityData,
  getProfileData,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [addressCountry, setAddressCountry] = useState(
    entityData.value?.registered_address?.country?.code
      ? {
          value: entityData.value?.registered_address?.country?.code,
          name: entityData.value?.registered_address?.country?.name,
        }
      : { value: "", name: "" }
  );

  const [lookupDisplay, setLookupDisplay] = useState(false);
  const [lookupSupported, setLookupSupported] = useState(false);
  const [lookupValue, setLookupValue] = useState("");
  const [lookupOptions, setLookupOptions] = useState([]);
  const [formDisplay, setFormDisplay] = useState(false);
  const [stateRequired, setStateRequired] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState("");

  const [addressLine1, setAddressLine1] = useState(
    entityData.value?.registered_address?.line_one
      ? entityData.value?.registered_address?.line_one
      : ""
  );
  const [addressLine2, setAddressLine2] = useState(
    entityData.value?.registered_address?.line_two
      ? entityData.value?.registered_address?.line_two
      : ""
  );
  const [addressLine3, setAddressLine3] = useState(
    entityData.value?.registered_address?.line_three
      ? entityData.value?.registered_address?.line_three
      : ""
  );
  const [addressLine4, setAddressLine4] = useState(
    entityData.value?.registered_address?.line_four
      ? entityData.value?.registered_address?.line_four
      : ""
  );
  const [addressLine5, setAddressLine5] = useState(
    entityData.value?.registered_address?.line_five
      ? entityData.value?.registered_address?.line_five
      : ""
  );
  const [addressLine6, setAddressLine6] = useState(
    entityData.value?.registered_address?.line_six
      ? entityData.value?.registered_address?.line_six
      : ""
  );
  const [addressCity, setAddressCity] = useState(
    entityData.value?.registered_address?.city ? entityData.value?.registered_address?.city : ""
  );
  const [addressState, setAddressState] = useState(
    entityData.value?.registered_address?.state ? entityData.value?.registered_address?.state : ""
  );
  const [addressPostcode, setAddressPostcode] = useState(
    entityData.value?.registered_address?.code ? entityData.value?.registered_address?.code : ""
  );

  const handleLookupAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/lookup`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          country: addressCountry ? addressCountry.value : "",
          postcode: lookupValue,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setLookupOptions(response.data.entries);
      } else {
        //handleError("No addresses found");
        setLookupOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getSelectedAddress = async (addressUUID) => {
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          country: addressCountry ? addressCountry.value : "",
          uuid: addressUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      setAddressLine1(response.data.line_one);
      setAddressLine2(response.data.line_two);
      setAddressLine3(response.data.line_three);
      setAddressLine4(response.data.line_four);
      setAddressLine5(response.data.line_five);
      setAddressLine6(response.data.line_six);
      setAddressCity(response.data.city);
      setAddressPostcode(response.data.code);
      setLookupDisplay(false);
      setFormDisplay(true);

      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/address/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          address_type: 1,
          line_one: addressLine1,
          line_two: addressLine2,
          line_three: addressLine3,
          line_four: addressLine4,
          line_five: addressLine5,
          line_six: addressLine6,
          city: addressCity,
          postcode: addressPostcode,
          country: addressCountry ? addressCountry.value : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();

      displayToast("Address updated successfully", () => {
        setProcessing(false);
        setAddressCountry();
        setAddressLine1("");
        setAddressLine2("");
        setAddressLine3("");
        setAddressLine4("");
        setAddressLine5("");
        setAddressLine6("");
        setAddressCity("");
        setAddressState("");
        setAddressPostcode("");
        setFormDisplay(false);
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setLookupDisplay(false);
        setLookupValue("");
        setLookupOptions([]);
        setSelectedAddress("");
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    console.log("country changed");

    if (addressCountry == "" || addressCountry == undefined) {
      console.log("undefined or empty address country");
      setFormDisplay(false);
      setLookupDisplay(false);
      setLookupSupported(false);
      setStateRequired(false);
      return;
    }

    switch (addressCountry?.value) {
      case "GB":
        if (entityData.value?.registered_address?.line_one != "") {
          setLookupDisplay(false);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(true);
        } else {
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
        }

        break;
      case "JE":
        if (entityData.value?.registered_address?.line_one != "") {
          setLookupDisplay(false);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(true);
        } else {
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
        }
        break;
      case "GG":
        if (entityData.value?.registered_address?.line_one != "") {
          setLookupDisplay(false);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(true);
        } else {
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
        }
        break;
      case "IM":
        if (entityData.value?.registered_address?.line_one != "") {
          setLookupDisplay(false);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(true);
        } else {
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
        }
        break;
      case "US":
        if (entityData.value?.registered_address?.line_one != "") {
          setLookupDisplay(false);
          setLookupSupported(false);
          setStateRequired(true);
          setFormDisplay(true);
        } else {
          setLookupDisplay(false);
          setLookupSupported(false);
          setStateRequired(true);
          setFormDisplay(true);
        }

        break;
      default:
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setFormDisplay(true);
        break;
    }
  }, [addressCountry]);

  useEffect(() => {
    if (selectedAddress != "") {
      getSelectedAddress();
    }
  }, [selectedAddress]);

  return (
    <Box>
      <Box>
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <CountrySelect value={addressCountry} setValue={setAddressCountry} />

            <Collapse in={lookupDisplay} exit={!lookupDisplay}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 2,
                  border: 1,
                  borderColor: "divider",
                }}
              >
                <Typography variant="subtitle2">Address lookup</Typography>
                <TextField
                  label="Postcode / Zipcode"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setLookupValue(e.target.value);
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Box sx={{ minWidth: "300px;", width: "50%" }}>
                    {lookupOptions && lookupOptions.length > 0 && (
                      <Box>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Lookup results
                        </Typography>

                        <FormControl fullWidth>
                          <InputLabel id="address-results-label">Select an address</InputLabel>
                          <Select
                            labelId="address-results-label"
                            id="address-results"
                            value={selectedAddress}
                            label="Select an address"
                            onChange={(e) => getSelectedAddress(e.target.value)}
                          >
                            {lookupOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          setLookupDisplay(false);
                          setFormDisplay(true);
                        }}
                      >
                        Manual entry
                      </Button>
                    </Box>
                    <Box>
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => handleLookupAddress(e)}
                      >
                        Search
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Collapse>

            <Collapse in={formDisplay} exit={!formDisplay}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  label="Address line 1"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />

                <Collapse
                  in={addressLine1 != "" ? true : addressLine2 != "" ? true : false}
                  exit={(addressLine1 == "") & (addressLine2 == "") ? true : false}
                >
                  <TextField
                    label="Address line 2"
                    variant="outlined"
                    sx={{ mt: 1 }}
                    fullWidth
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                  />
                </Collapse>
                <Collapse
                  in={addressLine2 != "" ? true : addressLine3 != "" ? true : false}
                  exit={(addressLine2 == "") & (addressLine3 == "") ? true : false}
                >
                  <TextField
                    label="Address line 3"
                    variant="outlined"
                    sx={{ mt: 1 }}
                    fullWidth
                    value={addressLine3}
                    onChange={(e) => setAddressLine3(e.target.value)}
                  />
                </Collapse>

                <Collapse
                  in={addressLine3 != "" ? true : addressLine4 != "" ? true : false}
                  exit={(addressLine3 == "") & (addressLine4 == "") ? true : false}
                >
                  <TextField
                    label="Address line 4"
                    variant="outlined"
                    sx={{ mt: 1 }}
                    fullWidth
                    value={addressLine4}
                    onChange={(e) => setAddressLine4(e.target.value)}
                  />
                </Collapse>
                <Collapse
                  in={addressLine4 != "" ? true : addressLine5 != "" ? true : false}
                  exit={(addressLine4 == "") & (addressLine5 == "") ? true : false}
                >
                  <TextField
                    label="Address line 5"
                    variant="outlined"
                    sx={{ mt: 1 }}
                    fullWidth
                    value={addressLine5}
                    onChange={(e) => setAddressLine5(e.target.value)}
                  />
                </Collapse>
                <Collapse
                  in={addressLine5 != "" ? true : addressLine6 != "" ? true : false}
                  exit={(addressLine5 == "") & (addressLine6 == "") ? true : false}
                >
                  <TextField
                    label="Address line 6"
                    variant="outlined"
                    sx={{ mt: 1 }}
                    fullWidth
                    value={addressLine6}
                    onChange={(e) => setAddressLine6(e.target.value)}
                  />
                </Collapse>
                <TextField
                  label="City"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  value={addressCity}
                  onChange={(e) => setAddressCity(e.target.value)}
                />

                <Collapse in={stateRequired} exit={!stateRequired}>
                  <TextField
                    label="State"
                    variant="outlined"
                    sx={{ mt: 1 }}
                    fullWidth
                    value={addressState}
                    onChange={(e) => setAddressState(e.target.value)}
                  />
                </Collapse>
                <TextField
                  label="Postcode/Zipcode"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  value={addressPostcode}
                  onChange={(e) => setAddressPostcode(e.target.value)}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  {lookupSupported && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        setLookupDisplay(true);
                        setFormDisplay(false);
                      }}
                    >
                      Lookup address
                    </Button>
                  )}
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => handleUpdate(e)}
                  >
                    Save address
                  </LoadingButton>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RegisteredAddress;
