import { React, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import { LoadingButton } from "@mui/lab";
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

function RegistrationStatus({ entityData, setManageProfile, getEntityData, getProfileData }) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);

  const [registrationStatus, setRegistrationStatus] = useState(
    entityData.value?.registration_status
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/registration/status`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          registration_status: parseInt(registrationStatus),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();

      displayToast("Registration name updated successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleChange = (event) => {
    setRegistrationStatus(event.target.value);
  };

  return (
    <Box>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Registration Status"
          name="status"
          value={registrationStatus}
          onChange={handleChange}
        >
          <FormControlLabel value={1} control={<Radio />} label="Active" />
          <FormControlLabel value={2} control={<Radio />} label="Dissolved" />
          <FormControlLabel value={3} control={<Radio />} label="Liquidation" />
          <FormControlLabel value={4} control={<Radio />} label="Administration" />
          <FormControlLabel value={5} control={<Radio />} label="Inactive/Dormant" />
        </RadioGroup>
      </FormControl>

      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <LoadingButton
          disabled={entityData.value?.registration_status == registrationStatus ? true : false}
          disableElevation
          loading={processing}
          onClick={(e) => {
            handleUpdate(e);
          }}
          variant="contained"
          color="success"
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default RegistrationStatus;
