import { React, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import CurrencyTextField from "@puhl/mui-currency-textfield";
import getSymbolFromCurrency from "currency-symbol-map";

import { LoadingButton } from "@mui/lab";
import { Box, Typography, Divider } from "@mui/material";

function LastYearRevenue({
  entityData,
  setManageProfile,
  getEntityData,
  getProfileData,
  financialData,
  getFinancialData,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState(
    getSymbolFromCurrency(financialData?.currency)
  );

  const [previousTurnover, setPreviousTurnover] = useState(
    financialData?.previous_annual_turnover ? financialData.previous_annual_turnover : 0
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/financial/previous-revenue`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          previous_turnover: parseFloat(previousTurnover),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();
      getFinancialData();

      displayToast("Previous annual revenue updated successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <Box sx={{ px: 2 }}>
        <Typography variant="subtitle2">Last years revenue</Typography>
      </Box>

      <Box sx={{ p: 2 }}>
        <CurrencyTextField
          fullWidth
          label="Last years revenue"
          variant="outlined"
          value={previousTurnover}
          currencySymbol={currencySymbol}
          outputFormat="number"
          minimumValue="0"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(event, value) => setPreviousTurnover(value)}
        />
      </Box>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <LoadingButton
          disableElevation
          loading={processing}
          onClick={(e) => {
            handleUpdate(e);
          }}
          variant="contained"
          color="success"
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default LastYearRevenue;
