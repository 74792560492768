import { React, useState } from "react";

import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";

import CaseTemplates from "./CaseTemplates";
import DataSources from "./DataSources";
import RiskAssessment from "./RiskAssessment";
import Age from "./Age";
import Countries from "./Countries";
import Industries from "./Industries";
import Capacity from "./Capacity";
import Profiles from "./Profiles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Screening = ({ partner, partnerData, partnerUser }) => {
  const [navValue, setNavValue] = useState(0);

  const handleNav = (event, newValue) => {
    setNavValue(newValue);
  };

  return (
    <>
      {/*<Box sx={{ width: "100%" }}>
        <Tabs
          onChange={handleNav}
          value={navValue}
          aria-label="Tabs where selection follows focus"
          selectionFollowsFocus
        >
          <Tab label="Case Templates" {...a11yProps(0)} />
          <Tab label="Sources" {...a11yProps(1)} />
          <Tab label="Risk Assessment" {...a11yProps(2)} />
          <Tab label="Age" {...a11yProps(3)} />
          <Tab label="Countries" {...a11yProps(4)} />
          <Tab label="Industries" {...a11yProps(5)} />

          <Tab label="Capacity" {...a11yProps(6)} />

          <Tab label="Profiles" {...a11yProps(7)} />
        </Tabs>
      </Box> 

      <TabPanel value={navValue} index={0}>
        <CaseTemplates partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={1}>
        <DataSources partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={2}>
        <RiskAssessment partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={3}>
        <Age partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={4}>
        <Countries partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={5}>
        <Industries partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={6}>
        <Capacity partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={7}> */}
      <Profiles partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      {/* </TabPanel> */}
    </>
  );
};

export default Screening;
