import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import { signal } from "@preact/signals-react";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";
import NotesIcon from "@mui/icons-material/Notes";

import { CssBaseline, Box, Divider, Typography, Button, Chip } from "@mui/material";

import ClientNav from "./ClientNav";
import Overview from "./Overview";
import Oversight from "./Oversight";
import Tasks from "./Tasks";
import Reports from "./Reports";
import Settings from "./Settings";
import Logs from "./Logs";
import Enterprise from "./Enterprise";
import Notes from "./Notes";
import Billing from "./Billing";
import CoSec from "./CoSec";
import Documents from "./Documents";
import Assets from "./Assets";
import Banking from "./Banking";
import Accounting from "./Accounting";
import { Task } from "./components/task";

const activeView = signal(0);
const partnerUser = signal({});
const partnerData = signal({});
const clientData = signal({});

const entityData = signal({});
const notes = signal([]);
const activeNote = signal({});

const ManageClient = () => {
  const { t } = useParams();

  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [navOpen, setNavOpen] = useState(true);
  const [notesOpen, setNotesOpen] = useState(false);

  const [userLoaded, setUserLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [telephoneData, setTelephoneData] = useState();
  const [addressData, setAddressData] = useState();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getClient = async (clientUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/data`,
        JSON.stringify({
          client_uuid: clientUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      clientData.value = response.data;

      if (clientData.value.entity_type == 1) {
        getPerson(clientData.value.entity_uuid);
        getPersonTelephoneData(clientData.value.entity_uuid);
        getPersonAddressData(clientData.value.entity_uuid);
      } else {
        getCorporation(clientData.value.entity_uuid);
        getCorporationAddressData(clientData.value.entity_uuid, 1);
      }
      // getNotes();
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPerson = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      entityData.value = response.data;
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPersonTelephoneData = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/telephone-number`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setTelephoneData(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPersonAddressData = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/address`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setAddressData(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getCorporation = async (corporationUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data`,
        JSON.stringify({
          corporation_uuid: corporationUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      entityData.value = response.data;
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getCorporationAddressData = async (corporationUUID, addressType) => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/address`,
        JSON.stringify({
          corporation_uuid: corporationUUID,
          address_type: parseInt(addressType),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setAddressData(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getNotes = async () => {
    try {
      const response = await entities.post(
        `/v1/${clientData.value.client_entity_type == 1 ? "people" : "corporations"}/notes`,
        clientData.value.client_entity_type == 1
          ? JSON.stringify({ person_uuid: clientData.value.entity_uuid })
          : JSON.stringify({ corporation_uuid: clientData.value.entity_uuid }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        notes.value = response.data.entries;
      } else {
        notes.value = [];
      }
    } catch (err) {
      notes.value = [];
      handleError(err);
    }
  };

  useEffect(() => {
    getClient(t);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ClientNav
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        activeView={activeView}
        partnerUser={partnerUser}
        partnerData={partnerData}
        userLoaded={userLoaded}
        setUserLoaded={setUserLoaded}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {isLoaded ? (
          <>
            <Box sx={{ border: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", gap: 2 }}>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6">{clientData.value.name}</Typography>
                  </Box>

                  {entityData.value.is_pep && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Chip
                        className="square-chip"
                        label="PEP"
                        size="large"
                        sx={{
                          backgroundColor: "#C83E4D",
                          color: "#ffffff",
                          textTransform: "uppercase",
                        }}
                      />
                    </Box>
                  )}
                </Box>

                <Box>
                  <Button
                    startIcon={<NotesIcon />}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setNotesOpen(true);
                    }}
                  >
                    Notes
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box>
                {activeView.value === 0 && (
                  <Overview
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                    addressData={addressData}
                    telephoneData={telephoneData}
                  />
                )}
                {activeView.value === 1 && (
                  <Oversight
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                    getEntityData={() => {
                      if (clientData.value.entity_type == 1) {
                        getPerson(clientData.value.entity_uuid);
                      } else {
                        getCorporation(clientData.value.entity_uuid);
                      }
                    }}
                  />
                )}
                {activeView.value === 2 && (
                  <Tasks clientData={clientData} activeView={activeView} />
                )}
                {activeView.value === 3 && (
                  <Reports partnerUser={partnerUser} partnerData={partnerData} />
                )}
                {activeView.value === 4 && (
                  <Logs
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                  />
                )}
                {activeView.value === 5 && (
                  <Settings partnerUser={partnerUser} partnerData={partnerData} />
                )}
                {activeView.value === 6 && (
                  <Enterprise partnerUser={partnerUser} partnerData={partnerData} />
                )}

                {activeView.value === 7 && (
                  <Documents
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                  />
                )}

                {activeView.value === 8 && (
                  <Accounting
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                  />
                )}
                {activeView.value === 9 && (
                  <Banking
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                  />
                )}
                {activeView.value === 10 && (
                  <Assets
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                  />
                )}
                {activeView.value === 11 && (
                  <Billing
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                  />
                )}
                {activeView.value === 12 && (
                  <CoSec
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    clientData={clientData}
                    entityData={entityData}
                  />
                )}
              </Box>
              {activeView.value === 13 && (
                <Task
                  partnerUser={partnerUser}
                  partnerData={partnerData}
                  clientData={clientData}
                  activeView={activeView}
                />
              )}
            </Box>
            <Notes
              partnerUser={partnerUser}
              notesOpen={notesOpen}
              setNotesOpen={setNotesOpen}
              clientData={clientData}
            />
          </>
        ) : (
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManageClient;
