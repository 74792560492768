import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";
import moment from "moment";
import { signal } from "@preact/signals-react";

import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import {
  Groups,
  Schema,
  Work,
  Block,
  Cottage,
  Diversity2,
  Email,
  Fingerprint,
  Hail,
  Home,
  Language,
  LocalPhone,
  LocalPolice,
  MarkEmailRead,
  MonitorHeart,
  PhoneLocked,
  ReportProblem,
  Store,
  Paid,
  ContentCopy,
} from "@mui/icons-material";
import RelatedCases from "./components/overview/RelatedCases";
import RiskAssessment from "./components/overview/RiskAssessment";
import Decision from "./components/overview/Decision";

const answers = signal();

const Overview = ({
  partnerData,
  partnerUser,
  caseData,
  entityData,
  addressData,
  getCase,
  getCorporation,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  const [relatedCases, setRelatedCases] = useState([]);
  const [flags, setFlags] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getRelatedCases = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyb/related`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRelatedCases(response.data.entries);
      } else {
        setRelatedCases([]);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getFlags = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/flags`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setFlags(response.data.entries);
      } else {
        setFlags([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getRelatedCases();
    getFlags();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ border: 1, borderColor: "divider" }}>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Profile name
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">{caseData.value.profile_name}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6">Case Progress</Typography>
                    <Box>
                      {caseData.value.status == 0 && (
                        <Box>
                          <Chip
                            label="Input Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 1 && (
                        <Box>
                          <Chip
                            label="Review Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 2 && (
                        <Box>
                          <Chip
                            label="Complete"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#8BBF9F",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 3 && (
                        <Box>
                          <Chip
                            label="Declined"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#C83E4D",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 4 && (
                        <Box>
                          <Chip
                            label="Assessment Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 5 && (
                        <Box>
                          <Chip
                            label="Waiting"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 6 && (
                        <Box>
                          <Chip
                            label="Decision Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      backgroundColor: "#0b0b0b",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Business details"
                    >
                      <Work
                        sx={{
                          fontSize: "2.5rem",
                          color: caseData.value.business_information ? "#D5E950" : "#6b6b6b",
                        }}
                      />
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Business activities"
                    >
                      <Store
                        sx={{
                          fontSize: "2.5rem",
                          color: caseData.value.activities ? "#D5E950" : "#6b6b6b",
                        }}
                      />
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Financials"
                    >
                      <Paid
                        sx={{
                          fontSize: "2.5rem",
                          color: caseData.value.financials ? "#D5E950" : "#6b6b6b",
                        }}
                      />
                    </Box>

                    {caseData.value.options.address && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Address"
                      >
                        {caseData.value.options.address_verification ? (
                          <Cottage
                            sx={{
                              fontSize: "2.5rem",
                              color: caseData.value.address_document ? "#D5E950" : "#2274A5",
                            }}
                          />
                        ) : (
                          <Home
                            sx={{
                              fontSize: "2.5rem",
                              color: caseData.value.address ? "#D5E950" : "#6b6b6b",
                            }}
                          />
                        )}
                      </Box>
                    )}

                    {caseData.value.options.ownership && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Ownership"
                      >
                        <Schema
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.ownership ? "#D5E950" : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.officers && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Officers and management"
                      >
                        <Groups
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.officers ? "#D5E950" : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.corporate_documents && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Corporate documents"
                      >
                        <ContentCopy
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.corporate_documents ? "#D5E950" : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.fatca && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="FATCA"
                      >
                        <Diversity2
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.fatca ? "#D5E950" : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.peps && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="State ownership check"
                      >
                        <LocalPolice
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.peps > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.sanctions && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Sanctions check"
                      >
                        <Block
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.sanctions > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.warnings && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Warning list check"
                      >
                        <ReportProblem
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.warnings > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.adverse_media && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Adverse media search"
                      >
                        <LocalPolice
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.adverse_media > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                {flags.length > 0 && (
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6">Flags and Alerts</Typography>
                      <Box></Box>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2, display: "flex", flexDirection: "row", gap: 1 }}>
                      {flags.map((flag, index) => (
                        <Box
                          key={index}
                          sx={{}}
                          title={flag.country?.code != "" ? flag.country.name : ""}
                        >
                          <Chip
                            label={flag.flag_type_label}
                            className="square-chip"
                            sx={{
                              backgroundColor:
                                flag.flag_type < 3
                                  ? "#C83E4D"
                                  : flag.flag_type < 5
                                  ? "#FCCA46"
                                  : flag.flag_type == 5
                                  ? "#2274A5"
                                  : "#FCCA46",
                              color:
                                flag.flag_type < 3
                                  ? "#ffffff"
                                  : flag.flag_type < 5
                                  ? "#0b0b0b"
                                  : flag.flag_type == 5
                                  ? "#0b0b0b"
                                  : "#0b0b0b",
                              textTransform: "uppercase",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}

                {caseData.value.status == 2 && (
                  <Decision
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => getCase}
                    getCorporation={getCorporation}
                    profileUUID={caseData.value.profile_uuid}
                  />
                )}
                {caseData.value.status == 3 && (
                  <Decision
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => getCase}
                    getCorporation={getCorporation}
                    profileUUID={caseData.value.profile_uuid}
                  />
                )}
                {caseData.value.status == 6 && (
                  <Decision
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => getCase}
                    getCorporation={getCorporation}
                    profileUUID={caseData.value.profile_uuid}
                  />
                )}

                <Box sx={{ border: 1, borderColor: "divider", borderBottom: 0 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1 }}>
                    <Typography variant="h6">Company Profile</Typography>
                  </Box>
                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Entity type</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.registration_type
                          ? entityData.value.registration_type
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Company reference</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.registration_reference
                          ? entityData.value.registration_reference
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Registration date</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.registration_date
                          ? moment(entityData.value.registration_date).format("Do MMMM YYYY")
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Registry URL</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.registry_url ? entityData.value.registry_url : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Registration country</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="subtitle2" align="right">
                        {entityData.value.registration_country?.code
                          ? entityData.value.registration_country.name
                          : "-"}
                      </Typography>

                      {entityData.value.registration_country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.registration_country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.registration_country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.registration_country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Financial year end</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.year_end_day > 0
                          ? moment(
                              new Date(
                                2023,
                                entityData.value.year_end_month - 1,
                                entityData.value.year_end_day
                              )
                            ).format("Do MMMM")
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">VAT number</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {entityData.value.vat_validated && (
                        <Chip
                          className="square-chip"
                          label="Validated"
                          size="small"
                          color="success"
                        />
                      )}
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle2">
                          {entityData.value.vat_number ? entityData.value.vat_number : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Registered address</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="subtitle2" align="right">
                        {addressData.line_one ? addressData.line_one : "-"}
                      </Typography>

                      {addressData.line_two && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_two}
                        </Typography>
                      )}

                      {addressData.line_three && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_three}
                        </Typography>
                      )}

                      {addressData.line_four && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_four}
                        </Typography>
                      )}

                      {addressData.line_five && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_five}
                        </Typography>
                      )}

                      {addressData.line_six && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_six}
                        </Typography>
                      )}

                      {addressData.city && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.city}
                        </Typography>
                      )}

                      {addressData.country?.name && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.country.name}
                        </Typography>
                      )}

                      {addressData.country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData.country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData.country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData.country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Corporation status</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      {entityData.value.registration_status == 1 ? (
                        <Chip className="square-chip" label="Active" size="small" color="success" />
                      ) : (
                        <Chip
                          className="square-chip"
                          label="Dissolved"
                          size="small"
                          sx={{ backgroundColor: "#C83E4D", color: "#ffffff" }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                {caseData.value.status == 4 && (
                  <RiskAssessment
                    caseData={caseData}
                    entityData={entityData}
                    answers={answers}
                    getCase={() => getCase()}
                    profileUUID={caseData.value.profile_uuid}
                  />
                )}

                <RelatedCases relatedCases={relatedCases} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Overview;
