import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";
import fileCheckLottie from "../../../components/lotties/file-check.json";

import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Collapse,
  List,
  ListItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import VideoCapture from "./proof-of-life/VideoCapture";

const ProofOfLife = ({
  partner,
  caseUUID,
  entityData,
  caseData,
  activeView,
  getPerson,
  requestData,
  getRequestData,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: fileCheckLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [polData, setPolData] = useState(null);
  const [videoCapture, setVideoCapture] = useState(false);

  const getProofOfLifeData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/proof-of-life`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      setPolData(response.data);

      if (!response.data.uploaded) {
        setEditMode(true);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getProofOfLifeData();
  }, []);

  return isLoaded ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="h6">We need to capture a short video of you</Typography>
        </Box>
      </Box>

      {!editMode ? (
        <Box>
          <Box sx={{ backgroundColor: "#8BBF9F", p: 2 }}>
            <Typography
              variant="h6"
              sx={{ color: "#ffffff", textTransform: "uppercase", textAlign: "center" }}
            >
              Upload completed successfully
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Lottie speed={1.0} options={successOptions} height={100} width={100} />
            </Box>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
            <Button
              variant="contained"
              size=""
              color="primary"
              onClick={() => {
                requestData.requirements.pep_declaration
                  ? (activeView.value = 8)
                  : (activeView.value = 9);
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {videoCapture ? (
            <VideoCapture
              partner={partner}
              setVideoCapture={setVideoCapture}
              entityData={entityData}
              getProofOfLifeData={() => getProofOfLifeData()}
              getRequestData={() => getRequestData()}
              getCase={() => getCase()}
              setEditMode={setEditMode}
            />
          ) : (
            <Box sx={{ p: 2 }}>
              <Typography variant="body2" gutterBottom>
                To ensure the security and authenticity of your account, we need you to record a
                short video. This video will serve as evidence that you are exactly who you claim to
                be.
              </Typography>

              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Follow the steps below to successfully complete the process
              </Typography>

              <List sx={{ listStyleType: "disc", marginLeft: "2em" }}>
                <ListItem sx={{ display: "list-item" }}>
                  <Typography variant="body2">
                    Ensure you are in a well-lit area with minimal background noise.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <Typography variant="body2">
                    Grant access permissions to the camera and microphone if prompted.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <Typography variant="body2">
                    Ensure your face is visible at all times during the recording.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <Typography variant="body2">Follow the on screen instructions.</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <Typography variant="body2">Click continue to begin.</Typography>
                </ListItem>
              </List>

              <Box
                sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", p: 2 }}
              >
                <Button variant="contained" color="primary" onClick={() => setVideoCapture(true)}>
                  Continue
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default ProofOfLife;
