import { React, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import central from "../../../../api/central";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import { LoadingButton } from "@mui/lab";
import DataTable from "react-data-table-component";

import {
  Box,
  Typography,
  TextField,
  FormControl,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  IconButton,
  Link,
} from "@mui/material";
import { Close, Delete, Description, Edit, Facebook } from "@mui/icons-material";
import { act } from "react";

function Links({
  partnerUser,
  entityData,
  setManageProfile,
  getEntityData,
  getProfileData,
  activitiesData,
  getActivitiesData,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const linkOptions = [
    { value: 0, label: "Website" },
    { value: 1, label: "Facebook" },
    { value: 2, label: "Twitter | X.com" },
    { value: 3, label: "LinkedIn" },
    { value: 4, label: "Instagram" },
    { value: 5, label: "YouTube" },
    { value: 6, label: "TikTok" },
    { value: 7, label: "Snapchat" },
    { value: 8, label: "Pinterest" },
    { value: 9, label: "Reddit" },
    { value: 10, label: "Trust Pilot" },
    { value: 11, label: "Google Reviews" },
    { value: 12, label: "Yelp" },
    { value: 13, label: "Glassdoor" },
    { value: 14, label: "Tradebody" },
    { value: 15, label: "Licensing Authority" },
    { value: 16, label: "Other" },
  ];

  const [linkUrl, setLinkUrl] = useState("");
  const [selectedLinkType, setSelectedLinkType] = useState();
  const [deleteLinkDialog, setDeleteLinkDialog] = useState(false);
  const [deleteLink, setDeleteLink] = useState("");

  const handleAddLink = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/links/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          link_type: selectedLinkType ? selectedLinkType.value : -1,
          url: linkUrl,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();
      getActivitiesData();

      displayToast("Link added successfully", () => {
        setLinkUrl("");
        setSelectedLinkType(null);
        setProcessing(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRemoveLink = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/links/remove`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          link_uuid: deleteLink,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();
      getActivitiesData();
      setDeleteLinkDialog(false);
      setDeleteLink("");

      displayToast("Link removed successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            id="add-link"
            options={linkOptions}
            onChange={(e, v) => {
              setSelectedLinkType(v);
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField {...params} label="Link type" />}
          />
        </FormControl>

        <TextField
          label="Link URL"
          fullWidth
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
        />

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <LoadingButton
            disableElevation
            variant="contained"
            color="success"
            loading={processing}
            onClick={(e) => handleAddLink(e)}
          >
            Add link
          </LoadingButton>
        </Box>
      </Box>

      <Dialog
        open={deleteLinkDialog}
        onClose={() => setDeleteLinkDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
              Are you sure you want to remove this link?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disableElevation
            loading={processing}
            onClick={() => setDeleteLinkDialog(false)}
            color="primary"
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            disableElevation
            sx={{ color: "#ffffff" }}
            loading={processing}
            onClick={(e) => {
              handleRemoveLink(e);
            }}
            variant="contained"
            color="danger"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {activitiesData && activitiesData?.links?.length > 0 && (
        <Box sx={{ border: 1, borderColor: "divider" }}>
          <Box
            sx={{
              backgroundColor: "#0b0b0b",
              color: "#ffffff",
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2">Link URL</Typography>
            <Typography variant="subtitle2">Link type</Typography>
          </Box>

          {activitiesData?.links.map((linkData, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle2">{linkData.url}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle2">{linkData.link_name}</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setDeleteLink(linkData.link_uuid);
                    setDeleteLinkDialog(true);
                  }}
                  sx={{
                    color: "#C83E4D",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Delete />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default Links;
