import { React, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import central from "../../../../api/central";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import { LoadingButton } from "@mui/lab";
import DataTable from "react-data-table-component";

import {
  Box,
  Typography,
  TextField,
  FormControl,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  IconButton,
} from "@mui/material";
import { Close, Delete, Description, Edit, Facebook } from "@mui/icons-material";
import { act } from "react";

function IndustryCodes({
  partnerUser,
  entityData,
  setManageProfile,
  getEntityData,
  getProfileData,
  activitiesData,
  getActivitiesData,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const manageColumns = [
    {
      name: "Industry name",
      sortable: true,
      width: "30%",
      selector: (row) => row.name,
    },
    {
      name: "Industry code",
      sortable: true,
      right: "true",
      selector: (row) => row.code,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.code}</Typography>
        </Box>
      ),
    },
    {
      name: "Remove",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box
          onClick={() => {
            setDeleteIndustryCode(row.uuid);
            setDeleteIndustryCodeDialog(true);
          }}
          sx={{ color: "#C83E4D", cursor: "pointer" }}
        >
          <Delete />
        </Box>
      ),
    },
  ];

  const [industryCodeOptions, setIndustryCodeOptions] = useState([]);
  const [selectedIndustryCode, setSelectedIndustryCode] = useState();

  const [deleteIndustryCodeDialog, setDeleteIndustryCodeDialog] = useState(false);
  const [deleteIndustryCode, setDeleteIndustryCode] = useState("");

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/registration/name`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          registered_name: registeredName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();

      displayToast("Registration name updated successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getIndustryOptions = async () => {
    try {
      const response = await central.post(
        `/v1/service/corporations/industry-codes/options`,
        JSON.stringify({ code_type: 0 }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setIndustryCodeOptions(response.data.entries);
      } else {
        setIndustryCodeOptions([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAddIndustryCode = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/industry-codes/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          code: selectedIndustryCode,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivitiesData();
      getEntityData();
      getProfileData();
      setSelectedIndustryCode("");

      displayToast("Industry code added successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRemoveIndustryCode = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/industry-codes/remove`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          code_uuid: deleteIndustryCode,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivitiesData();
      getEntityData();
      getProfileData();
      setDeleteIndustryCodeDialog(false);
      setDeleteIndustryCode("");

      displayToast("Industry code removed successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getIndustryOptions();
  }, []);

  return (
    <Box>
      {isLoaded && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
            <Typography variant="subtitle2">Select the industry codes</Typography>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-demo"
                options={industryCodeOptions}
                onChange={(event, newValue) => {
                  setSelectedIndustryCode(newValue.value);
                }}
                sx={{}}
                renderInput={(params) => <TextField {...params} label="Industry code" />}
              />
            </FormControl>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <LoadingButton
                disableElevation
                variant="contained"
                color="success"
                loading={processing}
                onClick={(e) => handleAddIndustryCode(e)}
              >
                Add industry code
              </LoadingButton>
            </Box>
          </Box>

          {activitiesData?.industry_codes?.length > 0 && (
            <Box sx={{ border: 1, borderColor: "divider" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  p: 2,
                  backgroundColor: "#0b0b0b",
                  color: "#ffffff",
                }}
              >
                <Typography variant="subtitle2">Currently assigned industry codes</Typography>
              </Box>
              {activitiesData?.industry_codes?.map((cd, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    p: 2,
                    borderTop: index > 0 ? 1 : 0,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">
                      {cd.name} ({cd.code})
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <IconButton
                      color="danger"
                      onClick={() => {
                        setDeleteIndustryCode(cd.uuid);
                        setDeleteIndustryCodeDialog(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Dialog
            open={deleteIndustryCodeDialog}
            onClose={() => setDeleteIndustryCodeDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to remove this industry code?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                disableElevation
                loading={processing}
                onClick={() => setDeleteIndustryCodeDialog(false)}
                color="primary"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleRemoveIndustryCode(e);
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
}

export default IndustryCodes;
