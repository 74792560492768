import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Check, Close, Edit } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const DateOfBirth = ({
  partner,
  caseUUID,
  entityData,
  caseData,
  activeView,
  getPerson,
  requestData,
  getRequestData,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(entityData.value.date_of_birth ? false : true);

  const [dateOfBirth, setDateOfBirth] = useState(
    entityData.value.date_of_birth ? moment(entityData.value.date_of_birth) : null
  );

  const handleUpdateDateOfBirth = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/birth/date`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          dob: dateOfBirth ? dateOfBirth.format("YYYY-MM-DD") : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getRequestData();
      getCase();

      displayToast("Date of birth updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <Divider />

      {!editMode ? (
        <Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle1" sx={{ fontSize: "13px" }}>
                Date of birth (age)
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography sx={{ textAlign: "end" }} variant="h6">
                  {entityData.value.date_of_birth
                    ? moment(entityData.value.date_of_birth).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
                {entityData.value.age > 0 && (
                  <Typography sx={{ textAlign: "end" }} variant="subtitle2">
                    {entityData.value.age} years old
                  </Typography>
                )}
              </Box>
              <IconButton
                title="Edit"
                onClick={() => {
                  setEditMode(!editMode);
                }}
              >
                {editMode ? <Close /> : <Edit />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pl: 2 }}>
              <Typography variant="subtitle1" sx={{ fontSize: "13px" }}>
                Date of birth (age)
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Box sx={{ py: 1 }}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                  <DatePicker
                    fullWidth
                    openTo="year"
                    label={`Date of birth`}
                    value={dateOfBirth ? dateOfBirth : null}
                    onChange={(date) => {
                      setDateOfBirth(date);
                    }}
                    slotProps={{ textField: { fullWidth: true } }}
                    maxDate={moment()}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1, pr: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    loading={processing}
                    color="success"
                    onClick={(e) => {
                      handleUpdateDateOfBirth(e);
                    }}
                  >
                    <Check />
                  </LoadingButton>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <LoadingButton
                    disableElevation
                    variant="outlined"
                    loading={processing}
                    color="primary"
                    onClick={(e) => {
                      setEditMode(false);
                    }}
                  >
                    <Close />
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DateOfBirth;
