import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import NoResults from "../../components/functions/NoResults";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";

import { Canvas, Node, Label, Edge } from "reaflow";

import {
  Box,
  Alert,
  Typography,
  Divider,
  Paper,
  Chip,
  Button,
  Collapse,
  IconButton,
  Link,
  Drawer,
  TextField,
} from "@mui/material";

import {
  Block,
  Cake,
  Cottage,
  Diversity2,
  Email,
  Fingerprint,
  Hail,
  Home,
  Language,
  LocalPhone,
  LocalPolice,
  MarkEmailRead,
  MonitorHeart,
  PhoneLocked,
  PriceCheck,
  ReportProblem,
  ZoomIn,
  Groups,
  Schema,
  Work,
  Store,
  Paid,
  ContentCopy,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Newspaper,
  Edit,
  Close,
  Upload,
  FileCopy,
  InsertDriveFile,
  Add,
  Shield,
  GppGood,
  Lan,
  List,
  People,
  Diversity3,
  Business,
  Check,
  ArrowForwardIos,
  Person,
  AutoFixHigh,
  HorizontalRule,
  Visibility,
  Delete,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import RegisteredName from "./components/oversight/RegisteredName";
import RegistrationNumber from "./components/oversight/RegistrationNumber";
import RegisteredAddress from "./components/oversight/RegisteredAddress";
import RegistrationCountry from "./components/oversight/RegistrationCountry";
import RegistrationDate from "./components/oversight/RegistrationDate";
import CorporationType from "./components/oversight/CorporationType";
import RegistryURL from "./components/oversight/RegistryURL";
import RegistrationStatus from "./components/oversight/RegistrationStatus";
import BusinessDescription from "./components/oversight/BusinessDescription";
import IndustryCodes from "./components/oversight/IndustryCodes";
import IndustryConsiderations from "./components/oversight/IndustryConsiderations";
import Links from "./components/oversight/Links";

import PrimaryCurrency from "./components/oversight/PrimaryCurrency";
import StartupFunding from "./components/oversight/StartupFunding";
import ProjectedRevenue from "./components/oversight/ProjectedRevenue";
import LastYearRevenue from "./components/oversight/LastYearRevenue";

import CorporateDocuments from "./components/oversight/CorporateDocuments";
import DocumentViewer from "./components/oversight/DocumentViewer";
import Officers from "./components/oversight/Officers";
import Ownership from "./components/oversight/Ownership";
import FatcaCorporate from "./components/oversight/FatcaCorporate";
import CrsCorporate from "./components/oversight/CrsCorporate";
import BackgroundChecks from "./components/oversight/BackgroundChecks";
import { useNavigate } from "react-router-dom";

const Oversight = ({ partnerData, partnerUser, clientData, entityData, getEntityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [activeProfileUUID, setActiveProfileUUID] = useState("");
  const [activeProfileData, setActiveProfileData] = useState({});

  const [manageProfile, setManageProfile] = useState(false);
  const [manageProfileType, setManageProfileType] = useState(0);
  const [manageProfileLabel, setManageProfileLabel] = useState("Manage Profile");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [screeningCases, setScreeningCases] = useState([]);

  const getScreeningCases = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/cases/client`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setScreeningCases(response.data.entries);
      } else {
        setScreeningCases([]);
      }

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getScreeningProfile = async (profileUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/client/profile`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveProfileUUID(profileUUID);
      setActiveProfileData(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  const getAssessmentData = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/client/risk-assessment`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          profile_uuid: activeProfileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setAssessmentData(response.data);
      setVisibleFormula(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const roleChipColor = (role) => {
    switch (parseInt(role)) {
      case 1:
        return "#935FA7";
      case 2:
        return "#DDA448";
      case 5:
        return "#94AE89";
      case 6:
        return "#94AE89";
      case 7:
        return "#94AE89";
      case 8:
        return "#94AE89";
      case 9:
        return "#94AE89";
      default:
        return "#413C58";
    }
  };

  const [visibleFormula, setVisibleFormula] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});

  const [visibleActivities, setVisibleActivities] = useState(false);
  const [activitiesData, setActivitiesData] = useState({});

  const [visibleFinancials, setVisibleFinancials] = useState(false);
  const [financialData, setFinancialData] = useState({});

  const [visibleOwnership, setVisibleOwnership] = useState(false);
  const [ownershipData, setOwnershipData] = useState(null);
  const [ownershipCompletion, setOwnershipCompletion] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [activeShareholderEntity, setActiveShareholderEntity] = useState(null);
  const [activeShareholderEntityShareholders, setActiveShareholderEntityShareholders] = useState();
  const [ownershipView, setOwnershipView] = useState(1);

  const [visibleOfficers, setVisibleOfficers] = useState(false);
  const [officersData, setOfficersData] = useState(null);
  const [officersCompletion, setOfficersCompletion] = useState(false);

  const [visibleFatca, setVisibleFatca] = useState(false);
  const [fatcaData, setFatcaData] = useState({});

  const [visibleCrs, setVisibleCrs] = useState(false);
  const [crsData, setCrsData] = useState({});

  const [visibleCorporateDocuments, setVisibleCorporateDocuments] = useState(false);
  const [corporateDocumentsData, setCorporateDocumentsData] = useState({});

  const [visibleBackgroundCheck, setVisibleBackgroundCheck] = useState(false);
  const [backgroundCheckData, setBackgroundCheckData] = useState(null);
  const [alertsData, setAlertsData] = useState([]);
  const [alertsType, setAlertsType] = useState(0);

  const [visibleAddressDocument, setVisibleAddressDocument] = useState(false);
  const [addressDocumentData, setAddressDocumentData] = useState(null);

  const [visibleIdentityDocument, setVisibleIdentityDocument] = useState(false);
  const [identityDocumentData, setIdentityDocumentData] = useState(null);

  const [visibleProofOfLife, setVisibleProofOfLife] = useState(false);
  const [proofOfLifeData, setProofOfLifeData] = useState(null);

  const [visibleLinkedEntities, setVisibleLinkedEntities] = useState(false);
  const [linkedEntitiesData, setLinkedEntitiesData] = useState(null);

  const [viewDocumentData, setViewDocumentData] = useState({});

  const [fullWidthDrawer, setFullWidthDrawer] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const getCorporation = async (corporationUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data`,
        JSON.stringify({
          corporation_uuid: corporationUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setActiveShareholderEntity(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getCorporationShareholders = async (corporationUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/ownership`,
        JSON.stringify({
          corporation_uuid: corporationUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setActiveShareholderEntityShareholders(response.data.entries);
      } else {
        setActiveShareholderEntityShareholders([]);
      }

      setManageEntity(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getActivitiesData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/activities`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
          profile_uuid: activeProfileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActivitiesData(response.data);
      setVisibleActivities(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getFinancialData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/financial`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setFinancialData(response.data);
      setVisibleFinancials(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getCorporateDocuments = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/corporate-documents`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setCorporateDocumentsData(response.data);
      setVisibleCorporateDocuments(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const corporateDocumentDownload = async (documentUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/corporate-document/download`,
        JSON.stringify({
          file_uuid: documentUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      setViewDocumentData(response.data);
      setManageProfileLabel("Document viewer");
      setManageProfileType(18);
      setManageProfile(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getOfficersData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/officers/list`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
          profile_uuid: activeProfileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      if (response.data.results > 0) {
        setOfficersData(response.data.entries);
      } else {
        setOfficersData([]);
      }

      if (response.data.complete_available) {
        setOfficersCompletion(true);
      }

      setVisibleOfficers(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getOwnershipData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/ownership`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
          profile_uuid: activeProfileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setOwnershipData(response.data.entries);
      } else {
        setOwnershipData([]);
      }

      setVisibleOwnership(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getOwnershipChartData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/ownership/chart?profile_uuid=${activeProfileUUID}`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setNodes(response.data.nodes);
      if (response.data.links != null) {
        setEdges(response.data.links);
      } else {
        setEdges([]);
      }

      setOwnershipCompletion(response.data.calculation_available);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getFatcaData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/fatca`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setFatcaData(response.data);
      setVisibleFatca(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getCrsData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/crs`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setCrsData(response.data);
      setVisibleCrs(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getBackgroundCheck = async (makeVisible) => {
    try {
      const response = await partner.post(
        `/v1/screening/client/background-check?entity_uuid=${
          entityData.value.entity_type == 1
            ? entityData.value.person_uuid
            : entityData.value.corporation_uuid
        }`,
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setBackgroundCheckData(response.data);

      if (makeVisible) {
        setVisibleBackgroundCheck(true);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getAlerts = async (alertType) => {
    try {
      const response = await partner.post(
        `/v1/screening/client/background-check/list/${alertType}`,
        JSON.stringify({
          entity_uuid:
            entityData.value.entity_type == 1
              ? entityData.value.person_uuid
              : entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      switch (alertType) {
        case "sanctions":
          setAlertsType(2);
          break;
        case "peps":
          setAlertsType(1);
          break;
        case "warnings":
          setAlertsType(3);
          break;
        case "adverse-media":
          setAlertsType(4);
          break;
        default:
          setAlertsType(0);
          break;
      }

      if (response.data.results > 0) {
        setAlertsData(response.data.entries);
      } else {
        setAlertsData([]);
      }

      setManageProfileLabel("Background check alerts");
      setManageProfileType(23);
      setManageProfile(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getAddressDocumentData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/address/document`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid ? entityData.value.person_uuid : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setAddressDocumentData(response.data);
      setVisibleAddressDocument(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getIdentityDocumentData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/identity`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid ? entityData.value.person_uuid : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setIdentityDocumentData(response.data);
      setVisibleIdentityDocument(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getProofOfLifeData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/proof-of-life`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid ? entityData.value.person_uuid : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setProofOfLifeData(response.data);
      setVisibleProofOfLife(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getLinkedEntities = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/client/corporation-linked-entities?corporation_uuid=${entityData.value.corporation_uuid}&profile_uuid=${activeProfileUUID}`,
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      if (response.data.results > 0) {
        setLinkedEntitiesData(response.data.entries);
      } else {
        setLinkedEntitiesData([]);
      }

      setVisibleLinkedEntities(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-gb", {
      style: "currency",
      currency: financialData?.currency ? financialData.currency : "GBP",
    }).format(value);

  useEffect(() => {
    getScreeningCases();
    getBackgroundCheck();
  }, []);

  return isLoaded ? (
    <>
      {activeProfileUUID ? (
        <Box>
          <Box
            sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h6">
                Viewing compliance profile - {activeProfileData?.profile_name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setActiveProfileUUID("");
                  setActiveProfileData({});
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
          <Divider />

          {activeProfileData?.root_case?.flags?.length > 0 && (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                    Flags & Alerts
                  </Typography>
                </Box>
                <Box sx={{ p: 2, display: "flex", flexDirection: "row", gap: 1 }}>
                  {activeProfileData?.root_case?.flags.map((flag, index) => (
                    <Box
                      key={index}
                      sx={{}}
                      title={flag.country?.code != "" ? flag.country.name : ""}
                    >
                      <Chip
                        label={flag.flag_type_label}
                        className="square-chip"
                        sx={{
                          backgroundColor:
                            flag.flag_type < 3
                              ? "#C83E4D"
                              : flag.flag_type < 5
                              ? "#FCCA46"
                              : flag.flag_type == 5
                              ? "#2274A5"
                              : "#FCCA46",
                          color:
                            flag.flag_type < 3
                              ? "#ffffff"
                              : flag.flag_type < 5
                              ? "#0b0b0b"
                              : flag.flag_type == 5
                              ? "#0b0b0b"
                              : "#0b0b0b",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>

              <Divider />
            </Box>
          )}

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: 2,
                gap: 1,
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                Template name
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {activeProfileData?.root_case?.template_name}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: 2,
                gap: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 500, color: "darkgray", textAlign: "end" }}
              >
                Profile Status
              </Typography>
              {activeProfileData?.root_case?.status == 0 && (
                <Box>
                  <Chip
                    label="Input Required"
                    className="square-chip"
                    sx={{
                      backgroundColor: "#2274A5",
                      color: "#ffffff",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}
              {activeProfileData?.root_case?.status == 1 && (
                <Box>
                  <Chip
                    label="Review Required"
                    className="square-chip"
                    sx={{
                      backgroundColor: "#D5E950",
                      color: "#1C2321",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}
              {activeProfileData?.root_case?.status == 2 && (
                <Box>
                  <Chip
                    label="Approved"
                    className="square-chip"
                    sx={{
                      backgroundColor: "#8BBF9F",
                      color: "#1C2321",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}
              {activeProfileData?.root_case?.status == 3 && (
                <Box>
                  <Chip
                    label="Declined"
                    className="square-chip"
                    sx={{
                      backgroundColor: "#C83E4D",
                      color: "#ffffff",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}
              {activeProfileData?.root_case?.status == 4 && (
                <Box>
                  <Chip
                    label="Assessment Required"
                    className="square-chip"
                    sx={{
                      backgroundColor: "#D5E950",
                      color: "#1C2321",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}
              {activeProfileData?.root_case?.status == 5 && (
                <Box>
                  <Chip
                    label="Waiting"
                    className="square-chip"
                    sx={{
                      backgroundColor: "#2274A5",
                      color: "#ffffff",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}
              {activeProfileData?.root_case?.status == 6 && (
                <Box>
                  <Chip
                    label="Decision Required"
                    className="square-chip"
                    sx={{
                      backgroundColor: "#D5E950",
                      color: "#1C2321",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                  Case progress
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <Button disableElevation variant="outlined" color="primary" onClick={() => {}}>
                  Case settings
                </Button>
                <Button
                  title="View case"
                  disableElevation
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (activeProfileData?.root_case?.case_type == 1) {
                      navigate(`/case/kyc/${activeProfileData?.root_case?.case_uuid}`);
                    } else {
                      navigate(`/case/kyb/${activeProfileData?.root_case?.case_uuid}`);
                    }
                  }}
                >
                  <Visibility />
                </Button>
              </Box>
            </Box>
            <Box>
              {activeProfileData?.root_case?.case_type == 1 ? (
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    backgroundColor: "#0b0b0b",
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    title="Date of birth"
                  >
                    <Cake
                      sx={{
                        fontSize: "2.5rem",
                        color: activeProfileData?.root_case?.progress.date_of_birth
                          ? "#D5E950"
                          : "#6b6b6b",
                      }}
                    />
                  </Box>

                  {activeProfileData?.root_case?.requirements.email_address && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Email address"
                    >
                      {activeProfileData?.root_case?.progress.email_address ? (
                        activeProfileData?.root_case?.requirements.email_verification ? (
                          <MarkEmailRead
                            sx={{
                              fontSize: "2.5rem",
                              color: activeProfileData?.root_case?.progress.email_verification
                                ? "#D5E950"
                                : "#2274A5",
                            }}
                          />
                        ) : (
                          <Email
                            sx={{
                              fontSize: "2.5rem",
                              color: activeProfileData?.root_case?.progress.email_address
                                ? "#D5E950"
                                : "#6b6b6b",
                            }}
                          />
                        )
                      ) : (
                        <Email
                          sx={{
                            fontSize: "2.5rem",
                            color: activeProfileData?.root_case?.progress.email_address
                              ? "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      )}
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.telephone_number && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Telephone number"
                    >
                      {activeProfileData?.root_case?.progress.telephone_number ? (
                        activeProfileData?.root_case?.requirements.telephone_verification ? (
                          <PhoneLocked
                            sx={{
                              fontSize: "2.5rem",
                              color: activeProfileData?.root_case?.progress.telephone_verification
                                ? "#D5E950"
                                : "#2274A5",
                            }}
                          />
                        ) : (
                          <LocalPhone
                            sx={{
                              fontSize: "2.5rem",
                              color: activeProfileData?.root_case?.progress.telephone_number
                                ? "#D5E950"
                                : "#6b6b6b",
                            }}
                          />
                        )
                      ) : (
                        <LocalPhone
                          sx={{
                            fontSize: "2.5rem",
                            color: activeProfileData?.root_case?.progress.telephone_number
                              ? "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      )}
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.citizenship && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Citizenship"
                    >
                      <Language
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.citizenship
                            ? "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.address && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Address"
                    >
                      {activeProfileData?.root_case?.progress.address ? (
                        activeProfileData?.root_case?.requirements.address_verification ? (
                          <Cottage
                            sx={{
                              fontSize: "2.5rem",
                              color: activeProfileData?.root_case?.progress
                                .address_document_uploaded
                                ? activeProfileData?.root_case?.progress.address_document_review
                                  ? "#FCCA46"
                                  : "#D5E950"
                                : "#2274A5",
                            }}
                          />
                        ) : (
                          <Home
                            sx={{
                              fontSize: "2.5rem",
                              color: activeProfileData?.root_case?.progress.address
                                ? "#D5E950"
                                : "#6b6b6b",
                            }}
                          />
                        )
                      ) : (
                        <Home
                          sx={{
                            fontSize: "2.5rem",
                            color: activeProfileData?.root_case?.progress.address
                              ? "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      )}
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.identity && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Identity verification"
                    >
                      <Fingerprint
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.identity_uploaded
                            ? activeProfileData?.root_case?.progress.identity_analysed
                              ? activeProfileData?.root_case?.progress.identity_review
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#2274A5"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.proof_of_life && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Proof of life"
                    >
                      <MonitorHeart
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.proof_of_life_uploaded
                            ? activeProfileData?.root_case?.progress.proof_of_life_review
                              ? "#FCCA46"
                              : !activeProfileData?.root_case?.progress.proof_of_life_analysed
                              ? "#2274A5"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.fatca && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="FATCA"
                    >
                      <Diversity2
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.fatca
                            ? "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.pep_declaration && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="PEP declaration"
                    >
                      <Hail
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.pep_declaration
                            ? "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.progress.source_of_wealth && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Source of wealth"
                    >
                      <PriceCheck
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.source_of_wealth_complete
                            ? !activeProfileData?.root_case?.progress.source_of_wealth_reviewed
                              ? "#FCCA46"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.peps && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="PEP search"
                    >
                      <LocalPolice
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.peps > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.peps > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.sanctions && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Sanctions check"
                    >
                      <Block
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.sanctions > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.sanctions > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.warnings && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Warning list check"
                    >
                      <ReportProblem
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.warnings > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.warnings > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.adverse_media && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Adverse media search"
                    >
                      <LocalPolice
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.adverse_media > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.adverse_media > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.has_enhanced && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Enhanced due diligence"
                    >
                      <ZoomIn
                        sx={{
                          fontSize: "2.5rem",
                          color: !activeProfileData?.root_case?.progress.enhanced_complete
                            ? "#2274A5"
                            : !activeProfileData?.root_case?.progress.enhanced_reviewed
                            ? "#FCCA46"
                            : "#D5E950",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    backgroundColor: "#0b0b0b",
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    title="Business details"
                    onClick={() => {}}
                  >
                    <Work
                      sx={{
                        fontSize: "2.5rem",
                        color: activeProfileData?.root_case?.progress.business_information
                          ? "#D5E950"
                          : "#6b6b6b",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    title="Business activities"
                    onClick={() => {
                      getActivitiesData();
                    }}
                  >
                    <Store
                      sx={{
                        fontSize: "2.5rem",
                        color: activeProfileData?.root_case?.progress.business_activities
                          ? "#D5E950"
                          : "#6b6b6b",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    title="Financials"
                    onClick={() => {
                      getFinancialData();
                    }}
                  >
                    <Paid
                      sx={{
                        fontSize: "2.5rem",
                        color: activeProfileData?.root_case?.progress.business_financials
                          ? "#D5E950"
                          : "#6b6b6b",
                      }}
                    />
                  </Box>

                  {activeProfileData?.root_case?.requirements.address_verification && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Address"
                    >
                      {activeProfileData?.root_case?.requirements.address_verification ? (
                        <Cottage
                          sx={{
                            fontSize: "2.5rem",
                            color: activeProfileData?.root_case?.progress.address_document_uploaded
                              ? "#D5E950"
                              : "#2274A5",
                          }}
                        />
                      ) : (
                        <Home
                          sx={{
                            fontSize: "2.5rem",
                            color: activeProfileData?.root_case?.progress.address
                              ? "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      )}
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.ownership && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="Ownership"
                      onClick={() => {
                        getOwnershipData();
                        getOwnershipChartData();
                      }}
                    >
                      <Schema
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.ownership
                            ? "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.officers && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="Officers and management"
                      onClick={() => {
                        getOfficersData();
                      }}
                    >
                      <Groups
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.officers
                            ? "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.corporate_documents && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="Corporate documents"
                      onClick={() => {
                        getCorporateDocuments();
                      }}
                    >
                      <ContentCopy
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.corporate_documents
                            ? "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.fatca && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="FATCA/CRS"
                      onClick={() => {
                        getFatcaData();
                        getCrsData();
                      }}
                    >
                      <Diversity2
                        sx={{
                          fontSize: "2.5rem",
                          color: activeProfileData?.root_case?.progress.fatca
                            ? "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.peps && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="State ownership check"
                      onClick={() => {
                        getBackgroundCheck(true);
                      }}
                    >
                      <LocalPolice
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.peps > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.peps > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.sanctions && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="Sanctions check"
                      onClick={() => {
                        getBackgroundCheck(true);
                      }}
                    >
                      <Block
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.sanctions > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.sanctions > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.warnings && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="Warning list check"
                      onClick={() => {
                        getBackgroundCheck();
                      }}
                    >
                      <ReportProblem
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.warnings > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.warnings > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}

                  {activeProfileData?.root_case?.requirements.adverse_media && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      title="Adverse media search"
                      onClick={() => {
                        getBackgroundCheck(true);
                      }}
                    >
                      <Newspaper
                        sx={{
                          fontSize: "2.5rem",
                          color: backgroundCheckData?.complete
                            ? backgroundCheckData?.review_counts.adverse_media > 0
                              ? "#FCCA46"
                              : backgroundCheckData?.true_hits.adverse_media > 0
                              ? "#C83E4D"
                              : "#D5E950"
                            : "#6b6b6b",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>

          {activeProfileData?.root_case?.assessment?.approvals_required != 0 && (
            <Box>
              <Box sx={{ p: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Risk Assessment
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 2,
                    gap: 1,
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                    Calculated Score
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                    <Typography variant="h4">
                      {activeProfileData?.root_case?.assessment?.calculated_score}
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Chip
                        label={activeProfileData?.root_case?.assessment?.risk_label}
                        className="square-chip"
                        sx={{
                          textTransform: "uppercase",
                          backgroundColor:
                            activeProfileData?.root_case?.assessment?.risk_category == 1
                              ? "#8BBF9F"
                              : activeProfileData?.root_case?.assessment?.risk_category == 2
                              ? "#FCCA46"
                              : "#C83E4D",
                          color:
                            activeProfileData?.root_case?.assessment?.risk_category == 1
                              ? "#0b0b0b"
                              : activeProfileData?.root_case?.assessment?.risk_category == 2
                              ? "#0b0b0b"
                              : "#ffffff",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>

                {activeProfileData?.root_case?.assessment?.approved ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: 2,
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: 500, color: "darkgray", textAlign: "end" }}
                    >
                      Onboarding Decision
                    </Typography>
                    <Chip
                      label="Approved"
                      className="square-chip"
                      sx={{
                        backgroundColor: "#8BBF9F",
                        color: "#0b0b0b",
                        textTransform: "uppercase",
                      }}
                    />
                  </Box>
                ) : activeProfileData?.root_case?.assessment?.declined ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: 2,
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: 500, color: "darkgray", textAlign: "end" }}
                    >
                      Onboarding Decision
                    </Typography>
                    <Chip
                      label="Declined"
                      className="square-chip"
                      sx={{
                        backgroundColor: "#C83E4D",
                        color: "#ffffff",
                        textTransform: "uppercase",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: 2,
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Approval Status
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: "end" }}>
                      {activeProfileData?.root_case?.assessment?.approvals_received}/
                      {activeProfileData?.root_case?.assessment?.approvals_required}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: visibleFormula ? 1 : 0,
                  borderColor: "divider",
                  backgroundColor: "#0b0b0b",
                  color: "#ffffff",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">Calculation formula</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <IconButton
                    onClick={() => {
                      if (!visibleFormula) {
                        getAssessmentData();
                      } else {
                        setVisibleFormula(false);
                      }
                    }}
                    sx={{ color: "#ffffff" }}
                  >
                    {visibleFormula ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </Box>
              </Box>

              <Collapse in={visibleFormula} exit={!visibleFormula}>
                <Box sx={{ p: 0 }}>
                  {assessmentData?.questions?.map((question, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: 1,
                        borderColor: "divider",
                        p: 2,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                          {question.question_text}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        {question.auto_calculated && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                            title="Automatically calculated"
                          >
                            <AutoFixHigh />
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "right",
                              color: question.value ? "#C83E4D" : "#0b0b0b",
                            }}
                          >
                            {question.value ? <Check /> : <HorizontalRule />}
                          </Box>
                          <Typography variant="subtitle2">Points: {question.points}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  {assessmentData?.manual_adjustment && (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: 1,
                          borderColor: "divider",
                          p: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                            Manual adjustment
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">
                            {assessmentData?.manual_adjustment_score} points
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider", fontSize: "13px" }}>
                        <Typography variant="subtitle2">
                          Reason for the manual adjustment
                        </Typography>
                        {parse(assessmentData?.manual_adjustment_reason)}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Collapse>
            </Box>
          )}

          <Box>
            <Box
              sx={{
                p: 2,
                backgroundColor: "#0b0b0b",
                color: "#ffffff",
                borderTop: 1,
                borderColor: "#ffffff",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Profile details
              </Typography>
            </Box>
            <Divider />

            {activeProfileData?.root_case?.case_type == 1 ? (
              <Box>
                {/* Personal profile */}
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Full name
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      -
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Previous names or aliases
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      -
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Date of birth
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      -
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Place of birth
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      -
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Email address
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      -
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Telephone number
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      -
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Residential address
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      -
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleAddressDocument ? 1 : 0,
                    borderColor: "divider",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleAddressDocument) {
                      getAddressDocumentData();
                    } else {
                      setVisibleAddressDocument(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Identity document</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleAddressDocument ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleAddressDocument} exit={!visibleAddressDocument}></Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleIdentity ? 1 : 0,
                    borderColor: "divider",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleIdentity) {
                      getIdentityDocumentData();
                    } else {
                      setVisibleIdentity(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Identity document</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleIdentity ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleIdentity} exit={!visibleIdentity}></Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleProofOfLife ? 1 : 0,
                    borderColor: "divider",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleProofOfLife) {
                      getProofOfLifeData();
                    } else {
                      setVisibleProofOfLife(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Proof of life</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleProofOfLife ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleProofOfLife} exit={!visibleProofOfLife}></Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleBackgroundCheck ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleBackgroundCheck) {
                      getBackgroundCheck(true);
                    } else {
                      setVisibleBackgroundCheck(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Background check</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleBackgroundCheck ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleBackgroundCheck} exit={!visibleBackgroundCheck}>
                  <Box sx={{ p: 2 }}></Box>
                </Collapse>
              </Box>
            ) : (
              <Box>
                {/* Business profile */}
                <Box
                  sx={{
                    px: 2,

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Registered name
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {entityData.value?.registered_name}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setManageProfileLabel("Update registered name");
                          setManageProfileType(1);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Registered address
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "end",
                      }}
                    >
                      {entityData.value?.registered_address?.line_one ? (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.line_one
                            ? entityData.value?.registered_address?.line_one
                            : "-"}
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2">-</Typography>
                      )}
                      {entityData.value?.registered_address?.line_two && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.line_two
                            ? entityData.value?.registered_address?.line_two
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.line_three && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.line_three
                            ? entityData.value?.registered_address?.line_three
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.line_four && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.line_four
                            ? entityData.value?.registered_address?.line_four
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.line_five && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.line_five
                            ? entityData.value?.registered_address?.line_five
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.line_six && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.line_six
                            ? entityData.value?.registered_address?.line_six
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.city && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.city
                            ? entityData.value?.registered_address?.city
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.code && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.code
                            ? entityData.value?.registered_address?.code
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.state && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.state
                            ? entityData.value?.registered_address?.state
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.country.code && (
                        <Typography variant="subtitle2">
                          {entityData.value?.registered_address?.country.code
                            ? entityData.value?.registered_address?.country.name
                            : "-"}
                        </Typography>
                      )}
                      {entityData.value?.registered_address?.country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setManageProfileLabel("Update registered address");
                          setManageProfileType(3);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Registration number
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {entityData.value?.registration_reference
                          ? entityData.value?.registration_reference
                          : "-"}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setManageProfileLabel("Update registration number");
                          setManageProfileType(2);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Registration country
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {entityData.value?.registration_country?.code != ""
                          ? entityData.value?.registration_country?.name
                          : "-"}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setManageProfileLabel("Update registeration country");
                          setManageProfileType(4);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Registration date
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {entityData.value?.registration_date != ""
                          ? moment(entityData.value?.registration_date).format("DD/MM/YYYY")
                          : "-"}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setManageProfileLabel("Update registration date");
                          setManageProfileType(5);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Corporation type
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {entityData.value?.registration_type
                          ? entityData.value?.registration_type
                          : "-"}
                      </Typography>
                    </Box>

                    <Box>
                      <IconButton
                        disabled={entityData.value?.registration_country?.code != "" ? false : true}
                        onClick={() => {
                          setManageProfileLabel("Update corporation type");
                          setManageProfileType(6);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Registry URL
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      {entityData.value?.registry_url ? (
                        <Link
                          href={entityData.value?.registry_url}
                          underline="hover"
                          variant="subtitle2"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ fontWeight: 500 }}
                        >
                          Visit Registry
                        </Link>
                      ) : (
                        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                          -
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setManageProfileLabel("Update public register url");
                          setManageProfileType(7);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                      Registration status
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      {entityData.value?.registration_status == 1 && (
                        <Box>
                          <Chip
                            className="square-chip"
                            label="Active"
                            sx={{
                              textTransform: "uppercase",
                              backgroundColor: "#8BBF9F",
                              color: "#0b0b0b",
                            }}
                          />
                        </Box>
                      )}
                      {entityData.value?.registration_status == 2 && (
                        <Box>
                          <Chip
                            className="square-chip"
                            label="Dissolved"
                            sx={{
                              textTransform: "uppercase",
                              backgroundColor: "#C83E4D",
                              color: "#ffffff",
                            }}
                          />
                        </Box>
                      )}
                      {entityData.value?.registration_status == 3 && (
                        <Box>
                          <Chip
                            className="square-chip"
                            label="Liquidation"
                            sx={{
                              textTransform: "uppercase",
                              backgroundColor: "#C83E4D",
                              color: "#ffffff",
                            }}
                          />
                        </Box>
                      )}
                      {entityData.value?.registration_status == 4 && (
                        <Box>
                          <Chip
                            className="square-chip"
                            label="Administration"
                            sx={{
                              textTransform: "uppercase",
                              backgroundColor: "#C83E4D",
                              color: "#ffffff",
                            }}
                          />
                        </Box>
                      )}
                      {entityData.value?.registration_status == 5 && (
                        <Box>
                          <Chip
                            className="square-chip"
                            label="Inactive/Dormant"
                            sx={{
                              textTransform: "uppercase",
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setManageProfileLabel("Update registration status");
                          setManageProfileType(8);
                          setManageProfile(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleActivities ? 1 : 0,
                    borderColor: "divider",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleActivities) {
                      getActivitiesData();
                    } else {
                      setVisibleActivities(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Business activities</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleActivities ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleActivities} exit={!visibleActivities}>
                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                          Business description
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setManageProfileLabel("Update business description");
                            setManageProfileType(9);
                            setManageProfile(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      {activitiesData?.business_description ? (
                        <Box className="text-output">
                          {parse(activitiesData?.business_description)}
                        </Box>
                      ) : (
                        <Box>
                          <Chip
                            label="Input required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Divider />
                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                          Industry codes
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setManageProfileLabel("Update industry codes");
                            setManageProfileType(10);
                            setManageProfile(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      {activitiesData?.industry_codes?.length > 0 ? (
                        activitiesData.industry_codes.map((codeData, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 4,
                              mb: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                                {codeData.name} ({codeData.code})
                              </Typography>
                            </Box>
                            <Box>
                              {codeData.high_risk && (
                                <Chip
                                  label="High Risk"
                                  className="square-chip"
                                  sx={{
                                    backgroundColor: "#C83E4D",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box>
                          <Chip
                            label="Input required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Divider />

                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                          Industry considerations
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setManageProfileLabel("Update industry considerations");
                            setManageProfileType(11);
                            setManageProfile(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      {activitiesData?.industry_consideration ? (
                        <Box className="text-output">
                          {parse(activitiesData?.industry_consideration)}
                        </Box>
                      ) : (
                        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                          -
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Divider />

                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                          Links
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setManageProfileLabel("Update links");
                            setManageProfileType(12);
                            setManageProfile(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Box>

                    {activitiesData?.links?.length > 0 ? (
                      activitiesData?.links.map((linkData, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">{linkData.url}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">{linkData.link_name}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box sx={{ width: "100%" }}>
                        <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                          -
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleFinancials ? 1 : 0,
                    borderTop: 1,

                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleFinancials) {
                      getFinancialData();
                    } else {
                      setVisibleFinancials(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Financial information</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleFinancials ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleFinancials} exit={!visibleFinancials}>
                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                          Primary currency
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setManageProfileLabel("Update primary currency");
                            setManageProfileType(13);
                            setManageProfile(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      {financialData?.currency ? (
                        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                          {financialData?.currency}
                        </Typography>
                      ) : (
                        <Box>
                          <Chip
                            label="Input required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Divider />

                  {financialData?.currency && (
                    <Box>
                      {financialData &&
                      financialData?.currency != "" &&
                      financialData?.is_startup ? (
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Startup funding</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2" sx={{ letterSpacing: ".05rem" }}>
                                {financialData?.startup_financing
                                  ? numberFormat(financialData.startup_financing)
                                  : "-"}
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => {
                                  setManageProfileLabel("Update startup funding");
                                  setManageProfileType(14);
                                  setManageProfile(true);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Last years revenue</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2" sx={{ letterSpacing: ".05rem" }}>
                                {financialData?.previous_annual_turnover
                                  ? numberFormat(financialData.previous_annual_turnover)
                                  : "-"}
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => {
                                  setManageProfileLabel("Update last years revenue");
                                  setManageProfileType(15);
                                  setManageProfile(true);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      <Box
                        sx={{
                          px: 2,
                          py: 1,
                          borderBottom: 0,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">Projected revenue</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ letterSpacing: ".05rem" }}>
                              {financialData?.est_annual_turnover
                                ? numberFormat(financialData.est_annual_turnover)
                                : "-"}
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() => {
                                setManageProfileLabel("Update projected revenue");
                                setManageProfileType(16);
                                setManageProfile(true);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleCorporateDocuments ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleCorporateDocuments) {
                      getCorporateDocuments();
                    } else {
                      setVisibleCorporateDocuments(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Corporate documents</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleCorporateDocuments ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleCorporateDocuments} exit={!visibleCorporateDocuments}>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                      {corporateDocumentsData?.results > 0 ? (
                        <>
                          {corporateDocumentsData?.entries.map((documentData, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                corporateDocumentDownload(documentData.document_uuid);
                              }}
                            >
                              <InsertDriveFile fontSize="large" />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {console.log(documentData)}
                                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                                  {documentData.document_type == 1 &&
                                    "Certificate of incorporation"}
                                  {documentData.document_type == 2 &&
                                    "Memorandum and articles of association"}
                                  {documentData.document_type == 3 && "Certificate of name change"}
                                  {documentData.document_type == 4 && "Business plan"}
                                  {documentData.document_type == 5 && "Income statement"}
                                  {documentData.document_type == 6 && "Investment contract"}
                                  {documentData.document_type == 7 && "Board resolution"}
                                  {documentData.document_type == 8 && "Shareholder agreement"}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontStyle: "italic", color: "darkgray" }}
                                >
                                  {moment
                                    .unix(documentData.upload_timestamp)
                                    .format("DD/MM/YYYY HH:mm")}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <Box>
                          {activeProfileData?.root_case?.requirements?.corporate_documents ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setManageProfileLabel("Upload corporate documents");
                                setManageProfileType(17);
                                setManageProfile(true);
                              }}
                            >
                              <InsertDriveFile fontSize="large" />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                                  Certificate of incorporation
                                </Typography>
                              </Box>
                              <Chip
                                label="Upload required"
                                className="square-chip"
                                sx={{
                                  backgroundColor: "#2274A5",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setManageProfileLabel("Upload corporate documents");
                                setManageProfileType(17);
                                setManageProfile(true);
                              }}
                            >
                              <InsertDriveFile fontSize="large" />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                                  Certificate of incorporation
                                </Typography>
                              </Box>
                              <Chip
                                label="Upload optional"
                                className="square-chip"
                                sx={{
                                  backgroundColor: "#2274A5",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          setManageProfileLabel("Upload corporate documents");
                          setManageProfileType(17);
                          setManageProfile(true);
                        }}
                        variant="outlined"
                        startIcon={<Upload />}
                      >
                        Upload documents
                      </Button>
                    </Box>
                  </Box>
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleOfficers ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleOfficers) {
                      getOfficersData();
                    } else {
                      setVisibleOfficers(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Officers and management</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleOfficers ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleOfficers} exit={!visibleOfficers}>
                  {officersData?.length > 0 ? (
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", gap: 2, minWidth: "50%" }}
                      >
                        {officersData.map((officer, index) => (
                          <Box key={index} sx={{ border: 1, borderColor: "divider", p: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  gap: 4,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                    {officer.name}
                                  </Typography>
                                </Box>
                                <Box>
                                  {officer.entity_type == 1
                                    ? officer.kyc_complete && (
                                        <Box
                                          className="square-chip"
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",

                                            backgroundColor: "#8BBF9F",
                                            px: 2,
                                            py: 1,
                                          }}
                                        >
                                          <GppGood />

                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              paddingLeft: "3px",
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontWeight: 600, fontSize: 12 }}
                                            >
                                              KYC complete
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )
                                    : officer.kyb_complete && (
                                        <Box
                                          className="square-chip"
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",

                                            backgroundColor: "#8BBF9F",
                                            px: 2,
                                            py: 1,
                                          }}
                                        >
                                          <GppGood />

                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              paddingLeft: "3px",
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontWeight: 600, fontSize: 12 }}
                                            >
                                              KYB complete
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )}
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                {officer.roles?.map((role, index) => (
                                  <Chip
                                    key={index}
                                    className="square-chip"
                                    label={role.label}
                                    sx={{
                                      color: "#ffffff",
                                      backgroundColor: roleChipColor(role.role),
                                      textTransform: "uppercase",
                                    }}
                                  />
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Button
                          onClick={() => {
                            setManageProfileLabel("Officers and management");
                            setManageProfileType(19);
                            setManageProfile(true);
                          }}
                          variant="outlined"
                          startIcon={<Edit />}
                        >
                          Manage officers
                        </Button>

                        {officersCompletion && (
                          <Chip
                            label="Confirmation required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  ) : (
                    activeProfileData?.root_case?.requirements?.officers && (
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Chip
                            label="Input required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                        <Box>
                          <Button
                            onClick={() => {
                              setManageProfileLabel("Add officers and management");
                              setManageProfileType(19);
                              setManageProfile(true);
                            }}
                            variant="outlined"
                            startIcon={<Edit />}
                          >
                            Manage officers
                          </Button>
                        </Box>
                      </Box>
                    )
                  )}
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleOwnership ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleOwnership) {
                      getOwnershipData();
                      getOwnershipChartData();
                    } else {
                      setVisibleOwnership(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Ownership</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleOwnership ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleOwnership} exit={!visibleOwnership}>
                  {ownershipData?.length > 0 ? (
                    ownershipView == 1 ? (
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", flexDirection: "column", gap: 2, minWidth: "50%" }}
                        >
                          {ownershipData.map((shareholder, index) => (
                            <Box key={index} sx={{ border: 1, borderColor: "divider", p: 2 }}>
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    gap: 4,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                      {shareholder.shareholder_name}
                                    </Typography>

                                    {shareholder.entity_type == 1
                                      ? shareholder.kyc_complete && (
                                          <Box
                                            className="square-chip"
                                            sx={{
                                              display: "flex",
                                              flexDirection: "row",

                                              backgroundColor: "#8BBF9F",
                                              px: 2,
                                              py: 1,
                                            }}
                                          >
                                            <GppGood />

                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                paddingLeft: "3px",
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontWeight: 600, fontSize: 12 }}
                                              >
                                                KYC complete
                                              </Typography>
                                            </Box>
                                          </Box>
                                        )
                                      : shareholder.kyb_complete && (
                                          <Box
                                            className="square-chip"
                                            sx={{
                                              display: "flex",
                                              flexDirection: "row",

                                              backgroundColor: "#8BBF9F",
                                              px: 2,
                                              py: 1,
                                            }}
                                          >
                                            <GppGood />

                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                paddingLeft: "3px",
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontWeight: 600, fontSize: 12 }}
                                              >
                                                KYB complete
                                              </Typography>
                                            </Box>
                                          </Box>
                                        )}
                                  </Box>
                                  <Box>
                                    <Typography variant="subtitle2" sx={{}}>
                                      {shareholder.percentage}%
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Button
                              onClick={() => {
                                setManageProfileLabel("Shareholders and ownership");
                                setManageProfileType(20);
                                setManageProfile(true);
                                // setActiveShareholderEntity(entityData.value);
                                // getCorporationShareholders(entityData.value.corporation_uuid);
                              }}
                              variant="outlined"
                              startIcon={<Edit />}
                            >
                              Manage shareholders
                            </Button>

                            <IconButton
                              onClick={() => {
                                if (ownershipView == 2) {
                                  setOwnershipView(1);
                                } else {
                                  setOwnershipView(2);
                                }
                              }}
                            >
                              {ownershipView == 2 ? <List /> : <Lan />}
                            </IconButton>
                          </Box>
                          {ownershipCompletion && (
                            <Chip
                              label="Confirmation required"
                              className="square-chip"
                              sx={{
                                backgroundColor: "#2274A5",
                                color: "#ffffff",
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            p: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              gap: 2,
                            }}
                          >
                            <Button
                              onClick={() => {
                                setManageProfileLabel("Shareholders and ownership");
                                setManageProfileType(20);
                                setManageProfile(true);
                                // setActiveShareholderEntity(entityData.value);
                                // setActiveShareholderEntityShareholders(
                                //   entityData.value.corporation_uuid
                                // );
                              }}
                              variant="outlined"
                              startIcon={<Edit />}
                            >
                              Manage shareholders
                            </Button>

                            <IconButton
                              onClick={() => {
                                if (ownershipView == 2) {
                                  setOwnershipView(1);
                                } else {
                                  setOwnershipView(2);
                                }
                              }}
                            >
                              {ownershipView == 2 ? <List /> : <Lan />}
                            </IconButton>
                          </Box>
                          {ownershipCompletion && (
                            <Chip
                              label="Confirmation required"
                              className="square-chip"
                              sx={{
                                backgroundColor: "#2274A5",
                                color: "#ffffff",
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                        </Box>
                        <Box sx={{ p: 2, width: "100%", height: "50vh" }}>
                          <Canvas
                            nodes={nodes}
                            edges={edges}
                            direction="UP"
                            readonly={true}
                            pannable={false}
                            fit={true}
                            edge={<Edge className="edge" selectable={false} />}
                            node={
                              <Node style={{ fill: "#474044" }}>
                                {(event) => (
                                  <foreignObject
                                    height={event.height}
                                    width={event.width}
                                    x={0}
                                    y={0}
                                  >
                                    {event.node.data.entity_type != 0 && (
                                      <Box
                                        sx={{
                                          background: "#ffffff",
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          borderBottom: 1,
                                          borderColor: "#0b0b0b",
                                          height: 45,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            p: 1,
                                          }}
                                        >
                                          {event.node.data.entity_type == 1 ? (
                                            <People />
                                          ) : event.node.data.entity_type == 2 ? (
                                            <Business />
                                          ) : (
                                            <Diversity3 />
                                          )}
                                        </Box>

                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                          {event.node.data.direct_control > 0 && (
                                            <Box
                                              sx={{
                                                backgroundColor: "#0b0b0b",
                                                color: "#ffffff",
                                                height: "100%",
                                                p: 1,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                fontWeight: 500,
                                              }}
                                              title="Direct"
                                            >
                                              {event.node.data.direct_control}%
                                            </Box>
                                          )}

                                          {event.node.data.entity_type != 3 &&
                                            event.node.data.indirect_control > 0 && (
                                              <Box
                                                sx={{
                                                  backgroundColor: "#aaaaaa",
                                                  color: "#ffffff",
                                                  height: "100%",
                                                  p: 1,
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  fontWeight: 500,
                                                  fontSize: "12px",
                                                }}
                                                title="Indirect"
                                              >
                                                {event.node.data.indirect_control.toFixed(2)}%
                                              </Box>
                                            )}
                                        </Box>
                                      </Box>
                                    )}

                                    <Box
                                      sx={{
                                        p: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: event.node.data.entity_type == 0 ? "100%" : "auto",
                                        fontWeight: 500,
                                        height: event.node.data.entity_type != 0 ? 55 : 75,
                                        backgroundColor:
                                          event.node.data.entity_type == 0
                                            ? "#474044"
                                            : event.node.data.entity_type == 1
                                            ? "#4AFE7B"
                                            : event.node.data.entity_type == 2
                                            ? "#4A8EFE"
                                            : "#B74FFF",
                                      }}
                                      onClick={() => {}}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: 500,
                                          fontSize: event.node.data.entity_type == 0 ? 16 : "14px",
                                          color:
                                            event.node.data.entity_type == 0
                                              ? "#ffffff"
                                              : event.node.data.entity_type == 1
                                              ? "#0b0b0b"
                                              : event.node.data.entity_type == 2
                                              ? "#0b0b0b"
                                              : "#ffffff",
                                        }}
                                      >
                                        {event.node.data.label}
                                      </Typography>
                                      {event.node.data.entity_type == 2 ? (
                                        event.node.data.control_assigned == 100 ? (
                                          <Check sx={{ color: "#0b0b0b" }} />
                                        ) : (
                                          <Typography
                                            sx={{
                                              color: "#0b0b0b",
                                              fontWeight: 500,
                                              fontSize: "13px",
                                            }}
                                          >
                                            {event.node.data.control_assigned}%
                                          </Typography>
                                        )
                                      ) : (
                                        ""
                                      )}

                                      {event.node.data.entity_type == 0 ? (
                                        event.node.data.control_assigned == 100 ? (
                                          <Check sx={{ color: "#8BBF9F" }} />
                                        ) : (
                                          <Typography
                                            sx={{
                                              color: "#ffffff",
                                              fontWeight: 500,
                                              fontSize: "13px",
                                            }}
                                          >
                                            {event.node.data.control_assigned}%
                                          </Typography>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </foreignObject>
                                )}
                              </Node>
                            }
                          />
                        </Box>
                      </Box>
                    )
                  ) : (
                    activeProfileData?.root_case?.requirements?.ownership && (
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Chip
                            label="Input required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                        <Box>
                          <Button
                            onClick={() => {
                              setManageProfileLabel("Shareholders and ownership");
                              setManageProfileType(20);
                              setManageProfile(true);
                              setActiveShareholderEntity(entityData.value);
                              setActiveShareholderEntityShareholders(
                                entityData.value.corporation_uuid
                              );
                            }}
                            variant="outlined"
                            startIcon={<Edit />}
                          >
                            Manage shareholders
                          </Button>
                        </Box>
                      </Box>
                    )
                  )}
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleFatca ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleFatca) {
                      getFatcaData();
                    } else {
                      setVisibleFatca(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">
                      Foreign Account Tax Compliance Act (FATCA)
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleFatca ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleFatca} exit={!visibleFatca}>
                  <Box
                    sx={{
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderTop: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                        Declared status
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      {fatcaData?.complete ? (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {fatcaData.entity_type == 1 && (
                                <Typography variant="subtitle2" sx={{ p: 2 }}>
                                  Specified US Person
                                </Typography>
                              )}

                              {fatcaData.entity_type == 2 && (
                                <Typography variant="subtitle2" sx={{ p: 2 }}>
                                  Non specified US Person
                                </Typography>
                              )}

                              {fatcaData.entity_type == 3 && (
                                <Typography variant="subtitle2" sx={{ p: 2 }}>
                                  Non US Person
                                </Typography>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                px: 2,
                              }}
                            >
                              {fatcaData.reportable && (
                                <Chip
                                  className="square-chip"
                                  label="Reportable"
                                  sx={{
                                    backgroundColor: "#C83E4D",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            </Box>
                          </Box>

                          {fatcaData.entity_type == 1 && (
                            <Box
                              sx={{
                                borderTop: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                  TIN
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                  {fatcaData.tin}
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {fatcaData.entity_type == 2 && (
                            <Box
                              sx={{
                                borderTop: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  minWidth: "30%",
                                }}
                              >
                                <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                  Exemption
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                  {fatcaData.us_person_exemption_label}
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {fatcaData.entity_type == 3 && fatcaData.financial_institution && (
                            <Box>
                              <Box
                                sx={{
                                  borderTop: 1,
                                  borderColor: "divider",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    minWidth: "30%",
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                    Financial institution type
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                    {fatcaData.financial_institution_type_label}
                                  </Typography>
                                </Box>
                              </Box>

                              {fatcaData.giin ? (
                                <Box
                                  sx={{
                                    borderTop: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      minWidth: "30%",
                                    }}
                                  >
                                    <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                      GIIN
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                      {fatcaData.giin}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : (
                                <Box>
                                  <Box
                                    sx={{
                                      borderTop: 1,
                                      borderColor: "divider",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        minWidth: "30%",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle"
                                        sx={{ fontSize: "13px", p: 2 }}
                                      >
                                        GIIN Exemption
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: "13px", p: 2 }}
                                      >
                                        {fatcaData.no_giin_reason_label}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  {fatcaData.no_giin_reason == 1 && (
                                    <Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            GIIN Sponsor
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaData.sponsor_name}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            Sponsors GIIN
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaData.sponsor_giin}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}

                                  {fatcaData.no_giin_reason == 2 && (
                                    <Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            GIIN Sponsor
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaData.trustee_name}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            Sponsors GIIN
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaData.trustee_giin}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}

                                  {fatcaData.no_giin_reason == 3 && (
                                    <Box
                                      sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          minWidth: "30%",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle"
                                          sx={{ fontSize: "13px", p: 2 }}
                                        >
                                          Exemption
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: "13px", p: 2 }}
                                        >
                                          {fatcaData.no_giin_exemption_type_label}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      ) : activeProfileData?.root_case?.requirements?.fatca ? (
                        <Box sx={{ p: 2 }}>
                          <Chip
                            label="Input required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box sx={{ p: 2 }}>
                          <Chip
                            label="Input optional"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}

                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <IconButton
                          disabled={
                            entityData.value?.registration_country?.code != "" ? false : true
                          }
                          onClick={() => {
                            setManageProfileLabel("FATCA Declaration");
                            setManageProfileType(21);
                            setManageProfile(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleCrs ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleCrs) {
                      getCrsData();
                    } else {
                      setVisibleCrs(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Common Reporting Standard (CRS)</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleCrs ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleCrs} exit={!visibleCrs}>
                  <Box
                    sx={{
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderTop: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "darkgray" }}>
                        Declared status
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        {crsData?.complete ? (
                          <Box sx={{}}>
                            <Box sx={{}}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  px: 2,
                                }}
                              >
                                {crsData.financial_institution ? (
                                  <Typography variant="subtitle2" sx={{ p: 2 }}>
                                    The entity is a Financial Institution
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle2" sx={{ p: 2 }}>
                                    The entity is not a Financial Institution
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ) : activeProfileData?.root_case?.requirements?.fatca ? (
                          <Box sx={{ p: 2 }}>
                            <Chip
                              label="Input required"
                              className="square-chip"
                              sx={{
                                backgroundColor: "#2274A5",
                                color: "#ffffff",
                                textTransform: "uppercase",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box sx={{ p: 2 }}>
                            <Chip
                              label="Input optional"
                              className="square-chip"
                              sx={{
                                backgroundColor: "#2274A5",
                                color: "#ffffff",
                                textTransform: "uppercase",
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <IconButton
                          onClick={() => {
                            setManageProfileLabel("CRS Declaration");
                            setManageProfileType(22);
                            setManageProfile(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleBackgroundCheck ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleBackgroundCheck) {
                      getBackgroundCheck(true);
                    } else {
                      setVisibleBackgroundCheck(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Background check</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleBackgroundCheck ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleBackgroundCheck} exit={!visibleBackgroundCheck}>
                  <Box sx={{}}>
                    {backgroundCheckData?.has_reviews && (
                      <Box
                        sx={{
                          py: 2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          width: "100%",
                          borderBottom: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Button
                          variant="contained"
                          disableElevation={true}
                          color="success"
                          onClick={() => console.log("engage burst capacity")}
                        >
                          Engage burst capacity
                        </Button>
                      </Box>
                    )}

                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          Sanctions
                        </Typography>
                      </Box>
                      <Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {backgroundCheckData?.review_counts.sanctions > 0 && (
                            <Box
                              sx={{
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Chip
                                className="square-chip"
                                label={`Review${
                                  backgroundCheckData?.review_counts.sanctions > 1 ? "s" : ""
                                } required (${backgroundCheckData?.review_counts.sanctions})`}
                                sx={{
                                  color: "#0b0b0b",
                                  backgroundColor: "#FCCA46",
                                  textTransform: "uppercase",
                                }}
                              />
                            </Box>
                          )}
                          <Box
                            title="Discounted"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 400,
                              fontSize: 13,
                              borderLeft: 1,
                              borderRight: 1,
                              borderColor: "divider",
                              color: "primary.light",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            {backgroundCheckData?.discounted.sanctions}
                          </Box>
                          <Box
                            title="True hits"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 600,
                              color:
                                backgroundCheckData?.true_hits.sanctions > 0
                                  ? "danger.main"
                                  : "text.primary",
                              borderLeft: 1,
                              borderColor: "#a6a6a6",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#ffc4ca",
                            }}
                          >
                            {backgroundCheckData?.true_hits.sanctions}
                          </Box>

                          <Box
                            sx={{
                              px: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              title="View"
                              disabled={
                                backgroundCheckData?.true_hits.sanctions > 0
                                  ? false
                                  : backgroundCheckData?.discounted.sanctions > 0
                                  ? false
                                  : backgroundCheckData?.review_counts.sanctions > 0
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                getAlerts("sanctions");
                              }}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          {entityData.value.entity_type == 1
                            ? "Politically exposed person"
                            : "State owned organisation (PEPs)"}
                        </Typography>
                      </Box>
                      <Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {backgroundCheckData?.review_counts.peps > 0 && (
                            <Box
                              sx={{
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Chip
                                className="square-chip"
                                label={`Review${
                                  backgroundCheckData?.review_counts.peps > 1 ? "s" : ""
                                } required (${backgroundCheckData?.review_counts.peps})`}
                                sx={{
                                  color: "#0b0b0b",
                                  backgroundColor: "#FCCA46",
                                  textTransform: "uppercase",
                                }}
                              />
                            </Box>
                          )}
                          <Box
                            title="Discounted"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 400,
                              fontSize: 13,
                              borderLeft: 1,
                              borderRight: 1,
                              borderColor: "divider",
                              color: "primary.light",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            {backgroundCheckData?.discounted.peps}
                          </Box>
                          <Box
                            title="True hits"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 600,
                              color:
                                backgroundCheckData?.true_hits.peps > 0
                                  ? "danger.main"
                                  : "text.primary",
                              borderLeft: 1,
                              borderColor: "#a6a6a6",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#ffc4ca",
                            }}
                          >
                            {backgroundCheckData?.true_hits.peps}
                          </Box>

                          <Box
                            sx={{
                              px: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              title="View"
                              disabled={
                                backgroundCheckData?.true_hits.peps > 0
                                  ? false
                                  : backgroundCheckData?.discounted.peps > 0
                                  ? false
                                  : backgroundCheckData?.review_counts.peps > 0
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                getAlerts("peps");
                              }}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          Warnings
                        </Typography>
                      </Box>
                      <Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {backgroundCheckData?.review_counts.warnings > 0 && (
                            <Box
                              sx={{
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Chip
                                className="square-chip"
                                label={`Review${
                                  backgroundCheckData?.review_counts.warnings > 1 ? "s" : ""
                                } required (${backgroundCheckData?.review_counts.warnings})`}
                                sx={{
                                  color: "#0b0b0b",
                                  backgroundColor: "#FCCA46",
                                  textTransform: "uppercase",
                                }}
                              />
                            </Box>
                          )}
                          <Box
                            title="Discounted"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 400,
                              fontSize: 13,
                              borderLeft: 1,
                              borderRight: 1,
                              borderColor: "divider",
                              color: "primary.light",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            {backgroundCheckData?.discounted.warnings}
                          </Box>
                          <Box
                            title="True hits"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 600,
                              color:
                                backgroundCheckData?.true_hits.warnings > 0
                                  ? "danger.main"
                                  : "text.primary",
                              borderLeft: 1,
                              borderColor: "#a6a6a6",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#ffc4ca",
                            }}
                          >
                            {backgroundCheckData?.true_hits.warnings}
                          </Box>

                          <Box
                            sx={{
                              px: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              title="View"
                              disabled={
                                backgroundCheckData?.true_hits.warnings > 0
                                  ? false
                                  : backgroundCheckData?.discounted.warnings > 0
                                  ? false
                                  : backgroundCheckData?.review_counts.warnings > 0
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                getAlerts("warnings");
                              }}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: 0,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          Adverse media
                        </Typography>
                      </Box>
                      <Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {backgroundCheckData?.review_counts.adverse_media > 0 && (
                            <Box
                              sx={{
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Chip
                                className="square-chip"
                                label={`Review${
                                  backgroundCheckData?.review_counts.adverse_media > 1 ? "s" : ""
                                } required (${backgroundCheckData?.review_counts.adverse_media})`}
                                sx={{
                                  color: "#0b0b0b",
                                  backgroundColor: "#FCCA46",
                                  textTransform: "uppercase",
                                }}
                              />
                            </Box>
                          )}
                          <Box
                            title="Discounted"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 400,
                              fontSize: 13,
                              borderLeft: 1,
                              borderRight: 1,
                              borderColor: "divider",
                              color: "primary.light",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            {backgroundCheckData?.discounted.adverse_media}
                          </Box>
                          <Box
                            title="True hits"
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontWeight: 600,
                              color:
                                backgroundCheckData?.true_hits.adverse_media > 0
                                  ? "danger.main"
                                  : "text.primary",
                              borderLeft: 1,
                              borderColor: "#a6a6a6",
                              minWidth: "70px",
                              textAlign: "center",
                              backgroundColor: "#ffc4ca",
                            }}
                          >
                            {backgroundCheckData?.true_hits.adverse_media}
                          </Box>

                          <Box
                            sx={{
                              px: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              title="View"
                              disabled={
                                backgroundCheckData?.true_hits.adverse_media > 0
                                  ? false
                                  : backgroundCheckData?.discounted.adverse_media > 0
                                  ? false
                                  : backgroundCheckData?.review_counts.adverse_media > 0
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                getAlerts("adverse-media");
                              }}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>

                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: visibleBackgroundCheck ? 1 : 0,
                    borderTop: 1,
                    borderColor: "#ffffff",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!visibleLinkedEntities) {
                      getLinkedEntities();
                    } else {
                      setVisibleLinkedEntities(false);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Linked entities</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <IconButton sx={{ color: "#ffffff" }}>
                      {visibleLinkedEntities ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={visibleLinkedEntities} exit={!visibleLinkedEntities}>
                  {linkedEntitiesData?.length > 0 ? (
                    linkedEntitiesData.map((linkedEntityData, index) => (
                      <Box
                        key={index}
                        sx={{
                          borderTop: index == 0 ? 0 : 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <Box
                            className="link"
                            title="View case"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                            onClick={() => {
                              navigate(
                                `/case/${linkedEntityData.entity_type == 1 ? "kyc" : "kyb"}/${
                                  linkedEntityData.case_token
                                }?profile_uuid=${activeProfileUUID}`,
                                true
                              );
                              navigate(0);
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                              {linkedEntityData.name}
                            </Typography>
                          </Box>
                          {linkedEntityData.entity_type == 1 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Person />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Business />
                            </Box>
                          )}
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              borderLeft: linkedEntityData.flags?.length > 0 ? 1 : 0,
                              borderColor: "divider",
                              p: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignContent: "center",
                              gap: 1,
                            }}
                          >
                            {linkedEntityData.flags?.map((flag, index) => (
                              <Box key={index}>
                                {flag.flag_type == 1 && (
                                  <Chip
                                    className="square-chip"
                                    label="PEP"
                                    sx={{
                                      backgroundColor: "#C83E4D",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 2 && (
                                  <Chip
                                    className="square-chip"
                                    label="Sanctioned"
                                    sx={{
                                      backgroundColor: "#C83E4D",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 3 && (
                                  <Chip
                                    className="square-chip"
                                    label="Warnings"
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 4 && (
                                  <Chip
                                    className="square-chip"
                                    label="Adverse media"
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 5 && (
                                  <Chip
                                    className="square-chip"
                                    label="FATCA"
                                    sx={{
                                      backgroundColor: "#2274A5",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 6 && (
                                  <Chip
                                    className="square-chip"
                                    label="Age risk"
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 7 && (
                                  <Chip
                                    className="square-chip"
                                    label="Industry risk"
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 8 && (
                                  <Chip
                                    className="square-chip"
                                    title={flag.country?.name}
                                    label={
                                      linkedEntityData.entity_type == 1
                                        ? "Birth country"
                                        : "High risk associate"
                                    }
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 9 && (
                                  <Chip
                                    className="square-chip"
                                    title={flag.country?.name}
                                    label="Citizenship country"
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 10 && (
                                  <Chip
                                    className="square-chip"
                                    title={flag.country?.name}
                                    label={
                                      linkedEntityData.entity_type == 1
                                        ? "Telephone country"
                                        : "Address country"
                                    }
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 11 && (
                                  <Chip
                                    className="square-chip"
                                    title={flag.country?.name}
                                    label=""
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 12 && (
                                  <Chip
                                    className="square-chip"
                                    title={flag.country?.name}
                                    label={
                                      linkedEntityData.entity_type == 1
                                        ? "Counterparty country"
                                        : "Registration country"
                                    }
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}

                                {flag.flag_type == 13 && (
                                  <Chip
                                    className="square-chip"
                                    title={
                                      linkedEntityData.entity_type == 2 ? flag.country?.name : ""
                                    }
                                    label={
                                      linkedEntityData.entity_type == 1
                                        ? "Associated PEP"
                                        : "Address country"
                                    }
                                    sx={{
                                      backgroundColor: "#FCCA46",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            ))}
                          </Box>

                          <Box
                            sx={{
                              borderLeft: 1,
                              borderColor: "divider",
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {linkedEntityData.case_status == 0 && (
                              <Chip
                                className="square-chip"
                                label="Input required"
                                sx={{
                                  backgroundColor: "#1C2321",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {linkedEntityData.case_status == 1 && (
                              <Chip
                                className="square-chip"
                                label="Review"
                                sx={{
                                  backgroundColor: "#D5E950",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {linkedEntityData.case_status == 2 && (
                              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                <Box
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box>Risk score: {linkedEntityData.risk_score}</Box>
                                </Box>
                                <Box>
                                  {linkedEntityData.risk_tier == 1 && (
                                    <Chip
                                      label={linkedEntityData.risk_category}
                                      className="square-chip"
                                      sx={{
                                        backgroundColor: "#8BBF9F",
                                        color: "#1C2321",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )}
                                  {linkedEntityData.risk_tier == 2 && (
                                    <Chip
                                      label={linkedEntityData.risk_category}
                                      className="square-chip"
                                      sx={{
                                        backgroundColor: "#FCCA46",
                                        color: "#1C2321",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )}
                                  {linkedEntityData.risk_tier == 3 && (
                                    <Chip
                                      label={linkedEntityData.risk_category}
                                      className="square-chip"
                                      sx={{
                                        backgroundColor: "#C83E4D",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>
                            )}

                            {linkedEntityData.case_status == 3 && (
                              <Chip
                                className="square-chip"
                                label="Failed"
                                sx={{
                                  backgroundColor: "#C83E4D",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}

                            {linkedEntityData.case_status == 4 && (
                              <Chip
                                className="square-chip"
                                label="Assessment required"
                                sx={{
                                  backgroundColor: "#D5E950",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}

                            {linkedEntityData.case_status == 5 && (
                              <Chip
                                className="square-chip"
                                label="Waiting"
                                sx={{
                                  backgroundColor: "#2274A5",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}

                            {linkedEntityData.case_status == 6 && (
                              <Chip
                                className="square-chip"
                                label="Decision required"
                                sx={{
                                  backgroundColor: "#D5E950",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}

                            {linkedEntityData.case_status == 7 && (
                              <Chip
                                className="square-chip"
                                label="Circular Dependencies"
                                sx={{
                                  backgroundColor: "#2274A5",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <NoResults message={`There are no linked entities to display`} />
                  )}
                </Collapse>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{}}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6">Select a compliance profile</Typography>
          </Box>
          <Divider />

          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            {screeningCases.length > 0 ? (
              screeningCases.map((screeningCase, index) => (
                <Box sx={{ border: 1, borderColor: "divider" }} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => getScreeningProfile(screeningCase.profile_uuid)}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 500, color: "darkgray" }}
                          >
                            Profile name
                          </Typography>
                          <Typography variant="h6">{screeningCase.profile_name}</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      ></Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        p: 2,
                      }}
                    >
                      {screeningCase.status == 0 && (
                        <Box>
                          <Chip
                            label="Input Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {screeningCase.status == 1 && (
                        <Box>
                          <Chip
                            label="Review Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {screeningCase.status == 2 && (
                        <Box>
                          <Chip
                            label="Approved"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#8BBF9F",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {screeningCase.status == 3 && (
                        <Box>
                          <Chip
                            label="Declined"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#C83E4D",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {screeningCase.status == 4 && (
                        <Box>
                          <Chip
                            label="Assessment Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {screeningCase.status == 5 && (
                        <Box>
                          <Chip
                            label="Waiting"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {screeningCase.status == 6 && (
                        <Box>
                          <Chip
                            label="Decision Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Box>
                <NoResults />
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Drawer anchor="right" open={manageProfile} onClose={() => setManageProfile(false)}>
        <Box
          sx={{
            width: fullWidthDrawer
              ? { xs: "100vw", lg: "100vw", xl: "100vw" }
              : { xs: "90vw", lg: "75vw", xl: "50vw" },
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              px: 2,
              py: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h6">{manageProfileLabel}</Typography>
            </Box>

            <Box sx={{}}>
              <IconButton
                title="Close"
                onClick={() => {
                  setManageProfile(false);
                  setFullWidthDrawer();
                }}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ p: 2 }}>
            {manageProfileType == 1 && (
              <RegisteredName
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 2 && (
              <RegistrationNumber
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 3 && (
              <RegisteredAddress
                partnerUser={partnerUser}
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 4 && (
              <RegistrationCountry
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 5 && (
              <RegistrationDate
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 6 && (
              <CorporationType
                partnerUser={partnerUser}
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 7 && (
              <RegistryURL
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 8 && (
              <RegistrationStatus
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
              />
            )}

            {manageProfileType == 9 && (
              <BusinessDescription
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                activitiesData={activitiesData}
                getActivitiesData={() => {
                  getActivitiesData();
                }}
              />
            )}

            {manageProfileType == 10 && (
              <IndustryCodes
                partnerUser={partnerUser}
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                activitiesData={activitiesData}
                getActivitiesData={() => {
                  getActivitiesData();
                }}
              />
            )}

            {manageProfileType == 11 && (
              <IndustryConsiderations
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                activitiesData={activitiesData}
                getActivitiesData={() => {
                  getActivitiesData();
                }}
              />
            )}

            {manageProfileType == 12 && (
              <Links
                partnerUser={partnerUser}
                entityData={entityData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                activitiesData={activitiesData}
                getActivitiesData={() => {
                  getActivitiesData();
                }}
              />
            )}

            {manageProfileType == 13 && (
              <PrimaryCurrency
                partnerUser={partnerUser}
                entityData={entityData}
                financialData={financialData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getFinancialData={() => {
                  getFinancialData();
                }}
              />
            )}

            {manageProfileType == 14 && (
              <StartupFunding
                partnerUser={partnerUser}
                entityData={entityData}
                financialData={financialData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getFinancialData={() => {
                  getFinancialData();
                }}
              />
            )}

            {manageProfileType == 15 && (
              <LastYearRevenue
                partnerUser={partnerUser}
                entityData={entityData}
                financialData={financialData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getFinancialData={() => {
                  getFinancialData();
                }}
              />
            )}

            {manageProfileType == 16 && (
              <ProjectedRevenue
                partnerUser={partnerUser}
                entityData={entityData}
                financialData={financialData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getFinancialData={() => {
                  getFinancialData();
                }}
              />
            )}

            {manageProfileType == 17 && (
              <CorporateDocuments
                partnerUser={partnerUser}
                entityData={entityData}
                financialData={financialData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getCorporateDocuments={() => {
                  getCorporateDocuments();
                }}
              />
            )}

            {manageProfileType == 18 && <DocumentViewer viewDocumentData={viewDocumentData} />}

            {manageProfileType == 19 && (
              <Officers
                partnerUser={partnerUser}
                entityData={entityData}
                financialData={financialData}
                setManageProfile={setManageProfile}
                profileUUID={activeProfileUUID}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                officersData={officersData}
                getOfficersData={() => getOfficersData()}
                completeAvailable={officersCompletion}
                setCompleteAvailable={setOfficersCompletion}
              />
            )}

            {manageProfileType == 20 && (
              <Ownership
                partnerUser={partnerUser}
                entityData={entityData}
                financialData={financialData}
                setManageProfile={setManageProfile}
                profileUUID={activeProfileUUID}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                ownershipData={ownershipData}
                getOwnershipData={() => getOwnershipData()}
                getOwnershipChartData={() => getOwnershipChartData()}
                completeAvailable={ownershipCompletion}
                setCompleteAvailable={setOwnershipCompletion}
                nodes={nodes}
                setNodes={setNodes}
                edges={edges}
                setEdges={setEdges}
                setFullWidthDrawer={setFullWidthDrawer}
                getCorporation={getCorporation}
                getCorporationShareholders={getCorporationShareholders}
                activeShareholderEntity={activeShareholderEntity}
                setActiveShareholderEntity={setActiveShareholderEntity}
                activeShareholderEntityShareholders={activeShareholderEntityShareholders}
                setActiveShareholderEntityShareholders={setActiveShareholderEntityShareholders}
              />
            )}

            {manageProfileType == 21 && (
              <FatcaCorporate
                partnerUser={partnerUser}
                entityData={entityData}
                profileUUID={activeProfileUUID}
                fatcaData={fatcaData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getFatcaData={() => {
                  getFatcaData();
                }}
              />
            )}

            {manageProfileType == 22 && (
              <CrsCorporate
                partnerUser={partnerUser}
                entityData={entityData}
                profileUUID={activeProfileUUID}
                crsData={crsData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getCrsData={() => {
                  getCrsData();
                }}
              />
            )}

            {manageProfileType == 23 && (
              <BackgroundChecks
                partnerUser={partnerUser}
                entityData={entityData}
                profileUUID={activeProfileUUID}
                caseData={activeProfileData}
                setManageProfile={setManageProfile}
                getProfileData={() => {
                  getScreeningProfile(activeProfileUUID);
                }}
                getEntityData={() => {
                  getEntityData();
                }}
                getBackgroundCheck={() => {
                  getBackgroundCheck();
                }}
                alertsType={alertsType}
                alertsData={alertsData}
                getAlerts={getAlerts}
              />
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Oversight;
