import { React, useEffect, useState } from "react";

import partner from "../../../../../../api/partner";
import central from "../../../../../../api/central";
import authHeader from "../../../../../../api/authHeader";

import HandleError from "../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import NoResults from "../../../../../../components/functions/NoResults";

import {
  Typography,
  Box,
  Button,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Add, Close, Delete, Edit } from "@mui/icons-material";

const Countries = ({ partner, partnerUser, partnerData, profileUUID }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);

  const [manageCountries, setManageCountries] = useState(false);

  const [removeDialog, setRemoveDialog] = useState(false);
  const [removeCountry, setRemoveCountry] = useState("");
  const [removeCountryName, setRemoveCountryName] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Country",
      sortable: true,
      width: "75%",
      selector: (row) => row.name,
    },
    {
      name: "Flags",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          {row.conflict && (
            <Chip
              className="square-chip"
              label="Conflict"
              size="small"
              sx={{
                backgroundColor: "#FF4A1C",
                color: "#1C2321",
                textTransform: "uppercase",
                minWidth: "180px",
              }}
            />
          )}
          {row.borders_conflict && (
            <Chip
              className="square-chip"
              label="Borders conflict"
              size="small"
              sx={{
                backgroundColor: "#977390",
                color: "#1C2321",
                textTransform: "uppercase",
                minWidth: "180px",
              }}
            />
          )}
          {row.is_secrecy && (
            <Chip
              className="square-chip"
              label="Secrecy"
              size="small"
              sx={{
                backgroundColor: "#999999",
                color: "#1C2321",
                textTransform: "uppercase",
                minWidth: "180px",
              }}
            />
          )}
        </Box>
      ),
    },
  ];

  const manageColumns = [
    {
      name: "Country name",
      sortable: true,
      width: "25%",
      selector: (row) => row.name,
    },
    {
      name: "FATF",
      sortable: true,
      selector: (row) => row.fatf,
      cell: (row) =>
        row.fatf ? (
          row.fatf == 1 ? (
            <Chip
              className="square-chip"
              label="Black"
              color="primary"
              sx={{ textTransform: "uppercase" }}
            />
          ) : (
            <Chip
              className="square-chip"
              label="Grey"
              sx={{ textTransform: "uppercase", backgroundColor: "#999999", color: "#ffffff" }}
            />
          )
        ) : (
          "-"
        ),
    },
    {
      name: "Basel index rating",
      sortable: true,
      selector: (row) => row.basel_score,
      cell: (row) => (row.basel_score > 0 ? row.basel_score : "-"),
    },
    {
      name: "Corruption perception index",
      sortable: true,
      selector: (row) => row.corruption_perception,
      cell: (row) => (row.corruption_perception > 0 ? row.corruption_perception : "-"),
    },
    {
      name: "Flags",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          {row.conflict && (
            <Box>
              <Chip
                className="square-chip"
                label="Conflict"
                size="small"
                sx={{
                  backgroundColor: "#FF4A1C",
                  color: "#1C2321",
                  textTransform: "uppercase",
                  minWidth: "180px",
                }}
              />
            </Box>
          )}
          {row.borders_conflict && (
            <Box>
              <Chip
                className="square-chip"
                label="Borders conflict"
                size="small"
                sx={{
                  backgroundColor: "#977390",
                  color: "#1C2321",
                  textTransform: "uppercase",
                  minWidth: "180px",
                }}
              />
            </Box>
          )}
          {row.is_secrecy && (
            <Box>
              <Chip
                className="square-chip"
                label="Secrecy"
                size="small"
                sx={{
                  backgroundColor: "#999999",
                  color: "#1C2321",
                  textTransform: "uppercase",
                  minWidth: "180px",
                }}
              />
            </Box>
          )}
        </Box>
      ),
    },
    {
      id: "options",
      name: "Options",
      sortable: true,
      right: "true",
      width: "10%",
      selector: (row) => row.high_risk,
      cell: (row) => (
        <Box>
          {row.high_risk ? (
            <IconButton color="danger" onClick={() => removeCountryDialog(row.code, row.name)}>
              <Delete />
            </IconButton>
          ) : (
            <IconButton color="success" onClick={() => handleAddCountry(row.code)}>
              <Add />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getPartnerCountries = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/data/countries`,
        JSON.stringify({
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleRemoveCountry = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/settings/countries/remove`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          country_code: removeCountry,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOptions();
      getPartnerCountries();
      setRemoveDialog(false);
      setRemoveCountry("");
      displayToast("Country removed", () => {});
    } catch (err) {
      handleError(err);
    }
  };

  const handleAddCountry = async (countryCode) => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/settings/countries/add`,
        JSON.stringify({
          country_code: countryCode,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );
      getOptions();
      getPartnerCountries();
      displayToast("Country added", () => {});
    } catch (err) {
      handleError(err);
    }
  };

  const getOptions = async () => {
    try {
      const response = await central.get(`/v1/service/countries/list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
          partner_uuid: partnerUser.value.partner_uuid,
        },

        //withCredentials: true,
      });

      console.log(response.data.entries);

      if (response.data.results > 0) {
        var sourceOptions = [];

        response.data.entries.map((entry) => {
          var high_risk = false;

          if (data?.length > 0) {
            data.map((source) => {
              if (source.code == entry.code) {
                high_risk = true;
              }
              if (high_risk) {
                return;
              }
            });
          }

          sourceOptions = [
            ...sourceOptions,
            {
              name: entry.name,
              code: entry.code,
              conflict: entry.conflict,
              borders_conflict: entry.borders_conflict,
              is_secrecy: entry.is_secrecy,
              high_risk: high_risk,
              basel_score: entry.basel_score,
              corruption_perception: entry.corruption_perception,
              fatf: entry.fatf,
            },
          ];
        });

        var sortedOptions = sourceOptions.sort((a, b) => {
          if (a.high_risk > b.high_risk) {
            return -1;
          }
          if (a.high_risk < b.high_risk) {
            return 1;
          }

          return 0;
        });

        setOptionsData(sortedOptions);
      } else {
        setOptionsData([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const removeCountryDialog = (countryCode, countryName) => {
    setRemoveCountryName(countryName);
    setRemoveDialog(true);
    setRemoveCountry(countryCode);
  };

  useEffect(() => {
    getPartnerCountries();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">High risk countries</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "fit-content",
                  }}
                >
                  <Button
                    onClick={() => {
                      getOptions();
                      setManageCountries(!manageCountries);
                    }}
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={manageCountries ? <Close /> : <Edit />}
                  >
                    {manageCountries ? "Close" : "Edit selected countries"}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Divider />
            {manageCountries ? (
              <DataTable
                columns={manageColumns}
                data={optionsData}
                customStyles={customStyles}
                defaultSortFieldId="options"
                pagination
                striped
              />
            ) : (
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                striped
                noDataComponent={
                  <NoResults message={`You must add at least one high risk country`} />
                }
              />
            )}
          </Box>

          <Dialog
            open={removeDialog}
            onClose={() => setRemoveDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to remove {removeCountryName} as a high risk country?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                disableElevation
                loading={processing}
                onClick={() => setRemoveDialog(false)}
                color="primary"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleRemoveCountry();
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Countries;
