import { React, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";

function RegistrationDate({ entityData, setManageProfile, getEntityData, getProfileData }) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [processing, setProcessing] = useState(false);

  const [registrationDate, setRegistrationDate] = useState(
    entityData.value.registration_date != "" ? moment(entityData.value.registration_date) : null
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/registration/date`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          registration_date: registrationDate.format("YYYY-MM-DD"),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();

      displayToast("Registration date updated successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
        <DatePicker
          fullWidth
          openTo="year"
          label={`Registration date`}
          value={registrationDate ? registrationDate : null}
          onChange={(date) => {
            setRegistrationDate(date);
          }}
          slotProps={{ textField: { fullWidth: true } }}
          maxDate={moment()}
        />
      </LocalizationProvider>

      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <LoadingButton
          disabled={
            entityData.value?.registration_date == registrationDate.format("YYYY-MM-DD")
              ? true
              : false
          }
          disableElevation
          loading={processing}
          onClick={(e) => {
            handleUpdate(e);
          }}
          variant="contained"
          color="success"
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default RegistrationDate;
