import { React, useState, useEffect } from "react";

import partner from "../../../../api/partner";
import central from "../../../../api/central";
import authHeader from "../../../../api/authHeader";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import DataTable from "react-data-table-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Canvas, Node, Label, Edge } from "reaflow";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Drawer,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import {
  Edit,
  Close,
  Delete,
  ImportContacts,
  People,
  Business,
  Group,
  Diversity3,
  Check,
  ArrowForward,
  Person,
  ArrowBack,
  GppGood,
  Lan,
  List,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import NoResults from "../../../../components/functions/NoResults";
import CountrySelect from "../../../../components/form-elements/CountrySelect";
import { act } from "react";

function Ownership({
  partnerUser,
  entityData,
  setManageProfile,
  getEntityData,
  profileUUID,
  getProfileData,
  ownershipData,
  getOwnershipData,
  getOwnershipChartData,
  completeAvailable,
  setCompleteAvailable,
  nodes,
  setNodes,
  edges,
  setEdges,
  setFullWidthDrawer,
  activeShareholderEntity,
  setActiveShareholderEntity,
  getCorporation,
  getCorporationShareholders,
  activeShareholderEntityShareholders,
  setActiveShareholderEntityShareholders,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [processing, setProcessing] = useState(false);

  const [editShareholder, setEditShareholder] = useState(false);
  const [newShareholder, setNewShareholder] = useState(false);

  const [activeShareholder, setActiveShareholder] = useState(ownershipData);

  const [rolodexOpen, setRolodexOpen] = useState(false);
  const [rolodexLetter, setRolodexLetter] = useState("A");
  const [rolodexData, setRolodexData] = useState();
  const [rolodexUUID, setRolodexUUID] = useState("");

  const [title, setTitle] = useState();
  const [firstName, setFirstName] = useState();
  const [middleNames, setMiddleNames] = useState();
  const [lastName, setLastName] = useState();

  const [lookupCorporation, setLookupCorporation] = useState(true);
  const [lookupName, setLookupName] = useState("");
  const [corporationOptions, setCorporationOptions] = useState([]);
  const [selectedCorporation, setSelectedCorporation] = useState("");

  const [registrationTypeOptions, setRegistrationTypeOptions] = useState([]);
  const [registrationType, setRegistrationType] = useState("");
  const [corporationTypeLabel, setCorporationTypeLabel] = useState("");
  const [registrationTypeData, setRegistrationTypeData] = useState({});
  const [registrationName, setRegistrationName] = useState("");
  const [registrationReference, setRegistrationReference] = useState("");
  const [registrationDate, setRegistrationDate] = useState(null);
  const [registrationCountry, setRegistrationCountry] = useState();

  const [ownershipAmount, setOwnershipAmount] = useState("");
  const [editShareholderAmount, setEditShareholderAmount] = useState("");

  const [currentShownMenu, setCurrentShownMenu] = useState(0);
  const [confirmAvailable, setConfirmAvailable] = useState(false);

  const [removeShareholderDialog, setRemoveShareholderDialog] = useState(false);
  const [removeShareholder, setRemoveShareholder] = useState();

  const [ownershipView, setOwnershipView] = useState(1);

  const [editBackEntity, setEditBackEntity] = useState(null);

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const filterOptions = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "#",
  ];

  const handleLookupCorporations = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/corporations/lookup/options`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          search_terms: lookupName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setCorporationOptions(response.data.entries);
      } else {
        setCorporationOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getRegistrationTypeOptions = async () => {
    if (!registrationCountry) {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/types/options`,
        JSON.stringify({
          country: registrationCountry.value ? registrationCountry.value : registrationCountry.code,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRegistrationTypeOptions(response.data.entries);
      } else {
        setRegistrationTypeOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getRegistrationTypeData = async () => {
    if (registrationType == "") {
      return;
    }

    try {
      const response = await central.post(
        `/v1/service/corporations/type-data`,
        JSON.stringify({
          type_uuid: registrationType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationTypeData(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  const getCorporationData = async () => {
    if (selectedCorporation == "") {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          uuid: selectedCorporation,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationName(response.data.name);
      setRegistrationReference(response.data.company_number);
      setRegistrationDate(response.data.incorporation_date);
      setRegistrationCountry(response.data.country);
      setCorporationTypeLabel(response.data.company_type);
    } catch (err) {
      handleError(err);
    }
  };

  const getRolodexData = async (letter, dataType) => {
    try {
      const response = await partner.post(
        `/v1/entity/rolodex/options`,
        JSON.stringify({
          data_type: dataType,
          letter: letter,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setRolodexLetter(letter);

      if (response.data.results > 0) {
        setRolodexData(response.data.entries);
      } else {
        setRolodexData([]);
      }

      setRolodexOpen(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getRolodexEntry = async (uuid, dataType) => {
    try {
      const response = await partner.post(
        `/v1/entity/rolodex/data`,
        JSON.stringify({
          entity_type: dataType,
          entity_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (dataType == 1) {
        setTitle(response.data.title);
        setFirstName(response.data.first_name);
        setMiddleNames(response.data.middle_names);
        setLastName(response.data.last_name);
      } else {
        setRegistrationName(response.data.registered_name);
        setRegistrationReference(response.data.registration_reference);
        setRegistrationDate(
          response.data.registration_date ? moment(response.data.registration_date) : ""
        );
        if (response.data.country?.code != "") {
          setRegistrationCountry({
            value: response.data.country?.code ? response.data.country.code : "",
            name: response.data.country?.name ? response.data.country.name : "",
          });
        }
        setRegistrationType(response.data.registration_type);
        setLookupCorporation(false);
      }

      setRolodexOpen(false);
    } catch (err) {
      handleError(err);
    }
  };

  const handleAddShareholder = async (e, shareholderType) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/ownership/add`,
        JSON.stringify({
          corporation_uuid: editBackEntity
            ? editBackEntity.entity_uuid
            : entityData.value.corporation_uuid,
          shareholder_type: shareholderType,

          entity_uuid: rolodexUUID,

          shareholding: parseFloat(ownershipAmount),

          title: title,
          first_name: firstName,
          middle_names: middleNames,
          last_name: lastName,

          corporation_registered_name: registrationName,
          corporation_registration_type: registrationType,
          corporation_registration_reference: registrationReference,
          corporation_registration_country: registrationCountry?.value
            ? registrationCountry.value
            : registrationCountry?.code
            ? registrationCountry.code
            : "",
          corporation_registration_date: registrationDate?.startDate
            ? registrationDate.startDate
            : "",
          corporation_lookup_uuid: selectedCorporation ? selectedCorporation : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOwnershipData();
      getOwnershipChartData();
      getEntityData();
      getProfileData();

      displayToast("Shareholder added successfully", () => {
        setProcessing(false);
        setNewShareholder(false);
        setRolodexData([]);
        setRolodexOpen(false);
        setRolodexUUID("");
        setRolodexLetter("A");
        setCurrentShownMenu(0);
        setOwnershipAmount("");
        resetForms();
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleEditShareholder = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/ownership/update`,
        JSON.stringify({
          target_entity: editBackEntity
            ? editBackEntity.entity_uuid
            : entityData.value.corporation_uuid,
          shareholder_uuid: activeShareholder.shareholder_uuid
            ? activeShareholder.shareholder_uuid
            : "",
          root_corporation: entityData.value.corporation_uuid,
          percentage: parseFloat(editShareholderAmount),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOwnershipData();
      getOwnershipChartData();
      getEntityData();
      getProfileData();

      displayToast("Shareholder updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteShareholder = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/ownership/remove`,
        JSON.stringify({
          target_entity: editBackEntity
            ? editBackEntity.entity_uuid
            : entityData.value.corporation_uuid,
          shareholder_uuid: activeShareholder.shareholder_uuid
            ? activeShareholder.shareholder_uuid
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfileData();
      setRemoveShareholderDialog(false);
      getOwnershipData();
      getOwnershipChartData();
      setCompleteAvailable(false);

      displayToast("Shareholder removed successfully", () => {
        setProcessing(false);
        setEditShareholder(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleConfirmShareholders = async (e) => {
    e.preventDefault();
    setProcessing(true);

    console.log(profileUUID);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/ownership/confirm`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          profile_uuid: profileUUID,
          nodes: nodes,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfileData();
      getEntityData();
      getOwnershipData();
      getOwnershipChartData();
      setCompleteAvailable(false);

      displayToast("Shareholder and management information confirmed", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const resetForms = () => {
    setTitle();
    setFirstName("");
    setMiddleNames("");
    setLastName("");

    setLookupName("");
    setLookupCorporation(true);
    setCorporationOptions([]);
    setSelectedCorporation("");
    setRegistrationType("");
    setRegistrationTypeOptions([]);
    setRegistrationTypeData({});
    setRegistrationName("");
    setRegistrationReference("");
    setRegistrationDate(null);
    setRegistrationCountry(null);
    setRolodexOpen(false);
    setRolodexLetter("A");
    setRolodexData([]);

    setOwnershipAmount("");
    setEditShareholderAmount("");
  };

  useEffect(() => {
    if (edges?.length > 0) {
      calculateApparentControl();
    }
  }, [edges]);

  function calculateApparentControl() {
    let nodeList = [];

    nodeList = recursiveSearch(100, entityData.value.corporation_uuid, nodeList);

    let toSet = createUniquenodeList(nodeList);

    //console.log(`To Set`);
    //console.log(toSet);
    setNodes(toSet);

    return;
  }

  function createUniquenodeList(nodeList) {
    let newList = [];
    nodeList.forEach((node) => {
      const index = newList.findIndex((currentNode) => {
        return currentNode.id === node.id;
      });

      if (index === -1) {
        newList.push(node);
      } else {
        const current = newList[index];
        const roundedIndirect = current.data.indirect_control + node.data.indirect_control;

        const newNode = {
          ...current,
          data: {
            ...current.data,
            direct_control: node.data.direct_control,
            indirect_control: roundedIndirect,
          },
        };

        newList[index] = newNode;
      }
    });
    return newList;
  }

  function recursiveSearch(control, nodeID, nodeList, isDirect = false) {
    // console.log(`Node ID (recursiveSearch): ${nodeID}`);

    let newList = edges.filter((edge) => {
      return edge.from === nodeID;
    });

    //console.log(`New List (recursiveSearch)`);
    //console.log(newList);

    nodeList.push(setNodeControl(nodeID, control, isDirect));

    //console.log(`Node List (recursiveSearch)`);
    // console.log(nodeList);
    //console.log(`Edges (recursiveSearch)`);
    newList.forEach((edge) => {
      let newControl = control * (edge.value / 100);
      // console.log(`New Control (recursiveSearch) ${edge.to} -> ${edge.from} : ${control} -> ${newControl}`);
      nodeList.concat(
        recursiveSearch(
          newControl,
          edge.to,
          nodeList,
          edge.from == entityData.value.corporation_uuid ? true : false
        )
      );
    });

    return nodeList;
  }

  function setNodeControl(nodeId, control, isDirect) {
    const index = nodes.findIndex((node) => {
      //console.log(`Node ID (setNodeControl): ${node.id}`);
      return node.id === nodeId;
    });

    let roundedControl = control.toFixed(2);

    if (Number.isInteger(control)) {
      roundedControl = control;
    }

    let newNode = {};

    isDirect
      ? (newNode = {
          ...nodes[index],
          data: {
            ...nodes[index].data,
            direct_control: parseFloat(roundedControl),
          },
        })
      : (newNode = {
          ...nodes[index],
          data: {
            ...nodes[index].data,
            indirect_control: parseFloat(roundedControl),
          },
        });

    //console.log(`New Node (setNodeControl)`);
    //console.log(isDirect);
    //console.log(newNode);

    return newNode;
  }

  return (
    <Box>
      {editShareholder ? (
        <Box>
          {editBackEntity && (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 600, mb: 2 }}>
                Editing {activeShareholder.shareholder_name} shareholding of{" "}
                {editBackEntity.shareholder_name}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              border: 1,
              borderColor: "divider",
            }}
          >
            <Box sx={{ p: 1, borderRight: 1, borderColor: "divider" }}>
              <IconButton
                onClick={() => {
                  setEditShareholder(editBackEntity ? true : false);
                  setActiveShareholder(editBackEntity ? editBackEntity : activeShareholder);
                  setEditBackEntity(null);
                  resetForms();
                }}
              >
                <ArrowBack />
              </IconButton>
            </Box>
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h6">{activeShareholder.shareholder_name}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  {activeShareholder.entity_type == 1
                    ? !activeShareholder.kyc_complete && (
                        <Box
                          className="square-chip"
                          sx={{
                            display: "flex",
                            flexDirection: "row",

                            backgroundColor: "#8BBF9F",
                            px: 2,
                            py: 1,
                          }}
                        >
                          <GppGood />

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              paddingLeft: "3px",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, fontSize: 12 }}>
                              KYC complete
                            </Typography>
                          </Box>
                        </Box>
                      )
                    : activeShareholder.kyb_complete && (
                        <Box
                          className="square-chip"
                          sx={{
                            display: "flex",
                            flexDirection: "row",

                            backgroundColor: "#8BBF9F",
                            px: 2,
                            py: 1,
                          }}
                        >
                          <GppGood />

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              paddingLeft: "3px",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, fontSize: 12 }}>
                              KYB complete
                            </Typography>
                          </Box>
                        </Box>
                      )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ border: 1, borderTop: 0, borderColor: "divider" }}>
            {activeShareholder.shareholder_type == 2 && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 600, fontStyle: "italic", marginBottom: 1 }}
                    >
                      Shareholders (control assigned: {activeShareholder.control_assigned} %)
                    </Typography>
                  </Box>
                  {activeShareholder.control_assigned < 100 && (
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setActiveShareholder(activeShareholder);
                        setEditBackEntity(activeShareholder);
                        setEditShareholder(false);
                        setNewShareholder(true);
                      }}
                    >
                      Add a shareholder
                    </Button>
                  )}
                </Box>

                {activeShareholder.shareholders?.length > 0 && (
                  <Box sx={{ p: 2, pt: 0, borderBottom: 1, borderColor: "divider" }}>
                    {activeShareholder.shareholders.map((shareholder, index) => (
                      <Box
                        key={index}
                        sx={{
                          border: 1,
                          borderTop: index == 0 ? 1 : 0,
                          borderColor: "divider",
                          p: 2,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                justifyContent: "space-between",
                              }}
                            >
                              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body1" sx={{ fontWeight: 400 }}>
                                    {shareholder.percentage} %
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                    {shareholder.shareholder_name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                {shareholder.shareholder_type == 2 && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                    title="Corporate shareholder"
                                  >
                                    <Business />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                              {shareholder.shareholder_type == 1
                                ? shareholder.kyc_complete && (
                                    <Box
                                      className="square-chip"
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",

                                        backgroundColor: "#8BBF9F",
                                        px: 2,
                                        py: 1,
                                      }}
                                    >
                                      <GppGood />

                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          paddingLeft: "3px",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontWeight: 600, fontSize: 12 }}
                                        >
                                          KYC complete
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )
                                : shareholder.shareholder_type == 2 && (
                                    <Box>
                                      {shareholder.control_assigned < 100 && (
                                        <Chip
                                          className="square-chip"
                                          label="Input required"
                                          sx={{
                                            backgroundColor: "#2274A5",
                                            color: "#ffffff",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      )}

                                      {shareholder.kyb_complete && (
                                        <Box
                                          className="square-chip"
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",

                                            backgroundColor: "#8BBF9F",
                                            px: 2,
                                            py: 1,
                                          }}
                                        >
                                          <GppGood />

                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              paddingLeft: "3px",
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontWeight: 600, fontSize: 12 }}
                                            >
                                              KYB complete
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  )}
                              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                  startIcon={<Edit />}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    if (shareholder.shareholder_type == 2) {
                                      setActiveShareholder(shareholder);
                                      setEditShareholder(true);
                                      setEditShareholderAmount(shareholder.percentage);
                                      setEditBackEntity(activeShareholder);
                                    } else {
                                      setActiveShareholder(shareholder);
                                      setEditShareholder(true);
                                      setEditShareholderAmount(shareholder.percentage);
                                      setEditBackEntity(activeShareholder);
                                    }
                                  }}
                                >
                                  Edit
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          {shareholder.shareholder_type == 2 &&
                            shareholder.shareholders?.length > 0 && (
                              <Box sx={{}}>
                                <Box sx={{}}>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 600, fontStyle: "italic", marginBottom: 1 }}
                                  >
                                    Shareholders
                                  </Typography>
                                </Box>
                                {shareholder.shareholders.map((subShareholder, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      gap: 4,

                                      border: 1,
                                      borderTop: index == 0 ? 1 : 0,
                                      borderColor: "divider",
                                      p: 1,
                                    }}
                                  >
                                    <Box sx={{}}>
                                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                        {subShareholder.shareholder_name}
                                      </Typography>
                                    </Box>
                                    <Box sx={{}}>
                                      <Typography variant="body1" sx={{ fontWeight: 400 }}>
                                        {subShareholder.percentage} %
                                      </Typography>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
              <TextField
                label="Ownership amount"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={editShareholderAmount}
                onChange={(e) => setEditShareholderAmount(e.target.value)}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}>
              <Button
                variant="outlined"
                startIcon={<Delete />}
                title="Delete shareholder"
                color="error"
                onClick={() => {
                  console.log("clicked");
                  setRemoveShareholder(activeShareholder?.entity_uuid);
                  setRemoveShareholderDialog(true);
                }}
              >
                Remove
              </Button>
              <LoadingButton
                disableElevation
                variant="contained"
                color="success"
                onClick={(e) => handleEditShareholder(e)}
                loading={processing}
              >
                Save changes
              </LoadingButton>
            </Box>
          </Box>

          <Dialog
            open={removeShareholderDialog}
            onClose={() => setRemoveShareholderDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to remove {activeShareholder.shareholder_name} as a
                  shareholder of ...?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                disableElevation
                loading={processing}
                onClick={() => setRemoveShareholderDialog(false)}
                color="primary"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleDeleteShareholder(e);
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>
      ) : newShareholder ? (
        <Box>
          {activeShareholder && (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 600, mb: 2 }}>
                Adding a shareholder to {activeShareholder.shareholder_name}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              border: 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                p: 1,
                borderRight: 1,
                borderColor: "divider",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  if (editBackEntity) {
                    setActiveShareholder(editBackEntity);
                    setEditShareholder(true);
                    setNewShareholder(false);
                    setCurrentShownMenu(0);
                    setEditBackEntity(null);
                    resetForms();
                  } else {
                    setEditBackEntity(null);
                    setNewShareholder(false);
                    setCurrentShownMenu(0);
                    resetForms();
                  }
                }}
              >
                <ArrowBack />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 2,
                p: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2">
                  Select the type of entity you would like to add
                </Typography>
              </Box>
              <ToggleButtonGroup
                color="primary"
                value={currentShownMenu}
                exclusive
                onChange={(e, newValue) => {
                  setCurrentShownMenu(newValue);
                }}
                aria-label="Case type"
              >
                <ToggleButton value={1} onClick={() => setCurrentShownMenu(1)}>
                  Individual
                </ToggleButton>
                <ToggleButton value={2} onClick={() => setCurrentShownMenu(2)}>
                  Corporation
                </ToggleButton>
                <ToggleButton value={3} onClick={() => setCurrentShownMenu(3)}>
                  Minority group
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          {currentShownMenu === 1 && (
            <Box>
              <Box sx={{}}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Personal information</strong>
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        {rolodexOpen ? (
                          <Button
                            startIcon={<Close />}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              resetForms();
                              setRolodexOpen(false);
                            }}
                          >
                            Close
                          </Button>
                        ) : (
                          <Button
                            startIcon={<ImportContacts />}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              getRolodexData("A", 1);
                            }}
                          >
                            Rolodex
                          </Button>
                        )}
                      </Box>
                    </Box>

                    <Divider />

                    {rolodexOpen ? (
                      <Box>
                        <Grid container>
                          {filterOptions.map((option, index) => (
                            <Grid
                              item
                              xs={1}
                              key={index}
                              onClick={() => {
                                getRolodexData(option, 1);
                              }}
                              sx={{
                                border: 1,
                                borderTop: index < 11 ? 0 : 0,
                                borderLeft: 0,
                                borderRight: index == 11 || index == 23 ? 0 : 1,
                                borderBottom: index > 23 ? 0 : 1,
                                py: 1,
                                textAlign: "center",
                                fontWeight: "bold",
                                borderColor: "divider",
                                cursor: "pointer",
                                background: rolodexLetter == option ? "#0b0b0b" : "",
                                color: rolodexLetter == option ? "#ffffff" : "",
                              }}
                            >
                              {option}
                            </Grid>
                          ))}
                        </Grid>
                        <Divider />

                        {rolodexData && rolodexData.length > 0 ? (
                          rolodexData.map((entry, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                px: 2,
                                py: 1,
                                borderBottom: index == rolodexData.length - 1 ? 0 : 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
                                <Box
                                  sx={{
                                    minWidth: "20%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "14px" }}>{entry.name}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                  {entry.country.code && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {entry.country.name}
                                      </Typography>
                                    </Box>
                                  )}
                                  {entry.kyc_complete && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Chip
                                        className="square-chip"
                                        label="KYC Complete"
                                        sx={{
                                          backgroundColor: "#55828b",
                                          color: "#ffffff",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {entry.kyb_complete && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Chip
                                        className="square-chip"
                                        label="KYB Complete"
                                        sx={{
                                          backgroundColor: "#474044",
                                          color: "#ffffff",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>

                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setRolodexUUID(entry.entity_uuid);
                                  getRolodexEntry(entry.entity_uuid, entry.entity_type);
                                }}
                              >
                                <ArrowForward />
                              </IconButton>
                            </Box>
                          ))
                        ) : (
                          <NoResults message={`There are no entities under that character`} />
                        )}
                      </Box>
                    ) : (
                      <Grid sx={{ p: 2 }} container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormControl fullWidth>
                            <InputLabel id="name-title-label">Title</InputLabel>
                            <Select
                              disabled={rolodexUUID ? true : false}
                              labelId="name-title"
                              id="name-title"
                              value={title}
                              label="Title"
                              onChange={(e) => setTitle(e.target.value)}
                            >
                              {titleOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="First name"
                            variant="outlined"
                            value={firstName}
                            disabled={rolodexUUID ? true : false}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Middle name(s)"
                            variant="outlined"
                            value={middleNames}
                            disabled={rolodexUUID ? true : false}
                            onChange={(e) => setMiddleNames(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Last name"
                            variant="outlined"
                            value={lastName}
                            disabled={rolodexUUID ? true : false}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {!rolodexOpen && (
                            <Box sx={{}}>
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="subtitle2">
                                  Enter the shareholding percentage
                                </Typography>
                                <TextField
                                  label="Ownership amount"
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  value={ownershipAmount}
                                  onChange={(e) => setOwnershipAmount(e.target.value)}
                                />
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mt: 2,
                                }}
                              >
                                <Box>
                                  {rolodexUUID && (
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        resetForms();
                                        setRolodexUUID("");
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  )}
                                </Box>
                                <LoadingButton
                                  disableElevation
                                  variant="contained"
                                  color="success"
                                  onClick={(e) => handleAddShareholder(e, 1)}
                                  loading={processing}
                                >
                                  Save
                                </LoadingButton>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {currentShownMenu === 2 && (
            <Box>
              <Box sx={{}}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Corporation information</strong>
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        {rolodexOpen ? (
                          <Button
                            startIcon={<Close />}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              resetForms();
                              setRolodexOpen(false);
                            }}
                          >
                            Close
                          </Button>
                        ) : (
                          <Button
                            startIcon={<ImportContacts />}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              getRolodexData("A", 2);
                            }}
                          >
                            Rolodex
                          </Button>
                        )}
                      </Box>
                    </Box>

                    <Divider />

                    {rolodexOpen ? (
                      <Box>
                        <Grid container>
                          {filterOptions.map((option, index) => (
                            <Grid
                              item
                              xs={1}
                              key={index}
                              onClick={() => {
                                getRolodexData(option, 2);
                              }}
                              sx={{
                                border: 1,
                                borderTop: index < 11 ? 0 : 0,
                                borderLeft: 0,
                                borderRight: index == 11 || index == 23 ? 0 : 1,
                                borderBottom: index > 23 ? 0 : 1,
                                py: 1,
                                textAlign: "center",
                                fontWeight: "bold",
                                borderColor: "divider",
                                cursor: "pointer",
                                background: rolodexLetter == option ? "#0b0b0b" : "",
                                color: rolodexLetter == option ? "#ffffff" : "",
                              }}
                            >
                              {option}
                            </Grid>
                          ))}
                        </Grid>
                        <Divider />

                        <Divider />

                        {rolodexData && rolodexData.length > 0 ? (
                          rolodexData.map((entry, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                px: 2,
                                py: 1,
                                borderBottom: index == rolodexData.length - 1 ? 0 : 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
                                <Box
                                  sx={{
                                    minWidth: "20%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "14px" }}>{entry.name}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                  {entry.country.code && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {entry.country.name}
                                      </Typography>
                                    </Box>
                                  )}
                                  {entry.kyc_complete && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Chip
                                        className="square-chip"
                                        label="KYC Complete"
                                        sx={{
                                          backgroundColor: "#55828b",
                                          color: "#ffffff",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {entry.kyb_complete && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Chip
                                        className="square-chip"
                                        label="KYB Complete"
                                        sx={{
                                          backgroundColor: "#474044",
                                          color: "#ffffff",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>

                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setRolodexUUID(entry.entity_uuid);
                                  getRolodexEntry(entry.entity_uuid, entry.entity_type);
                                }}
                              >
                                <ArrowForward />
                              </IconButton>
                            </Box>
                          ))
                        ) : (
                          <NoResults message={`There are no entities under that character`} />
                        )}
                      </Box>
                    ) : (
                      <Grid sx={{ p: 2 }} container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box sx={{ border: 1, borderColor: "divider" }}>
                            <Box sx={{ p: 2 }}>
                              <Typography variant="body2">
                                <strong>
                                  How would you like to input the business information?
                                </strong>
                              </Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                              <ToggleButtonGroup
                                color="primary"
                                value={lookupCorporation}
                                exclusive
                                onChange={(e, newValue) => {
                                  setLookupCorporation(newValue);
                                  setRegistrationCountry(null);
                                  setSelectedCorporation("");
                                  setRegistrationType("");
                                  setRegistrationTypeOptions([]);
                                  setRegistrationTypeData({});
                                  setRegistrationName("");
                                  setRegistrationReference("");
                                  setRegistrationDate(null);
                                }}
                                aria-label="Entry type"
                              >
                                <ToggleButton value={true}>Lookup corporation</ToggleButton>
                                <ToggleButton value={false}>Manual entry</ToggleButton>
                              </ToggleButtonGroup>

                              {lookupCorporation ? (
                                <Box width={"100%"}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Business name"
                                    variant="outlined"
                                    value={lookupName}
                                    onChange={(e) => setLookupName(e.target.value)}
                                  />

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      mt: 2,
                                    }}
                                  >
                                    <LoadingButton
                                      disableElevation
                                      loading={processing}
                                      variant="contained"
                                      color="primary"
                                      onClick={(e) => handleLookupCorporations(e)}
                                    >
                                      Lookup corporation
                                    </LoadingButton>
                                  </Box>

                                  {corporationOptions?.length > 0 && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        mt: 2,
                                        border: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <Box sx={{ p: 2 }}>
                                        <Typography variant="body2">
                                          <strong>Select a result</strong>
                                        </Typography>
                                      </Box>
                                      <Divider />

                                      <Box sx={{ p: 2 }}>
                                        <FormControl fullWidth>
                                          <InputLabel id="corporation-options-label">
                                            Search results
                                          </InputLabel>

                                          <Select
                                            labelId="corporation-options-label"
                                            id="corporation-options"
                                            value={selectedCorporation}
                                            label="Select corporation"
                                            onChange={(e) => {
                                              setSelectedCorporation(e.target.value);
                                            }}
                                          >
                                            {corporationOptions?.map((option, index) => (
                                              <MenuItem key={index} value={option.value}>
                                                <Typography variant="body2">
                                                  {option.label}
                                                </Typography>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <CountrySelect
                                      value={registrationCountry}
                                      setValue={setRegistrationCountry}
                                    />
                                  </Grid>

                                  {registrationCountry && (
                                    <>
                                      <Grid item xs={12} sm={12} md={12} lg={6}>
                                        {registrationTypeOptions?.length > 0 && (
                                          <>
                                            <FormControl fullWidth>
                                              <InputLabel id="owner-label">
                                                Registration type
                                              </InputLabel>

                                              <Select
                                                labelId="owner"
                                                id="owner"
                                                value={registrationType}
                                                label="Account manager"
                                                onChange={(e) =>
                                                  setRegistrationType(e.target.value)
                                                }
                                              >
                                                {registrationTypeOptions?.map((option, index) => (
                                                  <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </>
                                        )}
                                      </Grid>

                                      <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <TextField
                                          fullWidth
                                          id="outlined-basic"
                                          label={
                                            registrationType &&
                                            registrationTypeData?.registration_required
                                              ? "Registered name"
                                              : "Business name"
                                          }
                                          variant="outlined"
                                          value={registrationName}
                                          onChange={(e) => setRegistrationName(e.target.value)}
                                        />
                                      </Grid>

                                      {registrationType &&
                                        registrationTypeData.registration_required && (
                                          <Grid item xs={12} sm={12} md={12} lg={6}>
                                            <TextField
                                              fullWidth
                                              id="outlined-basic"
                                              label="Registration reference"
                                              variant="outlined"
                                              value={registrationReference}
                                              onChange={(e) =>
                                                setRegistrationReference(e.target.value)
                                              }
                                            />
                                          </Grid>
                                        )}

                                      <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterMoment}
                                          adapterLocale={locale}
                                        >
                                          <DatePicker
                                            fullWidth
                                            openTo="year"
                                            label={`${
                                              registrationType &&
                                              registrationTypeData.registration_required
                                                ? "Registration date"
                                                : "Start date"
                                            }`}
                                            value={registrationDate ? registrationDate : null}
                                            onChange={(date) => {
                                              setRegistrationDate(date);
                                            }}
                                            slotProps={{ textField: { fullWidth: true } }}
                                            maxDate={moment()}
                                          />
                                        </LocalizationProvider>
                                      </Grid>

                                      {registrationType && registrationTypeData.description && (
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                          <Card variant="outlined">
                                            <CardHeader title="Registration type information" />
                                            <CardContent sx={{ pt: 0 }}>
                                              <Typography variant="body2">
                                                {registrationTypeData.description}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Box>

                {!rolodexOpen && (
                  <Box sx={{ px: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="subtitle2">Enter the shareholding percentage</Typography>
                      <TextField
                        label="Ownership amount"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        value={ownershipAmount}
                        onChange={(e) => setOwnershipAmount(e.target.value)}
                      />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        onClick={(e) => handleAddShareholder(e, 2)}
                        loading={processing}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}

          {currentShownMenu === 3 && (
            <Box>
              <Box sx={{}}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Personal information</strong>
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        {rolodexOpen ? (
                          <Button
                            startIcon={<Close />}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              resetForms();
                              setRolodexOpen(false);
                            }}
                          >
                            Close
                          </Button>
                        ) : (
                          <Button
                            startIcon={<ImportContacts />}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              getRolodexData("A", 1);
                            }}
                          >
                            Rolodex
                          </Button>
                        )}
                      </Box>
                    </Box>

                    <Divider />

                    {rolodexOpen ? (
                      <Box>
                        <Grid container>
                          {filterOptions.map((option, index) => (
                            <Grid
                              item
                              xs={1}
                              key={index}
                              onClick={() => {
                                getRolodexData(option, 1);
                              }}
                              sx={{
                                border: 1,
                                borderTop: index < 11 ? 0 : 0,
                                borderLeft: 0,
                                borderRight: index == 11 || index == 23 ? 0 : 1,
                                borderBottom: index > 23 ? 0 : 1,
                                py: 1,
                                textAlign: "center",
                                fontWeight: "bold",
                                borderColor: "divider",
                                cursor: "pointer",
                                background: rolodexLetter == option ? "#0b0b0b" : "",
                                color: rolodexLetter == option ? "#ffffff" : "",
                              }}
                            >
                              {option}
                            </Grid>
                          ))}
                        </Grid>
                        <Divider />

                        {rolodexData && rolodexData.length > 0 ? (
                          rolodexData.map((entry, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                px: 2,
                                py: 1,
                                borderBottom: index == rolodexData.length - 1 ? 0 : 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
                                <Box
                                  sx={{
                                    minWidth: "20%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "14px" }}>{entry.name}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                  {entry.country.code && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {entry.country.name}
                                      </Typography>
                                    </Box>
                                  )}
                                  {entry.kyc_complete && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Chip
                                        className="square-chip"
                                        label="KYC Complete"
                                        sx={{
                                          backgroundColor: "#55828b",
                                          color: "#ffffff",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {entry.kyb_complete && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Chip
                                        className="square-chip"
                                        label="KYB Complete"
                                        sx={{
                                          backgroundColor: "#474044",
                                          color: "#ffffff",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>

                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setRolodexUUID(entry.entity_uuid);
                                  getRolodexEntry(entry.entity_uuid, entry.entity_type);
                                }}
                              >
                                <ArrowForward />
                              </IconButton>
                            </Box>
                          ))
                        ) : (
                          <NoResults message={`There are no entities under that character`} />
                        )}
                      </Box>
                    ) : (
                      <Grid sx={{ p: 2 }} container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>
                              Minority group of shareholders
                            </Typography>
                            <Typography variant="body2">
                              This will create a new group of shareholders that will be represented
                              as a single entity in the ownership structure.
                            </Typography>
                            <Typography variant="body2">
                              Minority shareholders are those who own less than 10% of the company.
                            </Typography>
                            <Typography variant="body2">
                              They are not in control of the company, and are not able to make
                              decisions on behalf of the company.
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          {!rolodexOpen && (
                            <Box sx={{}}>
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="subtitle2">
                                  Enter the combined percentage of all minority shareholders
                                </Typography>
                                <TextField
                                  label="Combined shareholding percentage"
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  value={ownershipAmount}
                                  onChange={(e) => setOwnershipAmount(e.target.value)}
                                />
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mt: 2,
                                }}
                              >
                                <Box></Box>
                                <LoadingButton
                                  disableElevation
                                  variant="contained"
                                  color="success"
                                  onClick={(e) => handleAddShareholder(e, 3)}
                                  loading={processing}
                                >
                                  Save
                                </LoadingButton>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 2,
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Managing shareholders of {entityData.value.registered_name} (Control assigned:{" "}
                {entityData.value.control_assigned}%)
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              {completeAvailable && (
                <Box>
                  <LoadingButton
                    variant="contained"
                    disableElevation
                    color="success"
                    onClick={(e) => handleConfirmShareholders(e)}
                    loading={processing}
                  >
                    Confirm shareholders
                  </LoadingButton>
                </Box>
              )}

              {entityData.value.control_assigned < 100 && (
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => setNewShareholder(true)}
                >
                  Add new shareholder
                </Button>
              )}
              {ownershipData?.length > 0 && !editShareholder && !newShareholder && (
                <IconButton
                  onClick={() => {
                    if (ownershipView == 2) {
                      setOwnershipView(1);
                      setFullWidthDrawer(false);
                    } else {
                      setOwnershipView(2);
                      setFullWidthDrawer(true);
                    }
                  }}
                >
                  {ownershipView == 2 ? <List /> : <Lan />}
                </IconButton>
              )}
            </Box>
          </Box>

          {ownershipData?.length > 0 ? (
            ownershipView == 1 ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, minWidth: "50%" }}>
                {ownershipData.map((shareholder, index) => (
                  <Box key={index} sx={{ border: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                                {shareholder.percentage} %
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                {shareholder.shareholder_name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                            {shareholder.shareholder_type == 2 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                                title="Corporate shareholder"
                              >
                                <Business />
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          {shareholder.shareholder_type == 1
                            ? shareholder.kyc_complete && (
                                <Box
                                  className="square-chip"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",

                                    backgroundColor: "#8BBF9F",
                                    px: 2,
                                    py: 1,
                                  }}
                                >
                                  <GppGood />

                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      paddingLeft: "3px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ fontWeight: 600, fontSize: 12 }}
                                    >
                                      KYC complete
                                    </Typography>
                                  </Box>
                                </Box>
                              )
                            : shareholder.shareholder_type == 2 && (
                                <Box>
                                  {shareholder.control_assigned < 100 && (
                                    <Chip
                                      className="square-chip"
                                      label="Input required"
                                      sx={{
                                        backgroundColor: "#2274A5",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )}

                                  {shareholder.kyb_complete && (
                                    <Box
                                      className="square-chip"
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",

                                        backgroundColor: "#8BBF9F",
                                        px: 2,
                                        py: 1,
                                      }}
                                    >
                                      <GppGood />

                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          paddingLeft: "3px",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontWeight: 600, fontSize: 12 }}
                                        >
                                          KYB complete
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              )}
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                              startIcon={<Edit />}
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => {
                                if (shareholder.shareholder_type == 2) {
                                  setActiveShareholder(shareholder);
                                  setEditShareholder(true);
                                  setEditShareholderAmount(shareholder.percentage);
                                } else {
                                  setActiveShareholder(shareholder);
                                  setEditShareholder(true);
                                  setEditShareholderAmount(shareholder.percentage);
                                }
                              }}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                      {shareholder.shareholder_type == 2 &&
                        shareholder.shareholders?.length > 0 && (
                          <Box sx={{}}>
                            <Box sx={{}}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, fontStyle: "italic", marginBottom: 1 }}
                              >
                                Shareholders
                              </Typography>
                            </Box>
                            {shareholder.shareholders.map((subShareholder, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  gap: 4,

                                  border: 1,
                                  borderTop: index == 0 ? 1 : 0,
                                  borderColor: "divider",
                                  p: 1,
                                }}
                              >
                                <Box sx={{}}>
                                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                    {subShareholder.shareholder_name}
                                  </Typography>
                                </Box>
                                <Box sx={{}}>
                                  <Typography variant="body1" sx={{ fontWeight: 400 }}>
                                    {subShareholder.percentage} %
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        )}
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box sx={{ p: 2, width: "100%", height: "70vh" }}>
                <Canvas
                  nodes={nodes}
                  edges={edges}
                  direction="UP"
                  readonly={true}
                  pannable={false}
                  fit={true}
                  edge={<Edge className="edge" selectable={false} />}
                  node={
                    <Node style={{ fill: "#474044" }}>
                      {(event) => (
                        <foreignObject height={event.height} width={event.width} x={0} y={0}>
                          {event.node.data.entity_type != 0 && (
                            <Box
                              sx={{
                                background: "#ffffff",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                borderBottom: 1,
                                borderColor: "#0b0b0b",
                                height: 45,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  p: 1,
                                }}
                              >
                                {event.node.data.entity_type == 1 ? (
                                  <People />
                                ) : event.node.data.entity_type == 2 ? (
                                  <Business />
                                ) : (
                                  <Diversity3 />
                                )}
                              </Box>

                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {event.node.data.direct_control > 0 && (
                                  <Box
                                    sx={{
                                      backgroundColor: "#0b0b0b",
                                      color: "#ffffff",
                                      height: "100%",
                                      p: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      fontWeight: 500,
                                    }}
                                    title="Direct"
                                  >
                                    {event.node.data.direct_control}%
                                  </Box>
                                )}

                                {event.node.data.entity_type != 3 &&
                                  event.node.data.indirect_control > 0 && (
                                    <Box
                                      sx={{
                                        backgroundColor: "#aaaaaa",
                                        color: "#ffffff",
                                        height: "100%",
                                        p: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                      }}
                                      title="Indirect"
                                    >
                                      {event.node.data.indirect_control.toFixed(2)}%
                                    </Box>
                                  )}
                              </Box>
                            </Box>
                          )}

                          <Box
                            sx={{
                              p: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: event.node.data.entity_type == 0 ? "100%" : "auto",
                              fontWeight: 500,
                              cursor: "pointer",
                              height: event.node.data.entity_type != 0 ? 55 : 75,
                              backgroundColor:
                                event.node.data.entity_type == 0
                                  ? "#474044"
                                  : event.node.data.entity_type == 1
                                  ? "#4AFE7B"
                                  : event.node.data.entity_type == 2
                                  ? "#4A8EFE"
                                  : "#B74FFF",
                            }}
                            onClick={() => {
                              if (event.node.data.entity_type == 2) {
                                getCorporation(event.node.data.entity_uuid);
                                getCorporationShareholders(event.node.data.entity_uuid);
                                setEditShareholder(true);
                              }

                              if (event.node.data.entity_type == 0) {
                                getCorporation(entityData.value.corporation_uuid);
                                getCorporationShareholders(entityData.value.corporation_uuid);
                              }
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: event.node.data.entity_type == 0 ? 16 : "14px",
                                color:
                                  event.node.data.entity_type == 0
                                    ? "#ffffff"
                                    : event.node.data.entity_type == 1
                                    ? "#0b0b0b"
                                    : event.node.data.entity_type == 2
                                    ? "#0b0b0b"
                                    : "#ffffff",
                              }}
                            >
                              {event.node.data.label}
                            </Typography>
                            {event.node.data.entity_type == 2 ? (
                              event.node.data.control_assigned == 100 ? (
                                <Check sx={{ color: "#0b0b0b" }} />
                              ) : (
                                <Typography
                                  sx={{ color: "#0b0b0b", fontWeight: 500, fontSize: "13px" }}
                                >
                                  {event.node.data.control_assigned}%
                                </Typography>
                              )
                            ) : (
                              ""
                            )}

                            {event.node.data.entity_type == 0 ? (
                              event.node.data.control_assigned == 100 ? (
                                <Check sx={{ color: "#8BBF9F" }} />
                              ) : (
                                <Typography
                                  sx={{ color: "#ffffff", fontWeight: 500, fontSize: "13px" }}
                                >
                                  {event.node.data.control_assigned}%
                                </Typography>
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        </foreignObject>
                      )}
                    </Node>
                  }
                />
              </Box>
            )
          ) : (
            setNewShareholder(true)
          )}
        </Box>
      )}
    </Box>
  );
}

export default Ownership;
