import { React, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";

import CountrySelect from "../../../../components/form-elements/CountrySelect";

function RegistrationCountry({ entityData, setManageProfile, getEntityData, getProfileData }) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);

  const [registrationCountry, setRegistrationCountry] = useState(
    entityData.value?.registration_country?.code != ""
      ? {
          value: entityData.value?.registration_country.code,
          name: entityData.value?.registration_country.name,
        }
      : { value: "", name: "" }
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/registration/country`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          country: registrationCountry.value,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();

      displayToast("Registration country updated successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <CountrySelect value={registrationCountry} setValue={setRegistrationCountry} />

      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <LoadingButton
          disabled={
            entityData.value?.registration_country?.code == registrationCountry.value ? true : false
          }
          disableElevation
          loading={processing}
          onClick={(e) => {
            handleUpdate(e);
          }}
          variant="contained"
          color="success"
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default RegistrationCountry;
