import * as faceApi from "face-api.js";
import { React, useCallback, useEffect, useRef, useState } from "react";

import authHeader from "../../../../api/hostedAuthHeader";

import HandleError from "../../../../components/functions/HostedHandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import { signal } from "@preact/signals-react";

import {
  Box,
  Button,
  Collapse,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import WebcamDisplay from "./WebcamDisplay";

const capturing = signal(false);
const screenshotImage = signal(null);

const VideoCapture = ({
  partner,
  setVideoCapture,
  getProofOfLifeData,
  entityData,
  getRequestData,
  getCase,
  setEditMode,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(true);

  const [startOption, setStartOption] = useState(true);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [baseInstructions, setBaseInstructions] = useState(
    "Camera and microphone permissions required"
  );
  const [instructionLeft, setInstructionLeft] = useState("");
  const [instructionRight, setInstructionRight] = useState("");

  const [instructionsUUID, setInstructionsUUID] = useState("");
  const [videoInstructions, setVideoInstructions] = useState();

  const [permissionsError, setPermissionsError] = useState(false);

  const [showVideo, setShowVideo] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  function hasGetUserMedia() {
    return !!(
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    );
  }

  if (hasGetUserMedia()) {
    var errorCallback = function (e) {
      console.log("getUserMedia error", e);
    };

    // Not showing vendor prefixes.
    navigator.getUserMedia(
      {
        video: true,
        audio: true,
      },
      function (localMediaStream) {
        //alert("good to go");
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
      },
      errorCallback
    );
  } else {
    console.log("getUserMedia is not supported");
  }

  const videoElement = useRef(null);
  const mediaRecorderRef = useRef(null);
  const snapshotInterval = useRef(null);
  const canvasRef = useRef();

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({ deviceId: "", label: "" });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [videoConstraints, setVideoConstraints] = useState({
    width: 640,
    height: 400,
    facingMode: "user",
    deviceId: selectedDevice.deviceId ? { exact: selectedDevice.deviceId } : undefined,
  });

  const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

  const BlinkedBox = styled("div")({
    backgroundColor: "#C83E4D",
    width: 15,
    height: 15,
    borderRadius: "50%",
    animation: `${blink} 1.75s linear infinite`,
  });

  function GetDevice(id) {
    console.log("Getting device");
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        devices.forEach(function (device) {
          if (device.deviceId == id) {
            setSelectedDevice({
              deviceId: device.deviceId === "default" ? "" : device.deviceId,
              label: device.label,
            });
            //console.log(device.kind + ": " + device.label != undefined ? device.label : "Default");
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  }

  const handleDevices = useCallback(
    (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    //navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const [faceDetected, setFaceDetected] = useState(true);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  function getSupportedMimeTypes(media, types, codecs) {
    const isSupported = MediaRecorder.isTypeSupported;
    const supported = [];
    types.forEach((type) => {
      const mimeType = `${media}/${type}`;
      codecs.forEach((codec) =>
        [
          `${mimeType};codecs=${codec}`,
          `${mimeType};codecs=${codec.toUpperCase()}`,
          // /!\ false positive /!\
          // `${mimeType};codecs:${codec}`,
          // `${mimeType};codecs:${codec.toUpperCase()}`
        ].forEach((variation) => {
          if (isSupported(variation)) supported.push(variation);
        })
      );
      if (isSupported(mimeType)) supported.push(mimeType);
    });
    return supported;
  }

  // Usage ------------------

  const videoTypes = ["webm", "ogg", "mp4", "x-matroska"];
  const audioTypes = ["webm", "ogg", "mp3", "x-matroska"];
  const codecs = [
    "should-not-be-supported",
    "vp9",
    "vp9.0",
    "vp8",
    "vp8.0",
    "avc1",
    "av1",
    "h265",
    "h.265",
    "h264",
    "h.264",
    "opus",
    "pcm",
    "aac",
    "mpeg",
    "mp4a",
  ];

  const supportedVideos = getSupportedMimeTypes("video", videoTypes, codecs);

  const startRecording = async () => {
    capturing.value = true;

    setBaseInstructions("Follow the onscreen instructions");
    setStartOption(false);

    mediaRecorderRef.current = new MediaRecorder(videoElement.current.stream, {
      mimeType: supportedVideos[0],
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();

    // start capturing the snapshots
    setTimeout(function () {
      snapshotInterval.current = setInterval(() => {
        captureSnapshot();
      }, 3000);
    }, 2000);

    setBaseInstructions("Read the numbers out loud as they appear on screen");

    // loop through the instructions
    function instructionsLoop(array, callback, delay) {
      let i = 0;
      let interval = setInterval(() => {
        callback(array[i], i, array);
        if (++i === array.length) clearInterval(interval);
      }, delay);
    }

    instructionsLoop(
      videoInstructions,
      (instruction, i, array) => {
        if (instruction.placement == "left") {
          setInstructionLeft(instruction.text);
          setInstructionRight("");
        }

        if (instruction.placement == "right") {
          setInstructionRight(instruction.text);
          setInstructionLeft("");
        }

        // check if it is the final instruction

        if (i + 1 == videoInstructions.length) {
          let interval = setTimeout(() => {
            setInstructionLeft("");
            setInstructionRight("");
            setBaseInstructions("Thank you, the recording is complete");
            stopRecording();
          }, 3000);
        }
      },
      2500
    );
  };

  const stopRecording = useCallback(() => {
    clearInterval(snapshotInterval.current);
    snapshotInterval.current = null;
    mediaRecorderRef.current.stop();
    let stream = videoElement.current.stream;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setShowVideo(false);
    setIsUploading(true);
  }, [mediaRecorderRef, videoElement]);

  const captureSnapshot = useCallback(() => {
    if (videoElement.current == null) {
      return;
    }
    screenshotImage.value = videoElement.current.getScreenshot();
    processSnapshot();
  }, [videoElement]);

  const processSnapshot = async () => {
    try {
      const response = await partner.post(
        "/v1/entity/people/person/proof-of-life/snapshot-upload",
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          image: screenshotImage.value,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (recordedChunks.length > 0) {
      processUpload();
    }
  }, [recordedChunks]);

  const processUpload = async () => {
    capturing.value = false;

    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });

      const formData = new FormData();
      formData.append("file", blob);
      formData.append("person_uuid", entityData.value.person_uuid);
      formData.append("device", selectedDevice.label);
      formData.append("instructions_uuid", instructionsUUID);

      try {
        const response = await partner.post(
          "/v1/entity/people/person/proof-of-life/video-capture",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Basic " + useAuthHeader.base64encodedData,
              service: "hosted",
            },

            //withCredentials: true,
          }
        );

        getProofOfLifeData();
        getRequestData();
        getCase();
        setRecordedChunks([]);
        setVideoCapture(false);
        setEditMode(false);
      } catch (err) {
        handleError(err);
      }
    }
  };

  const getInstructions = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/proof-of-life/instructions`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      setInstructionsUUID(response.data.instructions_uuid);
      setVideoInstructions(response.data.instructions);
      setBaseInstructions("Follow the onscreen instructions");
    } catch (err) {
      console.log(err);
      handleError(err);
      setStartOption(true);
    }
  };

  useEffect(() => {
    async function loadModels() {
      const MODEL_URL = "/models";
      try {
        await Promise.all([faceApi.nets.tinyFaceDetector.loadFromUri(MODEL_URL)]);
        // setStopFaceDetection(true);
      } catch (err) {
        console.error("error loading face detection models: ", err);
      }
    }

    const faceDetectionInterval = setInterval(async () => {
      const result = await faceApi.detectSingleFace(
        // @ts-expect-error video is a valid property
        videoElement?.current?.video,
        new faceApi.TinyFaceDetectorOptions()
      );
      if (result) {
        setFaceDetected(true);
      } else {
        setFaceDetected(false);
      }
    }, 1000);

    loadModels();
    getInstructions();

    return () => clearInterval(faceDetectionInterval);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box sx={{}}>
          <Box
            sx={{
              p: 2,
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#0b0b0b",
              color: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle2">Recording your video</Typography>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minWidth: { xs: "75px", md: "150px" },
                }}
              >
                <Typography sx={{ fontWeight: 600 }} variant="h1">
                  {instructionLeft}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Collapse in={capturing.value === true} exit={capturing.value !== true}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      justifyContent: "center",
                      backgroundColor: "#0b0b0b",
                      py: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BlinkedBox />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 600, textTransform: "uppercase", color: "#ffffff" }}
                        variant="h6"
                      >
                        Recording
                      </Typography>
                    </Box>
                  </Box>
                </Collapse>

                <Box
                  sx={{
                    minWidth: { xs: 400, lg: 640 },
                    minHeight: 400,
                    border: permissionsError ? 1 : 0,
                    borderColor: "divider",
                  }}
                >
                  {permissionsError ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        justifyItems: "center",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      <Typography variant="subtitle2">
                        Please allow access to your camera and microphone to proceed
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ position: "relative" }}>
                      <WebcamDisplay
                        videoElement={videoElement}
                        videoConstraints={videoConstraints}
                        setPermissionsError={setPermissionsError}
                        setBaseInstructions={setBaseInstructions}
                        getDevice={GetDevice}
                      />
                      <div className="overlay-container">
                        <canvas className="w-full h-fit" ref={canvasRef} />
                      </div>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      py: 1,
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: { xs: "1rem", md: "1.25rem" },
                      }}
                      variant="h6"
                    >
                      {baseInstructions}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      pt: 2,
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    {!capturing.value && devices?.length > 0 && (
                      <Box sx={{ width: "50%" }}>
                        <FormControl fullWidth>
                          <InputLabel id="recording-device-label">Recording device</InputLabel>
                          <Select
                            labelId="recording-device-label"
                            id="recording-device"
                            label="Select recording device"
                            value={selectedDevice.deviceId}
                            onChange={(e) => {
                              setSelectedDevice({
                                deviceId: e.target.value,
                                label: devices.find((device) => device.deviceId === e.target.value)
                                  .label,
                              });
                              setVideoConstraints({
                                width: 640,
                                height: 400,
                                facingMode: "user",
                                deviceId: e.target.value ? { exact: e.target.value } : undefined,
                              });
                            }}
                          >
                            <MenuItem disabled={true} value="">
                              Select a device
                            </MenuItem>
                            {devices.map((option, index) => (
                              <MenuItem key={index} value={option.deviceId}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}

                    <Fade in={startOption} exit={!startOption} timeout={1000}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          disabled={!faceDetected}
                          onClick={() => {
                            console.log("Started recording");
                            capturing.value = true;
                            startRecording();
                          }}
                        >
                          Start Recording
                        </Button>
                      </Box>
                    </Fade>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minWidth: { xs: "75px", md: "150px" },
                }}
              >
                <Typography sx={{ fontWeight: 600 }} variant="h1">
                  {instructionRight}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default VideoCapture;
