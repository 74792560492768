import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import central from "../../api/central";

import HandleError from "../../components/functions/HandleError";

import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Button from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading.json";
import { Fade } from "@mui/material";

import Modal from "@mui/material/Modal";
import VideoCapture from "./LoginImage";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © Cleverli.com "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const Login = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const handleError = HandleError();

  const [emailAddress, setEmailAddress] = useState("");
  const [processing, setProcessing] = useState(false);

  const [monitoring, setMonitoring] = useState(false);
  const [monitoringToken, setMonitoringToken] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await central.post(
        `/v1/partner/auth/login`,
        JSON.stringify({
          email_address: emailAddress,
        }),
        {
          headers: { "Content-Type": "application/json", service: "partners" },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.monitor_token !== "") {
        setMonitoringToken(response.data.monitor_token);
        setMonitoring(true);
        setProcessing(false);
        return;
      } else if (response.data.image_required) {
        setVideoCapture(true);
        setProcessing(false);
      }
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const [videoCapture, setVideoCapture] = useState(false);
  const [videoContent, setVideoContent] = useState([]);
  const [faceImage, setFaceImage] = useState("");
  const [videoComplete, setVideoComplete] = useState(false);

  const handleFaceProcessingVideo = async () => {
    if (videoContent.length > 0) {
      const blob = new Blob(videoContent, {
        type: "video/webm",
      });

      const formData = new FormData();
      formData.append("video_file", blob);
      formData.append("email_address", emailAddress);
      formData.append("facial_image", faceImage);

      try {
        const response = await central.post("/v1/partner/auth/facial-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        });

        setVideoContent([]);
        setFaceImage("");
        setMonitoringToken(response.data.monitor_token);
        setMonitoring(true);
        setProcessing(false);
      } catch (err) {
        setVideoContent([]);
        setFaceImage("");
        setProcessing(false);
        console.log(err);
        handleError(err);
      }
    }
  };

  const handleFaceProcessing = async () => {
    setProcessing(true);

    try {
      const response = await central.post(
        `/v1/partner/auth/login/facial-image`,
        JSON.stringify({
          email_address: emailAddress,
          is_mobile: false,
          facial_image: faceImage,
        }),
        {
          headers: { "Content-Type": "application/json", service: "partners" },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.monitor_token !== "") {
        setMonitoringToken(response.data.monitor_token);
        setMonitoring(true);
        setProcessing(false);
      }
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleMonitor = async () => {
    try {
      const response = await central.get("/v1/partner/auth/monitor/" + monitoringToken, {
        headers: { "Content-Type": "application/json", service: "partners" },
        //withCredentials: true,
      });

      console.log(response.headers);

      if (response.data.secret !== "") {
        cookies.set("sessionID", response.data.session_id, {
          path: "/",
          expires: new Date(Date.now() + 1080000000),
        });

        cookies.set("secretToken", response.data.secret, {
          path: "/",
          expires: new Date(Date.now() + 1080000000),
        });

        cookies.set("platformURL", response.data.platform_url, {
          path: "/",
          expires: new Date(Date.now() + 1080000000),
        });

        setMonitoring(false);
        clearInterval(interval.current);
        navigate("/dashboard", { replace: true });
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400) {
        setMonitoring(false);
        MySwal.fire({
          html: "<p>Link expired</p>",
          icon: "error",
          iconColor: "danger",
          timer: 2000,
          position: "top",
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
          showDenyButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          setProcessing(false);
        });
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setProcessing(true);

    if (monitoring) {
      clearInterval(interval.current);
      setEmailAddress("");
      setMonitoring(false);
    }

    setProcessing(false);
  };

  const interval = useRef();

  function startMonitoring() {
    interval.current = setInterval(() => {
      handleMonitor();
    }, 3000);
  }

  useEffect(() => {
    if (monitoring) {
      startMonitoring();
    } else {
      clearInterval(interval.current);
    }
  }, [monitoring]);

  useEffect(() => {
    console.log("faceImage: " + faceImage);
    if (faceImage) {
      handleFaceProcessing();
    }
  }, [faceImage]);

  return (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.primary.main : t.palette.primary.main,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{ height: "100%", width: "100%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Fade in={true} timeout={2000}>
              <Box width={"75%"} component="img" alt="Logo" src="/cleverli-logo.svg" />
            </Fade>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={0} square>
          <Box
            sx={{ height: "100%", width: "100%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ width: 56, height: 56, m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon fontSize="medium" sx={{ color: "#D5E950" }} />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ mt: 4, textTransform: "uppercase" }}>
                <strong>Login to your account</strong>
              </Typography>

              {monitoring ? (
                <Fade in={true} timeout={2000}>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      variant="div"
                      align="center"
                      alignContent={"center"}
                      sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={200}
                        width={250}
                        isStopped={false}
                        isPaused={false}
                      />
                    </Box>

                    <Typography
                      sx={{ mb: 2, textTransform: "uppercase" }}
                      align="center"
                      variant="h6"
                      gutterBottom
                    >
                      <strong>Please check your emails to confirm your login</strong>
                    </Typography>

                    <Button
                      onClick={(e) => handleCancel(e)}
                      loading={processing}
                      type="button"
                      variant="outlined"
                      sx={{ mt: 3, mb: 2, float: "right" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Fade>
              ) : (
                <>
                  <Box
                    component="form"
                    onSubmit={() => {
                      console.log("submitted: " + emailAddress);
                    }}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      onInput={(e) => setEmailAddress(e.target.value)}
                      value={emailAddress}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onKeyDown={(e) => e.key === "Enter" && handleLogin(e)}
                    />
                    <Button
                      onClick={(e) => handleLogin(e)}
                      loading={processing}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Continue
                    </Button>

                    <Copyright sx={{ mt: 5 }} />
                  </Box>
                </>
              )}
            </Box>

            <Modal
              open={videoCapture}
              onClose={() => setVideoCapture(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  minWidth: 800,
                  bgcolor: "background.paper",
                  border: 2,
                  borderColor: "divider",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box sx={{ width: "100%", border: 1, borderColor: "divider" }}>
                  <Box
                    variant="div"
                    align="center"
                    alignContent={"center"}
                    sx={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                  >
                    {/* <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} /> */}
                  </Box>

                  <Box
                    sx={{
                      p: 2,
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2">Verifying your request</Typography>
                    </Box>
                    <Box></Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <VideoCapture
                      setVideoCapture={setVideoCapture}
                      setVideoContent={setVideoContent}
                      setFaceImage={setFaceImage}
                    />
                  </Box>
                </Box>
              </Box>
            </Modal>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
