import { React, useEffect, useState } from "react";

import central from "../../../api/central";
import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";
import fileCheckLottie from "../../../components/lotties/file-check.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Close, Delete, Edit, Upload, PermMedia, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Collapse,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  ListItem,
  List,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CountrySelect from "../../../components/form-elements/CountrySelect";

import FileUpload from "./address/FileUpload";
import Notary from "./address/Notary";
import Certifier from "./address/Certifier";

const Address = ({
  partner,
  caseUUID,
  partnerUUID,
  entityData,
  caseData,
  activeView,
  getPerson,
  requestData,
  getRequestData,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: fileCheckLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [addressData, setAddressData] = useState();
  const [addressCountry, setAddressCountry] = useState();

  const [lookupDisplay, setLookupDisplay] = useState(false);
  const [lookupSupported, setLookupSupported] = useState(false);
  const [lookupValue, setLookupValue] = useState("");
  const [lookupOptions, setLookupOptions] = useState([]);
  const [formDisplay, setFormDisplay] = useState(false);
  const [stateRequired, setStateRequired] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressLine4, setAddressLine4] = useState("");
  const [addressLine5, setAddressLine5] = useState("");
  const [addressLine6, setAddressLine6] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressPostcode, setAddressPostcode] = useState("");

  const [documentData, setDocumentData] = useState(null);
  const [validateAddress, setValidateAddress] = useState(false);

  const documentTypeOptions = [
    { value: 1, label: "Utility bill" },
    { value: 2, label: "Bank or mortgage statement" },
    { value: 3, label: "Government issued letter" },
    { value: 4, label: "Residence Permit" },
  ];

  const [frontFile, setFrontFile] = useState(null);
  const [documentUUID, setDocumentUUID] = useState("");

  const [documentType, setDocumentType] = useState("");
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [password, setPassword] = useState("");
  const [issuingDate, setIssuingDate] = useState(null);
  const [validation, setValidation] = useState(true);
  const [notarised, setNotarised] = useState(null);
  const [certified, setCertified] = useState(null);

  const [notaryFirstName, setNotaryFirstName] = useState("");
  const [notaryMiddleNames, setNotaryMiddleNames] = useState("");
  const [notaryLastName, setNotaryLastName] = useState("");
  const [notaryEmail, setNotaryEmail] = useState("");
  const [notaryDiallingCode, setNotaryDiallingCode] = useState("");
  const [notaryPhoneCountry, setNotaryPhoneCountry] = useState("");
  const [notaryPhoneValue, setNotaryPhoneValue] = useState("");
  const [notaryTelephoneNumber, setNotaryTelephoneNumber] = useState("");
  const [notaryDate, setNotaryDate] = useState(null);

  const [certifierFirstName, setCertifierFirstName] = useState("");
  const [certifierMiddleNames, setCertifierMiddleNames] = useState("");
  const [certifierLastName, setCertifierLastName] = useState("");
  const [certifierCompanyName, setCertifierCompanyName] = useState("");
  const [certifierEmail, setCertifierEmail] = useState("");
  const [certifierPhoneValue, setCertifierPhoneValue] = useState("");
  const [certifierDiallingCode, setCertifierDiallingCode] = useState("");
  const [certifierPhoneCountry, setCertifierPhoneCountry] = useState("");
  const [certifierTelephoneNumber, setCertifierTelephoneNumber] = useState("");
  const [certifierDate, setCertifierDate] = useState(null);
  const [certifierQualification, setCertifierQualification] = useState(null);

  const getAddress = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/address`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      setAddressData(response.data);

      setAddressLine1(response.data.line_one);
      setAddressLine2(response.data.line_two);
      setAddressLine3(response.data.line_three);
      setAddressLine4(response.data.line_four);
      setAddressLine5(response.data.line_five);
      setAddressLine6(response.data.line_six);
      setAddressCity(response.data.city);
      setAddressPostcode(response.data.code);

      if (response.data.line_one == "") {
        setEditMode(true);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleLookupAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/lookup`,
        JSON.stringify({
          partner_uuid: partnerUUID,
          country: addressCountry ? addressCountry.value : "",
          postcode: lookupValue,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
            partner_uuid: partnerUUID,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setLookupOptions(response.data.entries);
      } else {
        // displayNoResults
        setLookupOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getSelectedAddress = async (addressUUID) => {
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/data`,
        JSON.stringify({
          partner_uuid: partnerUUID,
          country: addressCountry ? addressCountry.value : "",
          uuid: addressUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
            partner_uuid: partnerUUID,
          },

          // withCredentials: true,
        }
      );

      setAddressLine1(response.data.line_one);
      setAddressLine2(response.data.line_two);
      setAddressLine3(response.data.line_three);
      setAddressLine4(response.data.line_four);
      setAddressLine5(response.data.line_five);
      setAddressLine6(response.data.line_six);
      setAddressCity(response.data.city);
      setAddressPostcode(response.data.code);
      setLookupDisplay(false);
      setFormDisplay(true);

      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/address/add`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          line_one: addressLine1,
          line_two: addressLine2,
          line_three: addressLine3,
          line_four: addressLine4,
          line_five: addressLine5,
          line_six: addressLine6,
          city: addressCity,
          postcode: addressPostcode,
          country: addressCountry ? addressCountry.value : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getAddress(entityData.value.person_uuid);
      getRequestData();
      getCase();

      displayToast("Address updated successfully", () => {
        setProcessing(false);

        setEditMode(false);
        setAddressCountry();
        setAddressLine1("");
        setAddressLine2("");
        setAddressLine3("");
        setAddressLine4("");
        setAddressLine5("");
        setAddressLine6("");
        setAddressCity("");
        setAddressState("");
        setAddressPostcode("");
        setFormDisplay(false);
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setLookupDisplay(false);
        setLookupValue("");
        setLookupOptions([]);
        setSelectedAddress("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getDocumentData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/address/document`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      setDocumentData(response.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSetDocumentData = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/address/document`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          document_type: documentType,

          password: passwordProtected ? password : "",
          issue_date: issuingDate ? issuingDate.format("YYYY-MM-DD") : "",

          verification_required: validation,
          notarised_document: notarised,
          certified_document: certified,

          notary_first_name: notaryFirstName,
          notary_middle_names: notaryMiddleNames,
          notary_last_name: notaryLastName,

          notary_email_address: notaryEmail,
          notary_dialling_code: notaryDiallingCode ? notaryDiallingCode : "",
          notary_telephone_country: notaryPhoneCountry ? notaryPhoneCountry : "",
          notary_telephone_number: notaryTelephoneNumber ? notaryTelephoneNumber : "",
          notary_date: notaryDate ? notaryDate.format("YYYY-MM-DD") : "",

          certifier_first_name: certifierFirstName,
          certifier_middle_names: certifierMiddleNames,
          certifier_last_name: certifierLastName,
          certifier_company_name: certifierCompanyName,
          certifier_email_address: certifierEmail,
          certifier_dialling_code: certifierDiallingCode ? certifierDiallingCode : "",
          certifier_telephone_country: certifierPhoneCountry ? certifierPhoneCountry : "",
          certifier_telephone_number: certifierTelephoneNumber ? certifierTelephoneNumber : "",
          certifier_date: certifierDate ? certifierDate.format("YYYY-MM-DD") : "",
          certifier_qualification: certifierQualification ? certifierQualification.toString() : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      setDocumentUUID(response.data.document_uuid);

      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const processUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("frontFile", frontFile);
    formData.append("frontFileName", frontFile?.name);

    formData.append("document_uuid", documentUUID);
    formData.append("person_uuid", entityData.value.person_uuid);

    try {
      const response = await partner.post(
        "/v1/entity/people/person/address/document/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },
          onUploadProgress: function (progressEvent) {},

          //withCredentials: true,
        }
      );

      getDocumentData();
      getRequestData();
      getCase();

      displayToast("Document uploaded successfully", () => {
        setProcessing(false);
        setValidateAddress(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getAddress(entityData.value.person_uuid);
    getDocumentData();
  }, []);

  useEffect(() => {
    if (addressCountry == "" || addressCountry == undefined) {
      setFormDisplay(false);
      setLookupDisplay(false);
      setLookupSupported(false);
      setStateRequired(false);
      return;
    }

    switch (addressCountry?.value) {
      case "GB":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "JE":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "GG":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "IM":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "US":
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(true);
        setFormDisplay(true);
        break;
      default:
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setFormDisplay(true);
        break;
    }
  }, [addressCountry]);

  useEffect(() => {
    if (selectedAddress != "") {
      getSelectedAddress();
    }
  }, [selectedAddress]);

  return isLoaded ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography sx={{ textAlign: "center" }} variant="h6">
            {requestData.requirements.address_verification
              ? "We need you to provide and verify your current residential address"
              : "We need you to provide your current residential address"}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}
        ></Box>
      </Box>

      {!editMode ? (
        <>
          <Box>
            <Box
              sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle1" sx={{}}>
                  Current residential address
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography sx={{ textAlign: "end" }} variant="h6">
                    {addressData?.line_one ? addressData.line_one : "-"}
                  </Typography>
                  {addressData?.line_two && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.line_two ? addressData.line_two : "-"}
                    </Typography>
                  )}
                  {addressData?.line_three && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.line_three ? addressData.line_three : "-"}
                    </Typography>
                  )}
                  {addressData?.line_four && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.line_four ? addressData.line_four : "-"}
                    </Typography>
                  )}
                  {addressData?.line_five && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.line_five ? addressData.line_five : "-"}
                    </Typography>
                  )}
                  {addressData?.line_six && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.line_six ? addressData.line_six : "-"}
                    </Typography>
                  )}
                  {addressData?.city && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.city ? addressData.city : "-"}
                    </Typography>
                  )}
                  {addressData?.postcode && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.postcode ? addressData.postcode : "-"}
                    </Typography>
                  )}
                  {addressData?.state && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.state ? addressData.state : "-"}
                    </Typography>
                  )}
                  {addressData?.country.code && (
                    <Typography sx={{ textAlign: "end" }} variant="h6">
                      {addressData.country.code ? addressData.country.name : "-"}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <IconButton
                    title="Edit"
                    onClick={() => {
                      validateAddress ? setValidateAddress(false) : setEditMode(!editMode);
                    }}
                  >
                    {editMode ? <Close /> : validateAddress ? <Close /> : <Edit />}
                  </IconButton>
                </Box>
              </Box>
            </Box>

            {caseData.value.options.address_verification && caseData.value.address && (
              <Box>
                {requestData.progress.address_verification ? (
                  <Box>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        px: 2,
                        py: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#0b0b0b",
                        color: "#ffffff",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="h6">Address verification</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ backgroundColor: "#8BBF9F", p: 2 }}>
                      <Typography
                        variant="h6"
                        sx={{ color: "#ffffff", textTransform: "uppercase", textAlign: "center" }}
                      >
                        Upload completed successfully
                      </Typography>

                      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Lottie speed={1.0} options={successOptions} height={100} width={100} />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        p: 2,
                      }}
                    >
                      <Button
                        disableElevation
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          activeView.value = 0;
                        }}
                      >
                        <KeyboardArrowLeft />
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          activeView.value = 2;
                        }}
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                ) : documentUUID ? (
                  <Box sx={{}}>
                    <Box>
                      <Box
                        sx={{
                          borderBottom: 1,

                          borderColor: "divider",
                          p: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">Upload your image</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            title="Close"
                            onClick={() => {
                              setDocumentUUID("");
                              setValidateAddress(false);
                            }}
                          >
                            <Close sx={{ color: "#ffffff" }} />
                          </IconButton>
                        </Box>
                      </Box>

                      <Box>
                        <Box sx={{ p: 2 }}>
                          <Typography sx={{ fontSize: "13px", mb: 2 }}>
                            When submitting an image of your identity document, please ensure the
                            following requirements are met to ensure a clear and usable scan:
                          </Typography>
                          <Typography sx={{ fontSize: "13px", mb: 1 }}>
                            <b>High Quality:</b> The image should be high-resolution, aiming for a
                            minimum of 300 DPI (dots per inch) to ensure all text and images are
                            sharp and legible.
                          </Typography>
                          <Typography sx={{ fontSize: "13px", mb: 1 }}>
                            <b>Full Document Display:</b> Capture the entire document in the frame.
                            The edges of the document should not be cut off, and the image should
                            not be cropped too closely to the text or features of the document.
                          </Typography>
                          <Typography sx={{ fontSize: "13px", mb: 1 }}>
                            <b>Proper Lighting:</b> Ensure the document is well-lit, but avoid glare
                            that can obscure any part of the information. Natural light is often
                            best, but direct sunlight should be avoided to reduce glare.
                          </Typography>
                          <Typography sx={{ fontSize: "13px", mb: 1 }}>
                            <b>No Obstructions:</b> Make sure the document is free from fingers
                            holding the document, clips, or any other obstructions that can cover
                            the information and features of the ID.
                          </Typography>

                          <Typography sx={{ fontSize: "13px", mb: 1 }}>
                            <b>Focus and Clarity:</b> The document should be in focus, with no
                            blurring that might make any text or images indistinct. Hold the camera
                            steady or use a flat surface to avoid shake. Neutral Background: Place
                            the document on a contrasting, uniform, and single-colored background.
                            This helps the edges of the document stand out clearly and aids in
                            document verification processes.
                          </Typography>

                          <Typography sx={{ fontSize: "13px", mb: 1 }}>
                            <b>Avoid Filters and Edits:</b> Do not use any filters or editing
                            features that alter the colors or details of the document. The image
                            should represent the document as accurately as possible.
                          </Typography>

                          <Typography sx={{ fontSize: "13px", mb: 2 }}>
                            <b>Orientation:</b> The document should be oriented correctly in the
                            image; it should not be upside down or rotated. These guidelines are
                            designed to ensure that the uploaded image of your identity document is
                            clear, readable, and suitable for verification purposes.
                          </Typography>

                          <Typography sx={{ fontSize: "13px" }}>
                            Always double-check your image before submission to ensure all details
                            are visible and accurate.
                          </Typography>
                        </Box>

                        <Divider />

                        <Box sx={{ p: 2 }}>
                          <FileUpload setFile={setFrontFile} />

                          {frontFile && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,

                                border: 1,
                                borderColor: "divider",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 2,
                                        fontWeight: 600,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          pl: 2,
                                        }}
                                      >
                                        <PermMedia />
                                      </Box>
                                      <Box
                                        sx={{
                                          borderRight: 1,
                                          borderLeft: 1,
                                          borderColor: "divider",
                                          p: 1,
                                        }}
                                      >
                                        <Typography variant="">Filename</Typography>
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      fontSize: 13,
                                      pl: 2,
                                    }}
                                  >
                                    {frontFile.path}
                                  </Box>
                                </Box>

                                <Box sx={{ pr: 1 }}>
                                  <IconButton
                                    title="Remove file"
                                    onClick={() => {
                                      setFrontFile(null);
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          )}

                          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                            <Box></Box>

                            <LoadingButton
                              disableElevation
                              onClick={processUpload}
                              loading={processing}
                              variant="contained"
                              color="success"
                            >
                              Complete Upload
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRight: { xs: 0, xl: 1 },
                      borderColor: { xs: "divider", xl: "divider" },
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        px: 2,
                        py: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#0b0b0b",
                        color: "#ffffff",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="h6">Address verification</Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          p: 4,
                          backgroundColor: "#f0f0f0",
                          borderRadius: 10,
                          fontWeight: 500,
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          Please upload a clear and legible document that confirms your address.
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          The document must be recent (issued within the last 3 months) and display
                          your full name, address, and the issuing authority.
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          Accepted document types include:
                        </Typography>

                        <Box sx={{ pl: 4 }}>
                          <List
                            sx={{
                              listStyleType: "disc",
                              pl: 2,
                              "& .MuiListItem-root": {
                                display: "list-item",
                              },
                            }}
                          >
                            <ListItem>Utility bill</ListItem>
                            <ListItem>Bank or mortgage statement</ListItem>
                            <ListItem>Government issued letter</ListItem>
                            <ListItem>Residence Permit</ListItem>
                          </List>
                        </Box>

                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          Accepted File Formats: JPG, PNG, PDF Maximum File Size: 5MB
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="subtitle2">Document type</Typography>
                        <FormControl fullWidth>
                          <InputLabel id="document-type-label">Document type</InputLabel>
                          <Select
                            labelId="document-type-label"
                            id="document-type"
                            label="Document type"
                            onChange={(e) => setDocumentType(e.target.value)}
                          >
                            {documentTypeOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="subtitle2">Document issue date</Typography>

                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                          <DatePicker
                            fullWidth
                            openTo="year"
                            label={`Issue date`}
                            value={issuingDate ? issuingDate : null}
                            onChange={(date) => {
                              setIssuingDate(date);
                            }}
                            slotProps={{ textField: { fullWidth: true } }}
                            maxDate={moment()}
                          />
                        </LocalizationProvider>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">Password protected images</Typography>
                        </Box>

                        <Box>
                          <ToggleButtonGroup
                            color="primary"
                            value={passwordProtected}
                            exclusive
                            onChange={(e, value) => {
                              if (value !== null) setPasswordProtected(value);
                            }}
                          >
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>

                      <Collapse in={passwordProtected} exit={!passwordProtected}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="subtitle2">Document password</Typography>
                          <TextField
                            fullWidth
                            label="Document password"
                            value={password}
                            onChange={(e, value) => {
                              if (value !== null) setPassword(e.target.value);
                            }}
                          />
                        </Box>
                      </Collapse>

                      <Box
                        sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                      >
                        <LoadingButton
                          disableElevation
                          variant="contained"
                          loading={processing}
                          onClick={(e) => {
                            handleSetDocumentData(e);
                          }}
                          color="success"
                        >
                          Continue
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Box>
                )}

                {}
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box>
          <Box>
            <Box>
              <Box>
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      In which country is your primary residential address?
                    </Typography>

                    <CountrySelect value={addressCountry} setValue={setAddressCountry} />

                    <Collapse in={lookupDisplay} exit={!lookupDisplay}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          p: 2,
                          border: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Typography variant="subtitle2">Address lookup</Typography>
                        <TextField
                          label="Postcode / Zipcode"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            setLookupValue(e.target.value);
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <Box sx={{ minWidth: "300px;", width: "50%" }}>
                            {lookupOptions && lookupOptions.length > 0 && (
                              <Box>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                  Lookup results
                                </Typography>

                                <FormControl fullWidth>
                                  <InputLabel id="address-results-label">
                                    Select an address
                                  </InputLabel>
                                  <Select
                                    labelId="address-results-label"
                                    id="address-results"
                                    value={selectedAddress}
                                    label="Select an address"
                                    onChange={(e) => getSelectedAddress(e.target.value)}
                                  >
                                    {lookupOptions.map((option, index) => (
                                      <MenuItem key={index} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Box>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                  setLookupDisplay(false);
                                  setFormDisplay(true);
                                }}
                              >
                                Manual entry
                              </Button>
                            </Box>
                            <Box>
                              <LoadingButton
                                disableElevation
                                variant="contained"
                                color="success"
                                loading={processing}
                                onClick={(e) => handleLookupAddress(e)}
                              >
                                Search
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Collapse>

                    <Collapse in={formDisplay} exit={!formDisplay}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          label="Address line 1"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          fullWidth
                          value={addressLine1}
                          onChange={(e) => setAddressLine1(e.target.value)}
                        />

                        <Collapse
                          in={addressLine1 != "" ? true : addressLine2 != "" ? true : false}
                          exit={(addressLine1 == "") & (addressLine2 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 2"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                          />
                        </Collapse>
                        <Collapse
                          in={addressLine2 != "" ? true : addressLine3 != "" ? true : false}
                          exit={(addressLine2 == "") & (addressLine3 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 3"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine3}
                            onChange={(e) => setAddressLine3(e.target.value)}
                          />
                        </Collapse>

                        <Collapse
                          in={addressLine3 != "" ? true : addressLine4 != "" ? true : false}
                          exit={(addressLine3 == "") & (addressLine4 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 4"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine4}
                            onChange={(e) => setAddressLine4(e.target.value)}
                          />
                        </Collapse>
                        <Collapse
                          in={addressLine4 != "" ? true : addressLine5 != "" ? true : false}
                          exit={(addressLine4 == "") & (addressLine5 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 5"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine5}
                            onChange={(e) => setAddressLine5(e.target.value)}
                          />
                        </Collapse>
                        <Collapse
                          in={addressLine5 != "" ? true : addressLine6 != "" ? true : false}
                          exit={(addressLine5 == "") & (addressLine6 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 6"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine6}
                            onChange={(e) => setAddressLine6(e.target.value)}
                          />
                        </Collapse>
                        <TextField
                          label="City"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          fullWidth
                          value={addressCity}
                          onChange={(e) => setAddressCity(e.target.value)}
                        />

                        <Collapse in={stateRequired} exit={!stateRequired}>
                          <TextField
                            label="State"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressState}
                            onChange={(e) => setAddressState(e.target.value)}
                          />
                        </Collapse>
                        <TextField
                          label="Postcode/Zipcode"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          fullWidth
                          value={addressPostcode}
                          onChange={(e) => setAddressPostcode(e.target.value)}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 2,
                          py: 2,
                        }}
                      >
                        <Box>
                          {lookupSupported && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={(e) => {
                                setLookupDisplay(true);
                                setFormDisplay(false);
                              }}
                            >
                              Lookup address
                            </Button>
                          )}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                          <LoadingButton
                            disableElevation
                            variant="outlined"
                            color="primary"
                            loading={processing}
                            onClick={(e) => setEditMode(false)}
                          >
                            <Close />
                          </LoadingButton>
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            color="success"
                            loading={processing}
                            onClick={(e) => handleAddAddress(e)}
                          >
                            Save address
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Address;
