import { Children, React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";
import NoResults from "../../../../../components/functions/NoResults";

import PropTypes from "prop-types";
import { Tabs, Tab } from "@mui/material";

import { Typography, Box, Divider, Fade, Collapse, Chip } from "@mui/material";
import {
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Age from "./profiles/Age";
import Capacity from "./profiles/Capacity";
import Countries from "./profiles/Countries";
import Industries from "./profiles/Industries";
import DataSources from "./profiles/DataSources";
import CaseTemplates from "./profiles/CaseTemplates";
import RiskAssessment from "./profiles/RiskAssessment";
import Overview from "./profiles/Overview";
import Enhanced from "./profiles/Enhanced";
import SourceOfWealth from "./profiles/SourceOfWealth";

const Profiles = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [automatic, setAutomatic] = useState(false);
  const [automaticThreshold, setAutomaticThreshold] = useState("");

  const [confirmDialog, setConfirmDialog] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [profiles, setProfiles] = useState([]);
  const [newProfile, setNewProfile] = useState(false);
  const [manageProfile, setManageProfile] = useState(false);
  const [activeProfile, setActiveProfile] = useState(null);

  const [newProfileName, setNewProfileName] = useState("");

  const getProfiles = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/data/list`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setProfiles(response.data.entries);
      } else {
        setProfiles([]);
      }

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getProfileData = async (profileUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/profile/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveProfile(response.data);
      setManageProfile(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleCreateProfile = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/profile/create`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          profile_name: newProfileName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfileData(response.data.profile_uuid);
      getProfiles();

      displayToast("Profile created successfully", () => {
        setProcessing(false);
        setNewProfile(false);
      });
    } catch (err) {
      console.log(err);
      setProcessing(false);
      handleError(err);
    }
  };

  useEffect(() => {
    getProfiles();
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{}}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const [navValue, setNavValue] = useState(0);

  const handleNav = (event, newValue) => {
    setNavValue(newValue);
  };

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{}}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">
                    {manageProfile
                      ? "Managing profile: " + activeProfile.profile_name
                      : newProfile
                      ? "Create a new profile"
                      : "Compliance profiles"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 4 }}>
                {!manageProfile ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      setNewProfile(!newProfile);
                    }}
                  >
                    {newProfile ? "Close" : "Create Profile"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      setManageProfile(false);
                      setActiveProfile(null);
                    }}
                  >
                    Close
                  </Button>
                )}
              </Box>
            </Box>
            <Divider />

            <Box sx={{ border: 0, borderColor: "divider" }}>
              {manageProfile ? (
                <Box>
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      onChange={handleNav}
                      value={navValue}
                      aria-label="Tabs where selection follows focus"
                      selectionFollowsFocus
                    >
                      <Tab sx={{ fontSize: 12 }} label="Overview" {...a11yProps(0)} />
                      <Tab sx={{ fontSize: 12 }} label="Case Templates" {...a11yProps(1)} />
                      <Tab sx={{ fontSize: 12 }} label="Sources" {...a11yProps(2)} />
                      <Tab sx={{ fontSize: 12 }} label="Assessments" {...a11yProps(3)} />
                      <Tab sx={{ fontSize: 12 }} label="Age" {...a11yProps(4)} />
                      <Tab sx={{ fontSize: 12 }} label="Countries" {...a11yProps(5)} />
                      <Tab sx={{ fontSize: 12 }} label="Industries" {...a11yProps(6)} />
                      <Tab sx={{ fontSize: 12 }} label="Source of Wealth" {...a11yProps(7)} />
                      <Tab sx={{ fontSize: 12 }} label="Enhanced Templates" {...a11yProps(8)} />
                      <Tab sx={{ fontSize: 12 }} label="Burst Capacity" {...a11yProps(9)} />
                    </Tabs>
                  </Box>

                  <TabPanel value={navValue} index={0}>
                    <Overview
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile?.profile_uuid}
                      profileData={activeProfile}
                      getProfile={() => getProfileData(activeProfile?.profile_uuid)}
                      getProfiles={() => getProfiles()}
                      setManageProfile={setManageProfile}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={1}>
                    <CaseTemplates
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile?.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={2}>
                    <DataSources
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile?.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={3}>
                    <RiskAssessment
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={4}>
                    <Age
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={5}>
                    <Countries
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={6}>
                    <Industries
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={7}>
                    <SourceOfWealth
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={8}>
                    <Enhanced
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile.profile_uuid}
                    />
                  </TabPanel>

                  <TabPanel value={navValue} index={9}>
                    <Capacity
                      partner={partner}
                      partnerUser={partnerUser}
                      partnerData={partnerData}
                      profileUUID={activeProfile.profile_uuid}
                    />
                  </TabPanel>
                </Box>
              ) : newProfile ? (
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <TextField
                    label="Profile Name"
                    variant="outlined"
                    fullWidth
                    value={newProfileName}
                    onChange={(e) => setNewProfileName(e.target.value)}
                  />

                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      color="success"
                      loading={processing}
                      onClick={(e) => {
                        handleCreateProfile(e);
                      }}
                    >
                      Create Profile
                    </LoadingButton>
                  </Box>
                </Box>
              ) : profiles?.length > 0 ? (
                profiles.map((profile, index) => (
                  <Box
                    key={index}
                    sx={{
                      borderTop: index > 0 ? 1 : 0,
                      borderColor: "divider",
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        getProfileData(profile.uuid);
                      }}
                    >
                      <Typography variant="body2">{profile.profile_name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Box>
                        <Typography variant="subtitle2">Assigned clients</Typography>
                        <Typography variant="body1" sx={{ textAlign: "center" }}>
                          {profile.assigned_clients}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        {profile.active ? (
                          <Chip className="square-chip" label="Active" color="success" />
                        ) : (
                          <Chip className="square-chip" label="Inactive" color="error" />
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <NoResults message={`There are no profiles to display`} />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Profiles;
