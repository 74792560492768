import React from "react";

import Cookies from "universal-cookie";

import { Box } from "@mui/material";

const DocumentViewer = ({ viewDocumentData }) => {
  const partnerURL = () => {
    const cookies = new Cookies();
    return "https://" + cookies.get("platformURL");
  };

  const partnerAPI = partnerURL();

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mt: 2, border: 1, borderColor: "divider" }}>
        {viewDocumentData.filetype == ".pdf" ? (
          <embed
            width="100%"
            height="1200"
            src={`${partnerAPI}/${viewDocumentData.url}#view=Fit`}
            type={viewDocumentData.mimetype}
          />
        ) : (
          <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <img width={"60%"} sx={{}} src={`${partnerAPI}/${viewDocumentData.url}`} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DocumentViewer;
