import { React, useState, useEffect } from "react";
import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { Edit, Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CountrySelect from "../../../../components/form-elements/CountrySelect";

const FatcaCorporate = ({
  partnerUser,
  entityData,
  fatcaData,
  profileUUID,
  setManageProfile,
  getProfileData,
  getEntityData,
  getFatcaData,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [processing, setProcessing] = useState(false);

  const [entityType, setEntityType] = useState(fatcaData ? fatcaData.entity_type : 0);

  const [usPersonTin, setUsPersonTin] = useState(fatcaData ? fatcaData.tin : "");

  const [usPersonExemptionType, setUsPersonExemptionType] = useState(
    fatcaData ? fatcaData.us_person_exemption : 0
  );

  const [financialInstitution, setFinancialInstitution] = useState(
    fatcaData ? (fatcaData.financial_institution ? 1 : 0) : 0
  );
  const [financialInstitutionType, setFinancialInstitutionType] = useState(
    fatcaData ? fatcaData.financial_institution_type : 0
  );

  const [giin, setGiin] = useState(fatcaData ? fatcaData.giin : "");
  const [noGiinReason, setNoGiinReason] = useState(fatcaData ? fatcaData.no_giin_reason : 0);

  const [sponsoringEntityName, setSponsoringEntityName] = useState(
    fatcaData ? fatcaData.sponsor_name : ""
  );
  const [sponsoringEntityGIIN, setSponsoringEntityGIIN] = useState(
    fatcaData ? fatcaData.sponsor_giin : ""
  );
  const [trusteeName, setTrusteeName] = useState(fatcaData ? fatcaData.trustee_name : "");
  const [trusteeGIIN, setTrusteeGIIN] = useState(fatcaData ? fatcaData.trustee_giin : "");
  const [noGiinExemptionType, setNoGiinExemptionType] = useState(
    fatcaData ? fatcaData.no_giin_exemption_type : 0
  );

  const handleSaveFatcaDeclaration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/tax/fatca`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          profile_uuid: profileUUID,
          entity_type: parseInt(entityType),
          us_person_tin: usPersonTin,
          us_person_exemption_type: parseInt(usPersonExemptionType),
          financial_institution: financialInstitution == 1 ? true : false,
          financial_institution_type: parseInt(financialInstitutionType),
          giin: giin,
          no_giin_type: parseInt(noGiinReason),
          sponsor_name: sponsoringEntityName,
          sponsor_giin: sponsoringEntityGIIN,
          trustee_name: trusteeName,
          trustee_giin: trusteeGIIN,
          no_giin_exemption_type: parseInt(noGiinExemptionType),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfileData();
      getEntityData();
      getFatcaData();

      displayToast("FATCA declaration saved successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box sx={{}}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box>
          <Box sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 400, fontStyle: "italic" }}>
              FATCA is a US law that requires financial institutions to identify their US customers
              and report information about financial accounts held by US persons either directly or
              through certain foreign entities. FATCA requires financial institutions to report on
              the foreign assets held by their US account holders or be subject to withholding on
              withholdable payments.
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="fatca-type-options-label"
                defaultValue={0}
                value={entityType}
                onChange={(e) => setEntityType(e.target.value)}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={1}
                  control={<Radio />}
                  label="The entity is a Specified US Person"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={2}
                  control={<Radio />}
                  label="The entity is a US Person, that is not a Specified US Person"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={3}
                  control={<Radio />}
                  label="The entity is not considered a US Person"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

        <Collapse in={entityType == 1} exit={entityType != 1}>
          <Box>
            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6">Specified US Person</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  A Specified US Person under the FATCA (Foreign Account Tax Compliance Act) rules
                  typically includes any US corporation or entity that is not specifically excluded
                  by the legislation. This can be characterized as a corporation that is created or
                  organised in the United States or under the laws of the United States or any state
                  therein, excluding entities such as foreign governments, international
                  organizations, foreign central banks of issue, and others specifically exempted by
                  the FATCA regulations. These entities are subject to certain reporting
                  requirements for their financial accounts and offshore assets to ensure compliance
                  with US tax laws and regulations.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography variant="subtitle2">
                Provide the Tax identification number (TIN)
              </Typography>
              <TextField
                fullWidth
                label="TIN"
                variant="outlined"
                value={usPersonTin}
                onChange={(e) => setUsPersonTin(e.target.value)}
              />
            </Box>
          </Box>
        </Collapse>

        <Collapse in={entityType == 2} exit={entityType != 2}>
          <Box>
            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6">US Person (Exemption)</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Please select the option that describes how the entity is exempt from being a
                  Specified US Person.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ p: 2 }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="fatca-exemptions-options-label"
                  defaultValue={0}
                  value={usPersonExemptionType}
                  onChange={(e) => setUsPersonExemptionType(e.target.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={1}
                    control={<Radio />}
                    label="An organisation exempt from tax under section 501(a), or any individual retirement plan as defined in section 7701(a)(37)"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={2}
                    control={<Radio />}
                    label="The United States or any of its agencies or instrumentalities"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={3}
                    control={<Radio />}
                    label="A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions, agencies, or instrumentalities"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={4}
                    control={<Radio />}
                    label="A corporation the stock of which is regularly traded on one or more established securities markets, as described in Regulations 1.1472-1(c)(1)(i)"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={5}
                    control={<Radio />}
                    label="A state, the District of Columbia, a US territory, a political subdivision of any of the foregoing, or a wholly owned agency or instrumentality of any one or more of the foregoing"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={6}
                    control={<Radio />}
                    label="A corporation that is a member of the same expanded affiliated group as a corporation described in Regulations 1.1472-1(c)(1)(i)"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={7}
                    control={<Radio />}
                    label="A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the United States or any State"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={8}
                    control={<Radio />}
                    label="A real estate investment trust"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={9}
                    control={<Radio />}
                    label="A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={10}
                    control={<Radio />}
                    label="A common trust fund as defined in section 584(a)"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={11}
                    control={<Radio />}
                    label="A bank as defined in section 581"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={12}
                    control={<Radio />}
                    label="A broker"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={13}
                    control={<Radio />}
                    label="A trust exempt from tax under section 664 or described in section 4947"
                  />
                  <FormControlLabel
                    sx={{ mb: 1 }}
                    value={14}
                    control={<Radio />}
                    label="A tax-exempt trust under a section 403(b) plan or section 457(g) plan"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Collapse>

        <Collapse in={entityType == 3} exit={entityType != 3}>
          <Box>
            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6">Non US Person</Typography>
                <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                  Please complete the questions below.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="subtitle2">
                  Is the entity is a Registered Financial Institution?
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="fatca-three-point-one-label"
                    defaultValue={0}
                    value={financialInstitution}
                    onChange={(e) => setFinancialInstitution(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel sx={{ mb: 1 }} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel sx={{ mb: 1 }} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Collapse in={financialInstitution == 1} exit={financialInstitution == 0}>
                <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                  Select an option that describes the entity and provide the Global intermediary
                  identification number (GIIN)
                </Typography>

                <FormControl>
                  <RadioGroup
                    aria-labelledby="fatca-three-point-one-label"
                    defaultValue={0}
                    value={financialInstitutionType}
                    onChange={(e) => setFinancialInstitutionType(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      sx={{ mb: 1 }}
                      value={1}
                      control={<Radio />}
                      label="Isle of Man or IGA Partner Jurisdiction Financial Institution"
                    />
                    <FormControlLabel
                      sx={{ mb: 1 }}
                      value={2}
                      control={<Radio />}
                      label="Registered Deemed Compliant Foreign Financial Institution"
                    />
                    <FormControlLabel
                      sx={{ mb: 1 }}
                      value={3}
                      control={<Radio />}
                      label="Participating Foreign Financial Institution"
                    />
                  </RadioGroup>
                </FormControl>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}>
                  <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                    Provide the Global intermediary identification number (GIIN)
                  </Typography>
                  <TextField
                    fullWidth
                    label="GIIN"
                    variant="outlined"
                    value={giin}
                    onChange={(e) => setGiin(e.target.value)}
                  />
                </Box>

                <Collapse
                  in={financialInstitution == 1 && giin == ""}
                  exit={financialInstitution == 2 && giin != ""}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}>
                    <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                      If the entity is a Financial Institution but unable to provide a GIIN, please
                      select one of the reasons below
                    </Typography>

                    <FormControl>
                      <RadioGroup
                        aria-labelledby="fatca-three-point-one-label"
                        defaultValue={0}
                        value={noGiinReason}
                        onChange={(e) => setNoGiinReason(e.target.value)}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          sx={{ mb: 1 }}
                          value={1}
                          control={<Radio />}
                          label="The entity is a Sponsored Financial Institution (including a Sponsored Investment Entity and Sponsored Closely Held Investment Vehicle) and has not yet obtained a GIIN but is sponsored by another entity that has registered as a Sponsoring Entity"
                        />
                        <FormControlLabel
                          sx={{ mb: 1 }}
                          value={2}
                          control={<Radio />}
                          label="The entity is a Trustee Documented Trust"
                        />
                        <FormControlLabel
                          sx={{ mb: 1 }}
                          value={3}
                          control={<Radio />}
                          label="The entity is a Certified Deemed Compliant, or other Non-Reporting Foreign Financial Institution (including a Foreign Financial Institution deemed compliant under Annex II of an IGA, except for a Trustee Documented Trust or Sponsored Financial Institution)."
                        />
                        <FormControlLabel
                          sx={{ mb: 1 }}
                          value={4}
                          control={<Radio />}
                          label="The entity is an Owner Documented Financial Institution"
                        />
                        <FormControlLabel
                          sx={{ mb: 1 }}
                          value={5}
                          control={<Radio />}
                          label="The entity is a Non-Participating Foreign Financial Institution"
                        />
                      </RadioGroup>
                    </FormControl>

                    <Collapse in={noGiinReason == 1} exit={noGiinReason != 1}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                            Provide the sponsoring entities name
                          </Typography>
                          <TextField
                            fullWidth
                            label="Sponsoring entities name"
                            variant="outlined"
                            value={sponsoringEntityName}
                            onChange={(e) => setSponsoringEntityName(e.target.value)}
                          />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                            Provide the sponsoring entities Global intermediary identification
                            number (GIIN)
                          </Typography>
                          <TextField
                            fullWidth
                            label="Sponsoring entities GIIN"
                            variant="outlined"
                            value={sponsoringEntityGIIN}
                            onChange={(e) => setSponsoringEntityGIIN(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Collapse>

                    <Collapse in={noGiinReason == 2} exit={noGiinReason != 2}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                            Provide the trustees name
                          </Typography>
                          <TextField
                            fullWidth
                            label="Trustees name"
                            variant="outlined"
                            value={trusteeName}
                            onChange={(e) => setTrusteeName(e.target.value)}
                          />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                            Provide the trustees Global intermediary identification number (GIIN)
                          </Typography>
                          <TextField
                            fullWidth
                            label="Trustees GIIN"
                            variant="outlined"
                            value={trusteeGIIN}
                            onChange={(e) => setTrusteeGIIN(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Collapse>

                    <Collapse in={noGiinReason == 3} exit={noGiinReason != 3}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                          Select the reason for exemption
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="fatca-three-point-two-exemption-label"
                            defaultValue={0}
                            value={noGiinExemptionType}
                            onChange={(e) => setNoGiinExemptionType(e.target.value)}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={1}
                              control={<Radio />}
                              label="The entity is a Certified Deemed Compliant Foreign Financial Institution"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={2}
                              control={<Radio />}
                              label="The entity is a Non-Participating Foreign Financial Institution"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Collapse>
                  </Box>
                </Collapse>
              </Collapse>
            </Box>
          </Box>
        </Collapse>

        <Divider />

        <Box
          sx={{
            py: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton
            disableElevation
            variant="contained"
            color="success"
            onClick={(e) => {
              handleSaveFatcaDeclaration(e);
            }}
            loading={processing}
          >
            Save declaration
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FatcaCorporate;
