import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import central from "../../api/central";
import authHeader from "../../api/authHeader";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import DataTable from "react-data-table-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Drawer,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import {
  Edit,
  Close,
  Delete,
  ImportContacts,
  People,
  Business,
  Group,
  Diversity3,
  Check,
  ArrowForward,
  Person,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import NoResults from "../../components/functions/NoResults";
import CountrySelect from "../../components/form-elements/CountrySelect";

const Officers = ({ partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [data, setData] = useState([]);

  const [newOfficer, setNewOfficer] = useState(false);
  const [editOfficer, setEditOfficer] = useState(false);
  const [activeOfficer, setActiveOfficer] = useState();

  const [rolodexOpen, setRolodexOpen] = useState(false);
  const [rolodexLetter, setRolodexLetter] = useState("A");
  const [rolodexData, setRolodexData] = useState();
  const [rolodexUUID, setRolodexUUID] = useState("");

  const [title, setTitle] = useState();
  const [firstName, setFirstName] = useState();
  const [middleNames, setMiddleNames] = useState();
  const [lastName, setLastName] = useState();

  const [lookupCorporation, setLookupCorporation] = useState(true);
  const [lookupName, setLookupName] = useState("");
  const [corporationOptions, setCorporationOptions] = useState([]);
  const [selectedCorporation, setSelectedCorporation] = useState("");

  const [registrationTypeOptions, setRegistrationTypeOptions] = useState([]);
  const [registrationType, setRegistrationType] = useState("");
  const [corporationTypeLabel, setCorporationTypeLabel] = useState("");
  const [registrationTypeData, setRegistrationTypeData] = useState({});
  const [registrationName, setRegistrationName] = useState("");
  const [registrationReference, setRegistrationReference] = useState("");
  const [registrationDate, setRegistrationDate] = useState(null);
  const [registrationCountry, setRegistrationCountry] = useState();

  const [selectedRole, setSelectedRole] = useState([]);
  const [currentShownMenu, setCurrentShownMenu] = useState(0);
  const [confirmAvailable, setConfirmAvailable] = useState(false);

  const [removeOfficerDialog, setRemoveOfficerDialog] = useState(false);
  const [removeOfficer, setRemoveOfficer] = useState();

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const roleOptions = [
    { value: 1, label: "Director" },
    { value: 2, label: "Secretary" },
    { value: 3, label: "Accountant" },
    { value: 4, label: "Auditor" },
    { value: 5, label: "Beneficiary" },
    { value: 6, label: "Trustee" },
    { value: 7, label: "Settler" },
    { value: 8, label: "Protector" },
    { value: 9, label: "Enforcer" },
    { value: 10, label: "Guardian" },
    { value: 11, label: "Power of attorney" },
    { value: 12, label: "Solicitor" },
    { value: 13, label: "Barrister" },
    { value: 14, label: "Legal representative" },
    { value: 15, label: "Founder" },
    { value: 16, label: "Chairman" },
    { value: 17, label: "President" },
    { value: 18, label: "Vice President" },
    { value: 19, label: "Treasurer" },
    { value: 20, label: "Vice Treasurer" },
    { value: 21, label: "CEO" },
    { value: 22, label: "CFO" },
    { value: 23, label: "COO" },
    { value: 24, label: "CIO" },
    { value: 25, label: "CISO" },
    { value: 26, label: "CTO" },
  ];

  const roleChipColor = (role) => {
    switch (parseInt(role)) {
      case 1:
        return "#935FA7";
      case 2:
        return "#DDA448";
      case 5:
        return "#94AE89";
      case 6:
        return "#94AE89";
      case 7:
        return "#94AE89";
      case 8:
        return "#94AE89";
      case 9:
        return "#94AE89";
      default:
        return "#413C58";
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      width: "50%",
      selector: (row) => row.officer_name,
    },
    {
      name: "Roles",
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          {row.officer_role.map((role, index) => (
            <Chip
              key={index}
              className="square-chip"
              label={role.label}
              sx={{
                color: "#ffffff",
                backgroundColor: roleChipColor(role.value),
                textTransform: "uppercase",
              }}
            />
          ))}
        </Box>
      ),
    },
    {
      name: "Options",
      right: "true",
      omit: caseData.value.officers ? true : false,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <IconButton
            size="small"
            onClick={() => {
              var roles = [];

              row.officer_role.map((role) => {
                roles.push(parseInt(role.value));
              });

              setSelectedRole(roles);

              setActiveOfficer(row);
              setEditOfficer(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const filterOptions = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "#",
  ];

  const getOfficers = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/officers`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          profile_uuid: caseData.value.profile_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setConfirmAvailable(!caseData.value.officers ? response.data.completion_available : false);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleLookupCorporations = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/corporations/lookup/options`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          search_terms: lookupName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setCorporationOptions(response.data.entries);
      } else {
        setCorporationOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getRegistrationTypeOptions = async () => {
    if (!registrationCountry) {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/types/options`,
        JSON.stringify({
          country: registrationCountry.value ? registrationCountry.value : registrationCountry.code,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRegistrationTypeOptions(response.data.entries);
      } else {
        setRegistrationTypeOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getRegistrationTypeData = async () => {
    if (registrationType == "") {
      return;
    }

    try {
      const response = await central.post(
        `/v1/service/corporations/type-data`,
        JSON.stringify({
          type_uuid: registrationType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationTypeData(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  const getCorporationData = async () => {
    if (selectedCorporation == "") {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          uuid: selectedCorporation,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationName(response.data.name);
      setRegistrationReference(response.data.company_number);
      setRegistrationDate(response.data.incorporation_date);
      setRegistrationCountry(response.data.country);
      setCorporationTypeLabel(response.data.company_type);
    } catch (err) {
      handleError(err);
    }
  };

  const getRolodexData = async (letter, dataType) => {
    try {
      const response = await partner.post(
        `/v1/entity/rolodex/options`,
        JSON.stringify({
          data_type: dataType,
          letter: letter,
          profile_uuid: caseData.value.profile_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setRolodexLetter(letter);

      if (response.data.results > 0) {
        setRolodexData(response.data.entries);
      } else {
        setRolodexData([]);
      }

      setRolodexOpen(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getRolodexEntry = async (uuid, dataType) => {
    try {
      const response = await partner.post(
        `/v1/entity/rolodex/data`,
        JSON.stringify({
          entity_type: dataType,
          entity_uuid: uuid,
          profile_uuid: caseData.value.profile_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (dataType == 1) {
        setTitle(response.data.title);
        setFirstName(response.data.first_name);
        setMiddleNames(response.data.middle_names);
        setLastName(response.data.last_name);
      } else {
        setRegistrationName(response.data.registered_name);
        setRegistrationReference(response.data.registration_reference);
        setRegistrationDate(
          response.data.registration_date ? moment(response.data.registration_date) : ""
        );
        setRegistrationCountry({
          value: response.data.country.code,
          name: response.data.country.name,
        });
        setRegistrationType(response.data.registration_type);
        setLookupCorporation(false);
      }

      setRolodexOpen(false);
    } catch (err) {
      handleError(err);
    }
  };

  const resetForms = () => {
    setTitle();
    setFirstName("");
    setMiddleNames("");
    setLastName("");

    setLookupName("");
    setLookupCorporation(true);
    setCorporationOptions([]);
    setSelectedCorporation("");
    setRegistrationType("");
    setRegistrationTypeOptions([]);
    setRegistrationTypeData({});
    setRegistrationName("");
    setRegistrationReference("");
    setRegistrationDate(null);
    setRegistrationCountry(null);
    setRolodexOpen(false);
    setRolodexLetter("A");
    setRolodexData([]);
  };

  const handleAddOfficer = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/officers/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          roles: selectedRole,
          entity_type: currentShownMenu,

          entity_uuid: rolodexUUID,

          title: title,
          first_name: firstName,
          middle_names: middleNames,
          last_name: lastName,

          corporation_registered_name: registrationName,
          corporation_registration_type: registrationType,
          corporation_registration_reference: registrationReference,
          corporation_registration_country: registrationCountry?.value
            ? registrationCountry.value
            : registrationCountry?.code
            ? registrationCountry.code
            : "",
          corporation_registration_date: registrationDate?.startDate
            ? registrationDate.startDate
            : "",
          corporation_lookup_uuid: selectedCorporation ? selectedCorporation : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOfficers();

      displayToast("Officer added successfully", () => {
        setProcessing(false);
        setNewOfficer(false);
        setRolodexData([]);
        setRolodexOpen(false);
        setRolodexUUID("");
        setRolodexLetter("A");

        setFirstName("");
        setMiddleNames("");
        setLastName("");
        setTitle("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateOfficer = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/officers/edit`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          roles: selectedRole,
          entity_uuid: activeOfficer.entity_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOfficers();

      displayToast("Officer updated successfully", () => {
        setProcessing(false);
        setEditOfficer(false);
        setActiveOfficer();
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteOfficer = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/officers/remove`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          entity_uuid: activeOfficer.entity_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOfficers();

      displayToast("Officer deleted successfully", () => {
        setProcessing(false);
        setEditOfficer(false);
        setActiveOfficer();
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleConfirmOfficers = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/officers/confirm`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          profile_uuid: caseData.value.profile_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOfficers();

      displayToast("Officer and management information confirmed", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getOfficers();
  }, []);

  useEffect(() => {
    if (selectedCorporation != "") {
      getCorporationData();
    }
  }, [selectedCorporation]);

  useEffect(() => {
    if (registrationCountry) {
      getRegistrationTypeOptions();
    } else {
      setRegistrationTypeOptions([]);
      setRegistrationType("");
    }
  }, [registrationCountry]);

  useEffect(() => {
    if (registrationType != "") {
      getRegistrationTypeData();
    }
  }, [registrationType]);

  return isLoaded ? (
    <Box sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box sx={{ borderRight: { xs: 0, xl: 0 }, borderColor: { xl: "divider" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}
              >
                <Typography variant="h6">Officers & Management</Typography>
              </Box>
              {!caseData.value.officers && (
                <Box sx={{ p: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (newOfficer) {
                        setNewOfficer(false);
                        return;
                      }
                      if (editOfficer) {
                        setEditOfficer(false);
                        return;
                      }

                      setNewOfficer(true);
                    }}
                  >
                    {newOfficer ? "Close" : editOfficer ? "Close" : "Add a new officer"}
                  </Button>
                </Box>
              )}
            </Box>
            <Divider />

            {!newOfficer && !editOfficer && (
              <Box>
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  striped
                  noDataComponent={
                    <NoResults message={`There are no officers or managers assigned`} />
                  }
                />
                {confirmAvailable && (
                  <>
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                          handleConfirmOfficers(e);
                        }}
                        loading={processing}
                      >
                        Confirm details
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </Box>
            )}

            {newOfficer && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    p: 2,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">
                      Select the type of entity you would like to add
                    </Typography>
                  </Box>
                  <ToggleButtonGroup
                    color="primary"
                    value={currentShownMenu}
                    exclusive
                    onChange={(e, newValue) => {
                      setCurrentShownMenu(newValue);
                    }}
                    aria-label="Case type"
                  >
                    <ToggleButton value={1} onClick={() => setCurrentShownMenu(1)}>
                      Individual
                    </ToggleButton>
                    <ToggleButton value={2} onClick={() => setCurrentShownMenu(2)}>
                      Corporation
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {currentShownMenu === 1 && (
                  <Box>
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">
                                <strong>Personal information</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                              {rolodexOpen ? (
                                <Button
                                  startIcon={<Close />}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    resetForms();
                                    setRolodexOpen(false);
                                  }}
                                >
                                  Close
                                </Button>
                              ) : (
                                <Button
                                  startIcon={<ImportContacts />}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    getRolodexData("A", 1);
                                  }}
                                >
                                  Rolodex
                                </Button>
                              )}
                            </Box>
                          </Box>

                          <Divider />

                          {rolodexOpen ? (
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                  gap: 1,
                                  p: 2,
                                }}
                              >
                                {filterOptions.map((option, index) => (
                                  <Box
                                    key={index}
                                    onClick={() => {
                                      getRolodexData(option, 1);
                                    }}
                                    sx={{
                                      border: 1,
                                      p: 1,
                                      fontWeight: "bold",
                                      borderColor: "divider",
                                      cursor: "pointer",
                                      background: rolodexLetter == option ? "#0b0b0b" : "",
                                      color: rolodexLetter == option ? "#ffffff" : "",
                                    }}
                                  >
                                    {option}
                                  </Box>
                                ))}
                              </Box>

                              <Divider />

                              {rolodexData && rolodexData.length > 0 ? (
                                rolodexData.map((entry, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      px: 2,
                                      py: 1,
                                      borderBottom: index == rolodexData.length - 1 ? 0 : 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
                                      <Box
                                        sx={{
                                          minWidth: "20%",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {entry.name}
                                        </Typography>
                                      </Box>
                                      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                        {entry.country.code && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Typography sx={{ fontSize: "14px" }}>
                                              {entry.country.name}
                                            </Typography>
                                          </Box>
                                        )}
                                        {entry.kyc_complete && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Chip
                                              className="square-chip"
                                              label="KYC Complete"
                                              sx={{
                                                backgroundColor: "#55828b",
                                                color: "#ffffff",
                                                textTransform: "uppercase",
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {entry.kyb_complete && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Chip
                                              className="square-chip"
                                              label="KYB Complete"
                                              sx={{
                                                backgroundColor: "#474044",
                                                color: "#ffffff",
                                                textTransform: "uppercase",
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>

                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        setRolodexUUID(entry.entity_uuid);
                                        getRolodexEntry(entry.entity_uuid, entry.entity_type);
                                      }}
                                    >
                                      <ArrowForward />
                                    </IconButton>
                                  </Box>
                                ))
                              ) : (
                                <NoResults message={`There are no entities under that character`} />
                              )}
                            </Box>
                          ) : (
                            <Grid sx={{ p: 2 }} container spacing={2}>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="name-title-label">Title</InputLabel>
                                  <Select
                                    disabled={rolodexUUID ? true : false}
                                    labelId="name-title"
                                    id="name-title"
                                    value={title}
                                    label="Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                  >
                                    {titleOptions.map((option, index) => (
                                      <MenuItem key={index} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="First name"
                                  variant="outlined"
                                  value={firstName}
                                  disabled={rolodexUUID ? true : false}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Middle name(s)"
                                  variant="outlined"
                                  value={middleNames}
                                  disabled={rolodexUUID ? true : false}
                                  onChange={(e) => setMiddleNames(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Last name"
                                  variant="outlined"
                                  value={lastName}
                                  disabled={rolodexUUID ? true : false}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {!rolodexOpen && (
                                  <Box sx={{}}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                      <Typography variant="subtitle2">
                                        Select the roles the corporation fulfils
                                      </Typography>
                                      <FormControl fullWidth>
                                        <InputLabel id="role-label">Role(s)</InputLabel>
                                        <Select
                                          labelId="role-label"
                                          multiple={true}
                                          id="role"
                                          value={selectedRole}
                                          placeholder="Select roles"
                                          label="Role(s)"
                                          onChange={(e) => setSelectedRole(e.target.value)}
                                        >
                                          {roleOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 2,
                                      }}
                                    >
                                      <Box>
                                        {rolodexUUID && (
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                              resetForms();
                                              setRolodexUUID("");
                                            }}
                                          >
                                            Clear
                                          </Button>
                                        )}
                                      </Box>
                                      <LoadingButton
                                        disableElevation
                                        variant="contained"
                                        color="success"
                                        onClick={(e) => handleAddOfficer(e)}
                                        loading={processing}
                                      >
                                        Save
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}

                {currentShownMenu === 2 && (
                  <Box>
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                        <Box sx={{ border: 1, borderColor: "divider" }}>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">
                                <strong>Corporation information</strong>
                              </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                              {rolodexOpen ? (
                                <Button
                                  startIcon={<Close />}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    resetForms();
                                    setRolodexOpen(false);
                                  }}
                                >
                                  Close
                                </Button>
                              ) : (
                                <Button
                                  startIcon={<ImportContacts />}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    getRolodexData("A", 2);
                                  }}
                                >
                                  Rolodex
                                </Button>
                              )}
                            </Box>
                          </Box>

                          <Divider />

                          {rolodexOpen ? (
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                  gap: 1,
                                  p: 2,
                                }}
                              >
                                {filterOptions.map((option, index) => (
                                  <Box
                                    key={index}
                                    onClick={() => {
                                      getRolodexData(option, 2);
                                    }}
                                    sx={{
                                      border: 1,
                                      p: 1,
                                      fontWeight: "bold",
                                      borderColor: "divider",
                                      cursor: "pointer",
                                      background: rolodexLetter == option ? "#0b0b0b" : "",
                                      color: rolodexLetter == option ? "#ffffff" : "",
                                    }}
                                  >
                                    {option}
                                  </Box>
                                ))}
                              </Box>

                              <Divider />

                              {rolodexData && rolodexData.length > 0 ? (
                                rolodexData.map((entry, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      px: 2,
                                      py: 1,
                                      borderBottom: index == rolodexData.length - 1 ? 0 : 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
                                      <Box
                                        sx={{
                                          minWidth: "20%",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {entry.name}
                                        </Typography>
                                      </Box>
                                      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                        {entry.country.code && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Typography sx={{ fontSize: "14px" }}>
                                              {entry.country.name}
                                            </Typography>
                                          </Box>
                                        )}
                                        {entry.kyc_complete && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Chip
                                              className="square-chip"
                                              label="KYC Complete"
                                              sx={{
                                                backgroundColor: "#55828b",
                                                color: "#ffffff",
                                                textTransform: "uppercase",
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {entry.kyb_complete && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Chip
                                              className="square-chip"
                                              label="KYB Complete"
                                              sx={{
                                                backgroundColor: "#474044",
                                                color: "#ffffff",
                                                textTransform: "uppercase",
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>

                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        setRolodexUUID(entry.entity_uuid);
                                        getRolodexEntry(entry.entity_uuid, entry.entity_type);
                                      }}
                                    >
                                      <ArrowForward />
                                    </IconButton>
                                  </Box>
                                ))
                              ) : (
                                <NoResults message={`There are no entities under that character`} />
                              )}
                            </Box>
                          ) : (
                            <Grid sx={{ p: 2 }} container spacing={2}>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box sx={{ border: 1, borderColor: "divider" }}>
                                  <Box sx={{ p: 2 }}>
                                    <Typography variant="body2">
                                      <strong>
                                        How would you like to input the business information?
                                      </strong>
                                    </Typography>
                                  </Box>
                                  <Divider />
                                  <Box
                                    sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}
                                  >
                                    <ToggleButtonGroup
                                      color="primary"
                                      value={lookupCorporation}
                                      exclusive
                                      onChange={(e, newValue) => {
                                        setLookupCorporation(newValue);
                                        setRegistrationCountry(null);
                                        setSelectedCorporation("");
                                        setRegistrationType("");
                                        setRegistrationTypeOptions([]);
                                        setRegistrationTypeData({});
                                        setRegistrationName("");
                                        setRegistrationReference("");
                                        setRegistrationDate(null);
                                      }}
                                      aria-label="Entry type"
                                    >
                                      <ToggleButton value={true}>Lookup corporation</ToggleButton>
                                      <ToggleButton value={false}>Manual entry</ToggleButton>
                                    </ToggleButtonGroup>

                                    {lookupCorporation ? (
                                      <Box width={"100%"}>
                                        <TextField
                                          fullWidth
                                          id="outlined-basic"
                                          label="Business name"
                                          variant="outlined"
                                          value={lookupName}
                                          onChange={(e) => setLookupName(e.target.value)}
                                        />

                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            mt: 2,
                                          }}
                                        >
                                          <LoadingButton
                                            disableElevation
                                            loading={processing}
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => handleLookupCorporations(e)}
                                          >
                                            Lookup corporation
                                          </LoadingButton>
                                        </Box>

                                        {corporationOptions?.length > 0 && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              mt: 2,
                                              border: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <Box sx={{ p: 2 }}>
                                              <Typography variant="body2">
                                                <strong>Select a result</strong>
                                              </Typography>
                                            </Box>
                                            <Divider />

                                            <Box sx={{ p: 2 }}>
                                              <FormControl fullWidth>
                                                <InputLabel id="corporation-options-label">
                                                  Search results
                                                </InputLabel>

                                                <Select
                                                  labelId="corporation-options-label"
                                                  id="corporation-options"
                                                  value={selectedCorporation}
                                                  label="Select corporation"
                                                  onChange={(e) => {
                                                    setSelectedCorporation(e.target.value);
                                                  }}
                                                >
                                                  {corporationOptions?.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                      <Typography variant="body2">
                                                        {option.label}
                                                      </Typography>
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Box>
                                          </Box>
                                        )}
                                      </Box>
                                    ) : (
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                          <CountrySelect
                                            value={registrationCountry}
                                            setValue={setRegistrationCountry}
                                          />
                                        </Grid>

                                        {registrationCountry && (
                                          <>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                              {registrationTypeOptions?.length > 0 && (
                                                <>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="owner-label">
                                                      Registration type
                                                    </InputLabel>

                                                    <Select
                                                      labelId="owner"
                                                      id="owner"
                                                      value={registrationType}
                                                      label="Account manager"
                                                      onChange={(e) =>
                                                        setRegistrationType(e.target.value)
                                                      }
                                                    >
                                                      {registrationTypeOptions?.map(
                                                        (option, index) => (
                                                          <MenuItem
                                                            key={index}
                                                            value={option.value}
                                                          >
                                                            {option.label}
                                                          </MenuItem>
                                                        )
                                                      )}
                                                    </Select>
                                                  </FormControl>
                                                </>
                                              )}
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                              <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                label={
                                                  registrationType &&
                                                  registrationTypeData?.registration_required
                                                    ? "Registered name"
                                                    : "Business name"
                                                }
                                                variant="outlined"
                                                value={registrationName}
                                                onChange={(e) =>
                                                  setRegistrationName(e.target.value)
                                                }
                                              />
                                            </Grid>

                                            {registrationType &&
                                              registrationTypeData.registration_required && (
                                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                                  <TextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="Registration reference"
                                                    variant="outlined"
                                                    value={registrationReference}
                                                    onChange={(e) =>
                                                      setRegistrationReference(e.target.value)
                                                    }
                                                  />
                                                </Grid>
                                              )}

                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                              <LocalizationProvider
                                                dateAdapter={AdapterMoment}
                                                adapterLocale={locale}
                                              >
                                                <DatePicker
                                                  fullWidth
                                                  openTo="year"
                                                  label={`${
                                                    registrationType &&
                                                    registrationTypeData.registration_required
                                                      ? "Registration date"
                                                      : "Start date"
                                                  }`}
                                                  value={registrationDate ? registrationDate : null}
                                                  onChange={(date) => {
                                                    setRegistrationDate(date);
                                                  }}
                                                  slotProps={{ textField: { fullWidth: true } }}
                                                  maxDate={moment()}
                                                />
                                              </LocalizationProvider>
                                            </Grid>

                                            {registrationType &&
                                              registrationTypeData.description && (
                                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                                  <Card variant="outlined">
                                                    <CardHeader title="Registration type information" />
                                                    <CardContent sx={{ pt: 0 }}>
                                                      <Typography variant="body2">
                                                        {registrationTypeData.description}
                                                      </Typography>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              )}
                                          </>
                                        )}
                                      </Grid>
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      </Box>

                      {!rolodexOpen && (
                        <Box sx={{ px: 2 }}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="subtitle2">
                              Select the roles the corporation fulfils
                            </Typography>
                            <FormControl fullWidth>
                              <InputLabel id="role-label">Role(s)</InputLabel>
                              <Select
                                labelId="role-label"
                                multiple={true}
                                id="role"
                                value={selectedRole}
                                label="Role(s)"
                                onChange={(e) => setSelectedRole(e.target.value)}
                              >
                                {roleOptions.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>

                          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                            <LoadingButton
                              disableElevation
                              variant="contained"
                              color="success"
                              onClick={(e) => handleAddOfficer(e)}
                              loading={processing}
                            >
                              Save
                            </LoadingButton>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {editOfficer && (
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}>
                  <Typography variant="h6">{activeOfficer.officer_name}</Typography>
                </Box>
                <Box sx={{ px: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pt: 2 }}>
                    <Typography variant="subtitle2">
                      Select the roles the corporation fulfils
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">Role(s)</InputLabel>
                      <Select
                        labelId="role-label"
                        multiple={true}
                        id="role"
                        value={selectedRole}
                        label="Role(s)"
                        onChange={(e) => setSelectedRole(e.target.value)}
                      >
                        {roleOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
                    <IconButton
                      title="Delete officer"
                      color="error"
                      onClick={() => {
                        setRemoveOfficer(activeOfficer?.entity_uuid);
                        setRemoveOfficerDialog(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      color="success"
                      onClick={(e) => handleUpdateOfficer(e)}
                      loading={processing}
                    >
                      Save changes
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Dialog
            open={removeOfficerDialog}
            onClose={() => setRemoveOfficer(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to completely remove {activeOfficer?.officer_name}?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                disableElevation
                loading={processing}
                onClick={() => setRemoveOfficerDialog(false)}
                color="primary"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleDeleteOfficer(e);
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Officers;
