import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import moment from "moment";
import DataTable from "react-data-table-component";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import {
  Block,
  Cake,
  Cottage,
  Diversity2,
  Email,
  Fingerprint,
  Hail,
  Home,
  Language,
  LocalPhone,
  LocalPolice,
  MarkEmailRead,
  MonitorHeart,
  PhoneLocked,
  PriceCheck,
  ReportProblem,
  ZoomIn,
} from "@mui/icons-material";

import RelatedCases from "./components/overview/RelatedCases";
import RiskAssessment from "./components/overview/RiskAssessment";
import Decision from "./components/overview/Decision";

const Overview = ({
  partnerUser,
  partnerData,
  caseData,
  entityData,
  addressData,
  telephoneData,
  getCase,
  previousNames,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);
  const [relatedCases, setRelatedCases] = useState([]);
  const [flags, setFlags] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getRelatedCases = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/related`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRelatedCases(response.data.entries);
      } else {
        setRelatedCases([]);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getFlags = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/flags`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setFlags(response.data.entries);
      } else {
        setFlags([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getRelatedCases();
    getFlags();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ border: 1, borderColor: "divider" }}>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Profile name
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">{caseData.value.profile_name}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6">Case Progress</Typography>
                    <Box>
                      {caseData.value.status == 0 && (
                        <Box>
                          <Chip
                            label="Input Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#2274A5",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 1 && (
                        <Box>
                          <Chip
                            label="Review Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 2 && (
                        <Box>
                          <Chip
                            label="Approved"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#8BBF9F",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 3 && (
                        <Box>
                          <Chip
                            label="Declined"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#C83E4D",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 4 && (
                        <Box>
                          <Chip
                            label="Assessment Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                      {caseData.value.status == 6 && (
                        <Box>
                          <Chip
                            label="Decision Required"
                            className="square-chip"
                            sx={{
                              backgroundColor: "#D5E950",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      backgroundColor: "#0b0b0b",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      title="Date of birth"
                    >
                      <Cake
                        sx={{
                          fontSize: "2.5rem",
                          color: caseData.value.date_of_birth ? "#D5E950" : "#6b6b6b",
                        }}
                      />
                    </Box>

                    {caseData.value.options.email_address && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Email address"
                      >
                        {caseData.value.email_address ? (
                          caseData.value.options.email_verification ? (
                            <MarkEmailRead
                              sx={{
                                fontSize: "2.5rem",
                                color: caseData.value.email_verification ? "#D5E950" : "#2274A5",
                              }}
                            />
                          ) : (
                            <Email
                              sx={{
                                fontSize: "2.5rem",
                                color: caseData.value.email_address ? "#D5E950" : "#6b6b6b",
                              }}
                            />
                          )
                        ) : (
                          <Email
                            sx={{
                              fontSize: "2.5rem",
                              color: caseData.value.email_address ? "#D5E950" : "#6b6b6b",
                            }}
                          />
                        )}
                      </Box>
                    )}

                    {caseData.value.options.telephone_number && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Telephone number"
                      >
                        {caseData.value.telephone_number ? (
                          caseData.value.options.telephone_verification ? (
                            <PhoneLocked
                              sx={{
                                fontSize: "2.5rem",
                                color: caseData.value.telephone_verification
                                  ? "#D5E950"
                                  : "#2274A5",
                              }}
                            />
                          ) : (
                            <LocalPhone
                              sx={{
                                fontSize: "2.5rem",
                                color: caseData.value.telephone_number ? "#D5E950" : "#6b6b6b",
                              }}
                            />
                          )
                        ) : (
                          <LocalPhone
                            sx={{
                              fontSize: "2.5rem",
                              color: caseData.value.telephone_number ? "#D5E950" : "#6b6b6b",
                            }}
                          />
                        )}
                      </Box>
                    )}

                    {caseData.value.options.citizenship && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Citizenship"
                      >
                        <Language
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.citizenship ? "#D5E950" : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.address && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Address"
                      >
                        {caseData.value.address ? (
                          caseData.value.options.address_verification ? (
                            <Cottage
                              sx={{
                                fontSize: "2.5rem",
                                color: caseData.value.address_document_analysed
                                  ? caseData.value.address_document_review
                                    ? "#FCCA46"
                                    : "#D5E950"
                                  : "#2274A5",
                              }}
                            />
                          ) : (
                            <Home
                              sx={{
                                fontSize: "2.5rem",
                                color: caseData.value.address ? "#D5E950" : "#6b6b6b",
                              }}
                            />
                          )
                        ) : (
                          <Home
                            sx={{
                              fontSize: "2.5rem",
                              color: caseData.value.address ? "#D5E950" : "#6b6b6b",
                            }}
                          />
                        )}
                      </Box>
                    )}

                    {caseData.value.options.identity && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Identity verification"
                      >
                        <Fingerprint
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.identity
                              ? caseData.value.identity_analysed
                                ? caseData.value.identity_review
                                  ? "#FCCA46"
                                  : "#D5E950"
                                : "#2274A5"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.proof_of_life && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Proof of life"
                      >
                        <MonitorHeart
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.proof_of_life
                              ? caseData.value.proof_of_life_review
                                ? "#FCCA46"
                                : !caseData.value.proof_of_life_analysed
                                ? "#2274A5"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.fatca && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="FATCA"
                      >
                        <Diversity2
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.fatca ? "#D5E950" : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.pep_declaration && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="PEP declaration"
                      >
                        <Hail
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.pep_declaration ? "#D5E950" : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.source_of_wealth && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Source of wealth"
                      >
                        <PriceCheck
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.source_of_wealth
                              ? caseData.value.source_of_wealth_review
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.peps && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="PEP search"
                      >
                        <LocalPolice
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.peps > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.sanctions && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Sanctions check"
                      >
                        <Block
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.sanctions > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.warnings && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Warning list check"
                      >
                        <ReportProblem
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.warnings > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.options.adverse_media && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Adverse media search"
                      >
                        <LocalPolice
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.background_check_complete
                              ? caseData.value.background_results.review_counts.adverse_media > 0
                                ? "#FCCA46"
                                : "#D5E950"
                              : "#6b6b6b",
                          }}
                        />
                      </Box>
                    )}

                    {caseData.value.has_enhanced && (
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        title="Enhanced due diligence"
                      >
                        <ZoomIn
                          sx={{
                            fontSize: "2.5rem",
                            color: caseData.value.enhanced_input_required
                              ? "#2274A5"
                              : caseData.value.enhanced_require_review
                              ? "#FCCA46"
                              : "#D5E950",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                {flags.length > 0 && (
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6">Flags and Alerts</Typography>
                      <Box></Box>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2, display: "flex", flexDirection: "row", gap: 1 }}>
                      {flags.map((flag, index) => (
                        <Box
                          key={index}
                          sx={{}}
                          title={flag.country?.code != "" ? flag.country.name : ""}
                        >
                          <Chip
                            label={flag.flag_type_label}
                            className="square-chip"
                            sx={{
                              backgroundColor:
                                flag.flag_type < 3
                                  ? "#C83E4D"
                                  : flag.flag_type < 5
                                  ? "#FCCA46"
                                  : flag.flag_type == 5
                                  ? "#2274A5"
                                  : "#FCCA46",
                              color:
                                flag.flag_type < 3
                                  ? "#ffffff"
                                  : flag.flag_type < 5
                                  ? "#ffffff"
                                  : flag.flag_type == 5
                                  ? "#0b0b0b"
                                  : "#0b0b0b",
                              textTransform: "uppercase",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}

                {caseData.value.status == 2 && (
                  <Decision caseData={caseData} entityData={entityData} getCase={() => getCase()} />
                )}
                {caseData.value.status == 3 && (
                  <Decision caseData={caseData} entityData={entityData} getCase={() => getCase()} />
                )}
                {caseData.value.status == 6 && (
                  <Decision caseData={caseData} entityData={entityData} getCase={() => getCase()} />
                )}

                <Box sx={{ border: 1, borderColor: "divider", borderBottom: 0 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1 }}>
                    <Typography variant="h6">Personal Profile</Typography>
                  </Box>
                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Full name</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.title && entityData.value.title}{" "}
                        {entityData.value.first_name}
                        {entityData.value.middle_names && " " + entityData.value.middle_names}{" "}
                        {entityData.value.last_name && entityData.value.last_name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Email address</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.email_address ? entityData.value.email_address : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Telephone number</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="subtitle2">
                        {telephoneData?.telephone_number != ""
                          ? "+" +
                            telephoneData?.dialling_code +
                            " " +
                            telephoneData?.telephone_number +
                            " (" +
                            telephoneData?.country?.name +
                            ")"
                          : "-"}
                      </Typography>
                      {telephoneData?.country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {telephoneData?.country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {telephoneData?.country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {telephoneData?.country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Date of birth</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.date_of_birth
                          ? moment(entityData.value.date_of_birth).format("DD/MM/YYYY")
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Age</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {entityData.value.age ? entityData.value.age + " years old" : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Country of birth</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="subtitle2" align="right">
                        {entityData.value.birth_country?.code
                          ? entityData.value.birth_country.name
                          : "-"}
                      </Typography>

                      {entityData.value.birth_country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.birth_country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.birth_country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.birth_country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Residential address</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="subtitle2" align="right">
                        {addressData?.line_one ? addressData.line_one : "-"}
                      </Typography>

                      {addressData?.line_two && (
                        <Typography variant="subtitle2" align="right">
                          {addressData?.line_two}
                        </Typography>
                      )}

                      {addressData?.line_three && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_three}
                        </Typography>
                      )}

                      {addressData?.line_four && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_four}
                        </Typography>
                      )}

                      {addressData?.line_five && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_five}
                        </Typography>
                      )}

                      {addressData?.line_six && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.line_six}
                        </Typography>
                      )}

                      {addressData?.city && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.city}
                        </Typography>
                      )}

                      {addressData?.country?.name && (
                        <Typography variant="subtitle2" align="right">
                          {addressData.country.name}
                        </Typography>
                      )}

                      {addressData?.country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData?.country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData?.country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData?.country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">
                        Previous name{previousNames?.length > 1 ? "s" : ""}
                      </Typography>
                    </Box>
                    <Box>
                      {previousNames?.length > 0 ? (
                        previousNames.map((pnData, index) => (
                          <Typography key={index} variant="subtitle2">
                            {pnData.title} {pnData.first_name}{" "}
                            {pnData.middle_names && pnData.middle_names} {pnData.last_name}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="subtitle2">-</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                {caseData.value.status == 4 && (
                  <RiskAssessment
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => getCase()}
                    profileUUID={caseData.value.profile_uuid}
                  />
                )}

                <RelatedCases relatedCases={relatedCases} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Overview;
