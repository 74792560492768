import { React, useState, useEffect } from "react";
import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { Edit, Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CountrySelect from "../../../../components/form-elements/CountrySelect";

const CrsCorporate = ({
  partnerUser,
  entityData,
  crsData,
  profileUUID,
  setManageProfile,
  getProfileData,
  getEntityData,
  getCrsData,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [processing, setProcessing] = useState(false);

  const [crsFinancialInstitution, setCrsFinancialInstitution] = useState(
    crsData ? (crsData.financial_institution ? 1 : 2) : 0
  );
  const [crsFinancialInstitutionType, setCrsFinancialInstitutionType] = useState(
    crsData ? (crsData.passive_non_financial_institution ? 2 : 1) : 0
  );
  const [crsPassiveNonFinancialInstitution, setCrsPassiveNonFinancialInstitution] = useState(
    crsData ? (crsData.passive_non_financial_institution ? 2 : 1) : 0
  );
  const [crsReportingFinancialInstitutionType, setCrsReportingFinancialInstitutionType] = useState(
    crsData ? crsData.reporting_financial_institution_type : 0
  );
  const [crsNonReportingFinancialInstitutionType, setCrsNonReportingFinancialInstitutionType] =
    useState(crsData ? crsData.non_reporting_financial_institution : 0);
  const [crsOtherNonReportingEntity, setCrsOtherNonReportingEntity] = useState(
    crsData ? crsData.other_non_reporting_entity : ""
  );

  const [controllingName, setControllingName] = useState("");
  const [controllingResidentialAddress, setControllingResidentialAddress] = useState("");
  const [controllingCountryOfBirth, setControllingCountryOfBirth] = useState();
  const [controllingPlaceOfBirth, setControllingPlaceOfBirth] = useState("");
  const [controllingDateOfBirth, setControllingDateOfBirth] = useState("");
  const [controllingTin, setControllingTin] = useState("");
  const [controllerType, setControllerType] = useState();

  const controllerTypeOptions = [
    { value: 1, label: "Controlling Person of a Legal Person – Control by Ownership" },
    { value: 2, label: "Controlling Person of a Legal Person – Control by Other Means" },
    { value: 3, label: "Controlling Person of a Legal Person – Senior Managing Official" },
    { value: 4, label: "Controlling Person of a Trust – Settlor" },
    { value: 5, label: "Controlling Person of a Trust – Trustee" },
    { value: 6, label: "Controlling Person of a Trust – Protector" },
    { value: 7, label: "Controlling Person of a Trust – Beneficiary" },
    { value: 8, label: "Controlling Person of a Trust – Other" },
    {
      value: 9,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Settlor Equivalent",
    },
    {
      value: 10,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Trustee Equivalent",
    },
    {
      value: 11,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Protector Equivalent",
    },
    {
      value: 12,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Beneficiary Equivalent",
    },
    {
      value: 13,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Other Equivalent",
    },
  ];

  const handleSaveCrsDeclaration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/tax/crs`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          profile_uuid: profileUUID,
          financial_institution: crsFinancialInstitution == 1 ? true : false,
          financial_institution_type: parseInt(crsFinancialInstitutionType),
          passive_non_financial_institution: crsPassiveNonFinancialInstitution == 2 ? true : false,
          reporting_financial_institution_type: parseInt(crsReportingFinancialInstitutionType),
          non_reporting_financial_institution_type: parseInt(
            crsNonReportingFinancialInstitutionType
          ),
          other_non_reporting_entity: crsOtherNonReportingEntity,

          controlling_person_name: controllingName,
          controlling_person_residential_address: controllingResidentialAddress,
          controlling_person_date_of_birth: controllingDateOfBirth
            ? moment(controllingDateOfBirth).format("YYYY-MM-DD")
            : "",
          controlling_person_country_of_birth: controllingCountryOfBirth
            ? controllingCountryOfBirth.value
            : "",
          controlling_person_place_of_birth: controllingPlaceOfBirth,
          controlling_person_tin: controllingTin,
          controlling_person_type: parseInt(controllerType),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfileData();
      getEntityData();
      getCrsData();

      displayToast("CRS declaration saved successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box sx={{}}>
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 400, fontStyle: "italic" }}>
          The Common Reporting Standard (CRS) is a global standard for the automatic exchange of
          financial account information between tax authorities to help prevent tax evasion and
          ensure that everyone pays the correct amount of tax. The CRS is based on the model of the
          Foreign Account Tax Compliance Act (FATCA) developed by the Organisation for Economic
          Co-operation and Development (OECD).
        </Typography>
      </Box>

      <Box sx={{ p: 2, pt: 0 }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="fatca-type-options-label"
            defaultValue={0}
            value={crsFinancialInstitution}
            onChange={(e) => setCrsFinancialInstitution(e.target.value)}
            name="radio-buttons-group"
          >
            <FormControlLabel
              sx={{ mb: 1 }}
              value={1}
              control={<Radio />}
              label="The entity is a financial institution"
            />
            <FormControlLabel
              sx={{ mb: 1 }}
              value={2}
              control={<Radio />}
              label="The entity is not a financial institution"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Collapse in={crsFinancialInstitution == 1} exit={crsFinancialInstitution != 1}>
        <Box sx={{ p: 2, pt: 0 }}>
          <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
            Is the entity a reporting or non-reporting type of financial institution
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="fatca-type-options-label"
              defaultValue={0}
              value={crsFinancialInstitutionType}
              onChange={(e) => setCrsFinancialInstitutionType(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                sx={{ mb: 1 }}
                value={1}
                control={<Radio />}
                label="Reporting financial institution"
              />
              <FormControlLabel
                sx={{ mb: 1 }}
                value={2}
                control={<Radio />}
                label="Non-reporting financial institution"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Collapse in={crsFinancialInstitutionType == 1} exit={crsFinancialInstitutionType != 1}>
          <Box sx={{ p: 2, pt: 0, display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="subtitle2">
              Select the type of reporting financial institution
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="fatca-type-options-label"
                defaultValue={0}
                value={crsReportingFinancialInstitutionType}
                onChange={(e) => setCrsReportingFinancialInstitutionType(e.target.value)}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={1}
                  control={<Radio />}
                  label="Depository institution"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={2}
                  control={<Radio />}
                  label="Custodial institution"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={3}
                  control={<Radio />}
                  label="Investment entity"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={4}
                  control={<Radio />}
                  label="Managed investment entity"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={5}
                  control={<Radio />}
                  label="Specified insurance company"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Collapse
            in={crsReportingFinancialInstitutionType == 4}
            exit={crsReportingFinancialInstitutionType != 4}
          >
            <Box sx={{ p: 2, borderTop: 1, borderBottom: 1, borderColor: "divider" }}>
              <Typography variant="h6">Controlling person</Typography>
            </Box>

            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography>Name</Typography>
              <Typography>Residential address</Typography>
              <Typography>Mainling address (optional)</Typography>
              <Typography>Date of birth</Typography>
              <Typography>Country of birth</Typography>
              <Typography>Town or city of birth</Typography>

              <Typography>Tax residences with TIN</Typography>

              <Typography>Type of controlling person (options)</Typography>
            </Box>
          </Collapse>
        </Collapse>

        <Collapse in={crsFinancialInstitutionType == 2} exit={crsFinancialInstitutionType != 2}>
          <Box sx={{ p: 2, pt: 0, display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="subtitle2">
              Select the type of non-reporting financial institution
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="fatca-type-options-label"
                defaultValue={0}
                value={crsNonReportingFinancialInstitutionType}
                onChange={(e) => setCrsNonReportingFinancialInstitutionType(e.target.value)}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={1}
                  control={<Radio />}
                  label="Government entity"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={2}
                  control={<Radio />}
                  label="International organisation"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={3}
                  control={<Radio />}
                  label="Central bank"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={4}
                  control={<Radio />}
                  label="Broad participation retirement fund"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={5}
                  control={<Radio />}
                  label="Narrow participation retirement fund"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={10}
                  control={<Radio />}
                  label="Pension fund of a governmental entity, international organisation or central bank"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={6}
                  control={<Radio />}
                  label="Exempt collective investment vehicle"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={7}
                  control={<Radio />}
                  label="Trustee documented trust"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={8}
                  control={<Radio />}
                  label="Qualified credit card issuer"
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={9}
                  control={<Radio />}
                  label="Other entity defined under domestic law as low risk of being used to evade tax"
                />
              </RadioGroup>
            </FormControl>

            <Collapse
              in={crsNonReportingFinancialInstitutionType == 9}
              exit={crsNonReportingFinancialInstitutionType != 9}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="subtitle2">
                  Provide the type of financial institution
                </Typography>
                <TextField
                  fullWidth
                  label="Institution type"
                  variant="outlined"
                  value={crsOtherNonReportingEntity}
                  onChange={(e) => setCrsOtherNonReportingEntity(e.target.value)}
                />
              </Box>
            </Collapse>
          </Box>
        </Collapse>
      </Collapse>

      <Collapse in={crsFinancialInstitution == 2} exit={crsFinancialInstitution != 2}>
        <Box sx={{ p: 2, pt: 0 }}>
          <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
            Is the entity an active or passive non-financial institution
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="fatca-type-options-label"
              defaultValue={0}
              value={crsPassiveNonFinancialInstitution}
              onChange={(e) => setCrsPassiveNonFinancialInstitution(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                sx={{ mb: 1 }}
                value={1}
                control={<Radio />}
                label="Active non-financial institution"
              />
              <FormControlLabel
                sx={{ mb: 1 }}
                value={2}
                control={<Radio />}
                label="Passive non-financial institution"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Collapse
          in={crsPassiveNonFinancialInstitution == 2}
          exit={crsPassiveNonFinancialInstitution != 2}
        >
          <Box sx={{ p: 2, borderTop: 1, borderBottom: 1, borderColor: "divider" }}>
            <Typography variant="h6">Controlling person</Typography>
          </Box>

          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={controllingName}
              onChange={(e) => setControllingName(e.target.value)}
            />

            <TextField
              fullWidth
              label="Residential address"
              variant="outlined"
              value={controllingResidentialAddress}
              onChange={(e) => setControllingResidentialAddress(e.target.value)}
            />

            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
              <DatePicker
                fullWidth
                openTo="year"
                label={`Date of birth`}
                value={controllingDateOfBirth ? controllingDateOfBirth : null}
                onChange={(date) => {
                  setControllingDateOfBirth(date);
                }}
                slotProps={{ textField: { fullWidth: true } }}
                maxDate={moment()}
              />
            </LocalizationProvider>

            <CountrySelect
              value={controllingCountryOfBirth}
              setValue={setControllingCountryOfBirth}
            />

            <TextField
              fullWidth
              label="Town or city of birth"
              variant="outlined"
              value={controllingPlaceOfBirth}
              onChange={(e) => setControllingPlaceOfBirth(e.target.value)}
            />

            <TextField
              fullWidth
              label="Tax identification number (TIN)"
              variant="outlined"
              value={controllingTin}
              onChange={(e) => setControllingTin(e.target.value)}
            />

            <FormControl fullWidth>
              <InputLabel id="controlling-person-type-label">Type of controlling person</InputLabel>

              <Select
                labelId="controlling-person-type-label"
                id="controlling-person-type-select"
                value={controllerType}
                label="Type of controlling person"
                onChange={(e) => setControllerType(e.target.value)}
              >
                {controllerTypeOptions?.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Collapse>
      </Collapse>

      <Divider />
      <Box sx={{ py: 2, display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          disableElevation
          variant="contained"
          color="success"
          onClick={(e) => handleSaveCrsDeclaration(e)}
          loading={processing}
        >
          Save details
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CrsCorporate;
