import { React, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import central from "../../../../api/central";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import { LoadingButton } from "@mui/lab";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function CorporationType({
  partnerUser,
  entityData,
  setManageProfile,
  getEntityData,
  getProfileData,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [registrationTypeOptions, setRegistrationTypeOptions] = useState([]);
  const [registrationType, setRegistrationType] = useState(
    entityData.value.registration_type_uuid ? entityData.value.registration_type_uuid : ""
  );

  const getRegistrationTypeOptions = async () => {
    if (entityData.value.registration_country?.code == "") {
      return;
    }

    try {
      const response = await central.post(
        `/v1/service/corporations/types/options`,
        JSON.stringify({
          country: entityData.value.registration_country?.code
            ? entityData.value.registration_country?.code
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRegistrationTypeOptions(response.data.entries);
      } else {
        setRegistrationTypeOptions([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getRegistrationTypeOptions();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/registration/corporation-type`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          registration_type: registrationType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();

      displayToast("Registration name updated successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <>
      {isLoaded ? (
        <Box>
          <FormControl fullWidth>
            <InputLabel id="registration-type-label">Registration type</InputLabel>

            <Select
              labelId="registration-type-label"
              id="registration-type-select"
              value={registrationType}
              label="Registration type"
              onChange={(e) => setRegistrationType(e.target.value)}
            >
              {registrationTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <LoadingButton
              disabled={entityData.value.registration_type_uuid == registrationType ? true : false}
              disableElevation
              loading={processing}
              onClick={(e) => {
                handleUpdate(e);
              }}
              variant="contained"
              color="success"
            >
              Save changes
            </LoadingButton>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
}

export default CorporationType;
