import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Close, Delete, Edit } from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";

import CountrySelect from "../../components/form-elements/CountrySelect";
import { LoadingButton } from "@mui/lab";

const PersonalDetails = ({
  partnerUser,
  partnerData,
  caseData,
  entityData,
  telephoneData,
  getPerson,
  getTelephoneData,
  previousNames,
  getPreviousNames,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [processing, setProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [editName, setEditName] = useState(false);
  const [editDob, setEditDob] = useState(false);
  const [editGender, setEditGender] = useState(false);
  const [editCitizenship, setEditCitizenship] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editTelephone, setEditTelephone] = useState(false);

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];
  const [title, setTitle] = useState(entityData.value.title ? entityData.value.title : "");
  const [firstName, setFirstName] = useState(
    entityData.value.first_name ? entityData.value.first_name : ""
  );
  const [middleNames, setMiddleNames] = useState(
    entityData.value.middle_names ? entityData.value.middle_names : ""
  );
  const [lastName, setLastName] = useState(
    entityData.value.last_name ? entityData.value.last_name : ""
  );

  const [addPreviousName, setAddPreviousName] = useState(false);
  const [previousTitle, setPreviousTitle] = useState("");
  const [previousFirstName, setPreviousFirstName] = useState("");
  const [previousMiddleNames, setPreviousMiddleNames] = useState("");
  const [previousLastName, setPreviousLastName] = useState("");
  const [deletePreviousNameDialog, setDeletePreviousNameDialog] = useState(false);
  const [deletePreviousNameUUID, setDeletePreviousNameUUID] = useState("");

  const [emailAddress, setEmailAddress] = useState(
    entityData.value.email_address ? entityData.value.email_address : ""
  );

  const [countryOfBirth, setCountryOfBirth] = useState(
    entityData.value.birth_country?.code
      ? { value: entityData.value.birth_country.code, name: entityData.value.birth_country.name }
      : null
  );

  const [tin, setTin] = useState(entityData.value.tin ? entityData.value.tin : "");
  const [surrenderedCitizenship, setSurrenderedCitizenship] = useState(
    entityData.value.birth_country?.code
      ? entityData.value.birth_country?.code == "US" && entityData.value.tin == ""
        ? true
        : false
      : false
  );

  const [diallingCode, setDiallingCode] = useState(
    telephoneData?.dialling_code ? telephoneData.dialling_code : ""
  );
  const [phoneCountry, setPhoneCountry] = useState(
    telephoneData?.country ? telephoneData.country.code : ""
  );
  const [telephoneNumber, setTelephoneNumber] = useState(
    telephoneData?.telephone_number ? telephoneData.telephone_number : ""
  );
  const [phoneValue, setPhoneValue] = useState(
    telephoneData?.telephone_number
      ? "+" + telephoneData?.dialling_code + telephoneData?.telephone_number
      : ""
  );

  const [dateOfBirth, setDateOfBirth] = useState(
    entityData.value.date_of_birth ? moment(entityData.value.date_of_birth) : null
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const handleUpdateName = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/name/update`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          title: title,
          first_name: firstName,
          middle_names: middleNames,
          last_name: lastName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();
      getPreviousNames();

      displayToast("Name updated successfully", () => {
        setProcessing(false);
        setEditName(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddPreviousName = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/name/previous-names/add`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          title: previousTitle,
          first_name: previousFirstName,
          middle_names: previousMiddleNames,
          last_name: previousLastName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();
      getPreviousNames();

      displayToast("Previous name or alias added successfully", () => {
        setProcessing(false);
        setPreviousTitle("");
        setPreviousFirstName("");
        setPreviousMiddleNames("");
        setPreviousLastName("");
        setEditName(false);
        setAddPreviousName(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeletePreviousName = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/name/previous-names/remove`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          name_uuid: deletePreviousNameUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();
      getPreviousNames();
      setDeletePreviousNameDialog(false);

      displayToast("Previous name or alias deleted successfully", () => {
        setProcessing(false);
        setDeletePreviousNameUUID("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateEmailAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/email-address/update`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          email_address: emailAddress,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();

      displayToast("Email address updated successfully", () => {
        setProcessing(false);
        setEditEmail(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleSendEmailVerification = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/email-address/verify`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          case_uuid: caseData.value.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();

      displayToast("Email address verification request sent successfully", () => {
        setProcessing(false);
        setEditEmail(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateCountryOfBirth = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/birth/country`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          country: countryOfBirth.value,
          tin: tin,
          surrendered_citizenship: surrenderedCitizenship,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();

      displayToast("Country of birth updated successfully", () => {
        setProcessing(false);
        setEditCitizenship(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateDateOfBirth = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/birth/date`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          dob: dateOfBirth ? dateOfBirth.format("YYYY-MM-DD") : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();

      displayToast("Date of birth updated successfully", () => {
        setProcessing(false);
        setEditDob(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateTelephoneNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/telephone-number/update`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          telephone_number: telephoneNumber,
          dialling_code: diallingCode,
          telephone_country: phoneCountry,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTelephoneData();
      getCase();

      displayToast(`Telephone number updated successfully`, () => {
        setProcessing(false);
        setEditTelephone(false);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleSendTelephoneVerification = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/telephone-number/verify/send`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          case_uuid: caseData.value.case_uuid,
          request_type: 1,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getCase();

      displayToast("Telephone verification request sent successfully", () => {
        setProcessing(false);
        setEditTelephone(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12}>
            <Box sx={{}}>
              {!editMode ? (
                <Box
                  sx={{
                    borderRight: { xs: 0, xl: 0 },
                    borderColor: { xs: "divider", xl: "divider" },
                    borderBottom: 0,
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Personal Details</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <IconButton
                        title="Edit"
                        onClick={() => {
                          setEditName(true);
                          setEditMode(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Full name</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2">
                        {entityData.value.title && entityData.value.title}{" "}
                        {entityData.value.first_name}
                        {entityData.value.middle_names && " " + entityData.value.middle_names}{" "}
                        {entityData.value.last_name && entityData.value.last_name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <IconButton
                          title="Edit"
                          onClick={() => {
                            setEditEmail(true);
                            setEditMode(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Email address</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="subtitle2" sx={{ textAlign: "end" }}>
                          {entityData.value.email_address ? entityData.value.email_address : "-"}
                        </Typography>
                        {caseData.value.email_address &&
                          caseData.value.options.email_verification && (
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              {caseData.value.email_verification ? (
                                <Chip
                                  className="square-chip"
                                  label="Verified"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#0b0b0b",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : caseData.value.email_verification_pending ? (
                                <Chip
                                  className="square-chip"
                                  label="Pending Verification"
                                  sx={{
                                    backgroundColor: "#2274A5",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : (
                                <Chip
                                  className="square-chip"
                                  label="Verification Required"
                                  sx={{
                                    backgroundColor: "#2274A5",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Box>
                          <IconButton
                            title="Edit"
                            onClick={() => {
                              setEditTelephone(true);
                              setEditMode(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Telephone number</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <Typography variant="subtitle2">
                        {telephoneData?.telephone_number != ""
                          ? "+" +
                            telephoneData?.dialling_code +
                            " " +
                            telephoneData?.telephone_number +
                            " (" +
                            telephoneData?.country?.name +
                            ")"
                          : "-"}
                      </Typography>

                      {caseData.value.telephone_number &&
                        caseData.value.options.telephone_verification && (
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {caseData.value.telephone_verification ? (
                              <Chip
                                className="square-chip"
                                label="Verified"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#0b0b0b",
                                  textTransform: "uppercase",
                                }}
                              />
                            ) : caseData.value.telephone_verification_pending ? (
                              <Chip
                                className="square-chip"
                                label="Pending Verification"
                                sx={{
                                  backgroundColor: "#2274A5",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            ) : (
                              <Chip
                                className="square-chip"
                                label="Verification Required"
                                sx={{
                                  backgroundColor: "#2274A5",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        )}

                      {telephoneData?.country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {telephoneData?.country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {telephoneData?.country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {telephoneData?.country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <IconButton
                        title="Edit"
                        onClick={() => {
                          setEditDob(true);
                          setEditMode(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Date of birth</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2">
                        {entityData.value.date_of_birth
                          ? moment(entityData.value.date_of_birth).format("DD/MM/YYYY")
                          : "-"}
                      </Typography>
                      {entityData.value.age > 0 && (
                        <Typography variant="subtitle2">
                          {entityData.value.age} years old
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <IconButton
                          title="Edit"
                          onClick={() => {
                            setEditCitizenship(true);
                            setEditMode(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Country of birth</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <Typography variant="subtitle2" align="right">
                        {entityData.value.birth_country?.code
                          ? entityData.value.birth_country.name
                          : "-"}
                      </Typography>

                      {entityData.value.birth_country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.birth_country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.birth_country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {entityData.value.birth_country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 0,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <IconButton
                        title="Edit"
                        onClick={() => {
                          setEditName(true);
                          setAddPreviousName(true);
                          setEditMode(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">
                          Previous name{previousNames?.length > 1 ? "s" : ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      {previousNames?.length > 0 ? (
                        previousNames.map((pnData, index) => (
                          <Typography key={index} variant="subtitle2">
                            {pnData.title} {pnData.first_name}{" "}
                            {pnData.middle_names && pnData.middle_names} {pnData.last_name}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="subtitle2">-</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  {editName && (
                    <Box>
                      <Collapse in={!addPreviousName} exit={addPreviousName}>
                        <Box
                          sx={{
                            p: 2,
                            border: 1,
                            borderColor: "divider",
                            backgroundColor: "#0b0b0b",
                            color: "#ffffff",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="h6">What is their full name?</Typography>
                          </Box>
                          <IconButton
                            sx={{ color: "#ffffff" }}
                            title="Close"
                            onClick={() => {
                              setEditName(false);
                              setEditMode(false);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Box>

                        <Divider />

                        <Box
                          sx={{
                            borderRight: { xs: 0, xl: 1 },
                            borderColor: { xs: "divider", xl: "divider" },
                          }}
                        >
                          <Grid sx={{ p: 2 }} container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={1}>
                              <FormControl fullWidth>
                                <InputLabel id="name-title-label">Title</InputLabel>
                                <Select
                                  labelId="name-title"
                                  id="name-title"
                                  value={title}
                                  label="Title"
                                  onChange={(e) => setTitle(e.target.value)}
                                >
                                  {titleOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="First name"
                                variant="outlined"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Middle name(s)"
                                variant="outlined"
                                value={middleNames}
                                onChange={(e) => setMiddleNames(e.target.value)}
                                helperText="If applicable, separate with spaces"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Last name"
                                variant="outlined"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </Grid>
                          </Grid>

                          <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, pb: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Button
                                variant="outlined"
                                onClick={() => setAddPreviousName(!addPreviousName)}
                              >
                                {addPreviousName ? "Close" : "Add previous name"}
                              </Button>
                              {!addPreviousName && (
                                <LoadingButton
                                  disableElevation
                                  variant="contained"
                                  loading={processing}
                                  color="success"
                                  onClick={(e) => {
                                    handleUpdateName(e);
                                  }}
                                >
                                  Save changes
                                </LoadingButton>
                              )}
                            </Box>
                          </Box>

                          {previousNames?.length > 0 && (
                            <Box sx={{ border: 0, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  borderBottom: 1,
                                  borderColor: "divider",
                                  px: 2,
                                  py: 1,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  backgroundColor: "#0b0b0b",
                                  color: "#ffffff",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="h6">
                                    Previous name{previousNames?.length > 1 ? "s" : ""}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                {previousNames?.length > 0 &&
                                  previousNames.map((pnData, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        px: 2,
                                        py: 1,
                                        borderBottom: previousNames.length == index + 1 ? 0 : 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography variant="subtitle2">
                                          {pnData.title} {pnData.first_name}{" "}
                                          {pnData.middle_names && pnData.middle_names}{" "}
                                          {pnData.last_name}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <IconButton
                                          sx={{ color: "#C83E4D" }}
                                          title="Remove"
                                          onClick={() => {
                                            setDeletePreviousNameUUID(pnData.uuid);
                                            setDeletePreviousNameDialog(true);
                                          }}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Collapse>

                      <Collapse in={addPreviousName} exit={!addPreviousName}>
                        <Box
                          sx={{
                            p: 2,
                            border: 1,
                            borderColor: "divider",
                            backgroundColor: "#0b0b0b",
                            color: "#ffffff",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="h6">Add a previous name or alias</Typography>
                          </Box>
                          <IconButton
                            sx={{ color: "#ffffff" }}
                            title="Close"
                            onClick={() => {
                              setAddPreviousName(false);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Box>
                        <Divider />

                        <Box
                          sx={{
                            borderRight: { xs: 0, xl: 1 },
                            borderColor: { xs: "divider", xl: "divider" },
                          }}
                        >
                          <Box sx={{ p: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={6} lg={3} xl={1}>
                                <FormControl fullWidth>
                                  <InputLabel id="name-title-label">Title</InputLabel>
                                  <Select
                                    labelId="name-title"
                                    id="name-title"
                                    value={previousTitle}
                                    label="Title"
                                    onChange={(e) => setPreviousTitle(e.target.value)}
                                  >
                                    {titleOptions.map((option, index) => (
                                      <MenuItem key={index} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={3}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="First name"
                                  variant="outlined"
                                  value={previousFirstName}
                                  onChange={(e) => setPreviousFirstName(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Middle name(s)"
                                  variant="outlined"
                                  value={previousMiddleNames}
                                  onChange={(e) => setPreviousMiddleNames(e.target.value)}
                                  helperText="If applicable, separate with spaces"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Last name"
                                  variant="outlined"
                                  value={previousLastName}
                                  onChange={(e) => setPreviousLastName(e.target.value)}
                                />
                              </Grid>
                            </Grid>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <LoadingButton
                                disableElevation
                                variant="contained"
                                loading={processing}
                                color="success"
                                onClick={(e) => {
                                  handleAddPreviousName(e);
                                }}
                              >
                                Save name
                              </LoadingButton>
                            </Box>
                          </Box>

                          {previousNames?.length > 0 && (
                            <Box sx={{ border: 0, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  borderBottom: 1,
                                  borderColor: "divider",
                                  px: 2,
                                  py: 1,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  backgroundColor: "#0b0b0b",
                                  color: "#ffffff",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="h6">
                                    Previous name{previousNames?.length > 1 ? "s" : ""}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                {previousNames?.length > 0 &&
                                  previousNames.map((pnData, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        px: 2,
                                        py: 1,
                                        borderBottom: previousNames.length == index + 1 ? 0 : 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography variant="subtitle2">
                                          {pnData.title} {pnData.first_name}{" "}
                                          {pnData.middle_names && pnData.middle_names}{" "}
                                          {pnData.last_name}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <IconButton
                                          sx={{ color: "#C83E4D" }}
                                          title="Remove"
                                          onClick={() => {
                                            setDeletePreviousNameUUID(pnData.uuid);
                                            setDeletePreviousNameDialog(true);
                                          }}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Collapse>

                      <Dialog
                        open={deletePreviousNameDialog}
                        onClose={() => setDeletePreviousNameDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                        <DialogContent>
                          <Box>
                            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                              Are you sure you want to remove this previous name?
                            </DialogContentText>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={() => setDeletePreviousNameDialog(false)}
                            color="primary"
                          >
                            Cancel
                          </LoadingButton>

                          <LoadingButton
                            disableElevation
                            sx={{ color: "#ffffff" }}
                            loading={processing}
                            onClick={(e) => {
                              handleDeletePreviousName(e);
                            }}
                            variant="contained"
                            color="danger"
                            autoFocus
                          >
                            Confirm
                          </LoadingButton>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  )}
                  {editDob && (
                    <Box>
                      <Box
                        sx={{
                          p: 2,
                          border: 1,
                          borderColor: "divider",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">What is their date of birth?</Typography>
                        </Box>
                        <IconButton
                          sx={{ color: "#ffffff" }}
                          title="Close"
                          onClick={() => {
                            setEditDob(false);
                            setEditMode(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>

                      <Divider />

                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          borderRight: { xs: 0, xl: 1 },
                          borderColor: { xs: "divider", xl: "divider" },
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                          <DatePicker
                            fullWidth
                            openTo="year"
                            label={`Date of birth`}
                            value={dateOfBirth ? dateOfBirth : null}
                            onChange={(date) => {
                              setDateOfBirth(date);
                            }}
                            slotProps={{ textField: { fullWidth: true } }}
                            maxDate={moment()}
                          />
                        </LocalizationProvider>

                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            loading={processing}
                            color="success"
                            onClick={(e) => {
                              handleUpdateDateOfBirth(e);
                            }}
                          >
                            Save changes
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {editGender && <Box>edit gender</Box>}
                  {editCitizenship && (
                    <Box>
                      <Box
                        sx={{
                          p: 2,
                          border: 1,
                          borderColor: "divider",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">What is their country of birth?</Typography>
                        </Box>
                        <IconButton
                          sx={{ color: "#ffffff" }}
                          title="Close"
                          onClick={() => {
                            setEditCitizenship(false);
                            setEditMode(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>

                      <Divider />

                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          borderRight: { xs: 0, xl: 0 },
                          borderColor: { xs: "divider", xl: "divider" },
                        }}
                      >
                        <CountrySelect value={countryOfBirth} setValue={setCountryOfBirth} />

                        <Collapse
                          in={countryOfBirth && countryOfBirth?.value == "US"}
                          exit={!countryOfBirth}
                        >
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <TextField
                              fullWidth
                              id="us-tin"
                              label="Tax identification number"
                              variant="outlined"
                              value={tin}
                              onChange={(e) => setTin(e.target.value)}
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={surrenderedCitizenship}
                                  onChange={(e) => {
                                    setSurrenderedCitizenship(e.target.checked);
                                  }}
                                  name="surrenderedCitizenship"
                                  color="primary"
                                />
                              }
                              label="Surrendered citizenship"
                            />
                          </Box>
                        </Collapse>

                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            loading={processing}
                            color="success"
                            onClick={(e) => {
                              handleUpdateCountryOfBirth(e);
                            }}
                          >
                            Save changes
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {editEmail && (
                    <Box>
                      <Box
                        sx={{
                          p: 2,
                          border: 1,
                          borderColor: "divider",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">What is their email address?</Typography>
                        </Box>
                        <IconButton
                          sx={{ color: "#ffffff" }}
                          title="Close"
                          onClick={() => {
                            setEditEmail(false);
                            setEditMode(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>

                      <Divider />

                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          borderRight: { xs: 0, xl: 0 },
                          borderColor: { xs: "divider", xl: "divider" },
                        }}
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Email address"
                          variant="outlined"
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                        />

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Box>
                            {caseData.value.options.email_verification &&
                            caseData.value.email_verification ? (
                              <Chip
                                className="square-chip"
                                label="Verified"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#0b0b0b",
                                  textTransform: "uppercase",
                                }}
                              />
                            ) : caseData.value.email_verification_pending ? (
                              <LoadingButton
                                disableElevation
                                variant="outlined"
                                loading={processing}
                                color="primary"
                                onClick={(e) => {
                                  handleSendEmailVerification(e);
                                }}
                              >
                                Resend verification email
                              </LoadingButton>
                            ) : (
                              caseData.value.options.email_verification &&
                              caseData.value.email_address && (
                                <LoadingButton
                                  disableElevation
                                  variant="outlined"
                                  loading={processing}
                                  color="primary"
                                  onClick={(e) => {
                                    handleSendEmailVerification(e);
                                  }}
                                >
                                  Send verification email
                                </LoadingButton>
                              )
                            )}
                          </Box>
                          <Box>
                            <LoadingButton
                              disableElevation
                              variant="contained"
                              loading={processing}
                              color="success"
                              onClick={(e) => {
                                handleUpdateEmailAddress(e);
                              }}
                            >
                              Save changes
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {editTelephone && (
                    <Box>
                      <Box
                        sx={{
                          p: 2,
                          border: 1,
                          borderColor: "divider",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">What is their telephone number?</Typography>
                        </Box>
                        <IconButton
                          sx={{ color: "#ffffff" }}
                          title="Close"
                          onClick={() => {
                            setEditTelephone(false);
                            setEditMode(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>

                      <Divider />

                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          borderRight: { xs: 0, xl: 0 },
                          borderColor: { xs: "divider", xl: "divider" },
                        }}
                      >
                        <Box>
                          <MuiTelInput
                            fullWidth
                            defaultCountry="IM"
                            value={phoneValue}
                            onChange={(phone, meta) => {
                              setTelephoneNumber(meta.nationalNumber);
                              setPhoneCountry(meta.countryCode);
                              setDiallingCode(meta.countryCallingCode);
                              setPhoneValue(phone);
                            }}
                          />
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Box>
                            {caseData.value.options.telephone_verification &&
                              caseData.value.telephone_verification && (
                                <Chip
                                  className="square-chip"
                                  label="Verified"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#0b0b0b",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}

                            {caseData.value.telephone_verification_pending && (
                              <LoadingButton
                                disableElevation
                                variant="outlined"
                                loading={processing}
                                color="primary"
                                onClick={(e) => {
                                  handleSendTelephoneVerification(e);
                                }}
                              >
                                Resend verification message
                              </LoadingButton>
                            )}

                            {caseData.value.options.telephone_verification &&
                              !caseData.value.telephone_verification_pending &&
                              !caseData.value.telephone_verification && (
                                <Box>
                                  {caseData.value.telephone_number ? (
                                    entityData.value.email_address ? (
                                      <LoadingButton
                                        disableElevation
                                        variant="outlined"
                                        loading={processing}
                                        color="primary"
                                        onClick={(e) => {
                                          handleSendTelephoneVerification(e);
                                        }}
                                      >
                                        Send verification message
                                      </LoadingButton>
                                    ) : (
                                      <Box>
                                        <Alert severity="info">
                                          Email address required for verification
                                        </Alert>
                                      </Box>
                                    )
                                  ) : (
                                    <Box>
                                      <Alert severity="info">
                                        Telephone number required for verification
                                      </Alert>
                                    </Box>
                                  )}
                                </Box>
                              )}
                          </Box>
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            loading={processing}
                            color="success"
                            onClick={(e) => {
                              handleUpdateTelephoneNumber(e);
                            }}
                          >
                            Save changes
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default PersonalDetails;
