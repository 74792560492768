import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import parse from "html-react-parser";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  Drawer,
} from "@mui/material";

import { ArrowForwardIos, Close, Delete, Description, Edit, Facebook } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Decision from "./components/overview/Decision";
import NoResults from "../../components/functions/NoResults";

const Background = ({ partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [sanctionHits, setSanctionHits] = useState(
    caseData.value.background_results.true_hits.sanctions
  );
  const [sanctionDiscounted, setSanctionDiscounted] = useState(
    caseData.value.background_results.discounted.sanctions
  );
  const [sanctionReviews, setSanctionReviews] = useState(
    caseData.value.background_results.review_counts.sanctions
  );

  const [pepHits, setPepHits] = useState(caseData.value.background_results.true_hits.peps);
  const [pepDiscounted, setPepDiscounted] = useState(
    caseData.value.background_results.discounted.peps
  );
  const [pepReviews, setPepReviews] = useState(
    caseData.value.background_results.review_counts.peps
  );

  const [warningHits, setWarningHits] = useState(
    caseData.value.background_results.true_hits.warnings
  );
  const [warningDiscounted, setWarningDiscounted] = useState(
    caseData.value.background_results.discounted.warnings
  );
  const [warningReviews, setWarningReviews] = useState(
    caseData.value.background_results.review_counts.warnings
  );

  const [adverseMediaHits, setAdverseMediaHits] = useState(
    caseData.value.background_results.true_hits.adverse_media
  );
  const [adverseMediaDiscounted, setAdverseMediaDiscounted] = useState(
    caseData.value.background_results.discounted.adverse_media
  );
  const [adverseMediaReviews, setAdverseMediaReviews] = useState(
    caseData.value.background_results.review_counts.adverse_media
  );

  const [outstandingReviews, setOutstandingReviews] = useState([]);
  const [processReviews, setProcessReviews] = useState(false);
  const [alertType, setAlertType] = useState(0);

  const [alertData, setAlertData] = useState(null);
  const [manageAlert, setManageAlert] = useState(false);

  const [reviewDecision, setReviewDecision] = useState(0);
  const [reviewNote, setReviewNote] = useState("");

  const falsePositiveOptions = [
    { value: 1, label: "Different person" },
    { value: 2, label: "Incorrect fuzzy match" },
    { value: 3, label: "Incorrect context" },
    { value: 4, label: "Irrelevant information" },
    { value: 5, label: "Non-relevant jurisdiction" },
    { value: 6, label: "Time lapsed" },
    { value: 7, label: "Expired" },
    { value: 8, label: "Specific activities" },
  ];

  const getFalsePositiveTemplate = (value) => {
    switch (value) {
      case 1:
        return "<p>The information relates to a different entity</p>";
      case 2:
        return "<p>The matched name is an incorrect fuzzy match</p>";
      case 3:
        return "<p>The information should not be considered adverse in the context of the screened entity</p>";
      case 4:
        return "<p>The information is irrelevant</p>";
      case 5:
        return "<p>The origin of the alert is not relevent because [ADD CONTEXT]</p>";
      case 6:
        return "<p>The time that has elapsed since the alert was created deems the alert irrelevent because [ADD CONTEXT]</p>";
      case 7:
        return "<p>The alert is expired and no longer relevant because [ADD CONTEXT]</p>";
      case 8:
        return "<p>The activities that the alert is based on are not relevant to the screened entity because [ADD CONTEXT]</p>";

      default:
        return "";
    }
  };

  const inconclusiveOptions = [
    { value: 1, label: "Dead link" },
    { value: 2, label: "Paywalled" },
    { value: 3, label: "Unclear context" },
    { value: 4, label: "Insufficient information" },
    { value: 5, label: "Ambiguous identification" },
    { value: 6, label: "Data quality issues" },
    { value: 7, label: "Jurisdictional barriers" },
    { value: 8, label: "Language barriers" },
  ];

  const getInconclusiveTemplate = (value) => {
    switch (value) {
      case 1:
        return "<p>The link is dead and the information cannot be verified</p>";
      case 2:
        return "<p>The information is behind a paywall and cannot be accessed</p>";
      case 3:
        return "<p>The context of the information is unclear and cannot be verified</p>";
      case 4:
        return "<p>The information is insufficient to make a decision</p>";
      case 5:
        return "<p>The identification of the entity is ambiguous and cannot be verified</p>";
      case 6:
        return "<p>The quality of the data is poor and cannot be verified</p>";
      case 7:
        return "<p>There are jurisdictional barriers that prevent verification because [ADD CONTEXT]</p>";
      case 8:
        return "<p>There are language barriers that prevent verification</p>";
      default:
        return "";
    }
  };

  const getAlerts = async (alertType) => {
    try {
      const response = await partner.post(
        `/v1/screening/background/kyb/data/list/${alertType}`,
        JSON.stringify({
          entity_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      switch (alertType) {
        case "sanctions":
          setAlertType(2);
          break;
        case "peps":
          setAlertType(1);
          break;
        case "warnings":
          setAlertType(3);
          break;
        case "adverse-media":
          setAlertType(4);
          break;
        default:
          setAlertType(0);
          break;
      }

      if (response.data.results > 0) {
        setOutstandingReviews(response.data.entries);
      } else {
        setOutstandingReviews([]);
      }

      setProcessReviews(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getAlertData = async (alertUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/background/kyb/data`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          uuid: alertUUID,
          alert_type: alertType,
          entity_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setAlertData(response.data);
      setManageAlert(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAlertReview = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/background/kyb/review`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          uuid: alertData.uuid,
          alert_type: alertType,
          decision: reviewDecision,
          reason: reviewNote,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getAlerts(
        alertType == 1
          ? "peps"
          : alertType == 2
          ? "sanctions"
          : alertType == 3
          ? "warnings"
          : "adverse-media"
      );

      displayToast("Alert reviewed successfully", () => {
        setProcessing(false);
        setManageAlert(false);
        setAlertData(null);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={6}>
        {processReviews ? (
          <Box>
            <Box sx={{ borderRight: { xs: 0, xl: 1 }, borderColor: { xl: "divider" } }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">
                    Background screening -{" "}
                    {alertType == 1
                      ? "PEPs"
                      : alertType == 2
                      ? "Sanctions"
                      : alertType == 3
                      ? "Warnings"
                      : "Adverse Media"}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    title="Close"
                    onClick={() => {
                      setProcessReviews(false);
                      setOutstandingReviews([]);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>

              {outstandingReviews.length > 0 ? (
                outstandingReviews.map((alertData, index) => (
                  <Box key={index} sx={{ borderTop: index > 0 ? 1 : 0, borderColor: "divider" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box width={"75%"} sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 2,
                            borderRight: 1,
                            borderColor: "divider",
                            minWidth: "60%",
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                              Matched name
                            </Typography>
                            <Typography variant="subtitle2">{alertData.matched_name}</Typography>
                          </Box>
                          {alertData.matched_name != alertData.target_name && (
                            <Box>
                              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                                Target name
                              </Typography>
                              <Typography variant="subtitle2">{alertData.target_name}</Typography>
                            </Box>
                          )}
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 2,
                            borderRight: 1,
                            borderColor: "divider",
                            minWidth: "40%",
                          }}
                        >
                          <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                            Details
                          </Typography>
                          <Typography variant="subtitle2">
                            {alertData.details ? alertData.details : "-"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        {alertData.review_required && (
                          <Chip
                            className="square-chip"
                            label="Review"
                            sx={{
                              color: "#0b0b0b",
                              backgroundColor: "#FCCA46",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                        {alertData.true_hit && (
                          <Chip
                            className="square-chip"
                            label="True Hit"
                            sx={{
                              color: "#ffffff",
                              backgroundColor: "#C83E4D",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                        {alertData.inconclusive && (
                          <Chip
                            className="square-chip"
                            label="Inconclusive"
                            sx={{
                              color: "#ffffff",
                              backgroundColor: "#494f4d",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                        {alertData.false_positive && (
                          <Chip
                            className="square-chip"
                            label="False positive"
                            sx={{
                              color: "#ffffff",
                              backgroundColor: "#494f4d",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          pr: 1,
                        }}
                      >
                        <IconButton
                          title="View"
                          onClick={() => {
                            getAlertData(alertData.uuid);
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box>
                  <NoResults message={`There are no hits to display`} />
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{}}>
            <Box sx={{ borderRight: { xs: 0, xl: 1 }, borderColor: { xl: "divider" } }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Background screening</Typography>
                </Box>
                {caseData.value.background_results.has_reviews && (
                  <Box>
                    <Button
                      variant="contained"
                      disableElevation={true}
                      size="small"
                      color="success"
                      onClick={() => console.log("engage burst capacity")}
                    >
                      Engage burst capacity
                    </Button>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ px: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Sanctions
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {sanctionReviews > 0 && (
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          className="square-chip"
                          label={`Review${
                            sanctionReviews > 1 ? "s" : ""
                          } required (${sanctionReviews})`}
                          sx={{
                            color: "#0b0b0b",
                            backgroundColor: "#FCCA46",
                            textTransform: "uppercase",
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      title="Discounted"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 400,
                        fontSize: 13,
                        borderLeft: 1,
                        borderRight: 1,
                        borderColor: "divider",
                        color: "primary.light",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      {sanctionDiscounted}
                    </Box>
                    <Box
                      title="True hits"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 600,
                        color: sanctionHits > 0 ? "danger.main" : "text.primary",
                        borderLeft: 1,
                        borderColor: "#a6a6a6",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#ffc4ca",
                      }}
                    >
                      {sanctionHits}
                    </Box>

                    <Box
                      sx={{
                        px: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        title="View"
                        onClick={() => {
                          getAlerts("sanctions");
                        }}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ px: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    State owned organisation (PEPs)
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {pepReviews > 0 && (
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          className="square-chip"
                          label={`Review${pepReviews > 1 ? "s" : ""} required (${pepReviews})`}
                          sx={{
                            color: "#0b0b0b",
                            backgroundColor: "#FCCA46",
                            textTransform: "uppercase",
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      title="Discounted"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 400,
                        fontSize: 13,
                        borderLeft: 1,
                        borderRight: 1,
                        borderColor: "divider",
                        color: "primary.light",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      {pepDiscounted}
                    </Box>
                    <Box
                      title="True hits"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 600,
                        color: pepHits > 0 ? "danger.main" : "text.primary",
                        borderLeft: 1,
                        borderColor: "#a6a6a6",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#ffc4ca",
                      }}
                    >
                      {pepHits}
                    </Box>

                    <Box
                      sx={{
                        px: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        title="View"
                        onClick={() => {
                          getAlerts("peps");
                        }}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ px: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Warnings
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {warningReviews > 0 && (
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          className="square-chip"
                          label={`Review${
                            warningReviews > 1 ? "s" : ""
                          } required (${warningReviews})`}
                          sx={{
                            color: "#0b0b0b",
                            backgroundColor: "#FCCA46",
                            textTransform: "uppercase",
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      title="Discounted"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 400,
                        fontSize: 13,
                        borderLeft: 1,
                        borderRight: 1,
                        borderColor: "divider",
                        color: "primary.light",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      {warningDiscounted}
                    </Box>
                    <Box
                      title="True hits"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 600,
                        color: warningHits > 0 ? "danger.main" : "text.primary",
                        borderLeft: 1,
                        borderColor: "#a6a6a6",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#ffc4ca",
                      }}
                    >
                      {warningHits}
                    </Box>

                    <Box
                      sx={{
                        px: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        title="View"
                        onClick={() => {
                          getAlerts("warnings");
                        }}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  borderBottom: 0,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ px: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Adverse media
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {adverseMediaReviews > 0 && (
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          className="square-chip"
                          label={`Review${
                            adverseMediaReviews > 1 ? "s" : ""
                          } required (${adverseMediaReviews})`}
                          sx={{
                            color: "#0b0b0b",
                            backgroundColor: "#FCCA46",
                            textTransform: "uppercase",
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      title="Discounted"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 400,
                        fontSize: 13,
                        borderLeft: 1,
                        borderRight: 1,
                        borderColor: "divider",
                        color: "primary.light",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      {adverseMediaDiscounted}
                    </Box>
                    <Box
                      title="True hits"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 600,
                        color: adverseMediaHits > 0 ? "danger.main" : "text.primary",
                        borderLeft: 1,
                        borderColor: "#a6a6a6",
                        minWidth: "70px",
                        textAlign: "center",
                        backgroundColor: "#ffc4ca",
                      }}
                    >
                      {adverseMediaHits}
                    </Box>

                    <Box
                      sx={{
                        px: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        title="View"
                        onClick={() => {
                          getAlerts("adverse-media");
                        }}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Drawer anchor="right" open={manageAlert} onClose={() => setManageAlert(false)}>
          <Box sx={{ width: { xs: "90vw", lg: "75vw", xl: "50vw" } }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                px: 2,
                py: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h6">Background Check</Typography>
              </Box>

              <Box sx={{}}>
                <IconButton title="Close" onClick={() => setManageAlert(false)}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ border: 1, borderColor: "divider", borderBottom: 0 }}>
                <Grid container spacing={0} sx={{}}>
                  <Grid item xs={12} lg={6}>
                    <Box sx={{ borderRight: 1, borderColor: "divider" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          textTransform: "uppercase",
                          px: 2,
                          py: 1,
                        }}
                      >
                        <Typography variant="subtitle2">Our profile</Typography>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2" sx={{ fontSize: "13px" }}>
                              Full name
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {entityData.value.title && entityData.value.title}{" "}
                              {entityData.value.first_name}
                              {entityData.value.middle_names &&
                                " " + entityData.value.middle_names}{" "}
                              {entityData.value.last_name && entityData.value.last_name}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2" sx={{ fontSize: "13px" }}>
                              Email address
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.email_address
                                ? entityData.value.email_address
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2" sx={{ fontSize: "13px" }}>
                              Previous names /aliases
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">-</Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2" sx={{ fontSize: "13px" }}>
                              Date of birth (age)
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {entityData.value.date_of_birth
                                ? moment(entityData.value.date_of_birth).format("DD/MM/YYYY")
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2" sx={{ fontSize: "13px" }}>
                              Linked jurisdictions
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">-</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 0,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2" sx={{ fontSize: "13px" }}>
                              Linked entities
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">-</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box sx={{}}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          textTransform: "uppercase",
                          px: 2,
                          py: 1,
                        }}
                      >
                        <Typography variant="subtitle2">Matched profile</Typography>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2" sx={{ fontSize: "13px" }}>
                              Matched name
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {alertData?.matched_name ? alertData?.matched_name : "-"}
                            </Typography>
                          </Box>
                        </Box>
                        {alertData?.matched_name == alertData?.target_name && (
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2" sx={{ fontSize: "13px" }}>
                                Target name
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {alertData?.target_name ? alertData?.target_name : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {alertData?.aliases != "" && (
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2" sx={{ fontSize: "13px" }}>
                                Aliases
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {alertData?.aliases ? alertData?.aliases : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {alertData?.date_of_birth != "" && (
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2" sx={{ fontSize: "13px" }}>
                                Date of birth
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {alertData?.date_of_birth ? alertData?.date_of_birth : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {alertData?.place_of_birth != "" && (
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2" sx={{ fontSize: "13px" }}>
                                Place of birth
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {alertData?.place_of_birth ? alertData?.place_of_birth : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {alertData?.citizenship != "" && (
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2" sx={{ fontSize: "13px" }}>
                                Citizenship
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {alertData?.citizenship ? alertData?.citizenship : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {alertData?.jurisdictions != "" && (
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2" sx={{ fontSize: "13px" }}>
                                Jurisdictions
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {alertData?.jurisdictions ? alertData?.jurisdictions : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ px: 2, py: 1, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
                  <Typography variant="subtitle2">Alert Information</Typography>
                </Box>

                <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                  {alertData?.positions != "" && (
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "13px" }}>
                          Positions
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {alertData?.positions ? alertData?.positions : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {alertData?.political_parties != "" && (
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "13px" }}>
                          Political parties
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {alertData?.political_parties ? alertData?.political_parties : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {alertData?.links != "" && (
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "13px" }}>
                          Links
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {alertData?.links ? alertData?.links : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {alertData?.functions != "" && (
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "13px" }}>
                          Functions
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {alertData?.functions ? alertData?.functions : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {alertData?.details != "" && (
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "13px" }}>
                          Details
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {alertData?.details ? alertData?.details : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {alertData?.other_information != "" && (
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "13px" }}>
                          Other information
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {alertData?.other_information ? alertData?.other_information : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>

                {alertData?.reviewed_timestamp > 0 && (
                  <>
                    <Box sx={{ px: 2, py: 1, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
                      <Typography variant="subtitle2">Alert Review Information</Typography>
                    </Box>

                    <Box
                      sx={{
                        pr: 2,
                        pl: 1,
                        py: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Review details</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {alertData?.reviewed_by ? alertData?.reviewed_by : "-"}
                        </Typography>
                        <Typography variant="subtitle2">
                          {moment.unix(alertData?.reviewed_timestamp).format("DD/MM/YYYY HH:mm")}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}

                {alertData?.reviewed_timestamp > 0 && (
                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Review decision</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        justifyContent: "center",
                      }}
                    >
                      {alertData?.true_hit && (
                        <Chip
                          className="square-chip"
                          label="True Hit"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {alertData?.false_positive && (
                        <Chip
                          className="square-chip"
                          label="False Positive"
                          size="small"
                          sx={{
                            backgroundColor: "#494f4d",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {alertData?.inconclusive && (
                        <Chip
                          className="square-chip"
                          label="Inconclusive"
                          size="small"
                          sx={{
                            backgroundColor: "#494f4d",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                )}

                {alertData?.review_content != "" && (
                  <Box
                    sx={{
                      pr: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Review note</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        justifyContent: "center",
                      }}
                    >
                      {parse(alertData?.review_content ? alertData?.review_content : "")}
                    </Box>
                  </Box>
                )}

                <Collapse in={alertData?.review_required} exit={!alertData?.review_required}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      p: 2,
                      justifyContent: "space-between",
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Typography variant="h6">Review alert</Typography>
                    </Box>
                    <Box></Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderTop: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          p: 2,
                        }}
                      >
                        <Typography variant="subtitle2">Review decision</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <ToggleButtonGroup
                          color={reviewDecision == 1 ? "error" : "primary"}
                          value={reviewDecision}
                          exclusive
                          onChange={(e, value) => {
                            if (value !== null) setReviewDecision(value);
                          }}
                        >
                          <ToggleButton value={1}>True Hit</ToggleButton>
                          <ToggleButton value={2}>False Positive</ToggleButton>
                          <ToggleButton value={3}>Inconclusive</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>
                    <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                        <Typography variant="subtitle2">
                          Please provide information to support your decision
                        </Typography>
                      </Box>

                      <Collapse in={reviewDecision == 2} exit={reviewDecision != 2}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 2,
                            borderBottom: 1,
                            borderColor: "divider",
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="false-positive-template-label">Templates</InputLabel>
                            <Select
                              labelId="false-positive-template-label"
                              id="false-positive-template"
                              label="Templates"
                              defaultValue={""}
                              onChange={(e) => {
                                setReviewNote(getFalsePositiveTemplate(e.target.value));
                              }}
                            >
                              <MenuItem value={""} disabled={true}>
                                Select a template (optional)
                              </MenuItem>
                              {falsePositiveOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Collapse>

                      <Collapse in={reviewDecision == 3} exit={reviewDecision != 3}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 2,
                            borderBottom: 1,
                            borderColor: "divider",
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="inconclusive-template-label">Templates</InputLabel>
                            <Select
                              labelId="inconclusive-template-label"
                              id="inconclusive-template"
                              label="Templates"
                              defaultValue={""}
                              onChange={(e) => {
                                setReviewNote(getInconclusiveTemplate(e.target.value));
                              }}
                            >
                              <MenuItem value={""} disabled={true}>
                                Select a template (optional)
                              </MenuItem>
                              {inconclusiveOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Collapse>

                      <ReactQuill
                        theme="snow"
                        value={reviewNote}
                        onChange={setReviewNote}
                        style={{ minHeight: "300px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        p: 2,
                        borderTop: 1,
                        borderColor: "divider",
                      }}
                    >
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                          handleAlertReview(e);
                        }}
                        loading={processing}
                        disabled={reviewDecision == 0}
                      >
                        Submit decision
                      </LoadingButton>
                    </Box>
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Background;
