import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import Cookies from "universal-cookie";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Drawer,
} from "@mui/material";
import {
  Close,
  Delete,
  Edit,
  PermMedia,
  UploadFile,
  ViewAgenda,
  Visibility,
} from "@mui/icons-material";

import CountrySelect from "../../components/form-elements/CountrySelect";
import { LoadingButton } from "@mui/lab";

import FileUpload from "./components/address/FileUpload";
import Certifier from "./components/address/Certifier";
import Notary from "./components/address/Notary";
import TeamMembers from "./components/address/TeamMembers";

const Address = ({
  partnerData,
  partnerUser,
  caseData,
  entityData,
  addressData,
  getAddress,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const partnerURL = () => {
    const cookies = new Cookies();
    return "https://" + cookies.get("platformURL");
  };

  const partnerAPI = partnerURL();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [addressCountry, setAddressCountry] = useState();

  const [lookupDisplay, setLookupDisplay] = useState(false);
  const [lookupSupported, setLookupSupported] = useState(false);
  const [lookupValue, setLookupValue] = useState("");
  const [lookupOptions, setLookupOptions] = useState([]);
  const [formDisplay, setFormDisplay] = useState(false);
  const [stateRequired, setStateRequired] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressLine4, setAddressLine4] = useState("");
  const [addressLine5, setAddressLine5] = useState("");
  const [addressLine6, setAddressLine6] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressPostcode, setAddressPostcode] = useState("");

  const [documentData, setDocumentData] = useState(null);
  const [validateAddress, setValidateAddress] = useState(false);

  const handleLookupAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/lookup`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          country: addressCountry ? addressCountry.value : "",
          postcode: lookupValue,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setLookupOptions(response.data.entries);
      } else {
        //handleError("No addresses found");
        setLookupOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getSelectedAddress = async (addressUUID) => {
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          country: addressCountry ? addressCountry.value : "",
          uuid: addressUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      setAddressLine1(response.data.line_one);
      setAddressLine2(response.data.line_two);
      setAddressLine3(response.data.line_three);
      setAddressLine4(response.data.line_four);
      setAddressLine5(response.data.line_five);
      setAddressLine6(response.data.line_six);
      setAddressCity(response.data.city);
      setAddressPostcode(response.data.code);
      setLookupDisplay(false);
      setFormDisplay(true);

      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/address/add`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          line_one: addressLine1,
          line_two: addressLine2,
          line_three: addressLine3,
          line_four: addressLine4,
          line_five: addressLine5,
          line_six: addressLine6,
          city: addressCity,
          postcode: addressPostcode,
          country: addressCountry ? addressCountry.value : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getAddress();
      getCase();

      displayToast("Address updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
        setAddressCountry();
        setAddressLine1("");
        setAddressLine2("");
        setAddressLine3("");
        setAddressLine4("");
        setAddressLine5("");
        setAddressLine6("");
        setAddressCity("");
        setAddressState("");
        setAddressPostcode("");
        setFormDisplay(false);
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setLookupDisplay(false);
        setLookupValue("");
        setLookupOptions([]);
        setSelectedAddress("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const documentTypeOptions = [
    { value: 1, label: "Utility bill" },
    { value: 2, label: "Bank or mortgage statement" },
    { value: 3, label: "Government issued letter" },
    { value: 4, label: "Residence permit" },
  ];

  const [frontFile, setFrontFile] = useState(null);
  const [documentUUID, setDocumentUUID] = useState("");

  const [documentType, setDocumentType] = useState("");
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [password, setPassword] = useState("");
  const [issuingDate, setIssuingDate] = useState(null);
  const [validation, setValidation] = useState(true);
  const [notarised, setNotarised] = useState(null);
  const [certified, setCertified] = useState(null);

  const [notaryFirstName, setNotaryFirstName] = useState("");
  const [notaryMiddleNames, setNotaryMiddleNames] = useState("");
  const [notaryLastName, setNotaryLastName] = useState("");
  const [notaryEmail, setNotaryEmail] = useState("");
  const [notaryDiallingCode, setNotaryDiallingCode] = useState("");
  const [notaryPhoneCountry, setNotaryPhoneCountry] = useState("");
  const [notaryPhoneValue, setNotaryPhoneValue] = useState("");
  const [notaryTelephoneNumber, setNotaryTelephoneNumber] = useState("");
  const [notaryDate, setNotaryDate] = useState(null);

  const [certifierFirstName, setCertifierFirstName] = useState("");
  const [certifierMiddleNames, setCertifierMiddleNames] = useState("");
  const [certifierLastName, setCertifierLastName] = useState("");
  const [certifierCompanyName, setCertifierCompanyName] = useState("");
  const [certifierEmail, setCertifierEmail] = useState("");
  const [certifierPhoneValue, setCertifierPhoneValue] = useState("");
  const [certifierDiallingCode, setCertifierDiallingCode] = useState("");
  const [certifierPhoneCountry, setCertifierPhoneCountry] = useState("");
  const [certifierTelephoneNumber, setCertifierTelephoneNumber] = useState("");
  const [certifierDate, setCertifierDate] = useState(null);
  const [certifierQualification, setCertifierQualification] = useState(null);

  const [teamMember, setTeamMember] = useState("");
  const [otherTeamMember, setOtherTeamMember] = useState("");

  const [manageDocument, setManageDocument] = useState(false);
  const [documentImageURL, setDocumentImageURL] = useState("");
  const [documentImageFileType, setDocumentImageFileType] = useState("");
  const [documentImageType, setDocumentImageType] = useState("");

  const [editInformation, setEditInformation] = useState(false);

  const [manageDocumentReview, setManageDocumentReview] = useState(false);
  const [reviewDecision, setReviewDecision] = useState("");
  const [reviewNote, setReviewNote] = useState("");

  // get the document data
  const getDocumentData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/address/document`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      setDocumentData(response.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSetDocumentData = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/address/document`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          document_type: documentType,

          password: passwordProtected ? password : "",
          issue_date: issuingDate ? issuingDate.format("YYYY-MM-DD") : "",

          verification_required: validation,
          notarised_document: notarised,
          certified_document: certified,

          notary_first_name: notaryFirstName,
          notary_middle_names: notaryMiddleNames,
          notary_last_name: notaryLastName,

          notary_email_address: notaryEmail,
          notary_dialling_code: notaryDiallingCode ? notaryDiallingCode : "",
          notary_telephone_country: notaryPhoneCountry ? notaryPhoneCountry : "",
          notary_telephone_number: notaryTelephoneNumber ? notaryTelephoneNumber : "",
          notary_date: notaryDate ? notaryDate.format("YYYY-MM-DD") : "",

          certifier_first_name: certifierFirstName,
          certifier_middle_names: certifierMiddleNames,
          certifier_last_name: certifierLastName,
          certifier_company_name: certifierCompanyName,
          certifier_email_address: certifierEmail,
          certifier_dialling_code: certifierDiallingCode ? certifierDiallingCode : "",
          certifier_telephone_country: certifierPhoneCountry ? certifierPhoneCountry : "",
          certifier_telephone_number: certifierTelephoneNumber ? certifierTelephoneNumber : "",
          certifier_date: certifierDate ? certifierDate.format("YYYY-MM-DD") : "",
          certifier_qualification: certifierQualification ? certifierQualification.toString() : "",

          team_member_uuid: teamMember ? teamMember.value : "",
          other_team_member: otherTeamMember ? otherTeamMember : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      setDocumentUUID(response.data.document_uuid);
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const processUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("frontFile", frontFile);
    formData.append("frontFileName", frontFile?.name);

    formData.append("document_uuid", documentUUID);
    formData.append("person_uuid", entityData.value.person_uuid);

    try {
      const response = await partner.post(
        "/v1/entity/people/person/address/document/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          onUploadProgress: function (progressEvent) {},

          //withCredentials: true,
        }
      );

      getDocumentData();
      getCase();

      displayToast("Document uploaded successfully", () => {
        setProcessing(false);
        setValidateAddress(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const getDocumentImage = async (imageType) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/address/document/image`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          document_uuid: documentData.document_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data.image_url);

      setDocumentImageURL(response.data.image_url);
      setDocumentImageFileType(response.data.file_type);
      setDocumentImageType(imageType);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDocumentReview = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/address/document/review`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          document_uuid: documentData.document_uuid,
          decision: parseInt(reviewDecision),
          note: reviewNote,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getAddress();
      getDocumentData();
      getCase();

      displayToast("Document review submitted successfully", () => {
        setProcessing(false);
        setManageDocumentReview(false);
        if (parseInt(reviewDecision) == 2) {
          setManageDocument(false);
        }
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getDocumentData();
  }, []);

  useEffect(() => {
    if (addressCountry == "" || addressCountry == undefined) {
      setFormDisplay(false);
      setLookupDisplay(false);
      setLookupSupported(false);
      setStateRequired(false);
      return;
    }

    switch (addressCountry?.value) {
      case "GB":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "JE":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "GG":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "IM":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "US":
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(true);
        setFormDisplay(true);
        break;
      default:
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setFormDisplay(true);
        break;
    }
  }, [addressCountry]);

  useEffect(() => {
    if (selectedAddress != "") {
      getSelectedAddress();
    }
  }, [selectedAddress]);

  return isLoaded ? (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12}>
          <Box sx={{}}>
            {!editMode ? (
              !validateAddress ? (
                <Box
                  sx={{
                    borderRight: { xs: 0, xl: 0 },
                    borderColor: { xs: "divider", xl: "divider" },
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Residential Address</Typography>
                    </Box>
                    <Box>
                      <IconButton title="Edit" onClick={() => setEditMode(true)}>
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box sx={{ p: 2 }}>
                    <Box>
                      <Typography variant="subtitle2">
                        {addressData?.line_one ? addressData.line_one : "-"}
                      </Typography>
                      {addressData?.line_two && (
                        <Typography variant="subtitle2">
                          {addressData.line_two ? addressData.line_two : "-"}
                        </Typography>
                      )}
                      {addressData?.line_three && (
                        <Typography variant="subtitle2">
                          {addressData.line_three ? addressData.line_three : "-"}
                        </Typography>
                      )}
                      {addressData?.line_four && (
                        <Typography variant="subtitle2">
                          {addressData.line_four ? addressData.line_four : "-"}
                        </Typography>
                      )}
                      {addressData?.line_five && (
                        <Typography variant="subtitle2">
                          {addressData.line_five ? addressData.line_five : "-"}
                        </Typography>
                      )}
                      {addressData?.line_six && (
                        <Typography variant="subtitle2">
                          {addressData.line_six ? addressData.line_six : "-"}
                        </Typography>
                      )}
                      {addressData?.city && (
                        <Typography variant="subtitle2">
                          {addressData.city ? addressData.city : "-"}
                        </Typography>
                      )}
                      {addressData?.postcode && (
                        <Typography variant="subtitle2">
                          {addressData.postcode ? addressData.postcode : "-"}
                        </Typography>
                      )}
                      {addressData?.state && (
                        <Typography variant="subtitle2">
                          {addressData.state ? addressData.state : "-"}
                        </Typography>
                      )}
                      {addressData?.country.code && (
                        <Typography variant="subtitle2">
                          {addressData.country.code ? addressData.country.name : "-"}
                        </Typography>
                      )}
                      {addressData.country?.high_risk && (
                        <Chip
                          className="square-chip"
                          label="High risk"
                          size="small"
                          sx={{
                            backgroundColor: "#C83E4D",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData.country?.conflict && (
                        <Chip
                          className="square-chip"
                          label="Conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#FF4A1C",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData.country?.borders_conflict && (
                        <Chip
                          className="square-chip"
                          label="Borders conflict"
                          size="small"
                          sx={{
                            backgroundColor: "#977390",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      {addressData.country?.is_secrecy && (
                        <Chip
                          className="square-chip"
                          label="Secrecy"
                          size="small"
                          sx={{
                            backgroundColor: "#999999",
                            color: "#1C2321",
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  {caseData.value.options.address_verification && caseData.value.address && (
                    <Box>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderTop: 1,
                          borderColor: "divider",
                          px: 2,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">Address validation</Typography>
                        </Box>
                        <Box>
                          {documentData?.document_uuid == "" ? (
                            <Button
                              startIcon={<UploadFile />}
                              title="Validate address"
                              variant="contained"
                              color="primary"
                              onClick={() => setValidateAddress(true)}
                            >
                              Validate address
                            </Button>
                          ) : documentData?.upload_timestamp > 0 ? (
                            <Button
                              title="View document"
                              variant="contained"
                              color="primary"
                              startIcon={<Visibility />}
                              onClick={() => setManageDocument(true)}
                            >
                              View document
                            </Button>
                          ) : (
                            <Button
                              startIcon={<UploadFile />}
                              title="Validate address"
                              variant="contained"
                              color="primary"
                              onClick={() => setValidateAddress(true)}
                            >
                              Validate address
                            </Button>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {documentData?.uploaded && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 1,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">Document type</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {documentData?.document_type > 0
                                  ? documentTypeOptions.find(
                                      (dt) => dt.value === documentData.document_type
                                    ).label
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {documentData?.uploaded && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 1,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">Issue date</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {documentData?.issue_date
                                  ? moment(documentData.issue_date).format("DD/MM/YYYY")
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {documentData?.uploaded && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 1,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">Upload status</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {documentData?.uploaded
                                  ? "Uploaded - " +
                                    moment
                                      .unix(documentData.upload_timestamp)
                                      .format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {documentData?.uploaded && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 1,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">Document analysis</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {documentData?.upload_timestamp > 0 ? (
                                documentData?.verification_required ? (
                                  documentData?.analysis_submitted ? (
                                    documentData?.analysis_complete ? (
                                      documentData?.analysis_decision == 1 ? (
                                        <Chip
                                          className="square-chip"
                                          label="Pass"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#8BBF9F",
                                            color: "#1C2321",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      ) : (
                                        <Typography variant="subtitle2" sx={{ color: "#C83E4D" }}>
                                          Failed
                                        </Typography>
                                      )
                                    ) : (
                                      <Typography variant="subtitle2">Processing</Typography>
                                    )
                                  ) : (
                                    <Typography variant="subtitle2">Pending</Typography>
                                  )
                                ) : documentData?.notarised ? (
                                  <Typography variant="subtitle2">Notarised</Typography>
                                ) : documentData?.certified ? (
                                  <Typography variant="subtitle2">Certified</Typography>
                                ) : (
                                  documentData?.team_certified && (
                                    <Typography variant="subtitle2">Team Certified</Typography>
                                  )
                                )
                              ) : (
                                <Typography variant="subtitle2">-</Typography>
                              )}
                            </Box>
                          </Box>
                        )}

                        {documentData?.review_result > 0 && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 1,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">Document review</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {documentData?.review_actor ? documentData?.review_actor : "-"}
                              </Typography>
                              <Typography variant="subtitle2">
                                {documentData?.review_timestamp
                                  ? moment
                                      .unix(documentData?.review_timestamp)
                                      .format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {documentData?.review_result > 0 && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 1,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">Review decision</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {documentData?.review_result == 1 ? (
                                <Chip
                                  className="square-chip"
                                  label="Approved"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : (
                                <Chip
                                  className="square-chip"
                                  label="Rejected"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#C83E4D",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        )}

                        <Box
                          sx={{
                            pr: 2,
                            pl: 1,
                            py: 1,
                            borderBottom: 0,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Verification status</Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {documentData?.uploaded &&
                              documentData?.verification_required &&
                              !documentData?.analysis_complete && (
                                <Chip
                                  className="square-chip"
                                  label="Validating"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#1C2321",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            {documentData?.manual_review && (
                              <Chip
                                className="square-chip"
                                label="Manual review"
                                size="small"
                                sx={{
                                  backgroundColor: "#FCCA46",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {documentData?.analysis_complete &&
                              documentData?.analysis_decision == 1 && (
                                <Chip
                                  className="square-chip"
                                  label="Verified"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            {documentData?.review_result == 1 && (
                              <Chip
                                className="square-chip"
                                label="Verified"
                                size="small"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {documentData?.review_result == 2 && (
                              <Chip
                                className="square-chip"
                                label="Upload Required"
                                size="small"
                                sx={{
                                  backgroundColor: "#1C2321",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {documentData?.document_uuid == "" && (
                              <Chip
                                className="square-chip"
                                label="Upload Required"
                                size="small"
                                sx={{
                                  backgroundColor: "#1C2321",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}

                            {documentData?.document_uuid != "" && !documentData?.uploaded && (
                              <Chip
                                className="square-chip"
                                label="Upload Required"
                                size="small"
                                sx={{
                                  backgroundColor: "#1C2321",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {documentData?.notarised && (
                              <Chip
                                className="square-chip"
                                label="Verified"
                                size="small"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {documentData?.certified && (
                              <Chip
                                className="square-chip"
                                label="Verified"
                                size="small"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {documentData?.team_certified && (
                              <Chip
                                className="square-chip"
                                label="Verified"
                                size="small"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    borderRight: { xs: 0, xl: 0 },
                    borderColor: { xs: "divider", xl: "divider" },
                  }}
                >
                  {documentUUID ? (
                    <Box sx={{}}>
                      <Box>
                        <Box
                          sx={{
                            borderBottom: 1,

                            borderColor: "divider",
                            p: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "#0b0b0b",
                            color: "#ffffff",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="h6">Document Image</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              title="Close"
                              onClick={() => {
                                setDocumentUUID("");
                                setValidateAddress(false);
                              }}
                            >
                              <Close sx={{ color: "#ffffff" }} />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box>
                          <Box sx={{ p: 2 }}>
                            <Typography sx={{ fontSize: "13px", mb: 2 }}>
                              When submitting an image of your identity document, please ensure the
                              following requirements are met to ensure a clear and usable scan:
                            </Typography>
                            <Typography sx={{ fontSize: "13px", mb: 1 }}>
                              <b>High Quality:</b> The image should be high-resolution, aiming for a
                              minimum of 300 DPI (dots per inch) to ensure all text and images are
                              sharp and legible.
                            </Typography>
                            <Typography sx={{ fontSize: "13px", mb: 1 }}>
                              <b>Full Document Display:</b> Capture the entire document in the
                              frame. The edges of the document should not be cut off, and the image
                              should not be cropped too closely to the text or features of the
                              document.
                            </Typography>
                            <Typography sx={{ fontSize: "13px", mb: 1 }}>
                              <b>Proper Lighting:</b> Ensure the document is well-lit, but avoid
                              glare that can obscure any part of the information. Natural light is
                              often best, but direct sunlight should be avoided to reduce glare.
                            </Typography>
                            <Typography sx={{ fontSize: "13px", mb: 1 }}>
                              <b>No Obstructions:</b> Make sure the document is free from fingers
                              holding the document, clips, or any other obstructions that can cover
                              the information and features of the ID.
                            </Typography>

                            <Typography sx={{ fontSize: "13px", mb: 1 }}>
                              <b>Focus and Clarity:</b> The document should be in focus, with no
                              blurring that might make any text or images indistinct. Hold the
                              camera steady or use a flat surface to avoid shake. Neutral
                              Background: Place the document on a contrasting, uniform, and
                              single-colored background. This helps the edges of the document stand
                              out clearly and aids in document verification processes.
                            </Typography>

                            <Typography sx={{ fontSize: "13px", mb: 1 }}>
                              <b>Avoid Filters and Edits:</b> Do not use any filters or editing
                              features that alter the colors or details of the document. The image
                              should represent the document as accurately as possible.
                            </Typography>

                            <Typography sx={{ fontSize: "13px", mb: 2 }}>
                              <b>Orientation:</b> The document should be oriented correctly in the
                              image; it should not be upside down or rotated. These guidelines are
                              designed to ensure that the uploaded image of your identity document
                              is clear, readable, and suitable for verification purposes.
                            </Typography>

                            <Typography sx={{ fontSize: "13px" }}>
                              Always double-check your image before submission to ensure all details
                              are visible and accurate.
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <FileUpload setFile={setFrontFile} />

                            {frontFile && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,

                                  border: 1,
                                  borderColor: "divider",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box sx={{ display: "flex" }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: 2,
                                          fontWeight: 600,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            pl: 2,
                                          }}
                                        >
                                          <PermMedia />
                                        </Box>
                                        <Box
                                          sx={{
                                            borderRight: 1,
                                            borderLeft: 1,
                                            borderColor: "divider",
                                            p: 1,
                                          }}
                                        >
                                          <Typography variant="">Filename</Typography>
                                        </Box>
                                      </Box>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        fontSize: 13,
                                        pl: 2,
                                      }}
                                    >
                                      {frontFile.path}
                                    </Box>
                                  </Box>

                                  <Box sx={{ pr: 1 }}>
                                    <IconButton
                                      title="Remove file"
                                      onClick={() => {
                                        setFrontFile(null);
                                      }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            )}

                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                              <Box></Box>

                              <LoadingButton
                                disableElevation
                                onClick={processUpload}
                                loading={processing}
                                variant="contained"
                                color="success"
                              >
                                Upload
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        borderRight: { xs: 0, xl: 0 },
                        borderColor: { xs: "divider", xl: "divider" },
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          px: 2,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">Document information</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            title="Close"
                            onClick={() => {
                              setValidateAddress(false);
                            }}
                          >
                            <Close sx={{ color: "#ffffff" }} />
                          </IconButton>
                        </Box>
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="subtitle2">Document type</Typography>
                          <FormControl fullWidth>
                            <InputLabel id="document-type-label">Document type</InputLabel>
                            <Select
                              labelId="document-type-label"
                              id="document-type"
                              label="Document type"
                              onChange={(e) => setDocumentType(e.target.value)}
                            >
                              {documentTypeOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="subtitle2">Document date</Typography>

                          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                            <DatePicker
                              fullWidth
                              openTo="year"
                              label={`Issue date`}
                              value={issuingDate ? issuingDate : null}
                              onChange={(date) => {
                                setIssuingDate(date);
                              }}
                              slotProps={{ textField: { fullWidth: true } }}
                              maxDate={moment()}
                            />
                          </LocalizationProvider>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">Password protected images</Typography>
                          </Box>

                          <Box>
                            <ToggleButtonGroup
                              color="primary"
                              value={passwordProtected}
                              exclusive
                              onChange={(e, value) => {
                                if (value !== null) setPasswordProtected(value);
                              }}
                            >
                              <ToggleButton value={true}>Yes</ToggleButton>
                              <ToggleButton value={false}>No</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>

                        <Collapse in={passwordProtected} exit={!passwordProtected}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="subtitle2">Document password</Typography>
                            <TextField
                              fullWidth
                              label="Document password"
                              value={password}
                              onChange={(e, value) => {
                                if (value !== null) setPassword(e.target.value);
                              }}
                            />
                          </Box>
                        </Collapse>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">Validate document</Typography>
                            </Box>

                            <Box>
                              <ToggleButtonGroup
                                color="primary"
                                value={validation}
                                exclusive
                                onChange={(e, value) => {
                                  if (value !== null) setValidation(value);
                                }}
                              >
                                <ToggleButton value={true}>Yes</ToggleButton>
                                <ToggleButton value={false}>No</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>

                          <Collapse in={!validation} exit={validation}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    Is the document a notarised copy?
                                  </Typography>
                                </Box>

                                <Box>
                                  <ToggleButtonGroup
                                    color="primary"
                                    value={notarised}
                                    exclusive
                                    onChange={(e, value) => {
                                      if (value !== null) setNotarised(value);
                                    }}
                                  >
                                    <ToggleButton value={true}>Yes</ToggleButton>
                                    <ToggleButton value={false}>No</ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                              </Box>

                              <Collapse in={notarised} exit={!notarised}>
                                <Notary
                                  firstName={notaryFirstName}
                                  setFirstName={setNotaryFirstName}
                                  middleNames={notaryMiddleNames}
                                  setMiddleNames={setNotaryMiddleNames}
                                  lastName={notaryLastName}
                                  setLastName={setNotaryLastName}
                                  emailAddress={notaryEmail}
                                  setEmailAddress={setNotaryEmail}
                                  diallingCode={notaryDiallingCode}
                                  setDiallingCode={setNotaryDiallingCode}
                                  phoneCountry={notaryPhoneCountry}
                                  setPhoneCountry={setNotaryPhoneCountry}
                                  telephoneNumber={notaryTelephoneNumber}
                                  setTelephoneNumber={setNotaryTelephoneNumber}
                                  phoneValue={notaryPhoneValue}
                                  setPhoneValue={setNotaryPhoneValue}
                                  signatureDate={notaryDate}
                                  setSignatureDate={setNotaryDate}
                                />
                              </Collapse>

                              {notarised != null && (
                                <Collapse in={!notarised} exit={notarised}>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography variant="subtitle2">
                                          Is the document a certified copy?
                                        </Typography>
                                      </Box>

                                      <Box>
                                        <ToggleButtonGroup
                                          color="primary"
                                          value={certified}
                                          exclusive
                                          onChange={(e, value) => {
                                            if (value !== null) setCertified(value);
                                          }}
                                        >
                                          <ToggleButton value={true}>Yes</ToggleButton>
                                          <ToggleButton value={false}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                      </Box>
                                    </Box>

                                    <Collapse in={certified} exit={!certified}>
                                      <Certifier
                                        firstName={certifierFirstName}
                                        setFirstName={setCertifierFirstName}
                                        middleNames={certifierMiddleNames}
                                        setMiddleNames={setCertifierMiddleNames}
                                        lastName={certifierLastName}
                                        setLastName={setCertifierLastName}
                                        companyName={certifierCompanyName}
                                        setCompanyName={setCertifierCompanyName}
                                        emailAddress={certifierEmail}
                                        setEmailAddress={setCertifierEmail}
                                        diallingCode={certifierDiallingCode}
                                        setDiallingCode={setCertifierDiallingCode}
                                        phoneCountry={certifierPhoneCountry}
                                        setPhoneCountry={setCertifierPhoneCountry}
                                        telephoneNumber={certifierTelephoneNumber}
                                        setTelephoneNumber={setCertifierTelephoneNumber}
                                        phoneValue={certifierPhoneValue}
                                        setPhoneValue={setCertifierPhoneValue}
                                        signatureDate={certifierDate}
                                        setSignatureDate={setCertifierDate}
                                        qualification={certifierQualification}
                                        setQualification={setCertifierQualification}
                                      />
                                    </Collapse>
                                  </Box>
                                </Collapse>
                              )}

                              {certified != null && (
                                <Collapse
                                  in={!notarised && !certified}
                                  exit={notarised || certified}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle2">
                                        Who witnessed the collection of the document?
                                      </Typography>
                                    </Box>

                                    <Box sx={{ minWidth: "50%" }}>
                                      <TeamMembers value={teamMember} setValue={setTeamMember} />
                                      <Collapse
                                        in={teamMember?.value == "other"}
                                        exit={teamMember?.value != "other"}
                                      >
                                        <TextField
                                          sx={{ mt: 1 }}
                                          fullWidth
                                          label="Full name"
                                          value={otherTeamMember}
                                          onChange={(e) => setOtherTeamMember(e.target.value)}
                                        />
                                      </Collapse>
                                    </Box>
                                  </Box>
                                </Collapse>
                              )}
                            </Box>
                          </Collapse>
                        </Box>

                        <Box
                          sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                        >
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            loading={processing}
                            onClick={(e) => {
                              handleSetDocumentData(e);
                            }}
                            color="success"
                          >
                            Continue
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )
            ) : (
              <Box>
                <Box
                  sx={{
                    borderRight: { xs: 0, xl: 0 },
                    borderColor: { xs: "divider", xl: "divider" },
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Update address</Typography>
                    </Box>
                    <Box>
                      <IconButton title="Close" onClick={() => setEditMode(false)}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <CountrySelect value={addressCountry} setValue={setAddressCountry} />

                      <Collapse in={lookupDisplay} exit={!lookupDisplay}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 2,
                            border: 1,
                            borderColor: "divider",
                          }}
                        >
                          <Typography variant="subtitle2">Address lookup</Typography>
                          <TextField
                            label="Postcode / Zipcode"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              setLookupValue(e.target.value);
                            }}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: 2,
                            }}
                          >
                            <Box sx={{ minWidth: "300px;", width: "50%" }}>
                              {lookupOptions && lookupOptions.length > 0 && (
                                <Box>
                                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    Lookup results
                                  </Typography>

                                  <FormControl fullWidth>
                                    <InputLabel id="address-results-label">
                                      Select an address
                                    </InputLabel>
                                    <Select
                                      labelId="address-results-label"
                                      id="address-results"
                                      value={selectedAddress}
                                      label="Select an address"
                                      onChange={(e) => getSelectedAddress(e.target.value)}
                                    >
                                      {lookupOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              )}
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={(e) => {
                                    setLookupDisplay(false);
                                    setFormDisplay(true);
                                  }}
                                >
                                  Manual entry
                                </Button>
                              </Box>
                              <Box>
                                <LoadingButton
                                  variant="contained"
                                  color="success"
                                  loading={processing}
                                  onClick={(e) => handleLookupAddress(e)}
                                >
                                  Search
                                </LoadingButton>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Collapse>

                      <Collapse in={formDisplay} exit={!formDisplay}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <TextField
                            label="Address line 1"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                          />

                          <Collapse
                            in={addressLine1 != "" ? true : addressLine2 != "" ? true : false}
                            exit={(addressLine1 == "") & (addressLine2 == "") ? true : false}
                          >
                            <TextField
                              label="Address line 2"
                              variant="outlined"
                              sx={{ mt: 1 }}
                              fullWidth
                              value={addressLine2}
                              onChange={(e) => setAddressLine2(e.target.value)}
                            />
                          </Collapse>
                          <Collapse
                            in={addressLine2 != "" ? true : addressLine3 != "" ? true : false}
                            exit={(addressLine2 == "") & (addressLine3 == "") ? true : false}
                          >
                            <TextField
                              label="Address line 3"
                              variant="outlined"
                              sx={{ mt: 1 }}
                              fullWidth
                              value={addressLine3}
                              onChange={(e) => setAddressLine3(e.target.value)}
                            />
                          </Collapse>

                          <Collapse
                            in={addressLine3 != "" ? true : addressLine4 != "" ? true : false}
                            exit={(addressLine3 == "") & (addressLine4 == "") ? true : false}
                          >
                            <TextField
                              label="Address line 4"
                              variant="outlined"
                              sx={{ mt: 1 }}
                              fullWidth
                              value={addressLine4}
                              onChange={(e) => setAddressLine4(e.target.value)}
                            />
                          </Collapse>
                          <Collapse
                            in={addressLine4 != "" ? true : addressLine5 != "" ? true : false}
                            exit={(addressLine4 == "") & (addressLine5 == "") ? true : false}
                          >
                            <TextField
                              label="Address line 5"
                              variant="outlined"
                              sx={{ mt: 1 }}
                              fullWidth
                              value={addressLine5}
                              onChange={(e) => setAddressLine5(e.target.value)}
                            />
                          </Collapse>
                          <Collapse
                            in={addressLine5 != "" ? true : addressLine6 != "" ? true : false}
                            exit={(addressLine5 == "") & (addressLine6 == "") ? true : false}
                          >
                            <TextField
                              label="Address line 6"
                              variant="outlined"
                              sx={{ mt: 1 }}
                              fullWidth
                              value={addressLine6}
                              onChange={(e) => setAddressLine6(e.target.value)}
                            />
                          </Collapse>
                          <TextField
                            label="City"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressCity}
                            onChange={(e) => setAddressCity(e.target.value)}
                          />

                          <Collapse in={stateRequired} exit={!stateRequired}>
                            <TextField
                              label="State"
                              variant="outlined"
                              sx={{ mt: 1 }}
                              fullWidth
                              value={addressState}
                              onChange={(e) => setAddressState(e.target.value)}
                            />
                          </Collapse>
                          <TextField
                            label="Postcode/Zipcode"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressPostcode}
                            onChange={(e) => setAddressPostcode(e.target.value)}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            mt: 2,
                          }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            {lookupSupported && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                  setLookupDisplay(true);
                                  setFormDisplay(false);
                                }}
                              >
                                Lookup address
                              </Button>
                            )}
                            <LoadingButton
                              disableElevation
                              variant="contained"
                              color="success"
                              loading={processing}
                              onClick={(e) => handleAddAddress(e)}
                            >
                              Save address
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={manageDocument} onClose={() => setManageDocument(false)}>
        <Box sx={{ width: { xs: "90vw", lg: "75vw", xl: "50vw" } }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              px: 2,
              py: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h6">Document Details</Typography>
            </Box>

            <Box sx={{}}>
              <IconButton title="Close" onClick={() => setManageDocument(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 2,
                backgroundColor: "#0b0b0b",
                color: "#ffffff",
                py: 1,
                px: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2">Document information</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <IconButton
                  title="Edit"
                  sx={{ color: "#ffffff" }}
                  onClick={() => setEditInformation(true)}
                >
                  <Edit />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", border: 1, borderColor: "divider" }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  py: 1,
                  px: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Document type</Typography>
                <Typography variant="subtitle2">
                  {documentData?.document_type > 0
                    ? documentTypeOptions.find((dt) => dt.value === documentData.document_type)
                        .label
                    : "-"}
                </Typography>
              </Box>

              <Box
                sx={{
                  borderBottom: 0,
                  borderColor: "divider",
                  py: 1,
                  px: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">Issue date</Typography>
                <Typography variant="subtitle2">
                  {documentData?.issue_date != ""
                    ? moment(documentData?.issue_date).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: 2, border: 1, borderColor: "divider" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 2,
                  backgroundColor: "#0b0b0b",
                  color: "#ffffff",
                  py: 1,
                  px: 2,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">Upload information</Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                ></Box>
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pl: 1,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Upload status</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">
                    {documentData?.uploaded
                      ? "Uploaded - " +
                        moment.unix(documentData.upload_timestamp).format("DD/MM/YYYY HH:mm")
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pl: 1,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Upload source</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">
                    {documentData?.uploaded_by_type == 1 ? documentData.uploaded_by_name : "Client"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pl: 1,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Upload IP address</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography variant="subtitle2">
                    {documentData?.upload_ip_address ? documentData.upload_ip_address : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pl: 1,
                  py: 1,
                  borderBottom: 0,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Upload IP country</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography variant="subtitle2">
                    {documentData?.upload_ip_country?.code
                      ? documentData.upload_ip_country?.name
                      : "-"}
                  </Typography>
                  {documentData?.upload_ip_country?.high_risk && (
                    <Chip
                      className="square-chip"
                      label="High risk"
                      size="small"
                      sx={{
                        backgroundColor: "#C83E4D",
                        color: "#ffffff",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {documentData?.upload_ip_country?.conflict && (
                    <Chip
                      className="square-chip"
                      label="Conflict"
                      size="small"
                      sx={{
                        backgroundColor: "#FF4A1C",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {documentData?.upload_ip_country?.borders_conflict && (
                    <Chip
                      className="square-chip"
                      label="Borders conflict"
                      size="small"
                      sx={{
                        backgroundColor: "#977390",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {documentData?.upload_ip_country?.is_secrecy && (
                    <Chip
                      className="square-chip"
                      label="Secrecy"
                      size="small"
                      sx={{
                        backgroundColor: "#999999",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box sx={{ mt: 2, border: 1, borderColor: "divider" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 2,
                  backgroundColor: "#0b0b0b",
                  color: "#ffffff",
                  py: 1,
                  px: 2,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">Document analysis</Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                ></Box>
              </Box>

              <Box
                sx={{
                  pr: 2,
                  pl: 1,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Document analysis</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  {documentData.upload_timestamp > 0 ? (
                    documentData.verification_required ? (
                      documentData?.analysis_submitted ? (
                        documentData?.analysis_complete ? (
                          documentData?.analysis_decision == 1 ? (
                            <Chip
                              className="square-chip"
                              label="Pass"
                              size="small"
                              sx={{
                                backgroundColor: "#8BBF9F",
                                color: "#1C2321",
                                textTransform: "uppercase",
                              }}
                            />
                          ) : (
                            <Typography variant="subtitle2" sx={{ color: "#C83E4D" }}>
                              Failed
                            </Typography>
                          )
                        ) : (
                          <Typography variant="subtitle2">Processing</Typography>
                        )
                      ) : (
                        <Typography variant="subtitle2">Pending</Typography>
                      )
                    ) : documentData?.notarised ? (
                      <Typography variant="subtitle2">Notarised</Typography>
                    ) : documentData?.certified ? (
                      <Typography variant="subtitle2">Certified</Typography>
                    ) : (
                      documentData?.team_certified && (
                        <Typography variant="subtitle2">Team Certified</Typography>
                      )
                    )
                  ) : (
                    <Typography variant="subtitle2">-</Typography>
                  )}
                </Box>
              </Box>

              {documentData?.analysis_decision == 2 && (
                <Box
                  sx={{
                    pr: 2,
                    pl: 1,
                    py: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2">
                      Analysis failure reason
                      {documentData?.analysis_failure_reason.length > 1 && "s"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    {documentData.analysis_failure_reason?.length > 0 ? (
                      documentData.analysis_failure_reason.map((reason, index) => (
                        <Typography key={index} variant="subtitle2">
                          {reason.reason}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="subtitle2">-</Typography>
                    )}
                  </Box>
                </Box>
              )}

              {documentData?.review_result > 0 && (
                <Box
                  sx={{
                    pr: 2,
                    pl: 1,
                    py: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2">Review details</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="subtitle2">
                      {documentData?.review_actor ? documentData?.review_actor : "-"}
                    </Typography>
                    <Typography variant="subtitle2">
                      {moment.unix(documentData?.review_timestamp).format("DD/MM/YYYY HH:mm")}
                    </Typography>
                  </Box>
                </Box>
              )}

              {documentData?.review_result > 0 && (
                <Box
                  sx={{
                    pr: 2,
                    pl: 1,
                    py: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2">Review decision</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    {documentData?.review_result == 1 && (
                      <Chip
                        className="square-chip"
                        label="Approved"
                        size="small"
                        sx={{
                          backgroundColor: "#8BBF9F",
                          color: "#1C2321",
                          textTransform: "uppercase",
                        }}
                      />
                    )}
                    {documentData?.review_result == 2 && (
                      <Chip
                        className="square-chip"
                        label="Rejected"
                        size="small"
                        sx={{
                          backgroundColor: "#C83E4D",
                          color: "#ffffff",
                          textTransform: "uppercase",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}

              {documentData?.review_result > 0 && (
                <Box
                  sx={{
                    pr: 2,
                    pl: 1,
                    py: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2">Review note</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    {parse(documentData?.review_reason)}
                  </Box>
                </Box>
              )}

              {documentData?.notarised && (
                <Box
                  sx={{
                    px: 2,
                    pl: 1,
                    py: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2">Notary information</Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", textAlign: "end", gap: 1 }}>
                    <Typography variant="subtitle2">{documentData.certifier?.name}</Typography>
                    <Typography variant="subtitle2">
                      {documentData.certifier?.email_address}
                    </Typography>
                    <Typography variant="subtitle2">
                      + {documentData.certifier?.telephone_dialling_code}{" "}
                      {documentData.certifier?.telephone_number} (
                      {documentData.certifier?.telephone_country.name})
                    </Typography>
                    <Typography variant="subtitle2">
                      {moment(documentData.certifier?.certified_date).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                </Box>
              )}

              {documentData?.certified && (
                <Box
                  sx={{
                    px: 2,
                    pl: 1,
                    py: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2">Certifier information</Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", textAlign: "end", gap: 1 }}>
                    <Typography variant="subtitle2">{documentData.certifier?.name}</Typography>
                    <Typography variant="subtitle2">
                      {documentData.certifier?.qualification}
                    </Typography>
                    <Typography variant="subtitle2">
                      {documentData.certifier?.email_address}
                    </Typography>
                    <Typography variant="subtitle2">
                      + {documentData.certifier?.telephone_dialling_code}{" "}
                      {documentData.certifier?.telephone_number} (
                      {documentData.certifier?.telephone_country.name})
                    </Typography>

                    <Typography variant="subtitle2">
                      {moment(documentData.certifier?.certified_date).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                </Box>
              )}

              {!documentData?.certified &&
                !documentData.notarised &&
                !documentData.verification_required && (
                  <Box
                    sx={{
                      px: 2,
                      pl: 1,
                      py: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="body2">Team member information</Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", textAlign: "end", gap: 1 }}
                    >
                      <Typography variant="subtitle2">{documentData.certifier?.name}</Typography>
                      <Typography variant="subtitle2">
                        {moment(documentData.certifier?.certified_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  p: 2,
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <LoadingButton
                    disableElevation
                    onClick={() => {
                      getDocumentImage();
                    }}
                    loading={processing}
                    variant="contained"
                    color="primary"
                  >
                    Document Image
                  </LoadingButton>
                </Box>
                <Box>
                  {documentData?.manual_review && (
                    <Button
                      variant="contained"
                      color={manageDocumentReview ? "primary" : "warning"}
                      onClick={() => {
                        setManageDocumentReview(!manageDocumentReview);
                      }}
                    >
                      {manageDocumentReview ? "Close" : "Review Decision"}
                    </Button>
                  )}
                </Box>
              </Box>

              <Collapse in={manageDocumentReview} exit={!manageDocumentReview}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        p: 2,
                      }}
                    >
                      <Typography variant="subtitle2">Review decision</Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <ToggleButtonGroup
                        color={reviewDecision == 1 ? "success" : "error"}
                        value={reviewDecision}
                        exclusive
                        onChange={(e, value) => {
                          if (value !== null) setReviewDecision(value);
                        }}
                      >
                        <ToggleButton value={1}>Approve</ToggleButton>
                        <ToggleButton value={2}>Reject</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                    <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                      <Typography variant="subtitle2">
                        Please provide details surrounding your decision
                      </Typography>
                    </Box>
                    <ReactQuill
                      theme="snow"
                      value={reviewNote}
                      onChange={setReviewNote}
                      style={{ minHeight: "300px" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      p: 2,
                      borderTop: 1,
                      borderColor: "divider",
                    }}
                  >
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      color="success"
                      onClick={(e) => {
                        handleDocumentReview(e);
                      }}
                      loading={processing}
                      disabled={reviewDecision == 0}
                    >
                      Submit decision
                    </LoadingButton>
                  </Box>
                </Box>
              </Collapse>

              <Collapse in={documentImageURL != ""} exit={documentImageURL == ""}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 2,
                      p: 2,
                      borderBottom: 1,
                      borderTop: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2">Document Image</Typography>
                    </Box>
                    <IconButton
                      title="Close"
                      onClick={() => {
                        setDocumentImageURL("");
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  {documentImageFileType == ".pdf" ? (
                    <embed
                      width="100%"
                      height="600"
                      src={`${documentImageURL}#view=Fit`}
                      type={`application/pdf`}
                    />
                  ) : (
                    <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                      <img width={"60%"} sx={{}} src={`${documentImageURL}`} />
                    </Box>
                  )}
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Address;
