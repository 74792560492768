import React, { useState, useEffect } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Fade,
} from "@mui/material";
import { Edit, Close, Delete, Visibility, PermMedia } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Question from "./Question";

const RiskAssessment = ({ caseData, entityData, answers, getCase, profileUUID }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [manualAdjustment, setManualAdjustment] = useState(false);
  const [manualPoints, setManualPoints] = useState(0);
  const [assessmentNote, setAssessmentNote] = useState("");
  const [manualAdjustmentNote, setManualAdjustmentNote] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [questions, setQuestions] = useState(null);

  const getAssessmentQuestions = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/questions/list`,
        JSON.stringify({
          assessment_type: 2,
          entity_uuid: entityData.value.corporation_uuid,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      answers.value = [];

      if (response.data.results > 0) {
        setQuestions(response.data.entries);

        response.data.entries.forEach((question) => {
          answers.value.push({
            question_uuid: question.uuid,
            value: question.auto_calculate
              ? question.answers?.yes
                ? true
                : question.answers?.high_risk
                ? true
                : false
              : false,
            points: question.auto_calculate ? question.answers.points : 0,
          });
        });
      } else {
        setQuestions(null);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleRiskAssessment = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyb/risk-assessment`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          corporation_uuid: entityData.value.corporation_uuid,
          answers: answers.value,
          manual_adjustment: manualAdjustment,
          manual_adjustment_score: parseInt(manualPoints),
          manual_adjustment_reason: manualAdjustmentNote,
          content: assessmentNote,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCase();

      displayToast("Risk assessment completed successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getAssessmentQuestions();
  }, []);

  useEffect(() => {
    console.log(answers.value);
  }, [answers.value]);

  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1 }}>
        <Typography variant="h6">Risk assessment</Typography>
      </Box>

      {isLoaded ? (
        <Box>
          {questions?.length > 0 && (
            <>
              {questions.map((question, index) => (
                <Question
                  key={index}
                  questionUUID={question.uuid}
                  question={question}
                  answers={answers}
                  index={index}
                />
              ))}

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderTop: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">Manual adjustment</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <ToggleButtonGroup
                      value={manualAdjustment}
                      exclusive
                      color={manualAdjustment ? "success" : "error"}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setManualAdjustment(value);
                        }
                      }}
                      aria-label={`Manual adjustment`}
                    >
                      <ToggleButton value={true} aria-label="left aligned">
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false} aria-label="centered">
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>

                  <Collapse in={manualAdjustment} exit={!manualAdjustment}>
                    <TextField
                      label="Manual points"
                      variant="outlined"
                      value={manualPoints}
                      onChange={(e) => setManualPoints(e.target.value)}
                    />
                  </Collapse>
                </Box>
              </Box>

              <Collapse in={manualAdjustment} exit={!manualAdjustment}>
                <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle2">
                      What is the reason for the manual adjustment?
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <ReactQuill
                      theme="snow"
                      value={manualAdjustmentNote}
                      onChange={setManualAdjustmentNote}
                      style={{ minHeight: "300px" }}
                    />
                  </Box>
                </Box>
              </Collapse>

              <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Assessment note</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <ReactQuill
                    theme="snow"
                    value={assessmentNote}
                    onChange={setAssessmentNote}
                    style={{ minHeight: "300px" }}
                  />
                </Box>
              </Box>

              <Box sx={{ px: 2, py: 1, borderTop: 1, borderColor: "divider" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleRiskAssessment(e);
                    }}
                  >
                    Complete assessment
                  </LoadingButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </Box>
  );
};

export default RiskAssessment;
