import { Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const imageFileTypes = ["png", "jpg", "jpeg", "gif", "bmp", "svg", "jpe"];

const downloadFile = (fileUrl) => {
  if (!fileUrl) return;
  const fileName = fileUrl?.split("/")?.pop() || "file";
  // Create an anchor element
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.download = fileName; // Optional: Specify a default file name for downloading

  // Append the anchor to the body, click it, and then remove it
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

function getFileExtension(url) {
  try {
    if (!url) return "";

    // Parse the URL to get the pathname
    const pathname = new URL(url)?.pathname;

    // Use a regular expression to extract the file extension
    const match = pathname?.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

    // Return the file extension if found, otherwise return an empty string
    return match ? match[1] : "";
  } catch (error) {
    console.error("Error while getting file extension");
    return "";
  }
}

export function FileViewer({ fileUrl }) {
  const fileType = getFileExtension(fileUrl);
  if (fileType === "pdf") {
    return (
      <Stack direction="column" sx={{ width: "50vw", height: "100%", padding: 2 }}>
        <Button onClick={() => downloadFile(fileUrl)} sx={{ m: 2 }} variant="contained">
          Download
        </Button>
        <iframe
          src={fileUrl}
          width="100%"
          title="file"
          style={{
            border: "none",
            outline: "none",
            height: "calc(100% - 100px)",
          }}
        />
      </Stack>
    );
  }
  if (imageFileTypes?.includes(fileType)) {
    return (
      <Stack direction="column" sx={{ width: "50vw", height: "100%", padding: 2 }}>
        <Button onClick={() => downloadFile(fileUrl)} sx={{ m: 2 }} variant="contained">
          Download
        </Button>
        <img
          src={fileUrl}
          alt="file"
          style={{
            border: "none",
            outline: "none",
            width: "100%",
            objectFit: "contain",
            height: "calc(100% - 100px)",
          }}
        />
      </Stack>
    );
  } else {
    return (
      <Stack direction="column" spacing={2} sx={{ width: "50vw", height: "100%", padding: 2 }}>
        <Typography variant="h6">Please Download the file to see the content</Typography>
        <Button onClick={() => downloadFile(fileUrl)} sx={{ m: 2 }} variant="contained">
          Download
        </Button>
      </Stack>
    );
  }
}

FileViewer.propTypes = {
  fileUrl: PropTypes.string,
};
