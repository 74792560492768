import { React, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";

function BusinessDescription({
  entityData,
  setManageProfile,
  getEntityData,
  getProfileData,
  activitiesData,
  getActivitiesData,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);
  const [businessDescription, setBusinessDescription] = useState(
    activitiesData?.business_description
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/business-description`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          description: businessDescription,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getEntityData();
      getProfileData();
      getActivitiesData();

      displayToast("Business description updated successfully", () => {
        setProcessing(false);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <Box sx={{ border: 1, borderColor: "divider" }}>
        <ReactQuill
          theme="snow"
          value={businessDescription}
          onChange={setBusinessDescription}
          style={{ minHeight: "300px" }}
        />
      </Box>

      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <LoadingButton
          disableElevation
          loading={processing}
          onClick={(e) => {
            handleUpdate(e);
          }}
          variant="contained"
          color="success"
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default BusinessDescription;
