import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DataTable from "react-data-table-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import Cookies from "universal-cookie";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  List,
  ListItem,
  Drawer,
} from "@mui/material";
import { Close, Delete, Edit, PermMedia, UploadFile, Visibility } from "@mui/icons-material";
import SelfieUpload from "./components/proof-of-life/SelfieUpload";
import VideoUpload from "./components/proof-of-life/VideoUpload";
import Attestation from "./components/proof-of-life/Attestation";

import { LoadingButton } from "@mui/lab";
import VideoCapture from "./components/proof-of-life/VideoCapture";

const ProofOfLife = ({ partnerUser, partnerData, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const partnerURL = () => {
    const cookies = new Cookies();
    return "https://" + cookies.get("platformURL");
  };

  const partnerAPI = partnerURL();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [polData, setPolData] = useState({});

  const [captureMode, setCaptureMode] = useState(false);

  const [selfieFile, setSelfieFile] = useState(null);
  const [videoUploadFile, setVideoUploadFile] = useState(null);

  const [videoCapture, setVideoCapture] = useState(false);

  const [attestationDialog, setAttestationDialog] = useState(false);

  const [manageProofOfLife, setManageProofOfLife] = useState(false);

  const [manageVideoReview, setManageVideoReview] = useState(false);
  const [reviewDecision, setReviewDecision] = useState(0);
  const [reviewNote, setReviewNote] = useState("");

  const [videoURL, setVideoURL] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getProofOfLifeData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/proof-of-life`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      setPolData(response.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getEvidenceFile = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/proof-of-life/file`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          proof_of_life_uuid: polData.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      setVideoURL(response.data.file_url);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleVideoReview = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/proof-of-life/review`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          proof_of_life_uuid: polData.uuid,
          decision: parseInt(reviewDecision),
          note: reviewNote,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getProofOfLifeData();

      displayToast("Proof of life review submitted successfully", () => {
        setProcessing(false);
        setManageVideoReview(false);
        if (parseInt(reviewDecision) == 2) {
          setManageProofOfLife(false);
        }
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAttestation = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/proof-of-life/attestation`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getProofOfLifeData();
      setAttestationDialog(false);

      displayToast("Attestation submitted successfully", () => {
        setProcessing(false);
        setCaptureMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const processSelfieUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", selfieFile);
    formData.append("fileName", selfieFile.name);
    formData.append("person_uuid", entityData.value.person_uuid);

    try {
      const response = await partner.post(
        "/v1/entity/people/person/proof-of-life/selfie-upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          onUploadProgress: function (progressEvent) {},

          //withCredentials: true,
        }
      );

      getProofOfLifeData();

      displayToast("Selfie uploaded successfully", () => {
        setProcessing(false);
        setCaptureMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const processVideoUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", videoUploadFile);
    formData.append("fileName", videoUploadFile.name);
    formData.append("person_uuid", entityData.value.person_uuid);

    try {
      const response = await partner.post(
        "/v1/entity/people/person/proof-of-life/video-upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          onUploadProgress: function (progressEvent) {},

          //withCredentials: true,
        }
      );

      getProofOfLifeData();

      displayToast("Video uploaded successfully", () => {
        setProcessing(false);
        setCaptureMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getProofOfLifeData();
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box>
          {!captureMode ? (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={12}>
                  <Box
                    sx={{
                      borderRight: { xs: 0, xl: 0 },
                      borderColor: { xs: "divider", xl: "divider" },
                      borderBottom: 0,
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        px: 2,
                        py: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="h6">Proof of Life</Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        {polData.uuid == "" ? (
                          <IconButton onClick={() => setCaptureMode(true)}>
                            <UploadFile />
                          </IconButton>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Visibility />}
                            onClick={() => setManageProofOfLife(true)}
                          >
                            View file
                          </Button>
                        )}
                      </Box>
                    </Box>
                    {polData.uuid != "" && (
                      <>
                        <Box
                          sx={{
                            pr: 2,
                            pl: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Proof of life status</Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {caseData.value.options.proof_of_life && polData.uuid == "" && (
                              <Chip
                                className="square-chip"
                                label="Upload Required"
                                size="small"
                                sx={{
                                  backgroundColor: "#1C2321",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}

                            {!caseData.value.options.proof_of_life && polData.uuid == "" && (
                              <Chip
                                className="square-chip"
                                label="Optional"
                                size="small"
                                sx={{
                                  backgroundColor: "#0b0b0b",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}

                            {polData.uuid !== "" && (
                              <Chip
                                className="square-chip"
                                label="Complete"
                                size="small"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            pr: 2,
                            pl: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Proof of life type</Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {polData.pol_type == 0 && (
                              <Typography variant="subtitle2">-</Typography>
                            )}
                            {polData.pol_type == 1 && (
                              <Typography variant="subtitle2">Video capture</Typography>
                            )}
                            {polData.pol_type == 2 && (
                              <Typography variant="subtitle2">Video upload</Typography>
                            )}
                            {polData.pol_type == 3 && (
                              <Typography variant="subtitle2">Selfie</Typography>
                            )}
                            {polData.pol_type == 4 && (
                              <Typography variant="subtitle2">Attestation</Typography>
                            )}
                          </Box>
                        </Box>

                        {polData.uploaded && (
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              py: 1,
                              px: 2,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2">Upload date</Typography>
                            <Typography variant="subtitle2">
                              {polData?.upload_timestamp > 0
                                ? moment.unix(polData?.upload_timestamp).format("DD/MM/YYYY HH:mm")
                                : "-"}
                            </Typography>
                          </Box>
                        )}

                        {polData.pol_type == 1 && polData.uploaded && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">IP address</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {polData.upload_ip_address ? polData.upload_ip_address : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {polData.pol_type == 1 && polData.uploaded && (
                          <Box
                            sx={{
                              pr: 2,
                              pl: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">IP country</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: 1,
                              }}
                            >
                              <Typography variant="subtitle2">
                                {polData.upload_ip_country?.code
                                  ? polData.upload_ip_country.name
                                  : "-"}
                              </Typography>

                              {polData.upload_ip_country?.high_risk && (
                                <Chip
                                  className="square-chip"
                                  label="High risk"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#C83E4D",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {polData.upload_ip_country?.conflict && (
                                <Chip
                                  className="square-chip"
                                  label="Conflict"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#FF4A1C",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {polData.upload_ip_country?.borders_conflict && (
                                <Chip
                                  className="square-chip"
                                  label="Borders conflict"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#977390",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {polData.upload_ip_country?.is_secrecy && (
                                <Chip
                                  className="square-chip"
                                  label="Secrecy"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#999999",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        )}

                        {polData.pol_type == 4 && (
                          <Box
                            sx={{
                              px: 2,
                              pl: 2,
                              py: 1,
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body2">Attestation completed by</Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "end",
                                gap: 1,
                              }}
                            >
                              <Typography variant="subtitle2">
                                {polData.uploaded_by_name}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </>
                    )}

                    <Box
                      sx={{
                        pr: 2,
                        pl: 2,
                        py: 1,
                        borderBottom: 0,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Validation status</Typography>
                      </Box>

                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        {polData?.uuid == "" && !caseData.value.options.proof_of_life && (
                          <Typography variant="subtitle2">-</Typography>
                        )}

                        {polData?.video_analysis_required && !polData?.video_analysis_complete && (
                          <Chip
                            className="square-chip"
                            label="Validating"
                            size="small"
                            sx={{
                              backgroundColor: "#1C2321",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                        {polData?.manual_review && (
                          <Chip
                            className="square-chip"
                            label="Manual review"
                            size="small"
                            sx={{
                              backgroundColor: "#FCCA46",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                        {polData?.video_analysis_complete &&
                          polData?.video_analysis_result == 1 && (
                            <Chip
                              className="square-chip"
                              label="Verified"
                              size="small"
                              sx={{
                                backgroundColor: "#8BBF9F",
                                color: "#1C2321",
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                        {polData?.review_result == 1 && (
                          <Chip
                            className="square-chip"
                            label="Verified"
                            size="small"
                            sx={{
                              backgroundColor: "#8BBF9F",
                              color: "#1C2321",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                        {polData?.review_result == 2 && (
                          <Chip
                            className="square-chip"
                            label="Rejected"
                            size="small"
                            sx={{
                              backgroundColor: "#C83E4D",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        )}

                        {polData.uuid == "" && caseData.value.options.proof_of_life && (
                          <Chip
                            className="square-chip"
                            label="Upload Required"
                            size="small"
                            sx={{
                              backgroundColor: "#1C2321",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        )}

                        {polData.pol_type > 1 && !polData.video_analysis_required && (
                          <Chip
                            className="square-chip"
                            label="N/A"
                            size="small"
                            sx={{
                              backgroundColor: "#1C2321",
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Drawer
                anchor="right"
                open={manageProofOfLife}
                onClose={() => setManageProofOfLife(false)}
              >
                <Box sx={{ width: { xs: "90vw", lg: "75vw", xl: "50vw" } }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Proof of Life Details</Typography>
                    </Box>

                    <Box sx={{}}>
                      <IconButton title="Close" onClick={() => setManageProofOfLife(false)}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        backgroundColor: "#0b0b0b",
                        color: "#ffffff",
                        py: 1,
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="subtitle2">Evidence information</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        border: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          py: 1,
                          px: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2">Proof of life type</Typography>

                        {polData.pol_type == 0 && <Typography variant="subtitle2">-</Typography>}
                        {polData.pol_type == 1 && (
                          <Typography variant="subtitle2">Video capture</Typography>
                        )}
                        {polData.pol_type == 2 && (
                          <Typography variant="subtitle2">Video upload</Typography>
                        )}
                        {polData.pol_type == 3 && (
                          <Typography variant="subtitle2">Selfie</Typography>
                        )}
                        {polData.pol_type == 4 && (
                          <Typography variant="subtitle2">Attestation</Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          borderBottom: 0,
                          borderColor: "divider",
                          py: 1,
                          px: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2">Upload date</Typography>
                        <Typography variant="subtitle2">
                          {polData?.upload_timestamp > 0
                            ? moment.unix(polData?.upload_timestamp).format("DD/MM/YYYY HH:mm")
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2, border: 1, borderColor: "divider" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 2,
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          py: 1,
                          px: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">Upload information</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        ></Box>
                      </Box>
                      <Box
                        sx={{
                          pr: 2,
                          pl: 1,
                          py: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">Upload status</Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">
                            {polData?.uploaded ? "Uploaded" : "-"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          pr: 2,
                          pl: 1,
                          py: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">Upload source</Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">
                            {polData?.uploaded_by_type == 1 ? polData.uploaded_by_name : "Client"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          pr: 2,
                          pl: 1,
                          py: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">Upload IP address</Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            {polData?.upload_ip_address ? polData.upload_ip_address : "-"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          pr: 2,
                          pl: 1,
                          py: 1,
                          borderBottom: 0,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">Upload IP country</Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            {polData?.upload_ip_country?.code
                              ? polData.upload_ip_country?.name
                              : "-"}
                          </Typography>
                          {polData?.upload_ip_country?.high_risk && (
                            <Chip
                              className="square-chip"
                              label="High risk"
                              size="small"
                              sx={{
                                backgroundColor: "#C83E4D",
                                color: "#ffffff",
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                          {polData?.upload_ip_country?.conflict && (
                            <Chip
                              className="square-chip"
                              label="Conflict"
                              size="small"
                              sx={{
                                backgroundColor: "#FF4A1C",
                                color: "#1C2321",
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                          {polData?.upload_ip_country?.borders_conflict && (
                            <Chip
                              className="square-chip"
                              label="Borders conflict"
                              size="small"
                              sx={{
                                backgroundColor: "#977390",
                                color: "#1C2321",
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                          {polData?.upload_ip_country?.is_secrecy && (
                            <Chip
                              className="square-chip"
                              label="Secrecy"
                              size="small"
                              sx={{
                                backgroundColor: "#999999",
                                color: "#1C2321",
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2, border: 1, borderColor: "divider" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 2,
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                          py: 1,
                          px: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">File analysis</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        ></Box>
                      </Box>

                      <Box
                        sx={{
                          pr: 2,
                          pl: 1,
                          py: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">
                            {polData.pol_type < 3
                              ? "Video file analysis"
                              : polData.pol_type == 3
                              ? "Image file analysis"
                              : "-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {polData.upload_timestamp > 0 ? (
                            polData.pol_type < 4 ? (
                              polData.video_analysis_required ? (
                                polData?.video_analysis_submitted ? (
                                  polData?.video_analysis_complete ? (
                                    polData?.video_analysis_result == 1 ? (
                                      <Chip
                                        className="square-chip"
                                        label="Pass"
                                        size="small"
                                        sx={{
                                          backgroundColor: "#8BBF9F",
                                          color: "#1C2321",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    ) : (
                                      <Typography variant="subtitle2" sx={{ color: "#C83E4D" }}>
                                        Failed
                                      </Typography>
                                    )
                                  ) : (
                                    <Typography variant="subtitle2">Processing</Typography>
                                  )
                                ) : (
                                  <Typography variant="subtitle2">Pending</Typography>
                                )
                              ) : (
                                <Typography variant="subtitle2">N/A</Typography>
                              )
                            ) : (
                              <Typography variant="subtitle2">-</Typography>
                            )
                          ) : (
                            <Typography variant="subtitle2">-</Typography>
                          )}
                        </Box>
                      </Box>

                      {polData?.video_analysis_result == 2 && (
                        <Box
                          sx={{
                            pr: 2,
                            pl: 1,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">
                              Analysis failure reason
                              {polData?.video_analysis_failure_reason.length > 1 && "s"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            {polData.video_analysis_failure_reason?.length > 0 ? (
                              polData.video_analysis_failure_reason.map((reason, index) => (
                                <Typography key={index} variant="subtitle2">
                                  {reason.reason}
                                </Typography>
                              ))
                            ) : (
                              <Typography variant="subtitle2">-</Typography>
                            )}
                          </Box>
                        </Box>
                      )}

                      {polData?.review_result > 0 && (
                        <Box
                          sx={{
                            pr: 2,
                            pl: 1,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Review details</Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {polData?.review_actor ? polData?.review_actor : "-"}
                            </Typography>
                            <Typography variant="subtitle2">
                              {moment.unix(polData?.review_timestamp).format("DD/MM/YYYY HH:mm")}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                      {polData?.review_result > 0 && (
                        <Box
                          sx={{
                            pr: 2,
                            pl: 1,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Review decision</Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            {polData?.review_result == 1 && (
                              <Chip
                                className="square-chip"
                                label="Approved"
                                size="small"
                                sx={{
                                  backgroundColor: "#8BBF9F",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {polData?.review_result == 2 && (
                              <Chip
                                className="square-chip"
                                label="Rejected"
                                size="small"
                                sx={{
                                  backgroundColor: "#C83E4D",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      )}

                      {polData?.review_result > 0 && (
                        <Box
                          sx={{
                            pr: 2,
                            pl: 1,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Review note</Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              justifyContent: "center",
                            }}
                          >
                            {parse(polData?.review_reason)}
                          </Box>
                        </Box>
                      )}

                      {polData.pol_type == 4 && (
                        <Box
                          sx={{
                            px: 2,
                            pl: 1,
                            py: 1,
                            borderBottom: 0,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Attestation completed by</Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "end",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2">{polData.uploaded_by_name}</Typography>
                          </Box>
                        </Box>
                      )}

                      {polData?.pol_type != 4 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            p: 2,
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <LoadingButton
                              disableElevation
                              onClick={() => {
                                getEvidenceFile();
                              }}
                              loading={processing}
                              variant="contained"
                              color="primary"
                            >
                              Load {polData?.pol_type < 3 ? "video" : "file"}
                            </LoadingButton>
                          </Box>
                          <Box>
                            {polData?.manual_review && (
                              <Button
                                variant="contained"
                                color={manageVideoReview ? "primary" : "warning"}
                                onClick={() => {
                                  setManageVideoReview(!manageVideoReview);
                                }}
                              >
                                {manageVideoReview ? "Close" : "Review Decision"}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      )}

                      <Collapse in={manageVideoReview} exit={!manageVideoReview}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderTop: 1,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle2">Review decision</Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                              <ToggleButtonGroup
                                color={reviewDecision == 1 ? "success" : "error"}
                                value={reviewDecision}
                                exclusive
                                onChange={(e, value) => {
                                  if (value !== null) setReviewDecision(value);
                                }}
                              >
                                <ToggleButton value={1}>Approve</ToggleButton>
                                <ToggleButton value={2}>Reject</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                            <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                              <Typography variant="subtitle2">
                                Please provide details surrounding your decision
                              </Typography>
                            </Box>
                            <ReactQuill
                              theme="snow"
                              value={reviewNote}
                              onChange={setReviewNote}
                              style={{ minHeight: "300px" }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              p: 2,
                              borderTop: 1,
                              borderColor: "divider",
                            }}
                          >
                            <LoadingButton
                              disableElevation
                              variant="contained"
                              color="success"
                              onClick={(e) => {
                                handleVideoReview(e);
                              }}
                              loading={processing}
                              disabled={reviewDecision == 0}
                            >
                              Submit decision
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Collapse>

                      <Collapse in={videoURL != ""} exit={videoURL == ""}>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: 2,
                              p: 2,
                              borderBottom: 1,
                              borderTop: 1,
                              borderColor: "divider",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {polData.pol_type == 1
                                  ? "Video recording"
                                  : polData.pol_type == 2
                                  ? "Video file"
                                  : "Selfie image"}
                              </Typography>
                            </Box>
                            <IconButton
                              title="Close"
                              onClick={() => {
                                setVideoURL("");
                              }}
                            >
                              <Close />
                            </IconButton>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            {polData?.pol_type < 3 && (
                              <video width="100%" controls src={`${videoURL}`} />
                            )}
                            {polData?.pol_type == 3 && <img width="100%" src={`${videoURL}`} />}
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  </Box>
                </Box>
              </Drawer>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} xl={12}>
                <Box
                  sx={{
                    borderRight: { xs: 0, xl: 0 },
                    borderColor: { xs: "divider", xl: "divider" },
                    borderBottom: 0,
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Proof of Life</Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <IconButton onClick={() => setCaptureMode(false)}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                    <Typography variant="subtitle2">
                      How would you like to capture the proof of life?
                    </Typography>

                    <ToggleButtonGroup
                      value={captureMode}
                      color="primary"
                      exclusive
                      onChange={(event, newCaptureMode) => {
                        if (newCaptureMode !== null) {
                          setCaptureMode(newCaptureMode);
                        }
                      }}
                      aria-label="capture mode"
                    >
                      <ToggleButton value={1}>Video capture</ToggleButton>
                      <ToggleButton value={2}>Video upload</ToggleButton>
                      <ToggleButton value={3}>Selfie upload</ToggleButton>
                      <ToggleButton value={4}>Attestation</ToggleButton>
                    </ToggleButtonGroup>

                    {captureMode === 1 && (
                      <>
                        {videoCapture ? (
                          <VideoCapture
                            setVideoCapture={setVideoCapture}
                            entityData={entityData}
                            getProofOfLifeData={() => getProofOfLifeData()}
                            setCaptureMode={setCaptureMode}
                          />
                        ) : (
                          <Box>
                            <Box sx={{ border: 1, borderColor: "divider" }}>
                              <Box
                                sx={{
                                  p: 2,
                                  borderBottom: 1,
                                  borderColor: "divider",
                                  backgroundColor: "#0b0b0b",
                                  color: "#ffffff",
                                }}
                              >
                                <Typography variant="subtitle2">
                                  Capture the video proof of life
                                </Typography>
                              </Box>
                              <Box sx={{ p: 2, pb: 0 }}>
                                <Typography variant="body2" sx={{ fontSize: "13px", m: 0 }}>
                                  To verify the client's identity and well-being, you have two
                                  options:
                                </Typography>

                                <List sx={{ listStyleType: "disc", pl: 4 }}>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Box>
                                      <Typography variant="body2">
                                        <b>Record a Proof of Life Video (Client Present)</b>
                                      </Typography>
                                      <List sx={{ listStyleType: "disc", pl: 2 }}>
                                        <ListItem sx={{ display: "list-item" }}>
                                          <Box>
                                            <Typography variant="body2">
                                              If the client is currently with you, you can directly
                                              record a proof of life video. This option ensures
                                              immediate and accurate verification.
                                            </Typography>
                                          </Box>
                                        </ListItem>
                                        <ListItem sx={{ display: "list-item" }}>
                                          <Box>
                                            <Typography variant="body2">
                                              Click "Record Video" to start the recording process.
                                              Follow the on-screen instructions to capture a clear
                                              video of the client.
                                            </Typography>
                                          </Box>
                                        </ListItem>
                                      </List>
                                    </Box>
                                  </ListItem>
                                  <ListItem sx={{ display: "list-item" }}>
                                    <Box>
                                      <Typography variant="body2">
                                        <b>Send an Invitation to the Client</b>
                                      </Typography>
                                      <List sx={{ listStyleType: "disc", pl: 2 }}>
                                        <ListItem sx={{ display: "list-item" }}>
                                          <Box>
                                            <Typography variant="body2">
                                              If the client is not present with you, you can invite
                                              them to complete the proof of life verification on
                                              their own.
                                            </Typography>
                                          </Box>
                                        </ListItem>
                                        <ListItem sx={{ display: "list-item" }}>
                                          <Box>
                                            <Typography variant="body2">
                                              Click "Send Invite" to send the client a secure link.
                                              They will receive instructions on how to record and
                                              upload their proof-of-life video.
                                            </Typography>
                                          </Box>
                                        </ListItem>
                                      </List>
                                    </Box>
                                  </ListItem>
                                </List>
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Button variant="contained" color="primary" onClick={() => {}}>
                                  Send Invite
                                </Button>

                                <Button
                                  onClick={(e) => {
                                    setVideoCapture(true);
                                  }}
                                  variant="contained"
                                  color="success"
                                >
                                  Record Video
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                    {captureMode === 2 && (
                      <Box>
                        <VideoUpload setFile={setVideoUploadFile} />

                        {videoUploadFile && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 2,

                              border: 1,
                              borderTop: 0,
                              borderColor: "divider",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box sx={{ display: "flex" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 2,
                                      fontWeight: 600,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        pl: 2,
                                      }}
                                    >
                                      <PermMedia />
                                    </Box>
                                    <Box
                                      sx={{
                                        borderRight: 1,
                                        borderLeft: 1,
                                        borderColor: "divider",
                                        p: 1,
                                      }}
                                    >
                                      <Typography variant="">Filename</Typography>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    fontSize: 13,
                                    pl: 2,
                                  }}
                                >
                                  {videoUploadFile.path}
                                </Box>
                              </Box>

                              <Box sx={{ pr: 1 }}>
                                <IconButton
                                  title="Remove file"
                                  onClick={() => {
                                    setVideoUploadFile(null);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              processVideoUpload(e);
                            }}
                            variant="contained"
                            color="success"
                          >
                            Upload
                          </LoadingButton>
                        </Box>
                      </Box>
                    )}
                    {captureMode === 3 && (
                      <Box>
                        <SelfieUpload setFile={setSelfieFile} />

                        {selfieFile && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 2,

                              border: 1,
                              borderTop: 0,
                              borderColor: "divider",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box sx={{ display: "flex" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 2,
                                      fontWeight: 600,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        pl: 2,
                                      }}
                                    >
                                      <PermMedia />
                                    </Box>
                                    <Box
                                      sx={{
                                        borderRight: 1,
                                        borderLeft: 1,
                                        borderColor: "divider",
                                        p: 1,
                                      }}
                                    >
                                      <Typography variant="">Filename</Typography>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    fontSize: 13,
                                    pl: 2,
                                  }}
                                >
                                  {selfieFile.path}
                                </Box>
                              </Box>

                              <Box sx={{ pr: 1 }}>
                                <IconButton
                                  title="Remove file"
                                  onClick={() => {
                                    setSelfieFile(null);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              processSelfieUpload(e);
                            }}
                            variant="contained"
                            color="success"
                          >
                            Upload
                          </LoadingButton>
                        </Box>
                      </Box>
                    )}
                    {captureMode === 4 && (
                      <Box>
                        <Attestation partnerUser={partnerUser} entityData={entityData} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              setAttestationDialog(true);
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Continue
                          </LoadingButton>
                        </Box>
                        <Dialog open={attestationDialog} onClose={() => {}}>
                          <DialogTitle>Attestation</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              I, {partnerUser.value.name}, hereby attest that the client,{" "}
                              {entityData.value.title} {entityData.value.first_name}{" "}
                              {entityData.value.middle_names && entityData.value.middle_names}{" "}
                              {entityData.value.last_name} is alive and well and I can also confirm
                              that I have visually confirmed they are the person they claim to be. I
                              understand that providing false information may have legal
                              consequences.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setAttestationDialog(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <LoadingButton
                              disableElevation
                              loading={processing}
                              onClick={(e) => {
                                handleAttestation(e);
                              }}
                              variant="contained"
                              color="success"
                            >
                              Submit Attestation
                            </LoadingButton>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default ProofOfLife;
