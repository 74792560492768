import { React, useEffect, useState } from "react";

import partner from "../../../../../../api/partner";
import authHeader from "../../../../../../api/authHeader";

import HandleError from "../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../../components/lotties/loading-dots.json";

import { Typography, Box, Divider, Fade } from "@mui/material";
import { Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Overview = ({
  partner,
  partnerUser,
  partnerData,
  profileUUID,
  profileData,
  getProfile,
  getProfiles,
  setManageProfile,
}) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [lowAge, setLowAge] = useState("");
  const [highAge, setHighAge] = useState("");
  const [lowActive, setLowActive] = useState(false);
  const [highActive, setHighActive] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [profileName, setProfileName] = useState(profileData.profile_name);
  const [isActive, setIsActive] = useState(profileData.active);

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/profile/update`,
        JSON.stringify({
          profile_name: profileName,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfile();

      displayToast("Settings updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/profile/${isActive ? "deactivate" : "activate"}`,
        JSON.stringify({
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfiles();
      getProfile();

      displayToast(`Profile ${isActive ? "deactivated" : "activated"} successfully`, () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      getProfile();
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteProfile = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/profile/delete`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          profile_uuid: profileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getProfiles();

      displayToast("Profile deleted successfully", () => {
        setProcessing(false);
        setManageProfile(false);
        setActiveProfile(null);
      });
    } catch (err) {
      console.log(err);
      setProcessing(false);
      handleError(err);
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Overview</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />

            <Box sx={{ p: 2 }}>
              <TextField
                label="Profile Name"
                variant="outlined"
                fullWidth
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Box>

            <Box sx={{ pb: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ px: 2, display: "flex", gap: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Profile status</Typography>
                  </Box>
                  <ToggleButtonGroup
                    value={isActive}
                    exclusive
                    color={isActive ? "success" : "danger"}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setIsActive(value);
                        handleStatusUpdate(e);
                      }
                    }}
                  >
                    <ToggleButton value={true} sx={{}}>
                      Active
                    </ToggleButton>
                    <ToggleButton value={false} sx={{}}>
                      Inactive
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {!isActive && (
                  <Box sx={{ px: 2 }}>
                    <LoadingButton
                      disableElevation
                      variant="outlined"
                      color="danger"
                      loading={processing}
                      onClick={(e) => {
                        handleDeleteProfile(e);
                      }}
                    >
                      Delete profile
                    </LoadingButton>
                  </Box>
                )}
              </Box>

              <Box sx={{ px: 2 }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  color="success"
                  loading={processing}
                  onClick={(e) => {
                    handleSaveSettings(e);
                  }}
                >
                  Save changes
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Overview;
